import { useState, useEffect } from "react";
import Api from "utils/api";

const useHeaders = (jobId) => {
  const [headerColumns, setHeaderColumns] = useState([]);

  useEffect(() => {
    Api.IMPORT.IMPORT_JOB_ID.get({
      urlArgs: { job_id: jobId },
    }).then((response) => {
      setHeaderColumns(response.data.header_columns);
    });
  }, [jobId]);

  return { headerColumns };
};

export default useHeaders;
