import styled from "styled-components";
import {
  EmptyStateMessage,
  Icon,
  IconButton,
  SearchBox,
} from "@evertrue/et-components";
import { colors } from "style/colors";
import { PlainButton } from "style/components/index";
import DNDMultiColumnController from "components/dnd/dnd-multi-column-controller";

export const AddAllButton = styled(PlainButton)`
  color: ${colors.linkText};
`;

export const AvailableSearch = styled(SearchBox)`
  margin-top: 20px;
  flex-basis: 100%;
`;

export const RemoveAllButton = styled(PlainButton)`
  color: ${colors.errorDark};
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const DLItemLabel = styled.span`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const SecondaryLabel = styled.div`
  color: ${colors.lightText};
  margin-left: 8px;
  padding: 3px 4px 1px 3px;
  font-size: 10px;
  text-transform: uppercase;
  border: 1px solid ${colors.lightText};
`;

export const DNDListItem = styled.div`
  display: flex;
  position: relative;
  border: solid 1px ${colors.border};
  margin-bottom: 4px;
  background: ${colors.white};
  padding: 10px;
  cursor: move;

  .gt-icon-menu {
    color: ${colors.lightestText};
    margin-right: 12px;
  }
  i {
    font-size: 1.3em;
  }
`;

export const DNDWrapper = styled(DNDMultiColumnController)`
  display: flex;
`;

export const EditorMain = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const EditorInner = styled.div`
  padding: 2px 38px;
  overflow: auto;
`;

export const EditIcon = styled(IconButton)`
  float: right;
  margin-right: 8px;

  &:before {
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
  margin-top: auto;
  background: ${colors.white};
  border-top: 1px solid ${colors.border};
  min-height: 75px;
  min-height: 60px;

  .et--button {
    min-width: 129px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
`;

export const HelpText = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 8px;
  color: ${colors.darkerText};
`;

export const LIST_STYLES = `
  flex-basis: 50%;
  background-color: #f0f4f5;
  padding: 8px;
  margin: 8px;
  margin-top: 0;
  border: 1px solid ${colors.border};
  border-top: none;
  min-height: 40vh;
`;

export const Placeholder = styled.div`
  box-sizing: border-box;
  height: 38px;
  border: 1px dotted ${colors.border};
  padding: 10px;
`;

export const RightIcon = styled(Icon)`
  color: ${(props) => props.iconColor};
  margin-left: auto;
  cursor: pointer;
`;

export const Label = styled.span`
  color: ${colors.mainBase};
  font-weight: 600;
  margin-top: ${(props) => props.marginTop || "none"};
`;

export const StyledEmptyStateMessage = styled(EmptyStateMessage)`
  &.empty-state-message {
    max-width: 550px;
    margin: 70px auto;
  }

  .et--button {
    margin-top: 16px;
  }
`;

export const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-basis: 50%;
  flex-wrap: wrap;
  background-color: #f0f4f5;
  padding: 8px 8px 0 8px;
  margin: 0 8px 0 8px;
  border: 1px solid ${colors.border};
  border-bottom: none;
`;

export const Wrapper = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 30vh;
`;

export const TopRowWrapper = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  margin: 0 35px;
  justify-content: space-between;
`;
