import Api from "utils/api";
import { useEffect, useState, useCallback } from "react";
import Nango from "@nangohq/frontend";
import { STATE_ENUM } from "apps/integrations/crm/utils/constants";
import { launchToast } from "scripts/utils/show-toast";
import useIntegrations from "scripts/apps/auth/use-integrations";

export const PROVIDER_BB_CRM = {
  provider: "blackbaud-crm",
  displayName: "Blackbaud CRM",
}

export const PROVIDER_BB_RENXT = {
  provider: "blackbaud-renxt",
  displayName: "Blackbaud Raiser's Edge NXT",
}

export const buildNangoConfig = ({provider, displayName}) => {
  return {
    host: "https://nango.evertrue.com",
    displayName: displayName,
    provider: provider,
  };
};

export const useBlackBaudAuthFlow = (config) => {
  const [integration, setIntegration] = useState(null);
  const [nangoSession, setNangoSession] = useState(null);
  const [integrationState, setIntegrationState] = useState(STATE_ENUM.disabled);
  const { data: integrations, error, loading: integrationLoading, fetchAllIntegrations } = useIntegrations();
  const [loading, setLoading] = useState(true);

  const loadingSuperSet = integrationLoading || loading;

  const getNangoSession = async () => {
    try{
      const { data } = await Api.AUTH.NANGO_SESSION.post({
        data: {
          integrations: [config.provider],
        },
      });
      setNangoSession(data);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchAllIntegrations();
    getNangoSession();
  }, []);

  useEffect(() => {
    setLoading(false);

    if (integrations.length > 0) {
      const integration = integrations.find((integration) => integration.platform === "nango" && integration.authentication.integration_id === config.provider);
      if (integration) {
        setIntegration(integration);
        setIntegrationState(integration.state);
      }
    }
  }, [integrations, error]);

  const handleIntegrationClick = async () => {
    if (!nangoSession.data.token) {
      console.error("No integration token available");
      setIntegrationState(STATE_ENUM.disabled);
      return;
    }

    try {
      setIntegrationState(STATE_ENUM.pending);
      
      const nango = new Nango({
        host: config.host,
        connectSessionToken: nangoSession.data.token,
      });

      const result = await nango.auth(config.provider);
      const { data } = await Api.AUTH.INTEGRATIONS.post({
        data: {
          integration: {
            platform: "nango",
            state: "active",
            account_id: result.connectionId,
            display_name: config.displayName,
            authentication: {
              integration_id: config.provider,
            },
          },
        },
      });
      
      setIntegration(data);
      setIntegrationState(result.isPending ? STATE_ENUM.pending : STATE_ENUM.active);
    } catch (error) {
        launchToast({
            message: "Failed to connect to Blackbaud",
            type: "error",
          });
      console.error("Authentication failed:", error);
      setIntegrationState(STATE_ENUM.disabled);
    }
  };

  return {
    handleClick: handleIntegrationClick,
    integrationState,
    loading: loadingSuperSet,
  };
};

export default useBlackBaudAuthFlow;