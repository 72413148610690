import React, { useEffect, useState } from "react";
import { Button, Tooltip2 as Tooltip, Icon } from "@evertrue/et-components";
import {
  ToggleHeader,
  ToggleDescription,
  AlertDescription,
} from "scripts/apps/importer/common/importer-styled-components";
import {
  onboardVOIP,
  offboardVoip,
  getOrgVoipSettings,
} from "../utils/api-requests";
import PropTypes from "prop-types";
import ErrorLogger from "utils/error-logger";

const JourneyVoipButton = ({ oid, organization }) => {
  const [hasVoipEnabled, setHasVoipEnabled] = useState(false);
  const [voipSettings, setVoipSettings] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchVoip = async () => {
      try {
        setIsLoading(true);
        const orgVoipSettings = await getOrgVoipSettings();
        setVoipSettings(orgVoipSettings);
        setHasVoipEnabled(!!orgVoipSettings);
      } catch (error) {
        ErrorLogger.error(
          "There was an issue fetching the org VOIP status.",
          error
        );
      } finally {
        setIsLoading(false);
      }
    };
    fetchVoip();
  }, []);

  const handleClick = async () => {
    try {
      setIsLoading(true);
      if (hasVoipEnabled) {
        // offboard
        await offboardVoip();
        setVoipSettings(null);
        setHasVoipEnabled(false);
      } else {
        // onboard
        const orgVoipSettings = await onboardVOIP(oid, organization);
        setVoipSettings(orgVoipSettings);
        setHasVoipEnabled(!!orgVoipSettings);
      }
    } catch (error) {
      ErrorLogger.error(
        "There was an issue onboarding VOIP to this org.",
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formatAccountInfo = () => {
    if (!voipSettings) return {};
    const {
      friendly_name: name,
      owner_account_sid: accountSid,
      default_phone_number: number,
    } = voipSettings;
    return {
      "Org Name": name,
      SID: accountSid,
      "Default Number": number || "NOT SET",
    };
  };

  const buttonContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    marginTop: "5px",
  };
  return (
    <div>
      <div style={{ margin: "20px" }}>
        <ToggleHeader>
          <strong>VOIP Configuration</strong>
        </ToggleHeader>
        <ToggleDescription>
          Clicking the Onboard button will set up the Twiml app in the Twilio
          console for this organization.
        </ToggleDescription>
        <div style={buttonContainerStyle}>
          <Button disabled={isLoading} onClick={() => handleClick()}>
            {hasVoipEnabled ? "Offboard" : "Onboard"}
          </Button>
          <div>VoIP</div>
          {voipSettings && (
            <Tooltip
              contentWidth={350}
              trigger={
                <span>
                  <Icon icon="info" />
                </span>
              }
            >
              <div>
                {Object.entries(formatAccountInfo()).map(([key, value]) => (
                  <div key={key}>
                    <strong>{key}</strong>: {value}
                  </div>
                ))}
              </div>
            </Tooltip>
          )}
        </div>
        {hasVoipEnabled &&
          (!voipSettings || !voipSettings.default_phone_number) && (
            <AlertDescription>
              * A default phone number must be set on the Twilio dashboard
            </AlertDescription>
          )}
      </div>
    </div>
  );
};

JourneyVoipButton.propTypes = {
  oid: PropTypes.number,
  organization: PropTypes.string,
};

JourneyVoipButton.defaultProps = {
  oid: 0,
  organization: "",
};

export default JourneyVoipButton;
