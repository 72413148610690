import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import Api from "utils/api";
import styled from "styled-components";
import ModalEditor from "../../../components/modal-editor";

const secretValuePlaceholder = "******************";

const PlatformUpdateModal = ({
  account,
  refresh,
  onSuccess,
  onError,
  ...rest
}) => {
  const {display_name, account_id, invalidated_at} = account;
  const initialValues = {
    name: display_name,
    authentication: {
      api_key: secretValuePlaceholder,
      api_secret: secretValuePlaceholder,
    },
    account_id: account_id,
  };
  return (
    <ModalEditor
      {...rest}
      saveLabel="Save"
      isInitialValid={false}
      initialValues={initialValues}
      header="Update Account Credentials"
      validate={(value) => {
        if (!_.every(value, _.identity)) return { errors: true };
        return {};
      }}
      onSubmit={({name, account_id, authentication: {api_key, api_secret}}, dirty, touched) => {
        const data = {
          integration: {
            display_name: name,
            platform: "zoom_oauth",
            account_id: account_id,
          },
        };
        if (
          touched.api_key &&
          api_key !== secretValuePlaceholder
        ) {
          data.integration.authentication = {
            api_key,
          };
        }
        if (
          touched.api_secret &&
          api_secret !== secretValuePlaceholder
        ) {
          data.integration.authentication = {
            api_secret,
          };
        }
        Api.AUTH.INTEGRATIONS.put({
          urlExtend: `/${account.id}`,
          disableAlerts: true,
          data: data,
        })
          .then(onSuccess)
          .then(refresh)
          .catch(onError);
      }}
    >
      {invalidated_at && (
        <InfoMessage>
          Your Emma account credentials are no longer valid. Please
          re-authenticate with your API keys from Emma.
        </InfoMessage>
      )}
      <ModalEditor.FormField
        autoComplete="off"
        label="Platform Name"
        placeholder="What do you want to call this account in EverTrue?"
        name="name"
      />
      <ModalEditor.FormField
        type="password"
        autoComplete="off"
        label="API Key"
        name="authentication.api_key"
      />
      <ModalEditor.FormField
        type="password"
        autoComplete="off"
        label="API Secret"
        name="authentication.api_secret"
      />
      <ModalEditor.FormField
        type="text"
        autoComplete="off"
        label="Platform Account ID"
        placeholder="123456"
        name="account_id"
      />
      <input type="submit" style={{ display: "none" }} />
    </ModalEditor>
  );
};

PlatformUpdateModal.propTypes = {
  account: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
}

const InfoMessage = styled.div`
  background-color: #e2ebed;
  font-size: 14px;
  border-radius: 2px;
  padding: 8px;
  line-height: 1.5;
  white-space: pre-wrap;
  margin: 0px -10px 10px;
`;

export default PlatformUpdateModal;
