import React from "react";
import { ModalForm, Checkbox, Loading } from "@evertrue/et-components";
import _ from "underscore";
import T from "prop-types";
import Api from "utils/api";
import uid from "uuid/v4";
import styled from "styled-components";
import { FormWrapper } from "style/components/form";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .et--checkbox {
    padding: 4px 0;
  }
`;

const AddFromEsModal = (props) => {
  const [facets, setFacets] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  // load facets
  React.useEffect(() => {
    setLoading(true);
    const query = {
      must: [
        {
          parent_role: {
            match: "prospect",
          },
        },
        { pool_type: { match: props.groupType } },
      ],
      facet: [
        {
          field: "assignment_title",
        },
      ],
    };

    Api.SEARCH.ASSIGNMENTS.post({
      params: { limit: 0 },
      data: JSON.stringify(query),
    }).then((resp = {}) => {
      const resp_data = resp.data || {};
      const facets = resp_data.facets || {};
      const title_facets = facets["assignment_title"] || [];
      setFacets(title_facets);
      setLoading(false);
    });
  }, []);

  const handleSubmit = async () => {
    const selected = facets.filter((facet) => facet.checked);
    await Promise.all(
      selected.map((facet) =>
        props.handleCreate({
          active: false,
          sort_order: 0,
          title: facet.value,
        })
      )
    );
    props.whenClose();
  };

  const filtered_facets = facets.filter(props.filterFunc);
  const selected = filtered_facets.filter((facet) => facet.checked);
  return (
    <ModalForm
      onClose={props.onClose}
      onUnmount={props.onUnmount}
      disableFunc={() => !selected.length}
      header={props.header}
      handleSubmit={handleSubmit}
      width={500}
      render={() => {
        if (loading) return <Loading />;
        return (
          <FormWrapper>
            <Wrapper>
              {filtered_facets.length ? (
                filtered_facets.map((item) => (
                  <Checkbox
                    onChange={(checked) => {
                      setFacets((prev) => {
                        return prev.map((facet) =>
                          facet.value === item.value
                            ? { ...facet, checked }
                            : facet
                        );
                      });
                    }}
                    checked={item.checked}
                    key={uid()} // facets don't have ids
                  >
                    {item.value}
                  </Checkbox>
                ))
              ) : (
                <div>No items to add</div>
              )}
            </Wrapper>
          </FormWrapper>
        );
      }}
    />
  );
};

AddFromEsModal.propTypes = {
  whenClose: T.func,
  filterFunc: T.func,
  onClose: T.func,
  onUnmount: T.func,
  handleCreate: T.func.isRequired,
  header: T.string.isRequired,
  groupType: T.string,
};

AddFromEsModal.defaultProps = {
  whenClose: _.noop,
  filterFunc: () => true,
  onClose: _.noop,
  onUnmount: _.noop,
  groupType: "TEAM",
};

export default AddFromEsModal;
