import { useState, useEffect } from "react";

import useImportMappingTemplate from "apps/importer/hooks/use-import-mapping-template";

/**
 *  This is regenerative code
 *
 * If the field type is "field" or "list_field"
 *  OR we have arrived at the last item in the schema.
 *  OR the next field is NOT "field" or "list_field" THEN create "General Section".
 */
const createSectionsFromBaseFields = (schema) => {
  if (schema) {
    let previousIndex = 0;

    const isFieldType = (type) =>
      type.includes("field") || type.includes("list_field");

    return schema
      .map((value, index, array) => {
        const nextVal = array[index + 1] || { type: "none" };

        if (isFieldType(value.type) && !isFieldType(nextVal.type)) {
          const fieldSection = array.slice(previousIndex, index + 1);
          previousIndex = index + 1;
          return {
            type: "single_section",
            name: `general_section-${index}`,
            display_name: null,
            required: false,
            schema: fieldSection,
            section_id: previousIndex,
          };
        } else if (isFieldType(value.type)) {
          return null;
        } else {
          previousIndex = index + 1;
          return value;
        }
      })
      .filter((value) => value);
  }
};

export const useSchema = ({ jobId, viewOnly }) => {
  const [autoMap, setAutoMap] = useState(true);
  const [schema, setSchema] = useState([]);
  const [excludedImportColumns, setExcludedImportColumns] = useState([]);
  const [unmappedImportColumns, setUnmappedImportColumns] = useState([]);
  const [listFieldIds, setListFieldIds] = useState({});
  const [sectionIds, setSectionIds] = useState({});

  const { mappingData } = useImportMappingTemplate({ jobId, autoMap, viewOnly });
  const { display_name, footnotes } = mappingData;

  useEffect(() => {
    if (mappingData) {
      const { list_fields, sections } = mappingData.max_section_ids || {};
      const {
        schema: fetchedSchema,
        excluded_import_columns,
        unmapped_import_columns,
      } = mappingData;

      if (list_fields) {
        const listFields =
          Array.isArray(list_fields) &&
          list_fields.reduce((previousValue, currentValue) => {
            return {
              ...previousValue,
              [currentValue.section]: currentValue.id,
            };
          }, {});
        setListFieldIds(listFields);
      }

      if (sections) {
        const sectionIds =
          Array.isArray(sections) &&
          sections.reduce((previousValue, currentValue) => {
            return {
              ...previousValue,
              [currentValue.section]: currentValue.id,
            };
          }, {});
        setSectionIds(sectionIds);
      }
      const filteredSchema = createSectionsFromBaseFields(fetchedSchema);
      setSchema(filteredSchema);
      setExcludedImportColumns(excluded_import_columns);
      setUnmappedImportColumns(unmapped_import_columns);
    }
  }, [mappingData]);

  const removeFromList = (list, value) => {
    const indexPosition = list.findIndex((item) => item === value);
    return list.slice(0, indexPosition).concat(list.slice(indexPosition + 1));
  };

  const updateImportColumnValue = (
    baseSchema,
    sectionIndex,
    itemIndex,
    value
  ) => {
    const newItem = {
      ...baseSchema[sectionIndex].schema[itemIndex],
      import_column: value,
    };
    const newSection = baseSchema[sectionIndex].schema
      .slice(0, itemIndex)
      .concat(newItem)
      .concat(baseSchema[sectionIndex].schema.slice(itemIndex + 1));
    return baseSchema
      .slice(0, sectionIndex)
      .concat({ ...baseSchema[sectionIndex], schema: newSection })
      .concat(baseSchema.slice(sectionIndex + 1));
  };

  const onDragEndCallback = ({ source, destination, importColumn }) => {
    const destinationId =
      destination.id !== "unmapped-attributes" &&
      destination.id !== "explicit-skip-attributes"
        ? "default"
        : destination.id;
    const sourceId =
      source.id !== "unmapped-attributes" &&
      source.id !== "explicit-skip-attributes"
        ? "default"
        : source.id;

    const sourceToDestinationMap = {
      "unmapped-attributes": {
        "explicit-skip-attributes": "UNMAP_TO_EXCLUDE",
        default: "UNMAP_TO_MAP",
      },
      "explicit-skip-attributes": {
        "unmapped-attributes": "EXCLUDE_TO_UNMAP",
        default: "EXCLUDE_TO_MAP",
      },
      default: {
        "unmapped-attributes": "MAP_TO_UNMAP",
        "explicit-skip-attributes": "MAP_TO_EXCLUDE",
        default: "MAP_TO_MAP",
      },
    };

    switch (sourceToDestinationMap[sourceId][destinationId]) {
      case "UNMAP_TO_EXCLUDE": {
        setUnmappedImportColumns(
          removeFromList(unmappedImportColumns, importColumn)
        );
        setExcludedImportColumns([...excludedImportColumns, importColumn]);
        break;
      }
      case "UNMAP_TO_MAP": {
        const { sectionIndex, itemIndex } = destination.callbackArgs;
        setUnmappedImportColumns(
          removeFromList(unmappedImportColumns, importColumn)
        );
        setSchema(
          updateImportColumnValue(schema, sectionIndex, itemIndex, importColumn)
        );
        break;
      }
      case "EXCLUDE_TO_UNMAP": {
        setExcludedImportColumns(
          removeFromList(excludedImportColumns, importColumn)
        );
        setUnmappedImportColumns([...unmappedImportColumns, importColumn]);
        break;
      }
      case "EXCLUDE_TO_MAP": {
        const { sectionIndex, itemIndex } = destination.callbackArgs;
        setExcludedImportColumns(
          removeFromList(excludedImportColumns, importColumn)
        );
        setSchema(
          updateImportColumnValue(schema, sectionIndex, itemIndex, importColumn)
        );
        break;
      }
      case "MAP_TO_UNMAP": {
        const { sectionIndex, itemIndex } = source.callbackArgs;

        setUnmappedImportColumns([...unmappedImportColumns, importColumn]);
        setSchema(
          updateImportColumnValue(schema, sectionIndex, itemIndex, null)
        );
        break;
      }
      case "MAP_TO_EXCLUDE": {
        const { sectionIndex, itemIndex } = source.callbackArgs;

        setExcludedImportColumns([...excludedImportColumns, importColumn]);
        setSchema(
          updateImportColumnValue(schema, sectionIndex, itemIndex, null)
        );
        break;
      }
      case "MAP_TO_MAP": {
        const baseSchema = updateImportColumnValue(
          schema,
          source.callbackArgs.sectionIndex,
          source.callbackArgs.itemIndex,
          null
        );
        const remapSchema = updateImportColumnValue(
          baseSchema,
          destination.callbackArgs.sectionIndex,
          destination.callbackArgs.itemIndex,
          importColumn
        );
        setSchema(remapSchema);
        break;
      }
      default:
        break;
    }
  };

  const addLinkedField = ({ field, sectionIndex }) => {
    const { schema: baseSchema } = schema[sectionIndex];

    const lastIndex = baseSchema
      .map((element) => element.field_name)
      .lastIndexOf(field.field_name);

    const newField = {
      ...field,
      section_id: field.section_id,
      import_column: null,
    };

    const newFields = schema[sectionIndex].schema
      .slice(0, lastIndex + 1)
      .concat(newField)
      .concat(baseSchema.slice(lastIndex + 1));

    const newSection = { ...schema[sectionIndex], schema: [...newFields] };

    const newSchema = schema
      .slice(0, sectionIndex)
      .concat(newSection)
      .concat(schema.slice(sectionIndex + 1));

    setSchema(newSchema);
  };

  const removeLinkedField = ({ field, sectionIndex, itemIndex }) => {
    if (Number.isInteger(itemIndex)) {
      if (field.import_column) {
        setUnmappedImportColumns([
          ...unmappedImportColumns,
          field.import_column,
        ]);
      }

      const { schema: baseSchema } = schema[sectionIndex];

      const removeFieldSchema = baseSchema
        .slice(0, itemIndex)
        .concat(baseSchema.slice(itemIndex + 1));
      const updateSchema = schema
        .slice(0, sectionIndex)
        .concat({ ...schema[sectionIndex], schema: removeFieldSchema })
        .concat(schema.slice(sectionIndex + 1));

      setSchema(updateSchema);
    }
  };

  const addField = ({ field, sectionIndex }) => {
    const maxFieldId = listFieldIds[field.field_name] || 1;
    const { schema: baseSchema } = schema[sectionIndex];

    const lastIndex = baseSchema
      .map((element) => element.field_name)
      .lastIndexOf(field.field_name);

    const newField = {
      ...field,
      section_id: maxFieldId + 1,
      import_column: null,
    };

    const newFields = schema[sectionIndex].schema
      .slice(0, lastIndex + 1)
      .concat(newField)
      .concat(baseSchema.slice(lastIndex + 1));

    const newSection = { ...schema[sectionIndex], schema: [...newFields] };

    const newSchema = schema
      .slice(0, sectionIndex)
      .concat(newSection)
      .concat(schema.slice(sectionIndex + 1));

    setListFieldIds({ ...listFieldIds, [field.field_name]: maxFieldId + 1 });
    setSchema(newSchema);
  };

  /**
   * This logic is not DRY and requires some assembly.
   *  1. Remove the field that was deleted
   *  2. Find the remaining list of field multiples
   *  3. Reindex section_ids so they are in sequence
   *  4. Reconstruct the field schema
   *  5. Reconstruct the section schema
   *
   * TODO: Refactor and simplify - ET-14273
   */
  const removeField = ({ field, sectionIndex, itemIndex }) => {
    if (Number.isInteger(itemIndex)) {
      if (field.import_column) {
        setUnmappedImportColumns([
          ...unmappedImportColumns,
          field.import_column,
        ]);
      }

      const { schema: baseSchema } = schema[sectionIndex];

      const removeFieldSchema = baseSchema
        .slice(0, itemIndex)
        .concat(baseSchema.slice(itemIndex + 1));

      const firstIndex = removeFieldSchema
        .map((element) => element.field_name)
        .indexOf(field.field_name);

      const lastIndex = removeFieldSchema
        .map((element) => element.field_name)
        .lastIndexOf(field.field_name);

      const reindexSchema = removeFieldSchema
        .slice(firstIndex, lastIndex + 1)
        .map((item, index) => {
          return { ...item, section_id: index + 1 };
        });

      const finalSchema = removeFieldSchema
        .slice(0, firstIndex)
        .concat(reindexSchema)
        .concat(removeFieldSchema.slice(lastIndex + 1));

      const updateSchema = schema
        .slice(0, sectionIndex)
        .concat({ ...schema[sectionIndex], schema: finalSchema })
        .concat(schema.slice(sectionIndex + 1));

      setListFieldIds({
        ...listFieldIds,
        [field.field_name]: reindexSchema.length,
      });
      setSchema(updateSchema);
    }
  };

  const addSection = (section) => {
    const sectionId = sectionIds[section.name] || 1;

    const updateSchema = section.schema.map((item) => {
      return { ...item, import_column: null, section_id: sectionId + 1 };
    });
    const lastIndex = schema
      .map((element) => element.name)
      .lastIndexOf(section.name);
    const newSchema = schema
      .slice(0, lastIndex + 1)
      .concat({
        ...section,
        schema: updateSchema,
      })
      .concat(schema.slice(lastIndex + 1));
    setSectionIds({ ...sectionIds, [section.name]: sectionId + 1 });
    setSchema(newSchema);
  };

  const removeSection = (section, position) => {
    const removeColumns = section.schema
      .map((item) => item.import_column)
      .filter((item) => item);
    const newSchema = schema
      .slice(0, position)
      .concat(schema.slice(position + 1));
    setUnmappedImportColumns([...unmappedImportColumns, ...removeColumns]);
    setSchema(newSchema);
  };

  const unmapValue = (field, sectionIndex, itemIndex) => {
    schema[sectionIndex].schema[itemIndex].import_column = null;

    setUnmappedImportColumns([...unmappedImportColumns, field.id]);
    setSchema(schema);
  };

  const unmapExcluded = (field) => {
    const position = excludedImportColumns.indexOf(field.id);
    const newExcluded = excludedImportColumns
      .slice(0, position)
      .concat(excludedImportColumns.slice(position + 1));

    setExcludedImportColumns(newExcluded);
    setUnmappedImportColumns([...unmappedImportColumns, field.id]);
  };

  const excludeAllUnmappedItems = () => {
    setUnmappedImportColumns([]);
    setExcludedImportColumns([
      ...excludedImportColumns,
      ...unmappedImportColumns,
    ]);
  };

  const unmapAllExcludedItems = () => {
    setExcludedImportColumns([]);
    setUnmappedImportColumns([
      ...unmappedImportColumns,
      ...excludedImportColumns,
    ]);
  };

  return {
    schema,
    addField,
    addSection,
    removeField,
    removeSection,
    addLinkedField,
    removeLinkedField,
    setAutoMap,
    autoMap,
    excludedImportColumns,
    setExcludedImportColumns,
    unmappedImportColumns,
    setUnmappedImportColumns,
    unmapValue,
    unmapExcluded,
    display_name,
    footnotes,
    onDragEndCallback,
    excludeAllUnmappedItems,
    unmapAllExcludedItems,
  };
};

export default useSchema;
