import styled from "styled-components";
import { colors } from "style/colors";

export const Label = styled.label`
  display: inline-block;
  padding-bottom: 12px;
`;

export const Form = styled.div`
  padding: 0 20px;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.border};

  &:last-child {
    border-bottom: none;
  }
`;

export const FormField = styled.div`
  flex: 1;
  margin-right: 8px;

  &:last-child {
    margin: 0;
  }
`;

export const DeleteButton = styled.div`
  color: ${colors.errorLight};
  cursor: pointer;
  padding: 12px 0;
  &:hover {
    color: ${colors.errorDark};
  }
`;

export const StatusSection = styled.div`
  margin-top: 16px;
  line-height: 18px;

  label {
    display: flex;
  }

  .et--checkbox--box {
    min-width: 22px;
  }
`;
