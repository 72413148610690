import React from "react";
import Module from "components/module";
import axios from "axios";
import {
  IconButton,
  Icon,
  Button,
  useOpenClose,
  Switch,
  SearchInput,
  LinkButton,
} from "@evertrue/et-components";
import * as ProposalService from "../proposal-service";
import { launchToast } from "utils/show-toast";
import ProposalCustomFieldModal from "../components/proposal-custom-field-modal";
import DnDTable, { CustomDnDTableCell } from "components/dnd/dnd-table";
import { searchCompare } from "utils/utils";
import { Link } from "react-router-dom";
import { formatCustomFieldDisplayName } from "apps/custom-fields/custom-fields-utils";
import ConfirmDelete from "components/confirm-delete";

const ProposalCustomFieldController = () => {
  const [custom_fields, setCustomFields] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [item_to_edit, setItemToEdit] = React.useState(null);
  const [search_query, setSearchQuery] = React.useState("");
  const [isModalOpen, openModal, closeModal] = useOpenClose();
  const signal = React.useRef(axios.CancelToken.source());

  const fetchCustomFields = async () => {
    setLoading(true);
    const fields = await ProposalService.fetchProposalCustomFields(
      signal.current.token
    );
    setCustomFields(fields);
    setLoading(false);
  };

  React.useEffect(() => {
    fetchCustomFields();
    return () => {
      if (signal.current) {
        signal.current.cancel();
      }
    };
  }, []);

  const handleDeleteItem = async (item) => {
    await ProposalService.deleteProposalCustomField(item.id);
    launchToast({ message: "Custom field deleted" });
    fetchCustomFields();
  };

  const handleUpdate = async (item) => {
    await ProposalService.updateProposalCustomField(item);
    launchToast({ message: "Custom field updated" });
    fetchCustomFields();
  };

  const handleDragEnd = async (items) => {
    setCustomFields(items);
    await ProposalService.updateMultipleProposalCustomFields(
      items.map((i, idx) => ({ ...i, sort_order: idx }))
    );
    launchToast({ message: "Custom fields updated" });
    fetchCustomFields();
  };

  const active_custom_fields = custom_fields.filter((i) => i.active);

  const filtered_custom_fields = custom_fields.filter(({ display_name }) =>
    searchCompare(display_name, search_query)
  );

  const columns = [
    {
      header: "Display Name",
      accessor: (field) => (
        <LinkButton
          title={field.display_name}
          onClick={() => setItemToEdit(field)}
        >
          {field.display_name}
        </LinkButton>
      ),
    },
    {
      header: "Field Type",
      accessor: (field) =>
        formatCustomFieldDisplayName(field.data_type, field.ui_control_type),
    },
    {
      header: "Required",
      Cell: (field) => {
        const is_pick_list =
          field.dataType === "STRING" && field.uiControlType !== "FREEFORM";
        return (
          <CustomDnDTableCell>
            <Switch
              disabled={is_pick_list && !active_custom_fields.length}
              onChange={(required) => handleUpdate({ ...field, required })}
              name={`required-${field.id}`}
              checked={field.required}
            />
          </CustomDnDTableCell>
        );
      },
    },
    {
      header: "Active",
      Cell: (field) => {
        return (
          <CustomDnDTableCell>
            <Switch
              onChange={(active) => {
                handleUpdate({ ...field, active });
              }}
              name={`active-${field.id}`}
              checked={field.active}
            />
          </CustomDnDTableCell>
        );
      },
    },
    {
      header: "",
      Cell: (field) => {
        const is_pick_list =
          field.data_type === "STRING" && field.ui_control_type !== "FREEFORM";
        return (
          <CustomDnDTableCell>
            {is_pick_list && (
              <Link
                to={{
                  state: field,
                  pathname: `/proposals/custom-fields/values/${field.id}`,
                }}
              >
                <Icon icon="build" /> Edit Values
              </Link>
            )}
          </CustomDnDTableCell>
        );
      },
    },
    {
      header: "",
      Cell: (field) => {
        return (
          <CustomDnDTableCell>
            <IconButton
              icon="edit"
              size={1}
              title="edit custom field"
              onClick={() => setItemToEdit(field)}
            />
          </CustomDnDTableCell>
        );
      },
    },
    {
      header: "",
      Cell: (field) => {
        return (
          <CustomDnDTableCell>
            <ConfirmDelete
              field={field}
              fieldDisplayName={field.display_name}
              onDelete={handleDeleteItem}
            />
          </CustomDnDTableCell>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div
        css={`
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12px 24px 0;
        `}
      >
        <div
          css={`
            flex-basis: 25%;
            margin-right: auto;
          `}
        >
          <SearchInput
            value={search_query}
            onChange={setSearchQuery}
            placeholder="Search Field Names"
          />
        </div>
        <Button title="Create custom field" onClick={openModal}>
          Create Custom Field
        </Button>
      </div>
      <Module css="margin-top: 8px;">
        <ProposalCustomFieldModal
          customField={item_to_edit}
          customFields={custom_fields}
          isOpen={item_to_edit || isModalOpen}
          onClose={() => {
            if (item_to_edit) setItemToEdit(null);
            closeModal();
          }}
          onSuccess={async () => {
            await fetchCustomFields();
            launchToast({
              message: `Custom field ${item_to_edit ? "Updated" : "Added"}`,
            });
          }}
        />

        <DnDTable
          columns={columns}
          onDragEnd={handleDragEnd}
          data={filtered_custom_fields}
          loading={loading}
        />
      </Module>
    </React.Fragment>
  );
};

export default ProposalCustomFieldController;
