import React, { Component } from "react";
import _ from "underscore";
import { Redirect, Prompt } from "react-router-dom";
import T from "prop-types";
import {
  AlertActions,
  ModalTriggerDeprecated,
  Button,
  Loading,
  Portal,
} from "@evertrue/et-components";
import { cheapCloneData } from "utils/utils";
import Module from "components/module";
import * as StageService from "apps/stages/stages-service";
import StagesNav from "apps/stages/components/stages-nav";
import AddStageSetModal from "apps/stages/components/add-stage-set-modal";
import uid from "uuid/v4";
import ColumnConfigEditor from "components/column-config-editor/column-config-editor";
import { Label } from "style/components/index";
import {
  StageWrapper,
  StagesMain,
  EditorEditableInputWrapper,
  EditorNameLabel,
  StageContainer,
  StyledEmptyStateMessage,
} from "apps/stages/controllers/stages-controller-style";
import AddStageModal from "../components/add-stage-modal";

export const UNSAVED_GROUP_ID = 0;
const STAGE_LIMIT = 20;

const TEMP_NEW_STAGE_GROUP = () => ({
  id: UNSAVED_GROUP_ID,
  name: "Untitled",
  stages: [],
});

class VolunteerStagesController extends Component {
  static propTypes = {
    stageGroupId: T.number,
    history: T.object,
    groupType: T.string,
    baseRoute: T.string,
  };

  static defaultProps = {
    stageGroupId: undefined,
    history: {},
    groupType: null,
    baseRoute: null,
  };

  state = {
    stage_groups: [],
    temp_group: {},
  };

  componentDidMount() {
    this.fetchStageGroups();
  }

  componentDidUpdate(prevProps, prevState) {
    const { stageGroupId } = this.props;
    // on route change
    if (
      (this.state.stage_groups.length && _.isEmpty(prevState.temp_group)) ||
      (!_.isEmpty(prevState.temp_group) &&
        prevState.temp_group.id !== stageGroupId)
    ) {
      this.copyCurrentGroupIntoTempGroup(stageGroupId);
    }
  }

  fetchStageGroups = async () => {
    this.setState({ loading: true });
    const res = await StageService.fetchStageGroups(this.props.groupType);
    this.setState({
      stage_groups: _.sortBy(res.data, "updated_at").reverse(),
      loading: false,
    });
  };

  handleAddStageGroup = () => {
    const { temp_group } = this.state;
    // prevents from being able to add more than 1 unsaved group
    if (temp_group.id !== UNSAVED_GROUP_ID) {
      this.setState({
        temp_group: TEMP_NEW_STAGE_GROUP(),
      });
    }
  };

  handleEditTempGroup = (key, val) => {
    this.setState({
      temp_group: { ...this.state.temp_group, [key]: val },
    });
  };

  createStageGroup = async (temp_group) => {
    temp_group.stage_group_type = this.props.groupType;
    const res = await StageService.createStageGroup(temp_group);

    // set group to undefined before routing so that are you sure prompt doesn't show
    this.setState({ temp_group: {} }, async () => {
      await this.fetchStageGroups();
      this.props.history.push(`${this.props.baseRoute}${res.data.id}`);
      successToast();
    });
  };

  handleSaveStageGroup = async () => {
    const { temp_group, stage_groups } = this.state;
    if (temp_group.id === UNSAVED_GROUP_ID) {
      this.createStageGroup(temp_group);
    } else {
      const { id, stages: updated_stages, ...updated_group } = temp_group;

      const current = stage_groups.find((item) => item.id === id);
      const should_update_group = updated_group.name !== current.name;

      const current_stage_index = _.indexBy(current.stages, "id");
      const [updated_old_stages, new_stages] = _.partition(
        updated_stages,
        (stage) => current_stage_index[stage.id]
      );

      const updated_stage_requests = this.getUpdatedStages(
        current_stage_index,
        updated_old_stages
      ).map((stage) => StageService.updateStageInGroup(id, stage));

      const new_stages_requests = new_stages.map((stage) =>
        StageService.createStageInGroup(id, stage)
      );

      const requests_for_stages = [
        ...updated_stage_requests,
        ...new_stages_requests,
      ];
      await Promise.all(requests_for_stages);

      if (should_update_group) {
        await StageService.updateStageGroup(id, updated_group);
      }
      if (requests_for_stages.length || should_update_group) {
        await this.fetchStageGroups();

        this.copyCurrentGroupIntoTempGroup(temp_group.id);
        successToast();
      }
    }
  };

  getUpdatedStages(current_stage_index, new_stages) {
    const stages_have_changed = new_stages.reduce((accum, updated_stage) => {
      const current_stage = current_stage_index[updated_stage.id];
      if (!_.isEqual(current_stage, updated_stage)) {
        return [...accum, updated_stage];
      }

      return accum;
    }, []);
    return stages_have_changed;
  }

  handleAddStageToGroup = async (group_id, data) => {
    const { temp_group } = this.state;
    this.setState({
      temp_group: {
        ...temp_group,
        // add unique id b/c dndcontroller uses as key
        stages: [{ ...data, id: uid() }, ...temp_group.stages],
      },
    });
  };

  handleEditStage = (group_id, updated_stage) => {
    const { temp_group } = this.state;
    this.setState({
      item_to_edit: {},
      temp_group: {
        ...temp_group,
        stages: temp_group.stages.map((stage) => {
          if (stage.id === updated_stage.id) {
            return updated_stage;
          }
          return stage;
        }),
      },
    });
  };

  copyCurrentGroupIntoTempGroup = (group_id) => {
    if (group_id !== UNSAVED_GROUP_ID) {
      const new_group = this.state.stage_groups.find(
        (group) => group.id === group_id
      );
      this.setState({ temp_group: cheapCloneData(new_group) });
    }
  };

  handleCancel = () => {
    const { temp_group } = this.state;
    const existing_group = this.state.stage_groups.find(
      (group) => group.id === temp_group.id
    );
    this.setState({
      temp_group: existing_group,
    });
  };

  handleEditModal = (item) => {
    this.setState({ item_to_edit: item });
  };

  handleListChange = ({ listOne = [], listTwo = [] }) => {
    this.handleEditTempGroup("stages", [...listOne, ...listTwo]);
  };

  render() {
    const {
      stage_groups,
      temp_group: { stages: temp_group_stages = [] } = {},
      temp_group = {},
    } = this.state;
    const { stageGroupId } = this.props;
    // need to calcuate is_valid_id off this list and if there's an unsaved group, need to put it in the nav list
    const groups_with_temp = [
      ...(stageGroupId === UNSAVED_GROUP_ID
        ? [temp_group, ...stage_groups]
        : stage_groups),
    ];
    const has_max_stages = temp_group_stages.length >= STAGE_LIMIT;
    const has_max_stage_groups = stage_groups >= STAGE_LIMIT;

    const current_actual = _.findWhere(stage_groups, { id: temp_group.id });
    const are_unsaved_changes = Boolean(
      temp_group.id === UNSAVED_GROUP_ID ||
        (current_actual && !_.isEqual(current_actual, temp_group))
    );

    if (stage_groups.length) {
      const is_valid_id = groups_with_temp.find(
        (group) => group.id === stageGroupId
      );
      if (!is_valid_id || typeof stageGroupId === "undefined") {
        return <Redirect to={`${this.props.baseRoute}${stage_groups[0].id}`} />;
      }
    }

    return (
      <StageWrapper>
        <Module>
          {this.state.loading && <Loading />}
          {stage_groups.length ? (
            <StagesMain>
              <StagesNav
                groups={stage_groups}
                unsavedGroupID={are_unsaved_changes ? stageGroupId : null}
                baseRoute={this.props.baseRoute}
                onCreate={this.createStageGroup}
                groupType={this.props.groupType}
              />
              <StageContainer>
                <EditorEditableInputWrapper>
                  <EditorNameLabel>
                    <Label>Set Name</Label>
                    <input
                      type="text"
                      value={temp_group.name}
                      onChange={({ currentTarget: { value } }) => {
                        this.handleEditTempGroup("name", value);
                      }}
                    />
                  </EditorNameLabel>
                  <ModalTriggerDeprecated
                    disable={has_max_stages}
                    modal={
                      <AddStageModal
                        stageGroupId={temp_group.id}
                        handleAddStageToGroup={this.handleAddStageToGroup}
                        groupType={this.props.groupType}
                      />
                    }
                  >
                    <Button
                      disabled={
                        has_max_stages || this.props.groupType === "DXO"
                      }
                    >
                      + New Stage
                    </Button>
                  </ModalTriggerDeprecated>
                </EditorEditableInputWrapper>
                <ColumnConfigEditor
                  searchKey="stage"
                  disableSave={!are_unsaved_changes}
                  disableCancel={!are_unsaved_changes}
                  onEdit={this.handleEditModal}
                  handleCancel={this.handleCancel}
                  handleSave={() => this.handleSaveStageGroup(temp_group)}
                  onChange={this.handleListChange}
                  listOne={{
                    label: "Inactive Stages:",
                    items: temp_group_stages.filter((stage) => !stage.active),
                    notSortable: true,
                  }}
                  listTwo={{
                    label: "Active Stages:",
                    items: temp_group_stages.filter((stage) => stage.active),
                  }}
                  disabled={this.props.groupType === "DXO" ? true : false}
                />
              </StageContainer>
            </StagesMain>
          ) : (
            <StyledEmptyStateMessage
              icon="add-circle-outline"
              text="Create a set of stages to get started"
            >
              <div>
                A set of stages can be configured by pool to control the stages
                that volunteers can set for their prospects. For example, pools
                for reunions might use a specific set of stages that differ from
                those for your annual fund.
              </div>
              <ModalTriggerDeprecated
                disable={has_max_stage_groups}
                modal={<AddStageSetModal onSubmit={this.createStageGroup} />}
              >
                <Button
                  className={has_max_stages ? "is-disabled" : ""}
                  disabled={this.props.groupType === "DXO"}
                >
                  Create Stage Set
                </Button>
              </ModalTriggerDeprecated>
            </StyledEmptyStateMessage>
          )}
          <Prompt
            when={are_unsaved_changes}
            message="You have unsaved changes. Do you want to navigate away from this stage set without saving your changes?"
          />
        </Module>
        {!_.isEmpty(this.state.item_to_edit) && (
          <Portal>
            <AddStageModal
              onClose={() => this.handleEditModal({})}
              isEditing
              stage={this.state.item_to_edit}
              stageGroupId={temp_group.id}
              handleAddStageToGroup={this.handleEditStage}
              groupType={this.props.groupType}
            />
          </Portal>
        )}
      </StageWrapper>
    );
  }
}

function successToast() {
  AlertActions.launchToast({
    type: "success",
    message: "Stage Saved",
    timeout: 3000,
  });
}

export default VolunteerStagesController;
