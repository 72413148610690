import styled from "styled-components";
import { colors } from "style/colors";

export const Header = styled.div`
  width: 100%;
  background-color: ${colors.white};
  border-bottom: solid 1px ${colors.border};
  padding: 10px 30px;
  font-weight: normal;
  position: ${(props) => (props.fixed ? "fixed" : "static")};
  max-height: 85px;
  z-index: 3;
`;

export const AppSectionWrapper = styled.div`
  height: auto;
  padding: ${(props) => (props.padding ? props.padding : "30px;")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "65px")};
`;

export const Wrapper = styled.div`
  height: auto;
  width: 100%;
`;
