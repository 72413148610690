import React, { useEffect, useState } from "react";
import { useOpenClose } from "@evertrue/et-components";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
} from "style/components";
import JourneyGroupsTableRow from "./journey-groups-table-row";
import PageLayoutWithButtons from "apps/layout/components/page-layout-with-buttons";
import {
  showSuccessToast,
  editJourneyGroup,
  createJourneyGroup,
  deactivateJourneyGroup,
  addUsersToJourneyGroup,
  getJourneyGroups,
} from "apps/journey-groups/utils/api-requests";
import JourneyGroupModal from "./modals/journey-group-modal";
import PropTypes from "prop-types";

const JourneyGroupsTable = ({ oid }) => {
  const [groups, setGroups] = useState([]);
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  const SubtitleComponent = () => (
    <div>
      <span style={{ display: "block", marginBottom: "8px" }}>
      Create and manage categories of cadences to make it easy for 
      your team to find and use cadences in app.evertrue.com. 
      Create categories for permanent groupings like “Annual Giving” or 
      short-term initiatives like “Giving Day 2025".
      </span>
      <span>
        To start adding cadences to each new category, click on the “Cadences” tab at left.
      </span>
    </div>
  );

  useEffect(() => {
    const fetchJourneyGroups = async () => {
      const journeyGroupsFromGql = await getJourneyGroups(false);
      setGroups(journeyGroupsFromGql);
    };
    fetchJourneyGroups();
  }, []);

  const updateTableState = async (action, group) => {
    const journeysGroupCopy = [...groups];
    if (action === "create") {
      journeysGroupCopy.push(group);
      setGroups(journeysGroupCopy);
    } else if (action === "edit") {
      const indexToReplce = journeysGroupCopy.findIndex(
        (g) => g.id === group.id
      );
      journeysGroupCopy[indexToReplce] = group;
      setGroups(journeysGroupCopy);
    } else if (action === "delete") {
      const indexToDelete = journeysGroupCopy.findIndex(
        (g) => g.id === group.id
      );
      journeysGroupCopy.splice(indexToDelete, 1);
      setGroups(journeysGroupCopy);
    }
  };

  const handleCreate = async (journeyGroup, usersToAdd = []) => {
    const group = await createJourneyGroup(journeyGroup);
    let message = `${group.name} Created `;
    if (!!usersToAdd.length) {
      await addUsersToJourneyGroup(group.id, usersToAdd);
      message += " and users added successfully";
    }
    showSuccessToast(message);
    updateTableState("create", group);
    closeModal();
  };

  const handleEdit = async (payload, id, usersToAdd = []) => {
    const group = await editJourneyGroup(payload, id, oid);
    let message = `${group.name} Updated `;
    if (!!usersToAdd.length) {
      await addUsersToJourneyGroup(group.id, usersToAdd);
      message += " and users added successfully";
    }
    showSuccessToast(message);
    updateTableState("edit", group);
  };

  const handleDelete = async (journeyGroup) => {
    await deactivateJourneyGroup(journeyGroup);
    updateTableState("delete", journeyGroup);
  };

  return (
    <PageLayoutWithButtons
      title={"Cadence Categories"}
      subtitle={<SubtitleComponent />}
      buttons={[
        { key: 1, label: "Add Category", onClick: openModal, type: "default" },
      ]}
    > <Table>
        <TableHeader>
          <TableHeaderCell>Category</TableHeaderCell>
          <TableHeaderCell>Number of Users</TableHeaderCell>
          <TableHeaderCell>Number of Cadences</TableHeaderCell>
          <TableHeaderCell>Created By</TableHeaderCell>
          <TableHeaderCell></TableHeaderCell>
        </TableHeader>
        <TableBody>
          {groups.map((group) => (
            <JourneyGroupsTableRow
              key={group.id}
              group={group}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
            />
          ))}
        </TableBody>
      </Table>
      {isModalOpen && (
        <JourneyGroupModal
          isOpen={isModalOpen}
          onClose={closeModal}
          createGroup={handleCreate}
        />
      )}
    </PageLayoutWithButtons>
  );
};

JourneyGroupsTable.propTypes = {
  oid: PropTypes.number.isRequired,
};

export default JourneyGroupsTable;
