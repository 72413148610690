import React, { Component } from "react";
import _ from "underscore";
import { Popover2, Icon } from "@evertrue/et-components";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import styled from "styled-components";
import { colors } from "style/colors";
import moment from "moment";

class DatePicker extends Component {
  state = {
    to: null,
    from: null,
  };
  static defaultProps = {
    onChange: _.noop,
  };

  handleDayClick = (day) => {
    const { to, from } = this.state;
    const range = DateUtils.addDayToRange(day, { to, from });
    this.setState(range);
    this.props.onChange(range);
  };

  handleReset = () => {
    this.setState({ to: null, from: null });
    this.props.onChange({});
  };

  render() {
    const { from, to } = this.state;
    let trigger = "";
    if (from) {
      trigger += `${from.toLocaleDateString()} - `;
      if (to) {
        trigger += to.toLocaleDateString();
      }
    } else {
      trigger = "Select date range...";
    }

    return (
      <Popover2
        trigger={({ is_open, openPopover }) => (
          <div>
            <DatePickerTrigger onClick={openPopover}>
              {trigger}
              <Icon icon="chevron-down" />
            </DatePickerTrigger>
          </div>
        )}
      >
        {({ closePopover }) => (
          <DatePickerWrapper>
            <DayPicker
              selectedDays={[from, { from, to }]}
              modifiers={{ start: from, end: to }}
              onDayClick={this.handleDayClick}
              numberOfMonths={2}
              initialMonth={moment().subtract(1, "months").toDate()}
            />
            <DatePickerActionWrapper>
              <DatePickerAction onClick={this.handleReset}>
                Reset
              </DatePickerAction>
              <DatePickerAction onClick={closePopover}>Done</DatePickerAction>
            </DatePickerActionWrapper>
          </DatePickerWrapper>
        )}
      </Popover2>
    );
  }
}

const DatePickerTriggerInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  height: 40px;
  width: 280px;
  padding: 10px 20px;
  background: ${colors.secondary};
  border-radius: 3px;
  box-shadow: inset 0 -3px 0 0 ${colors.secondaryDark};
  cursor: pointer;

  &:hover {
    background: ${colors.secondaryLight};
  }
`;

const DatePickerTrigger = React.forwardRef((props, ref) => (
  <DatePickerTriggerInner {...props} innerRef={ref} />
));

const altColor = "#F6D2E5";
const DatePickerWrapper = styled.div`
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: ${altColor};
    color: ${colors.accent};
  }
  .DayPicker-Day {
    border-radius: 0;
  }
  .DayPicker-Day--start:not(.DayPicker-Day--outside) {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: ${colors.accent} !important;
    color: ${altColor};
  }
  .DayPicker-Day--end:not(.DayPicker-Day--outside) {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    background-color: ${colors.accent} !important;
    color: ${altColor};
  }
`;

const DatePickerActionWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 24px 12px 0;
`;
const DatePickerAction = styled.div`
  color: ${colors.linkText};
  cursor: pointer;
  font-weight: 500;
  margin-left: 16px;
  &:hover {
    color: ${colors.accent};
  }
`;

export default DatePicker;
