import React from "react";
import PropTypes from "prop-types";
import SearchResult from "./search-result-item";
import { SearchResults } from "scripts/style/components/search-box";

const SearchResultsList = ({
  onSelect,
  searchResults = [],
  selectedResult,
}) => {
  return (
    <SearchResults>
      {searchResults.map((result) => (
        <SearchResult
          key={result.id}
          result={result}
          selected={selectedResult && selectedResult.id === result.id}
          onClick={onSelect}
        >
          <span>{result.name}</span>
        </SearchResult>
      ))}
    </SearchResults>
  );
};

SearchResultsList.propTypes = {
  onSelect: PropTypes.func,
  searchResults: PropTypes.array,
  selectedResult: PropTypes.object,
};

SearchResultsList.defaultProps = {
  onSelect: () => {},
  searchResults: [],
  selectedResult: null,
};

export default SearchResultsList;
