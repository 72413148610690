import styled from "styled-components";
import { colors } from "style/colors";

export const HintText = styled.div`
  font-size: 12px;
  color: ${colors.lightText};
  margin-bottom: 12px;
`;

export const DeleteButton = styled.div`
  color: ${colors.errorLight};
  cursor: pointer;
  padding: 12px 0;
  margin-left: 24px;
  &:hover {
    color: ${colors.errorDark};
  }
`;
