import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Bar, SearchInput, Button } from "@evertrue/et-components";

const ImportFilters = ({ onSearch, searchValue }) => {
  return (
    <React.Fragment>
      <Bar
        left={
          <SearchInput
            width="350px"
            debounceTimeout={300}
            value={searchValue}
            className={"import-filters--search-box"}
            placeholder="Search by file name..."
            onChange={onSearch}
          />
        }
        right={
          <NavLink to="/importer/import-upload">
            <ImporterButton type="action">New Import</ImporterButton>
          </NavLink>
        }
      />
    </React.Fragment>
  );
};

ImportFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
};

const ImporterButton = styled(Button)`
  margin-left: 12px;
`;

export default ImportFilters;
