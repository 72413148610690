import { titleCase } from "utils/utils";

export const formatControl = (control_type) => {
  const control_dict = {
    FREEFORM: "Freeform",
    DROPDOWN: "Pick list",
  };
  const control_label =
    control_dict[control_type] ||
    (control_type ? titleCase(control_type) : null);
  return control_label;
};

export const formatLabel = (data_type, control_label) => {
  const dict = {
    DOUBLE: "Currency",
    LONG: "Number",
    date_string: "Date",
  };
  let label = dict[data_type] || (data_type ? titleCase(data_type) : null);
  if (control_label) {
    label = label + " - " + control_label;
  }
  return label;
};

export const formatCustomFieldDisplayName = (dataType, uiControlType) =>
  formatLabel(dataType, formatControl(uiControlType));

export const availableProducts = [
  {
    label: "Community",
    value: "590b6855ec417400015682e454c6acec8f371adf861b830b031fa9ad3ac84c90",
  },
  {
    label: "EverTrue",
    value: "815e8d01be8f78a41d1c71eb652b8be124b89058b74d284c6bb752a034dbb301",
  },
  {
    label: "Volunteers",
    value: "7cb9b8d4451567b725e81b998cdf46cfc58426f9936916c714acddbc9f082546",
  },
];
// used to prevent required dropdowns without any options to pick
export const getIsFieldPickList = (dataType, uiControlType) => {
  return dataType === "STRING" && uiControlType !== "FREEFORM";
};

export const CUSTOM_FIELD_VALUES_LIMIT = 15;
