import PropTypes from "prop-types";
import React from "react";
import {
  CharacterCountContainer,
  CharacterLimitExceeded,
} from "scripts/style/components/character-counter";

const CharacterCounter = ({ limit, count }) => {
  let total = limit - count;

  return (
    <CharacterCountContainer>
      {total >= 0 ? (
        <p>{String(total)} characters remaining</p>
      ) : (
        <CharacterLimitExceeded>
          <strong>{String(total)}</strong> characters (limit exceeded)
        </CharacterLimitExceeded>
      )}
    </CharacterCountContainer>
  );
};

CharacterCounter.propTypes = {
  limit: PropTypes.number,
  count: PropTypes.number,
};

CharacterCounter.defaultProps = {
  limit: 0,
  count: 0,
};

export default CharacterCounter;
