import React, { Component } from "react";
import _ from "underscore";
import Api from "utils/api";
import { Loading, Layout, Pagination } from "@evertrue/et-components";
import ImportTable from "./components/import-table";
import ImportFilters from "./components/import-filters";
import { compactObject } from "utils/utils";
import { withRouter } from "react-router-dom";
import { trackEvent } from "apps/importer/hooks/use-tracking";
import { Centered } from "apps/importer/common/importer-styled-components";
import { launchToast } from "utils/show-toast";

const LIMIT = 25;

const hasChanged = (prev = {}, next = {}, keys) => {
  return _.any(keys, (key) => !_.isEqual(prev[key], next[key]));
};

class ImporterController extends Component {
  state = {
    page: 1,
    loading: false,
    data: [],
    searchValue: "",
    sortProp: "desc",
    sortOrder: false,
    activeFilters: {},
  };

  componentDidMount() {
    trackEvent({ event: "Importer List Page" });
    this.fetchImports();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      hasChanged(this.state, prevState, [
        "page",
        "searchValue",
        "sortProp",
        "filters",
      ])
    ) {
      this.fetchImports();
    }
  }

  handleFileSearch = (searchValue) => {
    this.setState({ searchValue, page: 1 });
  };

  handleColumnSort = () => {
    this.setState({
      sortProp: this.state.sortProp === "desc" ? "asc" : "desc",
      page: 1,
      sortOrder: !this.state.sortOrder,
    });
  };

  handleFilterClear = () => {
    this.setState({ page: 1, activeFilters: {}, filters: {} });
  };

  handleFilterSubmit = () => {
    this.setState({ page: 1, filters: this.state.activeFilters });
  };

  handleFilterChange = (activeFilters) => {
    this.setState({ page: 1, activeFilters });
  };

  fetchImports = async () => {
    const { page, searchValue, sortProp, activeFilters } = this.state;
    const fetchKey = _.uniqueId();
    this.fetchKey = fetchKey;
    try {
      this.setState({ loading: true });
      const { data } = await Api.IMPORT.IMPORTS.get({
        params: compactObject({
          limit: LIMIT,
          offset: (page - 1) * LIMIT,
          sort_column: "created_at",
          sort_order: sortProp,
          filename: searchValue,
          status: activeFilters.status,
          type: activeFilters.type,
        }),
      });
      if (fetchKey === this.fetchKey)
        this.setState({ data: data, loading: false });
    } catch (error) {
      console.log(error);
      launchToast({message: error, type: "error"})
      if (fetchKey === this.fetchKey) this.setState({ loading: false });
    }
  };

  render() {
    const { data, page, loading, activeFilters, searchValue } = this.state;
    return (
      <Layout
        className="importer-layout"
        subHeader={
          <ImportFilters
            filters={activeFilters}
            searchValue={searchValue}
            onSearch={this.handleFileSearch}
            onFilterChange={this.handleFilterChange}
            onFilterSubmit={this.handleFilterSubmit}
            onFilterClear={this.handleFilterClear}
          />
        }
        footer={
          <Centered>
            <Pagination
              className="import-table--pagination"
              onChange={(page) => this.setState({ page })}
              page={page}
              total={Math.ceil(data.total / LIMIT) || 1}
              limit={LIMIT}
            />
          </Centered>
        }
      >
        {loading ? (
          <Loading />
        ) : (
          <ImportTable
            data={data.items}
            loading={loading}
            limit={LIMIT}
            onColumnSort={this.handleColumnSort}
            sortOrder={this.state.sortOrder}
          />
        )}
      </Layout>
    );
  }
}

export default withRouter(ImporterController);
