import { useEffect, useState } from "react";
import Api from "utils/api";
import { AlertActions } from "@evertrue/et-components";

// TODO: Unit Test should be available for this component. There is some work to be done to mock this out appropriately.
export const useDatasyncV1AuthFlow = () => {
  const [integration, setIntegration] = useState({});

  const getIntegration = async () => {
    try {
      const { data } = await Api.RENXT_INTEGRATION.INTEGRATION.get({disableAlerts: true});
      setIntegration(data);
    } catch (e) {
      // In this case its okay if the integration is not found. It just means they
      // have not yet connected their Raiser's Edge account to EverTrue.
      // TODO: api.js does not expose the response or status code when it rejects the request, only
      // option is to check the message directly, unfortunately.
      if (e.message === "No Blackbaud RE/NXT integration found.") {
        console.warn("Integration not found, it may have just not yet been configured.", e);
      } else {
        // For other errors, show toast
        AlertActions.launchToast({
          type: "error",
          message: e.message,
        });
        console.log({ e });
      }
    }
  };

  const handleIntegrationClick = async () => {
    const getRedirect = async () => {
      try {
        return await Api.RENXT_INTEGRATION.REDIRECT.get();
      } catch (e) {
        console.log({ e });
      }
    };

    const {
      data: { redirect },
    } = await getRedirect();

    window.location.replace(redirect);
  };

  const handleLogoutClick = () => {
    const { id } = integration;
    const patchIntegration = async ({ id, state }) => {
      try {
        await Api.AUTH.INTEGRATION.patch({
          urlArgs: {
            id,
          },
          data: {
            integration: {
              state,
            },
          },
        });
        getIntegration();
      } catch (e) {
        console.log({ e });
      }
    };

    patchIntegration({ id, state: "disabled" });
  };

  useEffect(() => {
    const searchParams = new URL(window.location).searchParams;
    const authCode = searchParams.get("code");

    if (authCode) {
      const postAuthentication = async ({ authCode }) => {
        try {
          const { status, data } =
            await Api.RENXT_INTEGRATION.AUTHENTICATE.post({
              data: {
                authorization_code: authCode,
              },
            });

          if (status === 200) {
            setIntegration(data);
            window.location.replace(window.location.href.split("?")[0]);
          }
        } catch (e) {
          console.log({ e });
        }
      };

      postAuthentication({ authCode });
    } else {
      getIntegration();
    }
  }, []);

  const { state } = integration;

  return { state, handleIntegrationClick, handleLogoutClick };
};

export default useDatasyncV1AuthFlow;
