import React from "react";
import { Bar } from "@evertrue/et-components";
import styled from "styled-components";
import PropTypes from "prop-types";
import { colors } from "style/colors";

const { string, any } = PropTypes;

const CardBody = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.border};
  border-radius: 4px;
  margin: 20px;
`;

const Title = styled.div`
  font-size: 20px;
  line-height: 20px;
`;

const CardContents = styled.div`
  padding: 16px;
  min-height: 100px;
`;

const propTypes = {
  title: string,
  right: any,
};

const Card = ({ title, right, children }) => (
  <CardBody>
    <Bar padding="8px 16px" left={<Title>{title}</Title>} right={right} />

    <CardContents>{children} </CardContents>
  </CardBody>
);

Card.propTypes = propTypes;

export default Card;
