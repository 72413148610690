import React from "react";
import Api from "utils/api";
import ModalEditor from "../../../components/modal-editor";
import { Field } from "formik";
import { Label } from "style/components/form";

const EmmaInfoModal = ({ account, refresh, onSuccess, onError, ...rest }) => {
  return (
    <ModalEditor
      {...rest}
      initialValues={{ name: account.name }}
      header="Edit Account Name"
      saveLabel="Save"
      validate={(values) => {
        let errors = {};
        if (account.name === values.name) {
          errors.name = "Use different name";
        }
        return errors;
      }}
      onSubmit={(value) => {
        Api.EMMA.ACCOUNT.patch({
          urlArgs: { id: account.id },
          data: value,
          disableAlerts: true,
        })
          .then(onSuccess)
          .then(refresh)
          .catch(onError);
      }}
    >
      <Label>Account Name</Label>
      <Field type="text" autoComplete="off" label="Account Name" name="name" />
    </ModalEditor>
  );
};

export default EmmaInfoModal;
