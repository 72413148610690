import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  SectionHeader,
  BoldText,
  ProcessContainer,
} from "apps/importer/common/importer-styled-components";
import { Button } from "@evertrue/et-components";
import { UploadActive } from "apps/importer/components/import-preprocess/upload/upload-active";
import { usePollingResults } from "apps/importer/hooks/use-polling-results";
import {
  STATUS,
  getProcessState,
  WORKER_TYPE,
  IMPORT,
} from "apps/importer/import-helper";

export const UploadContainer = ({ jobId, history, hasUploadStarted, setHasUploadStarted }) => {

  const { jobData } = usePollingResults({
    jobId,
    workerType: WORKER_TYPE.IMPORT,
    history,
  });

  const currentProcessState = getProcessState(IMPORT, jobData.status);

  setHasUploadStarted(currentProcessState !== STATUS.UNKNOWN && currentProcessState != STATUS.CANCELLED);
  
  return !hasUploadStarted ? (
    <StyledProcessContainer>
      <SectionHeader>
        <BoldText>Import Progress</BoldText>
        <div>To begin importing records, please select "Start Import"</div>
      </SectionHeader>
      <ButtonGroup>
        <Button type="secondary" disabled>
          Download Log File
        </Button>
        <Button disabled>Return to Import Jobs</Button>
      </ButtonGroup>
    </StyledProcessContainer>
  ) : (
    <UploadActive history={history} jobId={jobId} />
  );
};

UploadContainer.propTypes = {
  jobId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  hasUploadStarted: PropTypes.bool.isRequired,
  setHasUploadStarted: PropTypes.func.isRequired,
};

const StyledProcessContainer = styled(ProcessContainer)`
  height: auto;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
`;

export default UploadContainer;
