import { launchToast } from "utils/show-toast";
import Api from "utils/api";

import { formatFieldName } from "apps/importer/components/import-mapping/utils";

// Not really a hook, this is actually just a function. There is nothign "react" about it.
export const useImportMapping = ({ fieldColumns, history, jobId }) => {
  const handleImportMapping = ({ payloadList, jobId }) => {
    Api.IMPORT.IMPORT_MAPPING.post({
      urlArgs: { job_id: jobId },
      disableAlerts: true,
      data: payloadList,
    })
      .then(() => {
        Api.IMPORT.IMPORT_JOB_QUEUE.post({
          urlArgs: { job_id: jobId },
        }).then(() => history.push(`/importer/${jobId}/import-preprocess`));
      })
      .catch((e) => {
          let errText = e.errors[0].split(" ")[1]
          let notMappedItems = payloadList.filter(item => (item.exclude_column == false && item.field_name == undefined))
          if (notMappedItems.length == 0) {
             for (const key in fieldColumns) {
               if (key.includes(errText)) {
                 launchToast({ message: e.errors[0].replace(key.split(":")[0], fieldColumns[key].display_name), type: "error" })
                 return
               }
              }
            }
          else launchToast({ message: e.errors[0], type: "error" })
          });
  };

  const handleContinueClick = () => {
    const payload = Object.values(fieldColumns).map(
      ({
        id,
        attributeKeys,
        field_name,
        section_id,
        import_column,
        linked_columns,
      }) => {
        if (id === "explicit-skip-attributes") {
          return attributeKeys.map((key) => ({
            import_column: key,
            exclude_column: true,
          }));
        }

        // This is the structure the back end expects for linked_columns for submission
        // It determines uniqueness based on the relationships in the object since all of these have
        // the same "field_name"
        if (linked_columns) {
          return {
            field_name: field_name,
            import_column: import_column,
            section_id: section_id,
            linked_columns: linked_columns,
            exclude_column: false,
          };
        }

        return section_id
          ? {
            field_name: formatFieldName(field_name),
            import_column: attributeKeys[0],
            section_id: section_id,
            exclude_column: false,
          }
          : {
            field_name: formatFieldName(field_name),
            import_column: attributeKeys[0],
            exclude_column: false,
          };
      }
    );
    const payloadList = payload
      .flat()
      .filter(({ import_column }) => import_column);

    handleImportMapping({ jobId, payloadList, history });
  };

  return { handleContinueClick };
};

export default useImportMapping;
