import React from "react";
import ColumnConfigEditor from "components/column-config-editor/column-config-editor";
import * as ProposalService from "../proposal-service";
import { useOpenClose, Button } from "@evertrue/et-components";
import Module from "components/module";
import ProposalStageModal from "../components/proposal-stage-modal";
import { launchToast } from "utils/show-toast";
import { TopRowWrapper } from "components/column-config-editor/column-config-editor-style";
import { useMultiColumnConfigState } from "components/column-config-editor/column-config-editor-utils";

const KEYS = ["sort_order", "active"];

const ProposalStagesController = () => {
  const {
    data: stages,
    setData: setStages,
    loading,
    itemToEdit: item_to_edit,
    fetchData: fetchStages,
    setItemToEdit,
    itemsHaveChanged: items_have_changed,
  } = useMultiColumnConfigState({
    initialDataState: [],
    fetchDataFunc: ProposalService.fetchProposalStages,
    keysToDiff: KEYS,
  });
  const [is_modal_open, openModal, closeModal] = useOpenClose();

  const handleDeleteItem = async (item) => {
    await ProposalService.deleteProposalStage(item.id);
    launchToast({ message: "Stage deleted" });
    fetchStages();
  };
  const handleSave = async () => {
    await ProposalService.updateMultipleProposalStages(stages);
    fetchStages();
    launchToast({ message: "Stages saved" });
  };

  const active_stages = stages.filter((i) => i.active);
  const inactive_stages = stages.filter((i) => !i.active);

  return (
    <Module css="margin: 24px;">
      <TopRowWrapper>
        <Button onClick={openModal}>New Stage</Button>
        <ProposalStageModal
          onSuccess={async () => {
            await fetchStages();
            launchToast({
              message: `Stage ${item_to_edit ? "Updated" : "Added"}`,
            });
          }}
          stage={item_to_edit}
          stages={stages}
          isOpen={item_to_edit || is_modal_open}
          onClose={() => {
            if (item_to_edit) setItemToEdit(null);
            closeModal();
          }}
        />
      </TopRowWrapper>
      <ColumnConfigEditor
        loading={loading}
        onChange={({ listOne, listTwo }) => {
          setStages([...listOne, ...listTwo]);
        }}
        searchKey="stage_name"
        secondaryLabelKey="proposal_amount_type"
        onEdit={setItemToEdit}
        handleSave={handleSave}
        onDelete={handleDeleteItem}
        disableSave={!items_have_changed}
        disableCancel={!items_have_changed}
        handleCancel={fetchStages}
        listOne={{ label: "Inactive Stages", items: inactive_stages }}
        listTwo={{ label: "Active Stages", items: active_stages }}
      />
    </Module>
  );
};

ProposalStagesController.propTypes = {};

export default ProposalStagesController;
