import React, { Component } from "react";
import T from "prop-types";
import { ModalDeprecated, ModalConfirm } from "@evertrue/et-components";
import { FormWrapper, Label } from "style/components";

class AddStageSetModal extends Component {
  static propTypes = {
    onSubmit: T.func.isRequired,
  };

  state = {
    name: "",
  };

  handleChange = ({ target: { value } }) => {
    this.setState({ name: value });
  };

  render() {
    return (
      <ModalDeprecated {...this.props} width={500}>
        <ModalConfirm
          header="Create New Stage Set"
          disableSave={!this.state.name}
          onSubmit={() => {
            this.props.onSubmit({ name: this.state.name });
          }}
        >
          <FormWrapper>
            <Label>Name</Label>
            <input
              type="text"
              placeholder="Enter name for set..."
              value={this.state.name}
              onChange={this.handleChange}
            />
          </FormWrapper>
        </ModalConfirm>
      </ModalDeprecated>
    );
  }
}

export default AddStageSetModal;
