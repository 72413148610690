import React from "react";
import { Layout } from "@evertrue/et-components";
import IsFlagged from "scripts/apps/launch-darkly/is-flagged";

import { NxtIntegration } from "scripts/apps/integrations/crm/integration-cards/datasync-v1-integration";
import { BlackbaudCrmIntegration } from "apps/integrations/crm/integration-cards/blackbaudcrm-integration";
import { BlackbaudRenxtIntegration } from "apps/integrations/crm/integration-cards/blackbaudrenxt-integration";

// TODO: Add content card dynamic layout(pending further integrations of this type.)
const CrmController = () => {
  return (
    <React.Fragment>
      <Layout headerBorder={false}>
        <NxtIntegration />
        <IsFlagged feature="datawebCrmBb">
          <BlackbaudCrmIntegration /> 
        </IsFlagged>
        <IsFlagged feature="datawebCrmRenxt">
          <BlackbaudRenxtIntegration />
        </IsFlagged>
      </Layout>
    </React.Fragment>
  );
};

export default CrmController;
