import _ from "underscore";
import React, { Component, Fragment } from "react";
import { Layout, FlexTable, Bar, ActionsMenu } from "@evertrue/et-components";
import { NavItem, Nav } from "style/components/nav-tabs";
import { Switch, Route, Redirect } from "react-router-dom";
import moment from "moment";
import api from "../api";
import Card from "components/card";
import { colors } from "style/colors";
import styled from "styled-components";
import { GateContext } from "apps/dna/gate-context";

const Table = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  font-family: monospace;
`;

const Row = styled.div`
  display: table-row;
  padding: 12px;
  border-bottom: 1px solid ${colors.border};
`;

const Cell = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding: 12px;
  border-bottom: 1px solid ${colors.border};
`;

const InfoTable = ({ value }) => (
  <Table>
    {_.compact(
      _.map(value, (val, key) => {
        return (
          <Row key={key}>
            <Cell>{key}</Cell>
            <Cell>
              {_.isFinite(val) && val > 100000
                ? moment(val).format("YYYY-MM-DD hh:mm A")
                : _.isBoolean(val)
                ? val
                  ? "True"
                  : "False"
                : val && val.toString
                ? val.toString()
                : val}{" "}
            </Cell>
          </Row>
        );
      })
    )}
  </Table>
);

const Json = ({ json = {} }) => (
  <div
    style={{
      padding: 12,
      whiteSpace: "pre-wrap",
      fontFamily: "monospace",
      lineHeight: 1.4,
    }}
  >
    {JSON.stringify(json, null, 3)}
  </div>
);

const TABS = [
  "Session",
  "Scope",
  "User",
  "Affiliations",
  "Gates",
  "API",
  "Raw",
];

const Tabs = () => (
  <Nav>
    {_.map(TABS, (name) => (
      <NavItem
        key={name}
        activeClassName="active"
        to={`/debug/${name.toLowerCase()}`}
      >
        {name}
      </NavItem>
    ))}
  </Nav>
);

const columns = [
  {
    header: "URL",
    accessor: ({ url }) => url,
  },
  {
    header: "Type",
    accessor: ({ type }) => type,
  },
  {
    header: "Time",
    accessor: ({ time }) => time,
  },
];

const options = [
  { id: 1, label: "Renew Session", onClick: api.debug.renewSession },
  { id: 2, label: "Refresh Session", onClick: api.debug.refreshCurrentSession },
  { id: 3, label: "Set Invalid Token", onClick: api.debug.setInvalidToken },
  {
    id: 4,
    label: "Fetch Gates Twice",
    onClick: () => {
      const fetch = () =>
        api.DNA.GATES.get({
          headers: { "Cache-Control": "no-cache" },
          params: { oid: 158, user_id: 837 },
        });
      fetch();
      fetch();
    },
  },
];

class DebugController extends Component {
  state = {
    info: api.getValue(),
    debug: api.debug.getState(),
  };

  componentDidMount() {
    this.onAuthChange = this._onAuthChange.bind(this);
    this.onDebugChange = this._onDebugChange.bind(this);
    api.bind(this.onAuthChange);
    api.debug.bind(this.onDebugChange);
  }

  componentWillUnmount() {
    api.unbind(this.onAuthChange);
    api.debug.unbind(this.onDebugChange);
  }

  _onAuthChange() {
    this.setState({ info: api.getValue() });
  }

  _onDebugChange() {
    this.setState({ debug: api.debug.getState() });
  }

  render() {
    const { orgs, ...restInfo } = this.state.info;
    const { log, currentSession } = this.state.debug;

    const {
      user,
      organization: org,
      application_key: app_key,
      application,
    } = currentSession || {};

    return (
      <GateContext.Consumer>
        {({ gates }) => {
          const _gates = _.mapObject(gates, (gate) => gate.enabled.toString());

          const header = <Bar right={<ActionsMenu options={options} />} />;

          return (
            <Layout header={<Tabs />} headerBorder="none">
              <Switch>
                <Route path="/debug/session">
                  <Layout header={header}>
                    <Card title="Session">
                      <InfoTable value={currentSession} />
                    </Card>
                    <Card title="API Manager Info">
                      <InfoTable value={restInfo} />
                    </Card>
                  </Layout>
                </Route>

                <Route path="/debug/scope">
                  <Fragment>
                    <Card title="Organization">
                      <InfoTable value={org} />
                    </Card>
                    <Card title="Application">
                      <InfoTable value={{ ...application, app_key }} />
                    </Card>
                  </Fragment>
                </Route>

                <Route path="/debug/user">
                  <Card title="User">
                    <InfoTable value={user} />
                  </Card>
                </Route>

                <Route path="/debug/affiliations">
                  <Fragment>
                    {_.map(orgs, (org) => (
                      <Card title={org.name} key={org.id}>
                        <InfoTable value={org} />
                      </Card>
                    ))}
                  </Fragment>
                </Route>

                <Route path="/debug/gates">
                  <Card title="Gates">
                    <InfoTable value={_gates} />
                  </Card>
                </Route>

                <Route path="/debug/api">
                  <Card title="API">
                    <FlexTable
                      emptyStateMessageText="NO API Events?!?"
                      data={_.clone(log).reverse()}
                      total={1}
                      page={1}
                      limit={50}
                      columns={columns}
                      loading={false}
                      tableFlexBasis="auto"
                    />
                  </Card>
                </Route>

                <Route path="/debug/raw">
                  <Card title="Raw">
                    <Json json={this.state} />
                  </Card>
                </Route>

                <Route>
                  <Redirect to="/debug/session" />
                </Route>
              </Switch>
            </Layout>
          );
        }}
      </GateContext.Consumer>
    );
  }
}

export default DebugController;
