import { AlertActions } from "@evertrue/et-components";
import Api from "utils/api";

export const fetchCustomFields = () =>
  Api.CONTACT.CUSTOM_FIELD.get().catch((e) => {
    // todo add sentry
    showErrorToast(
      "There was a problem finding your custom fields. Please reload the browser to try again."
    );
    return Promise.reject(e);
  });

export const createCustomField = (data) =>
  Api.CONTACT.CREATE_CUSTOM_FIELD.post({
    data,
  }).catch((e) => {
    // todo add sentry
    showErrorToast();
    return Promise.reject(e);
  });

export const updateCustomField = (data) =>
  Api.CONTACT.CUSTOM_FIELD.put({
    urlExtend: `/${data.id}`,
    data,
  }).catch((e) => {
    // todo add sentry
    showErrorToast();
    return Promise.reject(e);
  });

export const deleteCustomField = (id) =>
  Api.CONTACT.CUSTOM_FIELD.delete({
    urlExtend: `/${id}`,
  }).catch((e) => {
    // todo add sentry
    showErrorToast(
      "There was a problem deleting your custom field please try again."
    );
    return Promise.reject(e);
  });

function showErrorToast(
  message = "There was a problem saving your custom field please try again."
) {
  AlertActions.launchToast({
    type: "error",
    message,
  });
}
