import { Link } from "react-router-dom";
import { TableCell, TableRow } from "style/components";
import React from "react";
import PropTypes from "prop-types";
import JourneyActionMenu from "../journey-action-menu";

const JourneysTableRow = ({ journey = {}, handleEdit, handleDelete }) => {
  const {
    id,
    name = "",
    duration_in_days = 0,
    number_of_steps = 0,
    active_constituents = 0,
    journey_group: { name: journeyGroupName = "" } = {},
  } = journey;

  return (
    <TableRow>
      <TableCell>
        <Link to={`/cadences/${id}`}>{name}</Link>
      </TableCell>
      <TableCell>{number_of_steps}</TableCell>
      <TableCell>{`${duration_in_days} days`}</TableCell>
      <TableCell>{active_constituents}</TableCell>
      <TableCell>{journeyGroupName}</TableCell>
      <TableCell>
        <JourneyActionMenu
          journey={journey}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          type="threeDotMenu"
        />
      </TableCell>
    </TableRow>
  );
};

JourneysTableRow.propTypes = {
  journey: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default JourneysTableRow;
