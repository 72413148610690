import { Route, Switch } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import JourneyPage from "./components/journey/journey-page";
import JourneysTable from "./components/journeys/journeys-table";

const JourneysRouteController = ({ oid }) => {
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/cadences"
          render={({ history }) => (
            <JourneysTable oid={oid} history={history} />
          )}
        />
        <Route
          path="/cadences/:id?"
          render={({ match: { params }, history }) => {
            const journeyId = params.id ? parseInt(params.id, 10) : undefined;
            return (
              <JourneyPage
                history={history}
                journeyId={journeyId}
                baseRoute="/cadences/"
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

JourneysRouteController.propTypes = {
  oid: PropTypes.number,
};

JourneysRouteController.defaultProps = {
  oid: null,
};

export default JourneysRouteController;
