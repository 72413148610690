import React from "react";
import { Icon, AlertActions, ActionsMenu } from "@evertrue/et-components";
import { colors } from "style/colors";
import EmmaDisconnectModal from "./emma-disconnect-modal";
import EmmaReauthModal from "./emma-reauth-modal";
import EmmaInfoModal from "./emma-info-modal";

export const launchToast = (message = "Success", type = "success") => {
  setTimeout(() => {
    AlertActions.launchToast({
      type,
      message,
      timeout: 5000,
    });
  }, 800);
};

const AccountActions = ({ account, refresh }) => {
  return (
    <ActionsMenu
      trigger={() => <Icon icon="more-horiz" size={2} />}
      options={[
        {
          id: 1,
          icon: "edit",
          label: "Edit Account Name",
          mountOnClick: ({ close, is_open }) => (
            <EmmaInfoModal
              onSuccess={() =>
                launchToast("Account Information successfully Updated")
              }
              onError={(message) => launchToast(message, "error")}
              account={account}
              refresh={refresh}
              isOpen={is_open}
              onClose={close}
            />
          ),
        },
        {
          id: 2,
          icon: "lock",
          label: "Update Credentials",
          mountOnClick: ({ close, is_open }) => (
            <EmmaReauthModal
              onSuccess={() => launchToast("Account credentials updated")}
              onError={(message) => launchToast(message, "error")}
              account={account}
              refresh={refresh}
              onClose={close}
              isOpen={is_open}
            />
          ),
        },
        {
          id: 3,
          icon: "delete",
          label: "Disconnect Account",
          iconStyle: { color: colors.red },
          mountOnClick: ({ close, is_open }) => (
            <EmmaDisconnectModal
              onClose={close}
              isOpen={is_open}
              account={account}
              open_key="disconnect"
              refresh={refresh}
              onSuccess={() => launchToast("Account Successfully Disconnected")}
              onError={(message) => launchToast(message, "error")}
            />
          ),
        },
      ]}
    />
  );
};

export default AccountActions;
