import React from "react";
import T from "prop-types";
import { Checkbox, ModalForm } from "@evertrue/et-components";
import { FormWrapper } from "style/components/form";
import { Label, StatusSection } from "./custom-field-modal-style";

const prop_types = {
  header: T.string.isRequired,
  width: T.number.isRequired,
  disableFunc: T.func.isRequired,
  data: T.object,
};

const default_props = { data: {} };

const CustomFieldValuesModal = (props) => (
  <ModalForm
    {...props}
    render={({ state, setState }) => (
      <FormWrapper>
        <Label>Name</Label>
        <input
          type="text"
          value={state.value}
          onChange={({ target: { value } }) => setState({ value: value })}
        />
        <StatusSection>
          <Checkbox
            checked={state.active === true}
            onChange={(val) => setState({ active: val })}
          >
            <div>
              <strong>Active</strong>
              <div>
                Enable gift officers and admins to use this value when updating
                and creating interactions.
              </div>
            </div>
          </Checkbox>
        </StatusSection>
      </FormWrapper>
    )}
  />
);

CustomFieldValuesModal.propTypes = prop_types;
CustomFieldValuesModal.defaultProps = default_props;

export default CustomFieldValuesModal;
