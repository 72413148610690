import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "style/colors";
import MappingItem from "apps/importer/components/import-mapping/mapping-item";
import { SectionIcon } from "apps/importer/common/importer-styled-components";
import { formatFieldName, formatNamingID } from "apps/importer/components/import-mapping/utils";

export const DefaultSectionDelimiter = "::SectionID=";

const Section = ({
  schema,
  sectionIndex,
  fieldColumns,
  draggableItems,
  unmapValue,
  isSectionAdd,
  addField,
  removeField,
  addSection,
  removeSection,
  sectionCount,
  viewOnly,
}) => {
  const { schema: nestedSchema, type, display_name } = schema;

  /* This function is being used so that we can display sequential numbers for the field_names
  it recognizes if there are more that 1 and it will not display "1" if there is ONLY one */
  const renderMappingItems = () => {
    let itemSequenceCounter = null;
    const fieldNames = nestedSchema.map((item) => item.field_name);

    return nestedSchema.map(({ field_name, section_id }, index) => {
      const currentFieldIndex = fieldNames.indexOf(field_name);
      const finalFieldIndex = fieldNames.lastIndexOf(field_name);
      const previousField = fieldNames[index - 1] || {};

      const isInSequence = currentFieldIndex !== finalFieldIndex;
      const isNewSequence =
        currentFieldIndex !== fieldNames.indexOf(previousField);

      itemSequenceCounter = isNewSequence ? null : itemSequenceCounter;
      itemSequenceCounter = isInSequence ? itemSequenceCounter + 1 : null;

      const formattedFieldName = formatFieldName(field_name);
      const namingID = formatNamingID(section_id, formattedFieldName);
      return (
        <MappingItem
          key={namingID}
          fieldColumn={fieldColumns[namingID]}
          index={index}
          addField={addField}
          removeField={removeField}
          draggableItems={draggableItems}
          unmapValue={unmapValue}
          sequenceCounter={itemSequenceCounter}
          viewOnly={viewOnly}
        />
      );
    });
  };

  const multiSectionIcon =
    !viewOnly && (
      sectionCount < 2 ? (
        <SectionIcon
          icon="add-circle"
          size={1}
          onClick={() => addSection(schema, sectionIndex)}
        />
      ) : (
        <SectionIcon
          icon="trash"
          onClick={() => removeSection(schema, sectionIndex)}
        />
      )
    );

  return (
    <StyledSection>
      <FlexSectionHeader type={type}>
        <div>
          {display_name} {type === "multiple_section" ? sectionCount : ""}
        </div>
        {isSectionAdd && multiSectionIcon}
      </FlexSectionHeader>
      {schema && renderMappingItems()}
    </StyledSection>
  );
};

Section.propTypes = {
  schema: PropTypes.object,
  sectionIndex: PropTypes.number.isRequired,
  fieldColumns: PropTypes.object.isRequired,
  draggableItems: PropTypes.object.isRequired,
  unmapValue: PropTypes.func.isRequired,
  sectionCount: PropTypes.number,
  isSectionAdd: PropTypes.bool,
  addField: PropTypes.func,
  removeField: PropTypes.func,
  addSection: PropTypes.func,
  removeSection: PropTypes.func,
  viewOnly: PropTypes.bool.isRequired,
};

Section.defaultProps = {
  schema: {},
  isSectionAdd: false,
  sectionCount: null,
  addField: () => {},
  removeField: () => {},
  addSection: () => {},
  removeSection: () => {},
};

const FlexSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  margin-bottom: 6px;
  margin-left: ${(props) =>
    ["multiple_section", "single_section", "linked_section"].includes(
      props.type
    ) && "-20px"};
`;

const StyledSection = styled.div`
  padding-bottom: 8px;
  padding-top: 8px;
  border-top: 1px solid ${colors.border};
`;

export default Section;
