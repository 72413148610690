import React from "react";
import PageLayout from "apps/layout/components/page-layout";
import { Redirect, Route, Switch } from "react-router-dom";
import RmTeamsController from "apps/relationship-management/controllers/rm-teams-controller";
import AssignmentTitlesController from "apps/relationship-management/controllers/assignment-titles-controller";
import Stages from "apps/stages/controllers/stages-controller";

const RelationshipManagementRouteController = () => (
  <PageLayout
    contentTop="113px" // hack for absolute positioning. couldn't get proper scrolling to work otherwise
    navItems={[
      { to: "/relationship-management/teams", label: "Teams", key: 1 },
      { to: "/relationship-management/stages", label: "Stages", key: 2 },
      {
        to: "/relationship-management/assignment-titles",
        label: "Assignment Titles",
        key: 3,
      },
    ]}
    title={"Relationship Management"}
    subtitle="Configure data for relationship management"
  >
    <Switch>
      <Route
        path="/relationship-management/teams"
        render={() => <RmTeamsController groupType={"TEAM"} />}
      />
      <Route
        path="/relationship-management/stages/:id?"
        render={({ match: { params }, history }) => {
          const stage_group_id = params.id
            ? parseInt(params.id, 10)
            : undefined;
          return (
            <Stages
              history={history}
              stageGroupId={stage_group_id}
              groupType="TEAM"
              baseRoute="/relationship-management/stages/"
            />
          );
        }}
      />
      <Route
        path="/relationship-management/assignment-titles/:id?"
        render={({ match: { params }, history }) => {
          const assignment_title_id = params.id
            ? parseInt(params.id, 10)
            : undefined;
          return (
            <AssignmentTitlesController
              history={history}
              assignmentTitleId={assignment_title_id}
              baseRoute="/relationship-management/assignment-titles/"
              groupType="TEAM"
            />
          );
        }}
      />
      <Route
        path="/relationship-management"
        render={() => <Redirect to="/relationship-management/teams" />}
      />
    </Switch>
  </PageLayout>
);

export default RelationshipManagementRouteController;
