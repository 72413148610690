import React, { useEffect, useState, Fragment } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Api from "utils/api";
import {
  BodyWrapper,
  ProcessContainer,
  SectionHeader,
  HeadersContainer,
  Header,
  BoldText,
} from "apps/importer/common/importer-styled-components";
import { DetailListButton } from "apps/importer/common/components/detail-list-button";
import { Icon } from "@evertrue/et-components";
import ImportPreCheckModal, {
  MODAL_ACTIONS,
} from "apps/importer/components/import-upload/import-precheck-modal";
import Uploader from "apps/importer/components/import-upload/uploader";
import ImportType from "apps/importer/components/import-upload/import-type";
import { ProcessMessage } from "apps/importer/components/import-preprocess/process-message";

import { useTracking } from "apps/importer/hooks/use-tracking";
import { usePollingResults } from "apps/importer/hooks/use-polling-results";

import { WORKER_TYPE, getProcessState, TRANSCODE, STATUS } from "apps/importer/import-helper";
import { launchToast } from "utils/show-toast";

const ImportUpload = withRouter(({ history, match }) => {
  const [jobId, setJobId] = useState(Number(match.params.jobId) || null);
  const [isModalActive, setIsModalActive] = useState(false);
  const [uploadFilename, setUploadFilename] = useState(null);

  const {
    jobData,
    jobData: { s3_filename },
    results,
    status,
  } = usePollingResults({
    jobId,
    workerType: WORKER_TYPE.FILE_CHECK,
    history,
  });

  useEffect(() => {
    if (s3_filename) {
      setUploadFilename(s3_filename);
    }
  }, [s3_filename]);

  const { headers, mapping_types: mappingOptions } = results.values
    ? results.values
    : {};

  const { trackEvent } = useTracking({
    history,
    trackingString: "Import Upload Page",
  });

  const currentProcessState = getProcessState(TRANSCODE, jobData.status);

  useEffect(() => {
    if (currentProcessState === STATUS.CANCELLED) {
      setIsModalActive(false);
      return
    }
    if (match.params.jobId && currentProcessState !== STATUS.CANCELLED) {
      setIsModalActive(true);
    }
  }, [currentProcessState, match.params.jobId]);

  const onModalAction = ({ modalAction }) => {
    setIsModalActive(false);

    if (modalAction === MODAL_ACTIONS.CANCEL && !match.params.jobId) {
      setJobId(null);
    }
  };

  const handleUploaderDrop = async ({ filename }) => {
    const fileTypeToCompressionMap = {
      ".zip": "ZIP",
      ".gz": "GZIP",
      ".csv": "NONE",
    };

    const fileType = filename.toLowerCase().slice(filename.lastIndexOf("."), filename.length);

    try {
      const {
        data: { id },
      } = await Api.IMPORT.IMPORT_JOB.post({
        data: {
          s3_filename: filename,
          source: "CSV",
          compression: fileTypeToCompressionMap[fileType],
          notify: true,
        },
      });

      setUploadFilename(filename);
      setJobId(id);
      setIsModalActive(true);
    } catch (error) {
      launchToast({
        message: `Unable to drop file for filename:${filename}`,
        type: "error",
      });
    }

    trackEvent({ event: "Importer Pre Check modal" });
  };

  const handleUploadReset = () => {
    setUploadFilename(null);
    setJobId(null);
  };

  return (
    <React.Fragment>
      <DetailListButton />
      <BodyWrapper>
        <ProcessContainer>
          {currentProcessState === STATUS.CANCELLED ? (<div>
            <SectionHeader>
              <BoldText>Upload File </BoldText>
              <div>Select and upload the file to import.</div>
              <ProcessMessage
                status={results.status}
                messageText={"Import Cancelled"}
                importCancelled={true}
              />
            </SectionHeader>
          </div>) : (<Fragment>
            <Uploader
              filename={uploadFilename}
              onReset={handleUploadReset}
              onDrop={handleUploaderDrop}
              history={history}
            />
            <SectionHeader>File Headers:</SectionHeader>
            <HeadersContainer>
              {headers &&
                headers.length > 0 &&
                headers.map((header, index) => (
                  <Header key={`${header}-${index + 1}`}>
                    {index + 1}. {header}
                  </Header>
                ))}
            </HeadersContainer>
          </Fragment>)}
        </ProcessContainer>

        <ImportType
          jobId={jobId}
          onImportRedirectTo={(path) => history.push(path)}
          mappingOptions={mappingOptions}
        />
      </BodyWrapper>
      <ImportPreCheckModal
        jobId={jobId}
        isOpen={isModalActive}
        onModalAction={onModalAction}
        history={history}
      />
    </React.Fragment>
  );
});

export default ImportUpload;
