import styled from "styled-components";
import { colors } from "style/colors";

export const ActionBar = styled.div`
  display: flex;
  width: 100%;
  padding: 18px 16px;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.background};
`;

export const SearchBoxWrapper = styled.div`
  width: 300px;
`;

export const CustomFieldTableWrapper = styled.tr`
  .dnd-controller--is-dragging {
    background: ${colors.blueGray};
  }
`;
