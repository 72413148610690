import React from "react";
import styled from "styled-components";
import HelpHeaderButton from "./help-header-button";

const IconGroup = styled.div`
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
`;

const IconHeaderGroup = ({ user }) => {
  return (
    <IconGroup>
      <HelpHeaderButton user={user} />
    </IconGroup>
  );
};

export default IconHeaderGroup;
