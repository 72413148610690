export default {
  AUTH: {
    ORGS: "auth/organizations",
    USERS_SEARCH: "auth/users/search",
    SESSION: "auth/session",
    REGISTER: "auth/registrations",
    SKIFF: "skiff",
    INTEGRATIONS: "auth/integrations",
    INTEGRATION: "auth/integrations/:id",
    NANGO_SESSION: "auth/nango/sessions",
  },
  ASSIGNMENTS: {
    POOLS: "assignments-java/v2/pools",
    ASSIGNMENT_TITLES: "assignments-java/v2/assignments/titles",
  },
  RENXT_INTEGRATION: {
    REDIRECT: "auth/integrations/blackbaud/renxt/redirect",
    INTEGRATION: "auth/integrations/blackbaud/renxt",
    AUTHENTICATE: "auth/integrations/blackbaud/renxt/authenticate",
    ACCESS_TOKEN: "auth/integrations/blackbaud/renxt/access_token",
  },
  INTERACTION: {
    TYPES: "ugc/v2/types/interaction",
    IMPORTED_TYPES: "ugc/v2/types/form_values/interaction/all",
    CUSTOM_FIELD: "ugc/v2/interactions/custom_fields",
    CUSTOM_FIELD_VALUES:
      "ugc/v2/interactions/custom_field_values/:customFieldId",
  },
  VOLUNTEERS: {
    STAGES: "assignments-java/v2/stages",
    UPDATE_STAGE: "assignments-java/v2/assignments/:assignment_id/stage",
  },
  CONTACT: {
    CONTACT: "contacts/v1/contacts/:id",
    CUSTOM_FIELD: "contacts/v1/properties",
    CREATE_CUSTOM_FIELD: "contacts/v1/properties/nested",
  },
  DNA: {
    GATES: "dna/gates",
  },
  SEARCH: {
    CONTACT_NOTE: "search/v2/contact_note/search",
    ASSIGNMENTS: "search/v2/assignment/search",
  },
  SUBMISSIONS: {
    SEARCH: "suggestions/v1/submissions/search",
    APPROVE: "suggestions/v1/suggestions/:id/approve",
    REJECT: "suggestions/v1/suggestions/:id/reject",
    APPROVE_BULK: "suggestions/v1/suggestions/approve/bulk",
    REJECT_BULK: "suggestions/v1/suggestions/reject/bulk",
    OPEN: "suggestions/v1/suggestions/:id/open",
  },
  EXPORT: {
    SUGGESTIONS: "exporter/v1/submit/suggestion",
  },
  IMPORT: {
    IMPORTS: "importer/v3/jobs",
    IMPORT_JOB: "importer/v3/jobs/",
    IMPORT_JOB_ID: "importer/v3/jobs/:job_id",
    IMPORT_JOB_RESULTS: "importer/v3/jobs/:job_id/results",
    IMPORT_INFO: "importer/v3/uploads/info",
    IMPORT_HEADERS: "importer/v3/jobs/:filename/headers",
    IMPORT_MAPPING: "importer/v3/jobs/:job_id/mappings",
    IMPORT_MAPPING_TEMPLATE: "importer/v3/jobs/:job_id/mappings/template",
    IMPORT_JOB_QUEUE: "importer/v3/jobs/:job_id/queue",
    IMPORT_JOB_START: "importer/v3/jobs/:job_id/import",
    IMPORT_JOB_CANCEL: "importer/v3/jobs/:job_id/cancel",
    IMPORT_LOGS: "importer/v3/jobs/:job_id/download",
    LATEST: "importer/v3/jobs/latest",
  },
  EMMA: {
    ACCOUNTS: "emma/v1/account",
    ACCOUNT: "emma/v1/account/:id",
    ACCOUNT_CREDS: "emma/v1/account/:id/credentials",
  },
  PROPOSAL: {
    STAGES: "ugc/v2/proposal/stage",
    CUSTOM_FIELDS: "ugc/v2/proposal/custom_fields",
    CUSTOM_FIELD_PICKLIST_VALUES:
      "ugc/v2/proposal/custom_fields/picklist/:customFieldId",
    TYPES: "ugc/v2/types/proposal",
    IMPORTED_TYPES: "ugc/v2/types/form_values/proposal/all",
    SOLICITOR_TITLES: "ugc/v2/solicitor_titles/proposal",
    IMPORTED_SOLICITOR_TITLES:
      "ugc/v2/solicitor_titles/form_values/proposal/all",
    DESIGNATIONS: "ugc/v2/designations/proposal",
  },
  GRAPHQL: {
    GRAPHQL: "graphql",
  },
  JOURNEYS: {
    JOURNEY: "journeys/v1/journeys/:id",
    JOURNEYS: "journeys/v1/journeys",
    JOURNEY_TASK: "journeys/v2/journey-tasks/:id",
    DEACTIVATE_JOURNEY_TASK: "journeys/v2/journey-tasks/:id/inactive",
    JOURNEY_TASKS: "journeys/v2/journey-tasks",
    ACTION_TYPES: "journeys/v2/tasks/action-types",
    JOURNEY_GROUPS: "journeys/v1/journey-groups",
    JOURNEY_GROUP: "journeys/v1/journey-groups/:id",
    JOURNEY_GROUP_USER: "journeys/v1/journey-group-users/:id",
    BULK_JOURNEY_GROUP_USERS: "journeys/v1/journey-group-users/bulk",
    ONBOARD: "journeys/v1/onboard/dxo",
  },
  OUTREACH: {
    ONBOARD_VOIP: "outreach/v1/voip/onboard",
    OFFBOARD_VOIP: "outreach/v1/voip/offboard",
    ORG_SETTINGS: "outreach/v1/voip/org-settings",
  },
};
