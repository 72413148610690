import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { colors } from "style/colors";
import { Button, Icon } from "@evertrue/et-components";
import {
  SectionHeader,
  BoldText,
} from "apps/importer/common/importer-styled-components";

import {
  ImportProgressBar,
  ProgressPlaceHolder,
} from "apps/importer/common/components/import-progress-bar";

import Api from "utils/api";
import { createS3Client } from "scripts/apps/importer/create-s3-client";
import { launchToast } from "utils/show-toast";

const Uploader = ({ onReset, filename, onDrop }) => {
  const [uploadPercent, setUploadPercent] = useState(0);

  const s3FilePut = async ({ file }) => {
    const {
      data: { tmp_id, tmp_key, session_token, path, filename, bucket },
    } = await Api.IMPORT.IMPORT_INFO.get({
      params: { filename: file[0].name },
    });

    const s3Client = createS3Client({
      roleArn: "arn:aws:iam::037590317780:role/AWSS3UploadRole",
      accessKeyId: tmp_id,
      secretAccessKey: tmp_key,
      sessionToken: session_token,
      bucket,
    });

    const uploader = s3Client.upload({
      Key: path,
      Body: file[0],
      ContentType: file[0].type,
    });

    uploader.on("httpUploadProgress", ({ loaded, total }) => {
      const percentComplete = (loaded / total) * 100;
      // If the progress bar is <= 1, we set the value to 100.
      // The lowest granularity we can allow is 2.
      const calculatedPercent = percentComplete > 1 ? percentComplete : 2;
      setUploadPercent(Math.ceil(calculatedPercent));
    });

    uploader.send((err) => {
      if (err) {
        console.log("ERROR", err, err.stack);
        launchToast({
          message: "Error - File Not Uploaded",
          type: "error",
        });
      } else {
        onDrop({ filename });
      }
    });
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      accept: [".csv"],
      onDropAccepted: (file) => {
        s3FilePut({ file });
      },
      onDropRejected: () => {
        launchToast({
          message:
            "This is not a valid file. Only the following file types are accepted: .csv",
          type: "error",
        });
      },
      disabled: filename,
      multiple: false,
    });

  return (
    <React.Fragment>
      <SectionHeader>
        <BoldText>Upload File </BoldText>
        <div>Select and upload the file to import.</div>
      </SectionHeader>
      {uploadPercent ? (
        <ImportProgressBar percent={uploadPercent} />
      ) : (
        <ProgressPlaceHolder />
      )}
      <DropArea {...getRootProps({ isDragAccept, isDragActive })}>
        {filename ? (
          <React.Fragment>
            <ClearDrop>
              <Button
                type="simple"
                onClick={() => {
                  setUploadPercent(0);
                  onReset();
                }}
              >
                <Icon icon="clear" size={2} />
              </Button>
            </ClearDrop>
            <Icon icon="proposal" size={5} />
            <UploadText>{filename}</UploadText>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Icon icon="dragndrop" size={5} />
            <input {...getInputProps()} />
            <UploadText>
              Drag and drop or <Highlight>select from here</Highlight>
            </UploadText>
          </React.Fragment>
        )}
      </DropArea>
    </React.Fragment>
  );
};

Uploader.propTypes = {
  onReset: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  filename: PropTypes.string,
};

Uploader.defaultProps = {
  filename: null,
};

const getColor = ({ isDragAccept, isDragActive }) => {
  if (isDragAccept) {
    return colors.darkBase;
  }
  if (isDragActive) {
    return colors.green;
  }
  return colors.darkerText;
};

const DropArea = styled.div`
  border-width: 2px;
  border-style: dashed;
  border-color: ${(props) => getColor(props)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  height: 30%;
  margin: 5% 5% 0 5%;
  border-radius: 8px;
`;

const Highlight = styled.span`
  color: ${colors.linkText};
`;

const ClearDrop = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-right: 5%;
`;

const UploadText = styled.span`
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  padding-top: 12px;
`;

export default Uploader;
