import React from "react";
import PropTypes from "prop-types";
import { Modal2, ModalConfirm } from "@evertrue/et-components";
import { Formik, Field } from "formik";
import { Label } from "style/components/form";

const FormField = ({ name, label, ...rest }) => (
  <React.Fragment>
    {label && (
      <Label
        htmlFor={name}
        css={`
          width: 100%;
          margin-bottom: 2px;
        `}
      >
        {label}
      </Label>
    )}
    <Field name={name} id={name} type="text" {...rest} />
  </React.Fragment>
);

// this replaces a similar component made on an alpha release of et-components
// It was made to avoid rewriting the 4 modals that were relying on it
const ModalEditor = ({
  onClose,
  isOpen,
  initialValues,
  header,
  saveLabel,
  children,
  validate,
  onSubmit,
  modalWidth = 440,
  ...rest
}) => {
  const [node, setRef] = React.useState(null);

  // manually call validate after we set a ref
  // so that the validation runs on mount, otherwise submit button is enabled
  React.useEffect(() => {
    if (node) {
      node.getFormikActions().validateForm();
    }
  }, [node]);

  return (
    <Modal2
      contentStyle={{ width: modalWidth }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Formik ref={setRef} initialValues={initialValues} validate={validate}>
        {({ errors, values, dirty, touched }) => (
          <ModalConfirm
            hasMargin={true}
            header={header}
            onSubmit={() => onSubmit(values, dirty, touched)}
            closeModal={onClose}
            saveLabel={saveLabel}
            disableSave={
              Boolean(Object.keys(errors).length) || !dirty || !touched
            }
            saveButtonType="submit"
          >
            {children}
          </ModalConfirm>
        )}
      </Formik>
    </Modal2>
  );
};
ModalEditor.FormField = FormField;
ModalEditor.propTypes = {
  children: PropTypes.any,
  header: PropTypes.string,
  initialValues: PropTypes.object,
  isOpen: PropTypes.bool,
  modalWidth: PropTypes.number,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  saveLabel: PropTypes.string,
  validate: PropTypes.func,
};

export default ModalEditor;
