import { LinkButton } from "@evertrue/et-components";
import styled from "styled-components";

import { colors } from "style/colors";

export const Container = styled.div`
  width: 100%;
  border: 1px solid lightgrey;
  background-color: ${colors.white};
  height: 100%;
  overflow-y: scroll;
`;

export const FieldsList = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px;
  line-height: 16px;

  div {
    width: max-content;
    padding-right: 6px;
    min-width: 160px;
    float: none;
  }
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.type === "skipped" ? "35%" : "60%")};
`;

export const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MappingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${(props) => (props.type === "mapping" ? "600px" : "450px")};
  height: 700px;
`;

export const StyledLinkButton = styled(LinkButton)`
  margin-right: 8px;
`;

export const FloatRightDiv = styled.div`
  display: flex;
  justify-content: right;
`;
