import { colors } from "style/colors";

export const STATUS = {
  UNKNOWN: "unknown",
  RUNNING: "running",
  FAILED: "failed",
  ERROR: "error",
  SUCCESS: "success",
  CANCELLED: "cancelled",
};

export const FILECHECK = "filecheck";
export const TRANSCODE = "transcode";
export const IMPORT = "import";

export const WORKER_TYPE = {
  FILE_CHECK: "FILE_CHECK",
  TRANSCODE: "TRANSCODE",
  IMPORT: "IMPORT",
};

export const WORKER_TYPE_TO_PROCESS = {
  [WORKER_TYPE.FILE_CHECK]: FILECHECK,
  [WORKER_TYPE.TRANSCODE]: TRANSCODE,
  [WORKER_TYPE.IMPORT]: IMPORT,
};

export const STATUS_MAP = {
  CANCELLED: "CANCELLED",
  FAILED: "FAILED",
  NEW: "NEW",
  OK: "OK",
  WARN: "WARN",
  PREPROCESS_ERROR: "PREPROCESS_ERROR",
  PREPROCESS_FAILED: "PREPROCESS_FAILED",
  PREPROCESS_FORKED: "PREPROCESS_FORKED",
  PREPROCESS_QUEUED: "PREPROCESS_QUEUED",
  PREPROCESS_RUNNING: "PREPROCESS_RUNNING",
  PREPROCESS_SUCCESS: "PREPROCESS_SUCCESS",
  PREPROCESS_WARNING: "PREPROCESS_WARNING",
  IMPORT_ERROR: "IMPORT_ERROR",
  IMPORT_FAILED: "IMPORT_FAILED",
  IMPORT_FORKED: "IMPORT_FORKED",
  IMPORT_QUEUED: "IMPORT_QUEUED",
  IMPORT_RUNNING: "IMPORT_RUNNING",
  IMPORT_SUCCESS: "IMPORT_SUCCESS",
  FILECHECK_FAILED: "FILECHECK_FAILED",
  FILECHECK_FORKED: "FILECHECK_FORKED",
  FILECHECK_QUEUED: "FILECHECK_QUEUED",
  FILECHECK_RUNNING: "FILECHECK_RUNNING",
  FILECHECK_SUCCESS: "FILECHECK_SUCCESS",
}

export const getStatusInfo = ({ status }) => {
  switch (status) {
    case STATUS_MAP.NEW:
      return { icon: "pause", color: colors.mainBase };

    case STATUS_MAP.OK:
    case STATUS_MAP.IMPORT_SUCCESS:
    case STATUS_MAP.FILECHECK_SUCCESS:
    case STATUS_MAP.PREPROCESS_SUCCESS:
      return { icon: "success-circle", color: colors.success, messageSubText: "Process Complete" };

    case STATUS_MAP.FILECHECK_FORKED:
    case STATUS_MAP.FILECHECK_RUNNING:
    case STATUS_MAP.PREPROCESS_FORKED:
    case STATUS_MAP.PREPROCESS_RUNNING:
    case STATUS_MAP.IMPORT_FORKED:
    case STATUS_MAP.IMPORT_RUNNING:
      return { icon: "spinner", color: colors.mainBase };

    case STATUS_MAP.WARN:
    case STATUS_MAP.PREPROCESS_WARNING:
    case STATUS_MAP.PREPROCESS_ERROR:
    case STATUS_MAP.IMPORT_ERROR:
      return { icon: "warning", color: colors.warning, messageSubText: "Process Completed with Warnings" };

    case STATUS_MAP.IMPORT_QUEUED:
    case STATUS_MAP.PREPROCESS_QUEUED:
    case STATUS_MAP.FILECHECK_QUEUED:
      return { icon: "list", color: colors.accent };

    case STATUS_MAP.PREPROCESS_FAILED:
      return { icon: "cancel-circle", color: colors.error, messageSubText: "Critical Error(s) Occurred:" };

    case STATUS_MAP.FAILED:
    case STATUS_MAP.CANCELLED:
    case STATUS_MAP.IMPORT_FAILED:
    case STATUS_MAP.FILECHECK_FAILED:
      return { icon: "cancel-circle", color: colors.error, messageSubText: "A Critical Error Occurred" };

    default:
      return { icon: "minus-circle" };
  }
};

export const getJobRoute = (jobId, mappingComplete, jobStatus) => {
  if (mappingComplete && !jobStatus.includes("FILECHECK")) {
    return `/importer/${jobId}/import-preprocess`;
  }

  return `/importer/${jobId}/import-upload`;
};

/**
 * Helper methods to determine the state of an import
 */
export const isImportRunning = (jobStatus) => {
  return (
    jobStatus === STATUS_MAP.IMPORT_QUEUED ||
    jobStatus === STATUS_MAP.IMPORT_FORKED ||
    jobStatus === STATUS_MAP.IMPORT_RUNNING ||
    jobStatus === STATUS_MAP.RECONCILE_PENDING
  );
};

export const isImportSuccess = (jobStatus) => {
  return jobStatus === STATUS_MAP.IMPORT_SUCCESS || jobStatus === STATUS_MAP.OK;
};

export const isImportFailed = (jobStatus) => {
  return (
    jobStatus === STATUS_MAP.IMPORT_FAILED ||
    jobStatus === STATUS_MAP.FAILED
  );
};

export const isPreprocessRunning = (jobStatus) => {
  return (
    jobStatus === STATUS_MAP.PREPROCESS_QUEUED ||
    jobStatus === STATUS_MAP.PREPROCESS_FORKED ||
    jobStatus === STATUS_MAP.PREPROCESS_RUNNING
  );
};

export const isPreprocessSuccess = (jobStatus) => {
  return (
    jobStatus === STATUS_MAP.PREPROCESS_SUCCESS ||
    isImportRunning(jobStatus) ||
    isImportSuccess(jobStatus) ||
    isImportFailed(jobStatus)
  );
};

export const isPreProcessWarning = (jobStatus) => {
  return jobStatus === STATUS_MAP.PREPROCESS_WARNING || jobStatus === STATUS_MAP.PREPROCESS_ERROR;
};

export const isImportWarning = (jobStatus) => {
  return jobStatus === STATUS_MAP.IMPORT_ERROR;
};

export const isPreprocessFailed = (jobStatus) => {
  return jobStatus === STATUS_MAP.PREPROCESS_FAILED;
};

export const isFileCheckRunning = (jobStatus) => {
  return (
    jobStatus === STATUS_MAP.FILECHECK_QUEUED ||
    jobStatus === STATUS_MAP.FILECHECK_FORKED ||
    jobStatus === STATUS_MAP.FILECHECK_RUNNING
  );
};
export const isFileCheckSuccess = (jobStatus) => {
  return (
    jobStatus === STATUS_MAP.FILECHECK_SUCCESS ||
    isPreprocessRunning(jobStatus) ||
    isPreprocessSuccess(jobStatus) ||
    isPreprocessFailed(jobStatus) ||
    isImportRunning(jobStatus) ||
    isImportSuccess(jobStatus) ||
    isImportFailed(jobStatus)
  );
};
export const isFileCheckFailed = (jobStatus) => {
  return jobStatus === STATUS_MAP.FILECHECK_FAILED;
};

export const isRunning = (jobStep, status) => {
  if (jobStep === FILECHECK) {
    return isFileCheckRunning(status);
  }

  if (jobStep === TRANSCODE) {
    return isPreprocessRunning(status);
  }

  if (jobStep === IMPORT) {
    return isImportRunning(status);
  }

  return false;
};

export const isSuccess = (jobStep, status) => {
  if (jobStep === FILECHECK) {
    return isFileCheckSuccess(status);
  }

  if (jobStep === TRANSCODE) {
    return isPreprocessSuccess(status);
  }

  if (jobStep === IMPORT) {
    return isImportSuccess(status);
  }

  return false;
};

export const isErrorOrWarning = (jobStep, status) => {
  if (jobStep === TRANSCODE) {
    return isPreProcessWarning(status);
  }

  if (jobStep === IMPORT) {
    return isImportWarning(status);
  }
};

export const isFailed = (jobStep, status) => {
  if (jobStep === FILECHECK) {
    return isFileCheckFailed(status);
  }

  if (jobStep === TRANSCODE) {
    return isPreprocessFailed(status);
  }

  if (jobStep === IMPORT) {
    return isImportFailed(status);
  }

  return false;
};

export const isCancelled = (jobStep, status) => {
  if (jobStep === IMPORT || jobStep === TRANSCODE){
     return status === STATUS_MAP.CANCELLED
  }
}

export const getProcessState = (jobStep, status) => {
  if (isFailed(jobStep, status)) {
    return STATUS.FAILED;
  } else if (isErrorOrWarning(jobStep, status)) {
    return STATUS.ERROR;
  } else if (isSuccess(jobStep, status)) {
    return STATUS.SUCCESS;
  } else if (isRunning(jobStep, status)) {
    return STATUS.RUNNING;
  } else if (isCancelled(jobStep, status)) {
    return STATUS.CANCELLED;
  } else {
    return STATUS.UNKNOWN;
  }
};

