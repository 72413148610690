import React, { Component } from "react";
import _ from "underscore";
import Decorator from "@evertrue/client-decorator";
import { Icon, Avatar, Popover2 } from "@evertrue/et-components";
import { Link } from "react-router-dom";
import T from "prop-types";
import api from "utils/api";
import { getOrgs } from "utils/org-utils";
import config from "utils/env";

import {
  DropDownButton,
  MenuButton,
  NameTag,
  DetailsText,
  HeaderDropdownBody,
  Header,
  StyledIcon,
  DropDownButtonWrapper,
  SubDetails,
} from "./user-header-dropdown-style";

class UserHeaderDropdown extends Component {
  static propTypes = {
    user: T.object,
    org: T.object,
  };

  static defaultProps = {
    user: {},
    org: {},
  };

  displayRoles = () => {
    const { user, org } = this.props;
    let roles = _.pluck(Decorator.User.getRoles(user, org.id), "name");
    if (user.super_user) {
      roles.push("Super User");
    }

    return (
      <div>
        Roles:
        <span key={"roles"}> {roles.join(", ", roles)} </span>
      </div>
    );
  };

  render() {
    const {
      user: {
        name,
        email,
        id: userID,
        user_profile_picture_url,
        super_user = false,
        password_set_at,
      } = {},
      org: { id: orgId, slug: orgSlug } = {},
    } = this.props;

    const multiOrgUser = getOrgs(this.props.user).length > 1;

    const changePasswordLink = config.isStageData()
      ? `https://stage-accounts.evertrue.com/evertrue/account/password/change/${orgSlug}`
      : `https://accounts.evertrue.com/evertrue/account/password/change/${orgSlug}`;

    return (
      <DropDownButtonWrapper>
        <Popover2
          contentWidth={300}
          trigger={({ togglePopover, is_open }) => (
            <DropDownButton lightText onClick={togglePopover}>
              <NameTag>{name}</NameTag>
              <Icon icon={is_open ? "chevron-up" : "chevron-down"} />
            </DropDownButton>
          )}
        >
          {({ closePopover }) => (
            <HeaderDropdownBody>
              <Header>
                <Avatar url={user_profile_picture_url} name={name} width={50} />
                <DetailsText leftPadding={"16px"}>
                  <strong>{name}</strong>
                  {orgSlug && <div>{`${orgId} • ${orgSlug}`}</div>}
                </DetailsText>
              </Header>

              <SubDetails>
                <DetailsText leftPadding={0}>
                  <strong>User:</strong>
                  {userID && <div>{`ID: ${userID}`}</div>}
                  {email && <div>{`Email: ${email}`}</div>}
                  {this.displayRoles()}
                </DetailsText>
              </SubDetails>

              {(super_user || multiOrgUser) && (
                <Link to="/auth/org" onClick={closePopover}>
                  <MenuButton>
                    <StyledIcon icon="switch" />
                    Change Organization
                  </MenuButton>
                </Link>
              )}

              {password_set_at && (
                <a href={changePasswordLink} data-bypass={true}>
                  <MenuButton>
                    <StyledIcon icon="change-password" />
                    Change Password
                  </MenuButton>
                </a>
              )}

              <MenuButton
                onClick={() => {
                  api.logout();
                  closePopover();
                }}
              >
                <StyledIcon icon="logout" />
                Sign Out
              </MenuButton>
            </HeaderDropdownBody>
          )}
        </Popover2>
      </DropDownButtonWrapper>
    );
  }
}

export default UserHeaderDropdown;
