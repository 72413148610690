import React from "react";
import { LDProvider } from "launchdarkly-react-client-sdk";
import config from "utils/env";
import PropTypes from "prop-types";
// Create the provider component for Launch Darkly
export const FlagProvider = ({ oid, user, children }) => {
  const clientSideId = config.getLaunchDarklyID();

  const organizationContext = {
    kind: "organization",
    key: oid,
  };

  const userContext = {
    kind: "user",
    key: user.id,
    name: user.name,
    email: user.email,
  };

  const platformContext = {
    kind: "platform",
    key: "data-web",
  };

  const multiContext = {
    kind: "multi",
    user: userContext,
    organization: organizationContext,
    platform: platformContext,
  };

  if (!user.id || !user.name || !user.email) {
    return null;
  }
  return (
    <LDProvider clientSideID={clientSideId} context={multiContext}>
      {children}
    </LDProvider>
  );
};

FlagProvider.propTypes = {
  oid: PropTypes.number.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
};
