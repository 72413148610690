import React from "react";
import styled from "styled-components";
import { colors } from "style/colors";
import { Button } from "@evertrue/et-components";

const ImportHelpDocs = () => {
  return (
    <HelpSection>
      <p>Have any questions on the CSV requirements?</p>
      <DocsLink
        href="https://evertrue.forumbee.com/category/implementation-data"
        target="_blank"
      >
        <DocsButton type="secondary">See Documentation</DocsButton>
      </DocsLink>
    </HelpSection>
  );
};

const HelpSection = styled.div`
  width: 90%;
  display: flex;
  border-top: 2px ${colors.border} solid;
  padding: 24px 0;
  margin-left: 24px;
  margin-right: 24px;
`;

const DocsLink = styled.a`
  width: 48%;
`;

const DocsButton = styled(Button)`
  width: 100%;
`;

export default ImportHelpDocs;
