import _ from "underscore";
// Get URL prefix to determine environment

const urlParts = window.location.host.split(".").reverse()[2].split("-");
const sandbox = "env_sandbox";

const urlEnvironment = urlParts.length > 1 ? urlParts[0] : "production";

// Environment data is based on localStorage first and url env second
const env = localStorage.getItem(sandbox) || urlEnvironment;

// Set the api url prefix based on environment
const apiPrefix = env !== "production" ? "stage-api" : "api";

const config = {
  appKey: "89567e74bec3afafc5f46db72b1a9323f41858256e3bec5dc9e522a7b3f3518f",
  baseRoute: `https://${apiPrefix}.evertrue.com/`,
  urlEnv: urlEnvironment,
  dataEnv: env === "production" ? "Production" : "Stage",
  evertrueUrl:
    env !== "production"
      ? "http://stage-app.evertrue.com"
      : "https://app.evertrue.com",
  redirectLoginUrl:
    env !== "production"
      ? "https://stage-accounts.evertrue.com/data/login"
      : "https://accounts.evertrue.com/data/login",
  isStageData() {
    return env !== "production";
  },
  isDevelopment() {
    return process.env.NODE_ENV !== "production";
  },
  isLocal() {
    return urlEnvironment === "local";
  },
  getUrlEnvironment() {
    return urlEnvironment;
  },
  switchEnvironment() {
    if (env !== "production") {
      localStorage.setItem(sandbox, "production");
    } else {
      localStorage.removeItem(sandbox);
    }

    localStorage.removeItem("oid");

    window.location.reload();
  },
  getLaunchDarklyID() {
    return env === "production"
      ? "66e07dc8faee100faf392388"
      : "66e07dd97d9438100bcfce07";
  },
};

if (config.isDevelopment()) {
  window._ = _;
}
export default config;
