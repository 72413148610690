import styled from "styled-components";
import { colors } from "style/colors";

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const Header = styled.div`
  width: 100%;
  height: 70px;
  padding: 12px 24px;
  background-color: ${colors.white};
  border-bottom: solid 1px ${colors.border};
  font-weight: normal;
  z-index: 3;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  h3 {
    font-size: 20px;
    line-height: 24px;
  }

  ${(props) =>
    props.isRow &&
    `
    flex-direction: column;
    justify-content: center;
    align-content: space-between;
  `}
`;

export const Subtitle = styled.div`
  color: ${colors.lightText};
  font-size: 14px;
  margin-top: 4px;
  flex-basis: 100%;
`;

export const ContentWrapper = styled.div`
  position: absolute;
  top: ${(props) => (props.top ? props.top : "70px")};
  bottom: 0px;
  right: 0px;
  left: 0px;
  overflow: auto;
`;
