import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { colors } from "style/colors";
import {
  Modal,
  ModalHeaderWithActions,
  ModalBody,
  ListboxSelect,
  Tooltip2 as ToolTip,
  Icon,
} from "@evertrue/et-components";
import {
  Form,
  JourneysFormField,
  JourneysRow,
  JourneysLabel,
  JourneysTextArea,
  JourneysInput,
} from "scripts/style/components/form";
import { getActionTypes } from "../../utils/api-requests/journeys-requests";
import { Formik } from "formik";
import { AddStepSchema } from "../../schemas/add-step-schema";
import CharacterCounter from "scripts/components/character-counter";

export const stepDefaults = (taskObj, actionTypes, stepCount) => {
  const {
    title: stepTitle,
    description: stepDescription,
    step_number: stepNumber,
    day_number: dayNumber,
    default_action_type: stepType,
  } = taskObj;

  const selectedType = stepType
    ? actionTypes.find((option) => option.value === stepType)
    : { value: "", label: "" };

  const taskDefaults = {
    stepTitle,
    stepDescription,
    stepNumber: stepNumber ? stepNumber : stepCount + 1,
    dayNumber,
    stepType: selectedType,
  };
  return taskDefaults;
};

const JourneyTaskModal = ({
  isOpen,
  onClose,
  existingJourneyTask = {},
  editJourneyTask = () => {},
  createJourneyTask = () => {},
  journeyId,
  stepCount,
}) => {
  const [editJourneyTaskId, setEditJourneyTaskId] = useState(
    existingJourneyTask.id
  );
  const [actionTypes, setActionTypes] = useState([]);

  const taskDefaults = stepDefaults(
    existingJourneyTask,
    actionTypes,
    stepCount
  );

  const handleSubmit = ({
    stepDescription,
    dayNumber,
    stepNumber,
    stepType,
    stepTitle,
  }) => {
    const payload = {
      description: stepDescription,
      day_number: Number(dayNumber),
      step_number: Number(stepNumber),
      journey_id: journeyId,
      default_action_type: stepType.value,
      title: stepTitle,
    };

    if (editJourneyTaskId) {
      editJourneyTask(payload, editJourneyTaskId);
    } else {
      const journeyTask = createJourneyTask(payload);
      setEditJourneyTaskId(journeyTask.id);
    }

    onClose();
  };

  useEffect(() => {
    const getAndSetActionTypes = async () => {
      const fetchedActionedTypes = await getActionTypes();
      setActionTypes(fetchedActionedTypes);
    };
    if (isOpen) getAndSetActionTypes();
  }, [isOpen]);

  if (actionTypes && !actionTypes.length) return null;
  return (
    <Modal isOpen={isOpen} closeModal={onClose} width={568}>
      <Formik
        initialValues={taskDefaults}
        validationSchema={AddStepSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        validateOnMount={true}
      >
        {({ handleChange, handleSubmit, values, isValid, setFieldValue }) => {
          return (
            <React.Fragment>
              <ModalHeaderWithActions
                title={editJourneyTaskId ? "Edit Step" : "Add Step"}
                submitLabel={editJourneyTaskId ? "Update" : "Create"}
                closeModal={onClose}
                onSubmit={handleSubmit}
                disableSubmit={!isValid}
              />
              <ModalBody scrollable={false}>
                <Form style={{ padding: "0" }}>
                  <JourneysRow>
                    <span>* Indicates required field</span>
                    <JourneysFormField>
                      <JourneysLabel htmlFor="display-name">
                        Step Name *
                      </JourneysLabel>
                      <JourneysInput
                        type="text"
                        onChange={handleChange("stepTitle")}
                        id="cadence-title"
                        value={values.stepTitle}
                      />
                      <CharacterCounter
                        limit={120}
                        count={values.stepTitle ? values.stepTitle.length : 0}
                      />
                    </JourneysFormField>
                    <JourneysFormField>
                      <JourneysLabel htmlFor="field-type">
                        Description
                      </JourneysLabel>
                      <JourneysTextArea
                        onChange={handleChange("stepDescription")}
                        id="cadence-description"
                        value={values.stepDescription}
                      />
                      <CharacterCounter
                        limit={250}
                        count={
                          values.stepDescription
                            ? values.stepDescription.length
                            : 0
                        }
                      />
                    </JourneysFormField>
                    <div style={{ display: "flex" }}>
                      <JourneysFormField style={{ flex: "0 100px" }}>
                        <JourneysLabel htmlFor="display-name">
                          Step Number *
                        </JourneysLabel>
                        <JourneysInput
                          type="text"
                          onChange={handleChange("stepNumber")}
                          name="name"
                          value={values.stepNumber}
                        />
                      </JourneysFormField>
                      <JourneysFormField
                        style={{
                          flex: "0 100px",
                          marginRight: "10px",
                        }}
                      >
                        <JourneysLabel
                          htmlFor="display-name"
                          style={{
                            display: "flex",
                          }}
                        >
                          Day Number
                          <ToolTip
                            trigger={
                              <div>
                                <Icon
                                  icon="question-circle"
                                  style={{
                                    color: colors.lightText,
                                  }}
                                />
                              </div>
                            }
                          >
                            <div style={{ maxWidth: 300 }}>
                              "Days" refer to business days (Monday through
                              Friday). Weekends and U.S. Federal Holidays are
                              not included.
                            </div>
                          </ToolTip>
                        </JourneysLabel>
                        <JourneysInput
                          type="text"
                          onChange={handleChange("dayNumber")}
                          name="name"
                          value={values.dayNumber}
                        />
                      </JourneysFormField>
                    </div>
                    <JourneysFormField>
                      <JourneysLabel htmlFor="display-name">
                        Step Type *
                      </JourneysLabel>
                      <ListboxSelect
                        label="Step Type"
                        emptyValue="Select Type"
                        options={actionTypes}
                        onChange={(v) => {
                          setFieldValue("stepType", v);
                        }}
                        value={values.stepType}
                      />
                    </JourneysFormField>
                  </JourneysRow>
                </Form>
              </ModalBody>
              <div
                style={{
                  paddingRight: "40px",
                  paddingBottom: "20px",
                  float: "right",
                }}
              ></div>
            </React.Fragment>
          );
        }}
      </Formik>
    </Modal>
  );
};

JourneyTaskModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  existingJourneyTask: PropTypes.object,
  editJourneyTask: PropTypes.func,
  createJourneyTask: PropTypes.func,
  journeyId: PropTypes.number,
  stepCount: PropTypes.number,
};

JourneyTaskModal.defaultProps = {
  existingJourneyTask: {},
  editJourneyTask: () => {},
  createJourneyTask: () => {},
  journeyId: null,
  stepCount: 0,
};

export default JourneyTaskModal;
