import React from "react";
import {
  TableBody,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Table,
} from "style/components/table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import uuid from "uuid/v4";
import { CustomDragHandle } from "./dnd-controller-style";
import { Loading } from "@evertrue/et-components";
import { TableCell } from "style/components";

export const CustomDnDTableCell = (props) => (
  <TableCell css="padding-left: 16px;" {...props} />
);
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
/*
 * Designed to allow a list of objects to be reordered
 * data: Array<Object> ex: [{id: 1, name: "Campaign"}] - your data (probably from the api), every item must have a consistent id (doesn't change across renders)
 * columns Array<Object> ex: [{header: "Field Type", Cell : obj => <Component data={obj}/> }]
 * can also use accessor in place of cell if no customization needed, wraps it in TableCell, ex: [{header: "Field Type", accessor : obj => obj[field_type] }]
 * Cell and accessor will be passed an object out of your data prop
 * onDragEnd calls back with an updated list of your data
 */
const DnDTable = ({
  loading,
  columns,
  data,
  onDragEnd: onDragEndProp = console.log,
  isDragDisabled,
}) => {
  const tableRef = React.createRef();
  const onDragEnd = (result) => {
    // dropped it outside DND area
    if (!result.destination) {
      return;
    }
    // no change
    if (result.destination.index === result.source.index) {
      return;
    }

    const updated = reorder(
      data,
      result.source.index,
      result.destination.index
    );
    onDragEndProp(updated);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Table>
        <TableHeader>
          {columns.map(({ header }) => (
            <TableHeaderCell key={uuid()}>{header}</TableHeaderCell>
          ))}
        </TableHeader>
        <Droppable droppableId="table">
          {(droppableProvided) => (
            <TableBody
              ref={(ref) => {
                tableRef.current = ref;
                droppableProvided.innerRef(ref);
              }}
              {...droppableProvided.droppableProps}
            >
              {loading ? (
                <tr>
                  <td>
                    <Loading />
                  </td>
                </tr>
              ) : (
                data.map((datum, index) => (
                  <Draggable
                    draggableId={datum.id}
                    key={datum.id}
                    index={index}
                    isDragDisabled={isDragDisabled}
                  >
                    {(provided, snapshot) => (
                      <TableRow
                        provided={provided}
                        ref={provided.innerRef}
                        snapshot={snapshot}
                        isDragging={snapshot.isDragging}
                        {...provided.draggableProps}
                      >
                        <td css="padding-left: 8px; cursor: move;">
                          <div
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <CustomDragHandle
                              className="custom-drag-handle"
                              icon="menu"
                            />
                          </div>
                        </td>

                        {columns.map((col, colIdx) => {
                          const { Cell } = col;
                          return Cell ? (
                            <Cell key={uuid()} {...datum} />
                          ) : (
                            <CustomDnDTableCell key={uuid()}>
                              {typeof col.accessor === "function"
                                ? col.accessor(datum)
                                : datum[col.accessor]}
                            </CustomDnDTableCell>
                          );
                        })}
                      </TableRow>
                    )}
                  </Draggable>
                ))
              )}
            </TableBody>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  );
};

DnDTable.propTypes = {};

export default DnDTable;
