import React from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";

import { BoldText } from "apps/importer/common/importer-styled-components";
import { Field } from "apps/importer/components/import-mapping/field";
import {
  FieldsList,
  Container,
  FieldContainer,
  FlexDiv,
  StyledLinkButton,
  FloatRightDiv,
} from "apps/importer/components/import-mapping/mapping-containers/shared-styles";

export const ExcludedContainer = ({
  excludedItems,
  unmapExcluded,
  unmapAllExcludedItems,
  viewOnly,
}) => {
  return (
    <React.Fragment>
      {excludedItems && (
        <FieldContainer type="skipped">
          <FlexDiv>
            <BoldText>Explicitly Skipped Fields</BoldText>
            <FloatRightDiv>
              {!viewOnly && 
              (<StyledLinkButton
                title="Unmap All"
                type="simple"
                onClick={unmapAllExcludedItems}
                disabled={excludedItems && excludedItems.length === 0}
              >
                Unskip all
              </StyledLinkButton>)}
            </FloatRightDiv>
          </FlexDiv>
          <Container>
            <Droppable droppableId={"explicit-skip-attributes"}>
              {(provided) => (
                <FieldsList
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {excludedItems &&
                    excludedItems.map((item, index) => (
                      <Field
                        key={item.id}
                        index={index}
                        field={item}
                        unmapValue={unmapExcluded}
                        isMapped={true}
                        viewOnly={viewOnly}
                      />
                    ))}
                  {provided.placeholder}
                </FieldsList>
              )}
            </Droppable>
          </Container>
        </FieldContainer>
      )}
    </React.Fragment>
  );
};

ExcludedContainer.propTypes = {
  excludedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  unmapExcluded: PropTypes.func.isRequired,
  unmapAllExcludedItems: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool.isRequired,
};
