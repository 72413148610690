import React from "react";

import { Icon } from "@evertrue/et-components";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { colors } from "style/colors";

export const DetailListButton = () => {
  return (
    <BackToImportsButton to="/importer">
      <Icon icon="arrow-left" size={1} />
      <span>Return to Importer Jobs</span>
    </BackToImportsButton>
  );
};

export const BackToImportsButton = styled(Link)`
  font-size: 16px;
  line-height: 40px;
  margin-left: 24px;
  color: ${colors.darkestText};
`;
