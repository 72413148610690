import React, { Component } from "react";
import PropTypes from "prop-types";
import api from "utils/api";

const { oneOfType, node, func } = PropTypes;

export const AuthContext = React.createContext({});

AuthContext.displayName = "AuthContext";

class AuthConnector extends Component {
  static displayName = "AuthConnector";
  static propTypes = {
    children: oneOfType([node, func]).isRequired,
  };

  state = api.getValue();

  componentDidMount() {
    this.onChange = this._onChange.bind(this);
    api.bind(this.onChange);
  }

  componentWillUnmount() {
    api.unbind(this.onChange);
  }

  _onChange() {
    this.setState(api.getValue());
  }

  render() {
    return (
      <AuthContext.Provider value={this.state}>
        {typeof this.props.children === "function"
          ? this.props.children(this.state)
          : this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthConnector;
