export const searchUsersForOrg = async (
  userName,
  roleIds = [],
  excludedUserIds = [],
  offset,
  limit = 5
) => {
  const queryRoles = {
    "affiliations.affiliation_roles.role_id": { in: roleIds },
  };
  const excludedIds = excludedUserIds.length
    ? { must_not: [{ id: { in: excludedUserIds } }] }
    : {};
  const querySort = { sort: [{ "name.untouched": { order: "asc" } }] };
  const query = userName
    ? {
        must: [queryRoles, { name: { wildcard: `${userName}*` } }],
        ...excludedIds,
        ...querySort,
      }
    : { must: [queryRoles], ...excludedIds, ...querySort };

  const data = await api.AUTH.USERS_SEARCH.post({
    data: JSON.stringify(query),
    params: { limit, offset },
  }).catch((error) => {
    showErrorToast("There was a problem fetching users.");
    return Promise.reject(error);
  });

  return data;
};
