import React from "react";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { getJourneysByGroupId } from "apps/journeys/utils/api-requests/journeys-requests";
import { Modal, ModalHeader, ModalBody, Button } from "@evertrue/et-components";

const DeleteJourneyGroupModal = ({ isOpen, onClose, group, handleDelete }) => {
  const { name, id } = group;

  const [hasActiveJourneys, setHasActiveJourneys] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchActiveJourneys = async () => {
      setLoading(true);
      const journeys = await getJourneysByGroupId(id);
      setLoading(false);
      setHasActiveJourneys(!!journeys.length);
    };
    if (isOpen) fetchActiveJourneys();
  }, [id, isOpen]);

  const deleteJourneyGroup = async () => {
    onClose();
    await handleDelete(group);
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeader title={"Delete Cadence Category"} closeModal={onClose} />
      <ModalBody scrollable={false}>
        {loading ? (
          <div>Loading...</div>
        ) : hasActiveJourneys ? (
          <div style={{ padding: "40px" }}>
            <span>
              You cannot delete the cadence category <strong> "{name}" </strong> because it
              has active cadences.
            </span>
          </div>
        ) : (
          <div>
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              Are you sure you want to delete the cadence category "{name}" ?
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "16px",
                justifyContent: "flex-end",
              }}
            >
              <Button type="simple" onClick={onClose}>
                Cancel
              </Button>
              <div style={{ margin: "0 20px" }}>
                <Button type="destroy" onClick={deleteJourneyGroup}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

DeleteJourneyGroupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default DeleteJourneyGroupModal;
