import React from "react";
import PropTypes from "prop-types";

import Section from "apps/importer/components/import-mapping/mapping-sections/section";
import LinkedSection from "apps/importer/components/import-mapping/mapping-sections/linked-section";

const Sections = (props) => {
  return props.schema.type === "linked_section" ? (
    <LinkedSection {...props} />
  ) : (
    <Section {...props} />
  );
};

Sections.propTypes = {
  schema: PropTypes.object.isRequired,
};

export default Sections;
