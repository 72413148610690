import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Popover2, Button, Checkbox } from "@evertrue/et-components";

import { colors } from "style/colors";
import MappingItem from "apps/importer/components/import-mapping/mapping-item";
import { GearIcon } from "apps/importer/common/importer-styled-components";

import { formatNamingID } from "apps/importer/components/import-mapping/utils";

const LinkedSection = ({
  schema,
  sectionIndex,
  fieldColumns,
  draggableItems,
  addLinkedField,
  removeLinkedField,
  unmapValue,
  addField,
  removeField,
  viewOnly,
}) => {
  const { schema: nestedSchema, linked_columns, type, display_name } = schema;
  const templates = linked_columns[0].template_field;
  const { no_data_label } = linked_columns[0];
  const showDefaultText = nestedSchema.length === 0;

  const handleCheckboxChange = ({ shouldAddField, field, sectionIndex }) => {
    if (shouldAddField) {
      addLinkedField({ field, sectionIndex });
    } else {
      removeLinkedField({ ...field.callbackArgs });
    }
  };

  return (
    <Section>
      <SectionHeader type={type}>
        <Popover2
          placement="bottom-start"
          trigger={({ togglePopover }) => (
            <Button
              aria-label="open selection popover"
              type="simple"
              onClick={togglePopover}
            >
              {!viewOnly && (<GearIcon icon="gear" />)}
            </Button>
          )}
        >
          <PopoverContent>
            <PopoverTitle>{linked_columns[0].display_name}</PopoverTitle>
            {Object.keys(templates).map((field) => {
              const templateField = templates[field];
              const fieldColumn =
                fieldColumns[
                  formatNamingID(templateField.section_id, templateField.field_name)
                ] || false;
              return (
                <CheckBox
                  key={templateField.display_name}
                  label={templateField.display_name}
                  checked={fieldColumn}
                  onChange={(shouldAddField) =>
                    handleCheckboxChange({
                      shouldAddField,
                      field: fieldColumn || templateField,
                      sectionIndex,
                    })
                  }
                >
                  {templateField.display_name}
                </CheckBox>
              );
            })}
          </PopoverContent>
        </Popover2>
        <div> {display_name} </div>
      </SectionHeader>
      {showDefaultText ? (
        <NoData>
          <NoDataText>{no_data_label}</NoDataText>
        </NoData>
      ) : (
        nestedSchema.map((item) => {
          const linkedValue =
            (item.linked_columns && item.linked_columns[0].value) ||
            item.display_name;
          const namingID = linkedValue
            ? formatNamingID(item.section_id, item.field_name)
            : item.field_name;
          return (
            <MappingItem
              key={namingID}
              draggableItems={draggableItems}
              fieldColumn={fieldColumns[namingID]}
              unmapValue={unmapValue}
              addField={addField}
              removeField={removeField}
              viewOnly={viewOnly}
            />
          );
        })
      )}
    </Section>
  );
};

LinkedSection.propTypes = {
  schema: PropTypes.object,
  sectionIndex: PropTypes.number.isRequired,
  fieldColumns: PropTypes.object.isRequired,
  draggableItems: PropTypes.object.isRequired,
  unmapValue: PropTypes.func.isRequired,
  addField: PropTypes.func.isRequired,
  removeField: PropTypes.func.isRequired,
  addLinkedField: PropTypes.func.isRequired,
  removeLinkedField: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool.isRequired,
};

LinkedSection.defaultProps = {
  schema: {},
};

const SectionHeader = styled.div`
  display: flex;
  font-weight: 500;
  line-height: 24px;
  font-size: 16px;
  margin-left: -20px;
`;

const Section = styled.div`
  padding-bottom: 8px;
  padding-top: 8px;
  border-top: 1px solid ${colors.border};
`;

const PopoverTitle = styled.div`
  color: ${colors.darkestText};
  font-weight: 500;
  margin-bottom: 12px;
`;

const NoData = styled.div`
  padding-left: 16px;
  line-height: 26px;
  margin-bottom: 4px;
`;

const CheckBox = styled(Checkbox)`
  padding-bottom: 4px;
`;

const PopoverContent = styled.div`
  width: 250px;
  padding: 16px;
  max-height: 300px;
  overflow-y: auto;
`;

const NoDataText = styled.p`
  color: ${colors.darkerText};
`;

export default LinkedSection;
