import React from "react";
import PropTypes from "prop-types";
import { ListItemWithTrashCan } from "scripts/style/components/form";
import { Button, Icon } from "@evertrue/et-components";

const ExistingUser = ({ name, onClick }) => {
  return (
    <ListItemWithTrashCan>
      <div style={{ flex: "none" }}>{name}</div>
      <Button type="simple" onClick={onClick}>
        <Icon icon="trash" />
      </Button>
    </ListItemWithTrashCan>
  );
};

ExistingUser.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
};

ExistingUser.defaultProps = {
  name: "",
  onClick: () => {},
};

export default ExistingUser;
