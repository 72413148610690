import styled from "styled-components";
import { colors } from "style/colors";

export const Unsaved = styled.div`
  color: ${colors.errorDark};
`;

export const Wrapper = styled.div`
  margin: 24px;
`;
