import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import SearchResultsList from "./search-results-list";
import { Icon } from "@evertrue/et-components";
import { SearchInput } from "scripts/style/components/search-box";

const NAVIGATION_KEYS = ["ArrowDown", "ArrowUp"];

const SearchBox = ({
  name,
  placeholder,
  defaultValue,
  onChange,
  onSelect,
  searchResults,
  disabled,
}) => {
  const [hasValue, setHasValue] = useState(false);
  const [needsDropdown, setNeedsDropdown] = useState(false);
  const [selectedResult, setSelectedResult] = useState(null);
  const searchbox = useRef(null);

  useEffect(() => {
    if (defaultValue || searchbox.current.value) {
      setHasValue(true);
    }
  }, [defaultValue]);

  const handleUpdateHasValue = () => {
    setHasValue(!!searchbox.current.value);
  };

  // Work in progress
  const handleDirectionalKeys = (e) => {
    const currentSelectedIndex = selectedResult
      ? searchResults.findIndex((result) => result.id === selectedResult.id)
      : -1;

    if (e.key === "ArrowDown" && searchResults.length > 0) {
      if (currentSelectedIndex < searchResults.length - 1) {
        setSelectedResult(searchResults[currentSelectedIndex + 1]);
      }
    }
    if (e.key === "ArrowUp" && searchResults.length > 0) {
      if (currentSelectedIndex > 0) {
        setSelectedResult(searchResults[currentSelectedIndex - 1]);
      } else {
        setSelectedResult(null);
      }
      // this is here to prevent the cursor from moving to the beginning of the input
      // can't use "preventDefault" because we are using keyUp
      // can't use keyDown because we need the input value to be updated
      searchbox.current.focus();
      const val = searchbox.current.value;
      searchbox.current.value = "";
      searchbox.current.value = val;
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    if (NAVIGATION_KEYS.includes(e.key)) {
      handleDirectionalKeys(e);
    } else if (e.key === "Enter") {
      if (selectedResult) {
        handleSelect(selectedResult, e);
      }
    } else {
      handleUpdateHasValue();
      setNeedsDropdown(true);
      setSelectedResult(null);
      onChange(searchbox.current.value, e);
    }
  };

  const handleClear = (e) => {
    setNeedsDropdown(false);
    if (searchbox.current) searchbox.current.focus();
    const node = searchbox.current;
    node.value = "";
    setSelectedResult(null);
    handleUpdateHasValue();
    onChange(searchbox.current.value, e);
  };

  function handleSelect(result, e) {
    onSelect(result);
    handleClear(e);
  }

  const handleCrossIconKeyUp = (e) => {
    if (searchbox.current) searchbox.current.focus();
    if (e.key === "Tab") {
      handleClear(e);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <SearchInput
        name={name}
        type="search"
        ref={searchbox}
        onKeyUp={handleChange}
        aria-label="Search box"
        data-testid="search-box"
        placeholder={placeholder}
        defaultValue={defaultValue}
        disabled={disabled}
      />
      {hasValue && (
        <Icon
          title="clear"
          className="et--search-box--clear"
          icon="cancel"
          onClick={handleClear}
          isReachable={true}
          onKeyUp={handleCrossIconKeyUp}
        />
      )}
      {hasValue && needsDropdown && searchResults.length > 0 && (
        <SearchResultsList
          onSelect={handleSelect}
          searchResults={searchResults}
          selectedResult={selectedResult}
        />
      )}
    </div>
  );
};

SearchBox.propTypes = {
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  searchResults: PropTypes.array,
  disabled: PropTypes.bool,
};

SearchBox.defaultProps = {
  name: "",
  defaultValue: "",
  placeholder: "",
  onChange: () => {},
  onSelect: () => {},
  searchResults: [],
  disabled: false,
};

export default SearchBox;
