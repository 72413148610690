import { useEffect } from "react";

export const trackEvent = ({ event }) => {
  window.mixpanel.track(event);
  window.Appcues.track(event);
};

export const useTracking = ({ history, trackingString }) => {
  useEffect(() => {
    trackEvent({ event: trackingString });
  }, [history.location, trackingString]);

  return { trackEvent };
};
