import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, Button } from "@evertrue/et-components";

const DeleteJourneyTaskModal = ({
  isOpen,
  onClose,
  journeyTask,
  handleDelete,
}) => {
  const { id, name } = journeyTask;
  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeader title="Delete Step" closeModal={onClose} />
      <ModalBody scrollable={false}>
        <div>
          <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
            Are you sure you want to delete the step {name} ?
          </div>
          <div
            style={{
              display: "flex",
              marginBottom: "16px",
              justifyContent: "flex-end",
            }}
          >
            <Button type="simple" onClick={onClose}>
              Cancel
            </Button>
            <div style={{ margin: "0 20px" }}>
              <Button type="destroy" onClick={() => handleDelete(id)}>
                Delete
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

DeleteJourneyTaskModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  journeyTask: PropTypes.object,
  handleDelete: PropTypes.func,
};

DeleteJourneyTaskModal.defaultProps = {
  journeyTask: {},
  handleDelete: () => {},
};

export default DeleteJourneyTaskModal;
