import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Api from "utils/api";

const useImportMappingTemplate = ({ jobId, autoMap, viewOnly }) => {
  const [mappingData, setMappingData] = useState({});

  useEffect(() => {
    Api.IMPORT.IMPORT_MAPPING_TEMPLATE.get({
      urlArgs: { job_id: jobId },
      params: { auto_map: autoMap, view_only: viewOnly },
    }).then((response) => {
      setMappingData(response.data);
    });
  }, [jobId, autoMap, viewOnly]);

  return { mappingData, setMappingData };
};

useImportMappingTemplate.propTypes = {
  jobId: PropTypes.number.isRequired,
  autoMap: PropTypes.bool.isRequired,
};

export default useImportMappingTemplate;
