import _ from "underscore";
import Cookies from "js-cookie";
import axios from "axios";
import Env from "utils/env";
import endpoints from "utils/api/api-config";
import { AlertActions } from "@evertrue/et-components";
import createApi, { UNAUTHORIZED, SCOPED } from "utils/api/create-api";

const { baseRoute, appKey, isStageData } = Env;

const isStage = isStageData();

const save = (key, value) => {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
};

const cleanToken = (token) => {
  return _.isString(token) && token.length > 10 ? token : undefined;
};

const cleanId = (id) => {
  const num = parseInt(id, 10);
  return _.isFinite(num) ? num : undefined;
};

const getUrlOid = () => {
  try {
    return new URLSearchParams(window.location.search).get("oid");
  } catch (e) {
    console.log(e, window.location.search);
  }
};

const skiffURL = `${baseRoute}skiff`;
const authSessionURL = `${baseRoute}auth/session`;
const STORED_TOKEN = isStage ? "stage--token" : "production--token";
const STORED_OID = isStage ? "stage--oid" : "production--oid";
const STORED_IMPERSONATION_USER_ID = isStage
  ? "stage--impersonation-user-id"
  : "production--impersonation-user-id";
const ACCOUNTS_COOKIE_TOKEN = isStage ? "stage_etauth" : "production_etauth";

// ----- TOKEN -----

const initial_token =
  cleanToken(Cookies.get(ACCOUNTS_COOKIE_TOKEN)) ||
  cleanToken(localStorage.getItem(STORED_TOKEN));

if (initial_token) {
  Cookies.remove(ACCOUNTS_COOKIE_TOKEN);
  save(STORED_TOKEN, initial_token);
}

// ----- OID -----

const initial_oid =
  cleanId(getUrlOid()) || cleanId(localStorage.getItem(STORED_OID));

if (initial_oid) {
  save(STORED_OID, initial_oid);
}

// ----- IMPERSONATION -----

const initial_impersonation_user_id = cleanId(
  localStorage.getItem(STORED_IMPERSONATION_USER_ID)
);

const default_auth_headers = {
  "Content-Type": "application/json",
  "Application-Key": appKey,
  "Authorization-Auto-Send": 0,
  "Authorization-Multifactor": 1,
  Accept: "*/*",
};

function showToast(message = "There was an error with your request") {
  AlertActions.launchToast({
    type: "error",
    message,
  });
}

const createUnscopedSession = () => {
  return axios(skiffURL, {
    method: "POST",
    headers: {
      ...default_auth_headers,
      "Authorization-Provider": "EverTruePrimeToken",
    },
    withCredentials: true,
  }).then(({ data }) => data);
};

const createScopedSession = (oid) => {
  return axios({
    method: "POST",
    url: skiffURL,
    headers: {
      ...default_auth_headers,
      "Authorization-Provider": "EverTruePrimeToken",
    },
    data: {
      type: SCOPED,
      oid,
      app_key: appKey,
    },
    withCredentials: true,
  }).then(({ data }) => data);
};

const api = createApi({
  endpoints,
  initial_token,
  initial_oid,
  initial_impersonation_user_id,
  app_key: appKey,
  base_route: baseRoute,
  onCreateSession(oid) {
    return oid ? createScopedSession(oid) : createUnscopedSession();
  },
  onRefreshSession(token) {
    return axios(authSessionURL, {
      method: "GET",
      headers: {
        ...default_auth_headers,
        "Authorization-Provider": "evertrueauthtoken",
        Authorization: token,
      },
    }).then(({ data }) => data);
  },
  onDeleteSesssion() {
    return axios(skiffURL, {
      method: "DELETE",
      headers: {
        ...default_auth_headers,
        "Authorization-Provider": "EverTruePrimeToken",
      },
      withCredentials: true,
    })
      .then((resp) => {
        window.mixpanel && window.mixpanel.reset();
        console.log("Logout success", resp);
      })
      .catch((resp) => {
        window.mixpanel && window.mixpanel.reset();
        console.log("Logout Error", resp);
      });
  },

  onRequest(options) {
    const { type, disableAlerts, ...rest } = options;

    return new Promise((resolve, reject) => {
      return axios({ ...rest, method: type })
        .then(resolve)
        .catch((error) => {
          if (axios.isCancel(error)) {
            reject("Request cancelled");
          }
          const { response = {} } = error;

          if (response.status === 401) {
            reject(UNAUTHORIZED);
          } else {
            if (!disableAlerts && !axios.isCancel(error)) {
              showToast(response.data && response.data.message);
            }
            reject(response.data);
          }
        });
    });
  },
  onPersistSessionInfo(session) {
    const { token, oid } = session || {};

    save(STORED_TOKEN, token);
    save(STORED_OID, oid);
  },
  onPersistImpersonateUserId(user_id) {
    save(STORED_IMPERSONATION_USER_ID, user_id);
  },
});

export default api;
