import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ListboxSelect } from "@evertrue/et-components";
import { withRouter } from "react-router-dom";
import Api from "utils/api";
import {
  SectionHeader,
  BoldText,
  RadioGroup,
  StyledBooleanRadio,
  ContinueButton,
  ProcessContainer,
} from "apps/importer/common/importer-styled-components";
import ImportHelpDocs from "apps/importer/components/import-upload/import-help-docs";
import { launchToast } from "utils/show-toast";
import { Button, useOpenClose } from "@evertrue/et-components";
import { WORKER_TYPE, getProcessState, TRANSCODE, STATUS } from "apps/importer/import-helper";
import { usePollingResults } from "apps/importer/hooks/use-polling-results";
import CancelImportModal from "apps/importer/components/import-preprocess/cancel-import/cancel-import-modal.js";

const ImportType = withRouter(({ history, jobId, onImportRedirectTo, mappingOptions }) => {
  const [isFullImportType, setIsFullImportType] = useState(false);
  const [hasMappingHistory, setHasMappingHistory] = useState(false);
  const [mappingType, setMappingType] = useState(null);
  const [autoMap, setAutoMap] = useState(true);

  const {
    jobData,
    results,
    status,
  } = usePollingResults({
    jobId,
    workerType: WORKER_TYPE.FILE_CHECK,
    history,
  });

  const currentProcessState = getProcessState(TRANSCODE, jobData.status);

  useEffect(() => {
    setHasMappingHistory(
      mappingOptions.find((element) => element.matches_previous) ? true : false
    );

    // Default type should be the type with the highest match rate. This assumes
    // the API will return in the order of highest match rate. We also don't want
    // to automatically select at type if there is no match at all.
    const highestMatch = mappingOptions.sort((a, b) => b.match_rate - a.match_rate)[0];
    const hasDefaultType =
      highestMatch != null
        ? {
          ...highestMatch,
          label: highestMatch.display_name,
          value: highestMatch.type,
        }
        : null;

    setMappingType(hasDefaultType);
  }, [mappingOptions]);

  const isContinueDisabled = !mappingType || currentProcessState === STATUS.CANCELLED;

  const isCancelDisabled = !mappingType || currentProcessState === STATUS.CANCELLED;

  useEffect(() => {
    if (!jobId) {
      setIsFullImportType(false);
      setHasMappingHistory(false);
      setAutoMap(true);
    }
  }, [jobId]);

  const [isOpen, openModal, closeModal] = useOpenClose();

  // Generates the categorized and sorted dropdown list of options
  function createOptions(options, selectedType) {
    let results = []
    let categories = []

    options.forEach(element => {
      let fileCategory = element.file_category
      if (!categories.includes(fileCategory)) {
        let obj = {
          "label": formatCategoryHeader(fileCategory),
          "items": [],
        }

        // Keep Standard Files at the top - could need additional sorting if more microfile types are added
        if (fileCategory == "STANDARD") {
          results.unshift(obj)
          categories.unshift(element.file_category);

        } else {
          results.push(obj)
          categories.push(element.file_category);
        }
      }
    });

    options.forEach(element => {
      let index = categories.indexOf(element.file_category)
      results[index].items.push({
        "label": element.display_name,
        "value": element.type,
      })
    })

    results.forEach(option => {
      option.items.sort((a, b) => a.label.localeCompare(b.label))
    })

    return results;
  }

  // Takes backend FileCategory type and converts to readable category header
  // "STANDARD" to "Standard Files", "MICRO_CONSTITUENT" to "Constituent Micro Files"
  function formatCategoryHeader(category) {
    let header = ["Files"] // All headers end with this

    if (category != null) {
      const stringList = category.toLocaleLowerCase().split("_")

      // Adds elements to the header in reverse order
      stringList.forEach(word => {
        header.unshift(word.charAt(0).toUpperCase() + word.slice(1))
      })
    }

    return header.join(" ")
  }

  const cancelImport = async () => {
    try {
      await Api.IMPORT.IMPORT_JOB_CANCEL.post({
        urlArgs: { job_id: jobId },
      });
    } catch (error) {
      launchToast({
        message: `Unable to cancel import for job:${jobId}`,
        type: "error",
      });
    }
    history.push("/importer");
  };

  const setupImportData = async () => {
    try {
      await Api.IMPORT.IMPORT_JOB_ID.put({
        urlArgs: { job_id: jobId },
        params: { auto_map: hasMappingHistory },
        disableAlerts: true,
        data: { job_type: mappingType.value, prune: isFullImportType },
      });
    } catch (error) {
      launchToast({
        message: `Unable to start preprocessing for job:${jobId}`,
        type: "error",
      });
    }

    if (hasMappingHistory && autoMap) {
      try {
        await Api.IMPORT.IMPORT_JOB_QUEUE.post({
          urlArgs: { job_id: jobId },
        });
      } catch (error) {
        launchToast({
          message: `Unable to start preprocessing for job:${jobId}`,
          type: "error",
        });
        onImportRedirectTo("/importer");
      }

      onImportRedirectTo(`/importer/${jobId}/import-preprocess`);
    } else {
      onImportRedirectTo(`/importer/${jobId}/import-mapping-layout`);
    }
  };

  return (
    <div>
      <ProcessContainer>
        <div>
          <SectionHeader>
            <BoldText>Select Import Type</BoldText>
            <div>What type of data do you want to import?</div>
          </SectionHeader>
          <ImportTypeSelect>
            <ListboxSelect
              size={"large"}
              options={createOptions(mappingOptions, mappingType)}
              onChange={(type) => setMappingType(type)}
              value={mappingType}
              label={"Select Import Template..."}
            />
          </ImportTypeSelect>
          <RadioGroup>
            <StyledBooleanRadio
              name="full_import_boolean"
              onChange={(_name, value) => setIsFullImportType(value)}
              trueLabel="Full Import (replace all records)"
              falseLabel="Partial Import (change only included records)"
              value={isFullImportType}
            />
          </RadioGroup>
          {hasMappingHistory && mappingType && (
            <RadioGroup>
              <BoldText>Previous Import Mapping Detected:</BoldText>
              <StyledBooleanRadio
                name="auto_mapping"
                onChange={(_name, value) => setAutoMap(value)}
                trueLabel="Use Previous Mapping"
                falseLabel="Review Mapping in Next Step"
                value={autoMap}
              />
            </RadioGroup>
          )}
        </div>

        <ImportHelpDocs />
      </ProcessContainer>
      <ButtonGroup>
        <Button type="secondary" onClick={isOpen ? closeModal : openModal} disabled={isCancelDisabled}>
          Cancel
        </Button>
        <Button disabled={isContinueDisabled} onClick={setupImportData}>
          Continue
        </Button>
      </ButtonGroup>
      <CancelImportModal
        isOpen={isOpen}
        closeModal={closeModal}
        cancelImport={cancelImport}
      ></CancelImportModal>
    </div>
  );
});

ImportType.propTypes = {
  jobId: PropTypes.number,
  onImportRedirectTo: PropTypes.func.isRequired,
  mappingOptions: PropTypes.array,
};

ImportType.defaultProps = {
  jobId: null,
  mappingOptions: [],
};

export const ButtonGroup = styled.div`
  margin-top: 14px;
  float: right;
`;

const ImportTypeSelect = styled.div`
  width: 300px;
  align-items: center;
  margin: 32px auto;
`;
export default ImportType;
