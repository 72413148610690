import React from "react";
import PropTypes from "prop-types";
import JourneysModal from "apps/journeys/components/modals/journeys-modal";
import DeleteJourneyModal from "apps/journeys/components/modals/delete-journey-modal";
import { ThreeDotActionsMenu, ActionsMenu } from "@evertrue/et-components";

const JourneyActionMenu = ({
  journey = {},
  handleEdit,
  handleDelete,
  type = "button",
}) => {
  const editAction = {
    id: 1,
    mountOnClick: ({ is_open, close }) => (
      <JourneysModal
        isOpen={is_open}
        onClose={close}
        existingJourney={journey}
        editJourney={handleEdit}
      />
    ),
    label: "Edit Cadence",
    icon: "edit",
  };

  const deleteAction = {
    id: 2,
    mountOnClick: ({ is_open, close }) => (
      <DeleteJourneyModal
        isOpen={is_open}
        onClose={close}
        journey={journey}
        handleDelete={handleDelete}
      />
    ),
    label: "Delete Cadence",
    icon: "trash",
  };

  const options = {
    id: 1,
    // heading: "Actions",
    items: [editAction, deleteAction],
  };

  return type === "button" ? (
    <ActionsMenu options={[options]} />
  ) : (
    <ThreeDotActionsMenu options={[options]} iconSize={1} />
  );
};

JourneyActionMenu.propTypes = {
  journey: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  type: PropTypes.string,
};

JourneyActionMenu.defaultProps = {
  type: "button",
};

export default JourneyActionMenu;
