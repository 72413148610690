import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import str from "underscore.string";
import {
  AlertActions,
  ModalTriggerDeprecated,
  Portal,
} from "@evertrue/et-components";
import ColumnConfigEditor from "components/column-config-editor/column-config-editor";
import CustomTypeModal from "apps/custom-types/components/custom-type-modal";
import { StyledEmptyStateMessage } from "components/column-config-editor/column-config-editor-style";
import {
  Container,
  TypeWrapper,
  Unsaved,
} from "apps/custom-types/controllers/custom-types-controller.style";
import Module from "components/module";
import CustomImportedTypeModal from "apps/custom-types/components/custom-imported-type-modal";
import { getDiffedItems } from "components/column-config-editor/column-config-editor-utils";
import { launchToast } from "utils/show-toast";

const CreateCustomTypeModal = ({
  fieldName,
  defaultCategories,
  categories,
  existingTypes = [],
  ...props
}) => (
  <CustomTypeModal
    defaultCategories={defaultCategories}
    categories={categories}
    header={`Add a New ${str.capitalize(fieldName)}`}
    disableFunc={(data) => {
      const dupe = existingTypes.find(
        (existing) => existing[fieldName] === data[fieldName]
      );
      return {
        isDisabled: !data[fieldName] || dupe,
        ...(dupe && {
          errors: `'${data[fieldName]}' already exists, please use a unique name.`,
        }),
      };
    }}
    fieldName={fieldName}
    {...props}
  />
);

CreateCustomTypeModal.propTypes = {
  defaultCategories: PropTypes.array,
  categories: PropTypes.array,
  existingTypes: PropTypes.array,
  fieldName: PropTypes.string,
};

CreateCustomTypeModal.defaultProps = {
  defaultCategories: [],
  categories: [],
  existingTypes: [],
  fieldName: "",
};
class CustomTypesController extends Component {
  state = {
    values: [],
    importedValues: [],
    origValues: {},
    itemToEdit: {},
    loading: false,
  };

  componentDidMount() {
    this.fetchCustomValues();
  }

  componentDidUpdate(prevProps) {
    if (this.props.endPoints.fetch !== prevProps.endPoints.fetch) {
      this.fetchCustomValues();
    }
  }

  getActiveItems = () => this.state.values.filter((i) => i.active);

  createCustomType = async (data) => {
    const duplicate_name = this.state.values.find(
      (type) => type[this.props.fieldName] === data[this.props.fieldName]
    );
    if (duplicate_name) {
      AlertActions.launchToast({
        type: "error",
        message: "Cannot have duplicate type names.",
        timeout: 5000,
      });
      return;
    }
    const active_items = this.getActiveItems();
    // so 0 is unsorted and length + 1 would be the last one
    const new_type = data.active
      ? { ...data, sort_order: active_items.length + 2 }
      : data;
    try {
      await this.props.endPoints.create(new_type);

      this.fetchCustomValues();
    } catch (error) {
      AlertActions.launchToast({
        type: "error",
        message: "There was an error saving your type",
      });
    }
  };

  updateCustomType = async (data) => {
    const active_items = this.getActiveItems();
    // if a modal changes it from inactive to active, add to end of list w/ sort_order
    const existing = this.state.values.find((type) => type.id === data.id);
    const updated_type =
      !existing.active && data.active
        ? { ...data, sort_order: active_items.length + 2 }
        : data;

    this.setState((state) => ({
      values: state.values.map((item) =>
        item.id === data.id ? updated_type : item
      ),
    }));
  };

  fetchCustomValues = async () => {
    this.setState({ loading: true });
    const { data } = await this.props.endPoints.fetch();
    const { data: importedValues } = this.hasImportedFields()
      ? await this.props.endPoints.fetchImported()
      : [];

    this.setState({
      values: data,
      importedValues: this.hasImportedFields()
        ? this.props.endPoints.filterImported(importedValues, data)
        : [],
      loading: false,
      origValues: _.indexBy(data, "id"), // save this to diff off for handleSave
    });
  };

  handleEditItem = (item) => {
    this.setState({
      itemToEdit: item,
    });
  };

  handleDeleteItem = async (item) => {
    await this.props.endPoints.delete(item);
    this.fetchCustomValues();

    launchToast({ type: "success", message: "Successfully deleted" });
  };

  onModalClose = () => {
    this.setState({
      itemToEdit: undefined,
    });
  };

  handleSave = async (changedItems) => {
    this.setState({ loading: true });

    try {
      await this.props.endPoints.bulkUpdate(changedItems);
      this.fetchCustomValues();
      successToast(this.props.fieldName);
    } catch (error) {
      AlertActions.launchToast({
        type: "error",
        message: "There was an error saving your types",
      });
    }
    this.setState({ loading: false });
  };

  handleCancel = () => {
    this.fetchCustomValues();
  };

  handleListChange = ({ listOne = [], listTwo = [] }) => {
    this.setState({
      values: [...listOne, ...listTwo],
    });
  };

  hasImportedFields = () =>
    typeof this.props.endPoints.fetchImported === "function";

  render() {
    const listOneItems = this.state.values.filter((type) => !type.active);
    const listTwoItems = this.state.values
      .filter((type) => type.active)
      .sort((a, b) => a.sort_order - b.sort_order);
    const changedItems = getDiffedItems(
      ["sort_order", "active", "categories", this.props.fieldName],
      this.state.origValues,
      this.state.values
    );

    const has_max_types = this.props.maxCount
      ? this.state.values.length >= this.props.maxCount
      : false;

    return (
      <TypeWrapper>
        <Module>
          <Container>
            {!!this.state.values.length && (
              <ModalTriggerDeprecated
                buttonType="default"
                disable={has_max_types}
                modal={
                  <CreateCustomTypeModal
                    defaultCategories={this.props.defaultCategories}
                    categories={this.props.categories}
                    existingTypes={this.state.values}
                    handleSubmit={this.createCustomType}
                    fieldName={this.props.fieldName}
                  />
                }
              >
                {`New ${str.capitalize(this.props.fieldName)}`}
              </ModalTriggerDeprecated>
            )}
            {changedItems.length > 0 && <Unsaved>Unsaved Changes</Unsaved>}

            {this.hasImportedFields() && (
              <ModalTriggerDeprecated
                disable={has_max_types}
                buttonType="default"
                modal={
                  <CustomImportedTypeModal
                    fieldName={this.props.fieldName}
                    types={this.state.importedValues}
                    handleCreate={async (data) => {
                      await this.props.endPoints.create({
                        ...data,
                        sort_order: listTwoItems.length + 1,
                        active: true,
                      });
                      successToast(this.props.fieldName);
                    }}
                    afterClose={() => {
                      this.fetchCustomValues();
                    }}
                  />
                }
              >
                <span>
                  Manage Imported Fields
                </span>
              </ModalTriggerDeprecated>
            )}
          </Container>
          {!this.state.values.length ? (
            <StyledEmptyStateMessage
              icon="add-circle-outline"
              text={`Create a new ${this.props.fieldName} to get started`}
            >
              <ModalTriggerDeprecated
                buttonType="default"
                modal={
                  <CreateCustomTypeModal
                    defaultCategories={this.props.defaultCategories}
                    categories={this.props.categories}
                    handleSubmit={this.createCustomType}
                    fieldName={this.props.fieldName}
                  />
                }
              >
                {`Create ${str.capitalize(this.props.fieldName)}`}
              </ModalTriggerDeprecated>
            </StyledEmptyStateMessage>
          ) : (
            <ColumnConfigEditor
              secondaryLabelKey="categories"
              loading={this.state.loading}
              onChange={this.handleListChange}
              onEdit={this.handleEditItem}
              onDelete={this.handleDeleteItem}
              handleCancel={this.handleCancel}
              handleSave={() => this.handleSave(changedItems)}
              disableCancel={!changedItems.length}
              disableSave={!changedItems.length}
              searchKey={this.props.fieldName}
              enabletoggleAll
              listOne={{
                label: "Available",
                items: listOneItems,
                notSortable: true,
              }}
              listTwo={{ label: "Active", items: listTwoItems }}
            />
          )}

          {!_.isEmpty(this.state.itemToEdit) && (
            <Portal>
              <CustomTypeModal
                categories={this.props.categories}
                header={`Edit ${str.capitalize(this.props.fieldName)}`}
                submitLabel="Edit"
                disableFunc={(data) => !data[this.props.fieldName]}
                onClose={this.onModalClose}
                onUnmount={this.onModalClose}
                handleSubmit={this.updateCustomType}
                customType={this.state.itemToEdit}
                isEditing
                fieldName={this.props.fieldName}
              />
            </Portal>
          )}
        </Module>
      </TypeWrapper>
    );
  }
}

CustomTypesController.propTypes = {
  fieldName: PropTypes.string.isRequired,
  defaultCategories: PropTypes.array,
  categories: PropTypes.array,
  maxCount: PropTypes.number,
  endPoints: PropTypes.shape({
    fetch: PropTypes.func,
    create: PropTypes.func,
    delete: PropTypes.func,
    bulkUpdate: PropTypes.func,
    fetchImported: PropTypes.func,
    filterImported: PropTypes.func,
  }).isRequired,
};

CustomTypesController.defaultProps = {
  defaultCategories: [],
  categories: [],
  maxCount: null,
};

function successToast(label) {
  AlertActions.launchToast({
    type: "success",
    message: `${str.capitalize(label)} Saved`,
    timeout: 3000,
  });
}

export default CustomTypesController;
