import _ from "underscore";

const ROLES = ["admin", "console user", "console", "volunteer"];

const hasDataRole = (affiliation_roles) => {
  return _.any(affiliation_roles, ({ role } = {}) => {
    return _.contains(ROLES, role && role.name && role.name.toLowerCase());
  });
};

export const getOrgs = (user = {}) => {
  return _.compact(
    _.map(user.affiliations, ({ affiliation_roles, organization }) => {
      return hasDataRole(affiliation_roles) ? organization : undefined;
    })
  );
};
