import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "style/colors";
import Sections from "apps/importer/components/import-mapping/mapping-sections/sections";
import { HeadersContainer } from "apps/importer/common/importer-styled-components";
import { MappingContainer } from "apps/importer/components/import-mapping/mapping-containers/shared-styles";

export const MappedContainer = ({
  schema,
  fieldColumns,
  addField,
  removeField,
  addLinkedField,
  removeLinkedField,
  draggableItems,
  unmapValue,
  addSection,
  removeSection,
  footnotes,
  viewOnly,
}) => {
  let sectionCount = null;
  const sectionNames = schema.map((schema) => schema.name);

  return (
    <React.Fragment>
      <MappingContainer type="mapping">
        <TempHeadersContainer>
          {schema.map((section, index, array) => {
            const { name } = section;
            const previousSection = array[index - 1] || {};

            const isInSequence =
              sectionNames.indexOf(name) !== sectionNames.lastIndexOf(name);
            const isNewSequence =
              sectionNames.indexOf(previousSection.name) !==
              sectionNames.indexOf(name);

            sectionCount = isNewSequence ? null : sectionCount;
            sectionCount = isInSequence ? sectionCount + 1 : null;

            return (
              <Sections
                key={`${section.name}-${sectionCount ? sectionCount : ""}`}
                sectionCount={sectionCount}
                schema={section}
                fieldColumns={fieldColumns}
                sectionIndex={index}
                draggableItems={draggableItems}
                unmapValue={unmapValue}
                addField={addField}
                removeField={removeField}
                addSection={addSection}
                addLinkedField={addLinkedField}
                removeLinkedField={removeLinkedField}
                isSectionAdd={section.type === "multiple_section"}
                removeSection={removeSection}
                viewOnly={viewOnly}
              />
            );
          })}
        </TempHeadersContainer>
      </MappingContainer>
      <Footnotes>
        {footnotes &&
          footnotes.map((footnote) => {
            return (
              <p key={footnote.index}>
                {" "}
                {`${"*".repeat(footnote.index)} ${footnote.description}`}{" "}
              </p>
            );
          })}
      </Footnotes>
    </React.Fragment>
  );
};

MappedContainer.propTypes = {
  schema: PropTypes.arrayOf(PropTypes.object),
  fieldColumns: PropTypes.arrayOf(PropTypes.object),
  addField: PropTypes.func.isRequired,
  removeField: PropTypes.func.isRequired,
  addLinkedField: PropTypes.func.isRequired,
  removeLinkedField: PropTypes.func.isRequired,
  draggableItems: PropTypes.arrayOf(PropTypes.object),
  unmapValue: PropTypes.func.isRequired,
  addSection: PropTypes.func.isRequired,
  removeSection: PropTypes.func.isRequired,
  footnotes: PropTypes.arrayOf(PropTypes.object),
  viewOnly: PropTypes.bool.isRequired,
};

MappedContainer.defaultProps = {
  footnotes: [],
  schema: [],
  fieldColumns: [],
  draggableItems: [],
};

const Footnotes = styled.div`
  color: red;
  padding-left: 32px;
  padding-top: 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  font-style: italic;
`;

const TempHeadersContainer = styled(HeadersContainer)`
  width: 100%;
  padding: 24px 12px 0 32px;
  margin: 0;
  border: 1px solid lightgrey;
  height: 100%;
  background-color: ${colors.white};
`;
