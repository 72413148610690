import styled from "styled-components";

import { colors } from "style/colors";

export const Container = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
  margin: 0 35px;
  justify-content: space-between;
`;

export const Unsaved = styled.div`
  color: ${colors.errorDark};
`;

export const TypeWrapper = styled.div`
  margin: 24px;
`;
