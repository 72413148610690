import styled from "styled-components";
import React, { Component } from "react";
import T from "prop-types";
import { Switch, Icon, ModalWrapper } from "@evertrue/et-components";
import _ from "underscore";
import { Link } from "react-router-dom";
import { PlainButton } from "style/components";
import { colors } from "style/colors";
import CustomFieldModal from "./custom-field-modal";
import DnDTable, { CustomDnDTableCell } from "components/dnd/dnd-table";

class CustomFieldTable extends Component {
  state = {
    isModalOpen: false,
    selectedField: {},
  };

  static propTypes = {
    filteredCustomFields: T.array,
    onDelete: T.func,
    handleUpdate: T.func,
    handleReorder: T.func,
    isDragDisabled: T.bool,
    pickListValuesMap: T.object,
  };

  static defaultProps = {
    filteredCustomFields: [],
    onDelete: _.noop,
    handleUpdate: _.noop,
    handleReorder: _.noop,
    sortDisabled: false,
  };

  handleToggleModal = (selectedField = {}) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      selectedField,
    });
  };

  getColumns = () => {
    return [
      {
        header: "Display Name",
        Cell: (customField) => {
          return (
            <CustomDnDTableCell css="padding-left: 10px;">
              <NameButton onClick={() => this.handleToggleModal(customField)}>
                {customField.displayName}
              </NameButton>
            </CustomDnDTableCell>
          );
        },
      },
      {
        header: "Field Type",
        accessor: (customField) => customField.typeLabel,
      },
      {
        header: "Required",
        Cell: (customField) => {
          const pickListValues =
            this.props.pickListValuesMap[customField.id] || [];
          const is_pick_list =
            customField.dataType === "STRING" &&
            customField.uiControlType !== "FREEFORM";
          return (
            <CustomDnDTableCell>
              <Switch
                disabled={
                  is_pick_list &&
                  !pickListValues.filter((field) => field.active).length
                }
                onChange={(required) =>
                  this.props.handleUpdate({ ...customField, required })
                }
                name={`required-${customField.id}`}
                checked={customField.required}
              />
            </CustomDnDTableCell>
          );
        },
      },
      {
        header: "Active",
        Cell: (customField) => {
          return (
            <CustomDnDTableCell>
              <Switch
                onChange={(active) => {
                  this.props.handleUpdate({ ...customField, active });
                }}
                name={`active-${customField.id}`}
                checked={customField.active}
              />
            </CustomDnDTableCell>
          );
        },
      },
      {
        header: "",
        Cell: (customField) => {
          const is_pick_list =
            customField.dataType === "STRING" &&
            customField.uiControlType !== "FREEFORM";
          return (
            <CustomDnDTableCell
              css={`
                width: auto;
                justify-content: center;
                white-space: nowrap;
              `}
            >
              {is_pick_list && (
                <Link
                  to={{
                    state: customField,
                    pathname: `/interactions/custom-fields/values/${customField.id}`,
                  }}
                >
                  <Icon icon="build" /> Edit Values
                </Link>
              )}
            </CustomDnDTableCell>
          );
        },
      },
      {
        header: "",
        Cell: (customField) => {
          return (
            <CustomDnDTableCell>
              <EditButton onClick={() => this.handleToggleModal(customField)}>
                <Icon icon="edit" />
              </EditButton>
            </CustomDnDTableCell>
          );
        },
      },
    ];
  };

  render() {
    const {
      filteredCustomFields,
      isDragDisabled,
      handleReorder,
      handleUpdate,
      onDelete,
    } = this.props;

    return (
      <React.Fragment>
        <DnDTable
          isDragDisabled={isDragDisabled}
          data={filteredCustomFields}
          onDragEnd={handleReorder}
          columns={this.getColumns()}
        />
        <ModalWrapper
          isOpen={this.state.isModalOpen}
          onClose={this.handleToggleModal}
          width={500}
          render={({ close }) => (
            <CustomFieldModal
              close={close}
              fieldData={this.state.selectedField}
              pickListValues={
                this.props.pickListValuesMap[this.state.selectedField.id]
              }
              fieldType="interactions"
              onDelete={onDelete}
              onSubmit={(fieldData) => handleUpdate(fieldData)}
            />
          )}
        />
      </React.Fragment>
    );
  }
}

const NameButton = styled(PlainButton)`
  color: ${colors.linkText};

  &:hover {
    color: ${colors.accent};
  }
`;

const EditButton = styled(PlainButton)`
  color: ${colors.lightText};
  font-size: 18px;
  width: 100%;

  &:hover {
    color: ${colors.linkText};
  }
`;

export default CustomFieldTable;
