import _ from "underscore";

const appNamesById = {
  35: "Volunteers",
  6: "Evertrue",
};

export const regexEscape = (str) =>
  str ? str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") : null; // eslint-disable-line

export const searchCompare = (str = "", query) =>
  !query ||
  str.toLowerCase().match(new RegExp(`\\b${regexEscape(query.toLowerCase())}`));

export const titleCase = (str = "") =>
  str
    .split("")
    .map((letter, index) =>
      index === 0 ? letter.toUpperCase() : letter.toLowerCase()
    )
    .join("");

// omits functions
export const cheapCloneData = (obj = {}) => JSON.parse(JSON.stringify(obj));

export const asyncDelay = (ms) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const compactObject = (obj = {}) =>
  _.pick(obj, (val) => !_.isNull(val) && !_.isUndefined(val));

export const sum = (list = []) => list.reduce((memo, val) => memo + val, 0);

export const getAppNameForKey = (appId) => {
  return appNamesById[appId];
};

export const humanizeString = (str) =>
  str
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/^[a-z]/, (m) => m.toUpperCase());

// export const getDeviceOS = () => {
//   if (navigator.userAgent.match(/Android/i)) {
//     return "android";
//   } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
//     return "ios";
//   } else if (navigator.userAgent.match(/Macintosh/i)) {
//     // welcome to hack city: need to do additional checks for iPad from IOS13. https://stackoverflow.com/questions/4460205/detect-ipad-iphone-webview-via-javascript/59016446#59016446
//     // need to distinguish between Macbook and iPad
//     const canvas = document.createElement("canvas");
//     if (canvas != null) {
//       const context = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
//       if (context) {
//         const info = context.getExtension("WEBGL_debug_renderer_info");
//         if (info) {
//           const renderer = context.getParameter(info.UNMASKED_RENDERER_WEBGL);
//           if (renderer.indexOf("Apple") !== -1) {
//             return "ios";
//           }
//         }
//       }
//     }
//   }
// };
