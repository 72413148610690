import React, { Component } from "react";
import T from "prop-types";
import _ from "underscore";
import { Button, Icon, Loading } from "@evertrue/et-components";
import { colors } from "style/colors";
import {
  AddAllButton,
  RemoveAllButton,
  AvailableSearch,
  Container,
  DLItemLabel,
  DNDListItem,
  DNDWrapper,
  EditorMain,
  EditorInner,
  EditIcon,
  Footer,
  HeaderContainer,
  HelpText,
  LIST_STYLES,
  RightIcon,
  Label,
  StyledEmptyStateMessage,
  TitleSection,
  SecondaryLabel,
  Wrapper,
} from "components/column-config-editor/column-config-editor-style";
import { searchCompare, titleCase, humanizeString } from "utils/utils";
import ConfirmDelete from "components/confirm-delete";

const DLItem = ({
  item,
  onDelete,
  onEdit,
  icon,
  toggleItemStatus,
  searchKey,
  secondaryLabelKey,
  disabled,
}) => {
  const secondaryLabel = item[secondaryLabelKey];
  return (
    <DNDListItem>
      <Icon icon="menu" title="drag and drop handle" />
      <DLItemLabel>
        <div>{item[searchKey]}</div>

        {secondaryLabel &&
          (Array.isArray(secondaryLabel) ? (
            secondaryLabel.map(
              (label) =>
                label.display_name && (
                  <SecondaryLabel key={label.category}>
                    {label.display_name}
                  </SecondaryLabel>
                )
            )
          ) : (
            <SecondaryLabel>{secondaryLabel}</SecondaryLabel>
          ))}
      </DLItemLabel>

      {typeof onDelete === "function" && (
        <ConfirmDelete
          field={item}
          fieldDisplayName={item[searchKey]}
          onDelete={onDelete}
        />
      )}

      {typeof onEdit === "function" && (
        <EditIcon title={icon} icon="edit" onClick={() => onEdit(item)} />
      )}
      {!disabled && (
        <RightIcon
          iconColor={colors.linkText}
          onClick={() => toggleItemStatus(item, true)}
          icon={icon}
        />
      )}
    </DNDListItem>
  );
};

DLItem.propTypes = {
  item: T.object,
  onDelete: T.func,
  onEdit: T.func,
  icon: T.string,
  toggleItemStatus: T.func,
  searchKey: T.string,
  secondaryLabelKey: T.oneOfType([T.string, T.array]),
  disabled: false,
};

const list_propTypes = T.shape({
  items: T.array.isRequired,
  label: T.string.isRequired,
  notSortable: T.bool,
});

class ColumnConfigEditor extends Component {
  static propTypes = {
    handleCancel: T.func,
    loading: T.bool,
    handleSave: T.func.isRequired,
    onEdit: T.func,
    searchKey: T.string.isRequired, // used for the left column client side search box, as well as displaying the name of each item
    secondaryLabelKey: T.string,
    listOne: list_propTypes.isRequired,
    listTwo: list_propTypes.isRequired,
    editorEmptyState: T.any,
    disableSave: T.bool,
    enabletoggleAll: T.bool,
    disableCancel: T.bool,
    onChange: T.func.isRequired,
    disabled: T.bool,
  };

  static defaultProps = {
    handleCancel: _.noop,
    onEdit: _.noop,
    loading: false,
    disableSave: false,
    disableCancel: false,
    enabletoggleAll: false,
    editorEmptyState: () => (
      <StyledEmptyStateMessage text="Add an item to get started" />
    ),
    secondaryLabelKey: "",
    disabled: false,
  };

  state = {
    search_val: "",
  };

  getItemLists = () => {
    const {
      listTwo: { items: list_two_items = [] },
      listOne: { items: list_one_items = [] },
    } = this.props;
    return { list_one_items, list_two_items };
  };

  toggleItemStatus = (status, itemToToggle) => {
    const { list_one_items, list_two_items } = this.getItemLists();

    const place = list_two_items.length + 2;
    const updated_items = toggleActiveStatus({
      items: [...list_two_items, ...list_one_items],
      status,
      itemToToggle,
      place,
    });
    const updated_list_one = updated_items.filter((i) => !i.active);
    const updated_list_two = updated_items.filter((i) => i.active);

    if (status === true) {
      this.props.onChange({
        listOne: updated_list_one,
        listTwo: updated_list_two,
      });
    } else {
      const resorted_list_two_items = updated_list_two
        .sort((a, b) => a.sort_order - b.sort_order)
        .map((item, index) => ({ ...item, sort_order: index + 2 }));

      this.props.onChange({
        listOne: updated_list_one,
        listTwo: resorted_list_two_items,
      });
    }
  };

  toggleAllStatus = (status) => {
    const { list_one_items, list_two_items } = this.getItemLists();
    const updater = _.partial(toggleStatus, status);

    this.props.onChange({
      listOne: list_one_items.map(updater),
      listTwo: list_two_items.map(updater),
    });
  };

  handleReorder = ({ listOne, listTwo }) => {
    this.props.onChange({
      listTwo: listTwo.map((stage, index) => ({
        ..._.omit(stage, "component"),
        active: true,
        sort_order: index + 1, // 0 is unsorted
      })),
      listOne: listOne.map((stage) => ({
        ..._.omit(stage, "component"),
        active: false,
        sort_order: 0,
      })),
    });
  };

  handleSearch = (search_val) => {
    this.setState({ search_val });
  };

  render() {
    const { listOne, listTwo, searchKey, secondaryLabelKey } = this.props;

    const leftList = {
      ...listOne,
      items: listOne.items
        .filter((item) => searchCompare(item[searchKey], this.state.search_val))
        .map((item) => ({
          ...item,
          component: () => (
            <DLItem
              item={item}
              toggleItemStatus={_.partial(this.toggleItemStatus, true)}
              onEdit={this.props.onEdit}
              onDelete={this.props.onDelete}
              icon="arrow-right"
              searchKey={searchKey}
              secondaryLabelKey={secondaryLabelKey}
              disabled={this.props.disabled}
            />
          ),
        })),
      disabled: this.props.disabled,
    };

    const rightList = {
      ...listTwo,
      items: listTwo.items.map((item) => ({
        ...item,
        component: () => (
          <DLItem
            item={item}
            toggleItemStatus={_.partial(this.toggleItemStatus, false)}
            onDelete={this.props.onDelete}
            onEdit={this.props.onEdit}
            icon="arrow-left"
            searchKey={searchKey}
            secondaryLabelKey={secondaryLabelKey}
            disabled={this.props.disabled}
          />
        ),
      })),
      disabled: this.props.disabled,
    };

    const lists = {
      listOne: leftList,
      listTwo: rightList,
    };

    return (
      <Wrapper>
        <EditorMain>
          <EditorInner>
            {this.props.loading ? (
              <Loading />
            ) : (
              <React.Fragment>
                <HeaderContainer>
                  <TitleSection>
                    <Container>
                      <Label>{this.props.listOne.label}</Label>
                      {this.props.enabletoggleAll && (
                        <AddAllButton
                          onClick={() => this.toggleAllStatus(true)}
                        >
                          add all{" "}
                          <RightIcon
                            title="arrow right button"
                            iconColor={colors.linkText}
                            icon="arrow-right"
                          />
                        </AddAllButton>
                      )}

                      <AvailableSearch
                        onKeyUp={this.handleSearch}
                        name="search"
                        placeholder={`Search by ${humanizeString(
                          titleCase(this.props.searchKey)
                        )}`}
                      />
                    </Container>
                  </TitleSection>
                  <TitleSection>
                    <Label marginTop="4px">{this.props.listTwo.label}</Label>
                    {this.props.enabletoggleAll && (
                      <RemoveAllButton
                        onClick={() => this.toggleAllStatus(false)}
                      >
                        remove all{" "}
                        <RightIcon iconColor={colors.errorDark} icon="cancel" />
                      </RemoveAllButton>
                    )}
                    <HelpText>Drag and drop to prioritize the order.</HelpText>
                  </TitleSection>
                </HeaderContainer>
                <DNDWrapper
                  listStyles={LIST_STYLES}
                  className="sort-selector--content"
                  lists={lists}
                  onReorder={this.handleReorder}
                />
              </React.Fragment>
            )}{" "}
          </EditorInner>
          <Footer>
            <Button
              disabled={this.props.disableCancel}
              onClick={this.props.handleCancel}
              className="et--button-secondary"
            >
              Cancel
            </Button>
            <Button
              type="action"
              className="et--button"
              disabled={this.props.disableSave}
              onClick={this.props.handleSave}
            >
              Save
            </Button>
          </Footer>
        </EditorMain>
      </Wrapper>
    );
  }
}
function toggleActiveStatus({ items, itemToToggle, status, place }) {
  return items.map((item) => {
    if (item.id === itemToToggle.id) {
      return {
        ...item,
        active: status,
        sort_order: status === true ? place : 0,
      };
    }
    return item;
  });
}

function toggleStatus(status, item) {
  return { ..._.omit(item, "component"), active: status };
}

export default ColumnConfigEditor;
