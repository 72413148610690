import Api from "utils/api";

export const fetchAssignmentTitles = () =>
  Api.ASSIGNMENTS.ASSIGNMENT_TITLES.get();

export const updateAssignmentTitle = (data) =>
  Api.ASSIGNMENTS.ASSIGNMENT_TITLES.put({
    urlExtend: `/${data.id}`,
    data,
  });

export const createAssignmentTitle = (data) =>
  Api.ASSIGNMENTS.ASSIGNMENT_TITLES.post({
    data,
  });

export const deleteAssignmentTitle = (data) =>
  Api.ASSIGNMENTS.ASSIGNMENT_TITLES.delete({
    urlExtend: `/${data.id}`,
  });

export const bulkUpdateAssignmentTitles = (data) =>
  Api.ASSIGNMENTS.ASSIGNMENT_TITLES.put({
    urlExtend: "/bulk",
    data,
  });
