import styled from "styled-components";
import { font, colors } from "style/colors";
import { EmptyStateMessage } from "@evertrue/et-components";

export const EditorEditableInputWrapper = styled.div`
  align-self: flex-start;
  justify-content: flex-start;
  padding: 16px 8px;
  font-size: 20px;
  display: flex;
  margin: 0 36px;

  .stage-editor--name-input {
    flex-grow: 2;
    padding: 4px 0;
  }
`;

export const EditorNameLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  flex-grow: 2;
  display: flex;

  input[type="text"] {
    padding: 16px;
    font-size: ${font.baseFontSize};
    color: ${font.baseFontColor}
    width: 100%;
    max-width: 300px;
    margin-left: 16px;
    border-radius: 2px;
    appearance: none;

  }
`;

export const StageContainer = styled.div`
  border-left: 1px solid ${colors.border};
  flex-basis: 100%;
`;

export const StyledEmptyStateMessage = styled(EmptyStateMessage)`
  &.empty-state-message {
    max-width: 550px;
    margin: 70px auto;
  }

  .et--button {
    margin-top: 16px;
  }
`;

export const StageWrapper = styled.div`
  margin: 24px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
`;

export const StagesMain = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
`;
