import React from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";

import { Field } from "apps/importer/components/import-mapping/field";
import {
  FieldContainer,
  Container,
  FieldsList,
} from "apps/importer/components/import-mapping/mapping-containers/shared-styles";

export const UnmappedContainer = ({ unmappedItems }) => {
  return (
    <React.Fragment>
      {unmappedItems && (
        <FieldContainer type="unmapped">
          <Container>
            <Droppable droppableId={"unmapped-attributes"}>
              {(provided) => (
                <FieldsList
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {unmappedItems &&
                    unmappedItems.map((item, index) => (
                      <Field key={item.id} index={index} field={item} />
                    ))}
                  {provided.placeholder}
                </FieldsList>
              )}
            </Droppable>
          </Container>
        </FieldContainer>
      )}
    </React.Fragment>
  );
};

UnmappedContainer.propTypes = {
  unmappedItems: PropTypes.arrayOf(PropTypes.object).isRequired,
};
