import styled from "styled-components";
import { colors } from "style/colors";

export const CharacterCountContainer = styled.div`
  text-align: right;
  margin: 0px;
  p {
    margin: 0px;
  }
`;
export const CharacterLimitExceeded = styled.p`
  color: ${colors.errorLight};
`;
