import React from "react";
import PropTypes from "prop-types";
import { SearchResultItem } from "scripts/style/components/search-box";

const SearchResult = ({ result, selected, onClick }) => {
  return (
    <SearchResultItem
      className={selected ? "selected-row" : ""}
      onClick={(e) => onClick(result, e)}
    >
      <span>{result.name}</span>
    </SearchResultItem>
  );
};

SearchResult.propTypes = {
  result: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

SearchResult.defaultProps = {
  result: {},
  selected: false,
  onClick: () => {},
};

export default SearchResult;
