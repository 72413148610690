import Api from "utils/api";

export const fetchInteractionTypes = () => Api.INTERACTION.TYPES.get();

export const createInteractionType = (data) =>
  Api.INTERACTION.TYPES.post({
    data,
  });

export const deleteInteractionType = (data) =>
  Api.INTERACTION.TYPES.delete({
    urlExtend: `/${data.id}`,
  });

export const bulkUpdateInteractionTypes = (data) =>
  Api.INTERACTION.TYPES.put({
    urlExtend: "/bulk",
    data,
  });

export const fetchImportedInteractionTypes = () =>
  Api.INTERACTION.IMPORTED_TYPES.get();

export const filterImportedInteractionTypes = (importedTypes, types) => {
  const filtered = importedTypes.filter(
    (item) =>
      !item.includes("Volunteer") &&
      item !== "EverTrue Comment" &&
      item !== "DXO Ask" &&
      !types.find((type) => type.type === item)
  );
  return filtered;
};
