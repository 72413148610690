import Api from "utils/api";

export const fetchStageGroups = (group_type) =>
  Api.VOLUNTEERS.STAGES.get({
    params: { stage_group_type: group_type },
  });

export const createStageGroup = (data) =>
  Api.VOLUNTEERS.STAGES.post({
    data,
  });

export const updateStageGroup = (id, data) =>
  Api.VOLUNTEERS.STAGES.put({
    urlExtend: `/${id}`,
    data,
  });

export const createStageInGroup = (group_id, data) =>
  Api.VOLUNTEERS.STAGES.post({
    urlExtend: `/${group_id}`,
    data,
  });

export const updateStageInGroup = (group_id, stage) =>
  Api.VOLUNTEERS.STAGES.put({
    urlExtend: `/${group_id}/${stage.id}`,
    data: {
      stage: stage.stage,
      sort_order: stage.sort_order,
      active: stage.active,
    },
  });
