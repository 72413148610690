import React from "react";
import styled from "styled-components";
import { colors } from "style/colors";
import evertrue from "../../../../images/evertrue-logo-white.png";
import UserHeaderDropDown from "./user-header-dropdown";
import { Link } from "react-router-dom";
import IconHeaderGroup from "../icon-header-group/icon-header-group";

const AppHeader = ({ user, org = {} }) => (
  <StyledHeader>
    <Link
      to="/"
      onDoubleClick={() => {
        window.mixpanel && window.mixpanel.track("Home Logo Click", {});
        window.location = "/";
      }}
    >
      <LogoWrapper>
        <FlexLogo>
          <LogoText>Data Manager</LogoText>
        </FlexLogo>
        <PoweredBy>
          <PoweredByText>Powered By</PoweredByText>
          <EverTrueLogo src={evertrue} alt="evertrue logo" />
        </PoweredBy>
      </LogoWrapper>
    </Link>
    <Header>
      <PageTitle>
        <OrgName> {org.name} </OrgName>
      </PageTitle>
      <UserItems>
        <IconHeaderGroup user={user} />
        {user ? <UserHeaderDropDown user={user} org={org} /> : <span />}
      </UserItems>
    </Header>
  </StyledHeader>
);

const StyledHeader = styled.div`
  display: flex;
  min-height: 60px;
  max-height: 60px;
  background-color: #5598ab;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const UserItems = styled.div`
  display: flex;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
  padding: 12px 12px;
  border-right: solid 1px ${colors.infoDark};
  background-color: #4c899a;
  border-right: solid 1px #447a89;
  min-width: 235px;
`;

const PageTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 80%;
`;

const FlexLogo = styled.div`
  display: flex;
  justify-content: center;
`;

const EverTrueLogo = styled.img`
  margin-left: 4px;
  margin-top: -2px;
  height: 12px;
`;

const LogoText = styled.span`
  font: 20px Roboto;
  text-decoration: none;
  color: white;
  font-weight: 500;
`;

const PoweredBy = styled.p`
  display: flex;
  justify-content: center;
  margin-top: 2px;
  text-transform: lowercase;
`;

const PoweredByText = styled.span`
  font-size: 10px;
  color: white;
`;

const OrgName = styled.span`
  color: ${colors.white};
  font-size: 20px;
  font-weight: 500;
  padding: 20px 24px;
`;

export default AppHeader;
