import React, { Component } from "react";
import _ from "underscore";
import {
  AlertActions,
  Button,
  Loading,
  ModalWrapper,
  SearchBox,
} from "@evertrue/et-components";
import { Wrapper } from "style/components";
import { searchCompare } from "utils/utils";
import * as CustomFieldService from "apps/interactions/interaction-custom-fields-service";
import {
  ActionBar,
  SearchBoxWrapper,
} from "apps/interactions/controllers/custom-fields-interactions-controller-style";
import CustomFieldTable from "apps/custom-fields/components/custom-field-table";
import CustomFieldModal from "apps/custom-fields/components/custom-field-modal";
import {
  formatLabel,
  formatControl,
  getIsFieldPickList,
} from "apps/custom-fields/custom-fields-utils";

// TODO: maybe don't use 1 as first index
const WHITELIST = [
  "id",
  "oid",
  "dataType",
  "required",
  "displayName",
  "uiControlType",
  "esField",
  "createdAt",
  "updatedAt",
  "sortOrder",
  "active",
];

class CustomFieldsInteractionsController extends Component {
  state = {
    customFields: [],
    searchVal: "",
    isDragDisabled: false,
    isModalOpen: false,
    pickListValuesMap: {},
  };

  async componentDidMount() {
    await this.fetchData();

    const pick_list_values = await Promise.all(
      this.state.customFields
        .filter((field) =>
          getIsFieldPickList(field.dataType, field.uiControlType)
        )
        .map(
          (field) =>
            CustomFieldService.fetchCustomFieldValues(field.id)
              .then((data) => data.data)
              .then((data) => ({ [field.id]: data.items })) // data is an array ex: {12: []}
        )
    );
    this.setState({
      pickListValuesMap: pick_list_values.reduce(
        (acc, value) => ({ ...acc, ...value }),
        {}
      ),
    });
  }

  fetchData = async () => {
    try {
      this.setState({
        loading: true,
      });
      const { data = [] } = await CustomFieldService.fetchCustomFields();
      const dataWithNames = data.map((field = {}) => {
        let control_type = field.uiControlType;
        // if there is no control type, but data type is a string
        // then the default type will be a dropdown
        if (field.dataType === "STRING" && !field.uiControlType) {
          control_type = "DROPDOWN";
        }
        const control_label = formatControl(control_type);
        return {
          ...field,
          typeLabel: formatLabel(field.dataType, control_label),
        };
      });

      this.setState({
        customFields: dataWithNames,
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  handleSearch = (searchVal) => {
    this.setState({
      searchVal,
      isDragDisabled: Boolean(searchVal),
    });
  };

  showToastAndFetch = (message = "Custom field created.") => {
    AlertActions.launchToast({
      type: "success",
      message,
      timeout: 2000,
    });
    this.fetchData();
  };

  handleModalSubmit = async (obj = {}) => {
    if (this.validateDisplayName(obj.displayName)) return;

    await CustomFieldService.createCustomField(
      _.pick(
        { ...obj, sortOrder: this.state.customFields.length + 1 },
        WHITELIST
      )
    );
    this.showToastAndFetch();
  };

  handleUpdate = async (obj = {}, validateName = false) => {
    if (validateName && this.validateDisplayName(obj.displayName)) return;

    await CustomFieldService.updateCustomField(_.pick(obj, WHITELIST));
    this.showToastAndFetch("Custom field updated.");
  };

  validateDisplayName = (name = "") => {
    if (!name) {
      AlertActions.launchToast({
        type: "error",
        message: "A custom field must have a display name",
      });
    }
    const existing = this.state.customFields.find(
      ({ displayName = "" } = {}) =>
        displayName.toLowerCase() === name.toLowerCase()
    );

    if (existing) {
      AlertActions.launchToast({
        type: "error",
        message: "Display names must be unique",
      });
    }
    return !name || existing;
  };

  handleDelete = async (id) => {
    await CustomFieldService.deleteCustomField(id);
    const list = this.state.customFields.filter((field) => field.id !== id);
    this.handleReorder(list);
  };

  handleReorder = async (updated) => {
    if (!updated.length) return;
    const newList = updated.map((i, idx) => ({ ...i, sortOrder: idx }));
    this.setState({ loading: true });
    const { customFields: oldList } = this.state;
    const requests = oldList.reduce((accum, field = {}) => {
      // find position in the new list and use it as sortOrder
      const newSortOrder =
        newList.findIndex((item) => item.id === field.id) + 1;
      // get any that have changes
      if (newSortOrder !== field.sortOrder) {
        const obj = {
          ...field,
          sortOrder: newSortOrder,
        };
        return [
          ...accum,
          CustomFieldService.updateCustomField(_.pick(obj, WHITELIST)),
        ];
      }
      return accum;
    }, []);
    await Promise.all(requests);
    this.showToastAndFetch("Custom fields updated.");
  };

  handleToggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  render() {
    const filteredCustomFields = this.state.customFields.filter(
      ({ displayName }) => searchCompare(displayName, this.state.searchVal)
    );

    return (
      <Wrapper>
        <ActionBar>
          <SearchBoxWrapper>
            <SearchBox
              placeholder="Search Field Names"
              onChange={this.handleSearch}
            />
          </SearchBoxWrapper>
          <ModalWrapper
            isOpen={this.state.isModalOpen}
            width={500}
            onClose={this.handleToggleModal}
            render={({ close }) => (
              <CustomFieldModal
                fieldType="interactions"
                onSubmit={this.handleModalSubmit}
                close={close}
              />
            )}
          />
          <Button type="default" onClick={this.handleToggleModal}>
            New Interactions Field
          </Button>
        </ActionBar>
        {this.state.loading ? (
          <Loading />
        ) : (
          <CustomFieldTable
            isDragDisabled={this.state.isDragDisabled}
            handleReorder={this.handleReorder}
            filteredCustomFields={filteredCustomFields}
            handleUpdate={this.handleUpdate}
            onDelete={this.handleDelete}
            pickListValuesMap={this.state.pickListValuesMap}
          />
        )}
      </Wrapper>
    );
  }
}

export default CustomFieldsInteractionsController;
