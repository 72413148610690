import { useFlags } from "launchdarkly-react-client-sdk";
import PropTypes from "prop-types";

export const useFeatureFlag = (flagName) => {
  const flags = useFlags();
  const flagEnabled = flags.hasOwnProperty(flagName) ? flags[flagName] : false;

  // helper function for dev use only that prints an array of all flag keys
  const printAllFlags = () => {
    const allFlagKeys = Object.keys(flags);
    console.log(allFlagKeys);
  };

  return { flagEnabled, printAllFlags };
};

useFeatureFlag.propTypes = {
  flagName: PropTypes.string.isRequired,
};
