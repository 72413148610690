import React from "react";
import { Redirect, Route, Switch, Link } from "react-router-dom";
import { Layout, Icon } from "@evertrue/et-components";
import TitleHeader from "components/title-header";
import { Nav, NavItem } from "style/components/nav-tabs";
import ProposalStagesController from "./proposal-stages-controller";
import ProposalCustomFieldsController from "./proposal-custom-fields-controller";
import ProposalCustomFieldValuesController from "./proposal-custom-field-values-controller";
import CustomTypesController from "apps/custom-types/controllers/custom-types-controller";
import * as ProposalTypesService from "../proposal-types-service";
import * as ProposalSolicitorTitlesService from "../proposal-solicitor-titles-service";
import * as ProposalDesignationsService from "../proposal-designations-service";
import { Header } from "apps/layout/components/page-layout-style";
import IsGated, { useGate } from "apps/dna/is-gated";

const ProposalRouteController = (props) => {
  const { show: proposal_writes_enabled } = useGate("proposal_writes");
  return (
    <Switch>
      {/* remove nav tabs from this section since based on id */}
      <Route
        path="/proposals/custom-fields/values/:id?"
        render={({ match: { params }, location }) => {
          const custom_field_id = params.id
            ? parseInt(params.id, 10)
            : undefined;
          return (
            <Layout
              headerBorder="none"
              header={
                <Header>
                  <Link to="/proposals/custom-fields">
                    <Icon icon="chevron-left" size={2} />
                  </Link>
                  <h3>{location.state.display_name}</h3>
                </Header>
              }
            >
              <ProposalCustomFieldValuesController
                customField={location.state}
                customFieldId={custom_field_id}
              />
              ;
            </Layout>
          );
        }}
      />
      <Route>
        <Layout
          headerBorder={false}
          header={
            <React.Fragment>
              <TitleHeader title="Proposals" />
              <Nav>
                <IsGated gate="proposal_writes">
                  <NavItem activeClassName="active" to="/proposals/stages">
                    Stages
                  </NavItem>
                </IsGated>
                <IsGated gate="proposal_custom_fields">
                  <NavItem
                    activeClassName="active"
                    to="/proposals/custom-fields"
                  >
                    Custom Fields
                  </NavItem>
                </IsGated>
                <NavItem activeClassName="active" to="/proposals/types">
                  Types
                </NavItem>
                <NavItem
                  activeClassName="active"
                  to="/proposals/solicitor-titles"
                >
                  Solicitor Titles
                </NavItem>
                <NavItem activeClassName="active" to="/proposals/designations">
                  Designations
                </NavItem>
              </Nav>
            </React.Fragment>
          }
        >
          <Switch>
            <Route path="/proposals/stages">
              <ProposalStagesController />
            </Route>

            <Route path="/proposals/custom-fields">
              <ProposalCustomFieldsController />
            </Route>

            <Route
              path="/proposals/types"
              render={() => (
                <CustomTypesController
                  maxCount={50}
                  fieldName="type"
                  endPoints={{
                    fetch: ProposalTypesService.fetchProposalTypes,
                    create: ProposalTypesService.createProposalType,
                    delete: ProposalTypesService.deleteProposalType,
                    bulkUpdate: ProposalTypesService.bulkUpdateProposalTypes,
                    fetchImported:
                      ProposalTypesService.fetchImportedProposalTypes,
                    filterImported:
                      ProposalTypesService.filterImportedProposalTypes,
                  }}
                />
              )}
            />

            <Route
              path="/proposals/solicitor-titles"
              render={() => (
                <CustomTypesController
                  fieldName="title"
                  endPoints={{
                    fetch: ProposalSolicitorTitlesService.fetchSolicitorTitles,
                    create: ProposalSolicitorTitlesService.createSolicitorTitle,
                    delete: ProposalSolicitorTitlesService.deleteSolicitorTitle,
                    bulkUpdate:
                      ProposalSolicitorTitlesService.bulkUpdateSolicitorTitles,
                    fetchImported:
                      ProposalSolicitorTitlesService.fetchImportedSolicitorTitles,
                    filterImported:
                      ProposalSolicitorTitlesService.filterImportedSolicitorTitles,
                  }}
                />
              )}
            />

            <Route
              path="/proposals/designations"
              render={() => (
                <CustomTypesController
                  fieldName="name"
                  endPoints={{
                    fetch:
                      ProposalDesignationsService.fetchProposalDesignations,
                    create:
                      ProposalDesignationsService.createProposalDesignation,
                    delete:
                      ProposalDesignationsService.deleteProposalDesignation,
                    bulkUpdate:
                      ProposalDesignationsService.bulkUpdateProposalDesignations,
                  }}
                />
              )}
            />

            <Route path="/proposals">
              <Redirect
                to={`/proposals/${
                  proposal_writes_enabled ? "stages" : "custom-fields"
                }`}
              />
            </Route>
          </Switch>
        </Layout>
      </Route>
    </Switch>
  );
};

ProposalRouteController.propTypes = {};

export default ProposalRouteController;
