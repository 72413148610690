import { AlertActions } from "@evertrue/et-components";
import DX_GROUP_PAYLOAD from "./dx-group-payload.json";
import SIGNAL_BY_ET_GROUP_PAYLOAD from "./signal-group-payload.json";
import { showSuccessToast } from "scripts/apps/journey-groups/utils/api-requests";

const showErrorToast = (msg) => {
  const message =
    msg ||
    "Sorry, there was an error completing your request. If the problem persists, please contact genius@evertrue.com";
  AlertActions.removeToast(); // Clear existing toast
  AlertActions.launchToast({
    type: "error",
    message,
    timeout: 2000,
  });
};

export const onboardGroup = async (groupName) => {
  const PAYLOAD =
    groupName === "dx" ? DX_GROUP_PAYLOAD : SIGNAL_BY_ET_GROUP_PAYLOAD; // will be "signal" or "dx"

  const response = await api.JOURNEYS.ONBOARD.post({
    data: PAYLOAD,
  }).catch((error) => {
    showErrorToast(
      "There was a problem onboarding your organization. Please try again."
    );
    return Promise.reject(error);
  });
  showSuccessToast("Success! Your organization has been onboarded!");
  return response.data;
};

export const onboardVOIP = async (oid, organization) => {
  const PAYLOAD = { oid: oid, name: organization };
  const response = await api.OUTREACH.ONBOARD_VOIP.post({
    data: PAYLOAD,
  }).catch((error) => {
    showErrorToast(
      "There was a problem onboarding VOIP to your organization. Please try again."
    );
    return Promise.reject(error);
  });
  showSuccessToast("Success! Your organization has been onboarded to VOIP!");
  return response.data;
};

export const offboardVoip = async () => {
  const response = await api.OUTREACH.OFFBOARD_VOIP.post().catch((error) => {
    showErrorToast(
      "There was a problem offboarding your organization from our VOIP system. Please try again."
    );
    return Promise.reject(error);
  });
  showSuccessToast(
    "Success! Your organization has been offboarded from our VOIP system!"
  );
  return response.data;
};

export const getOrgVoipSettings = async () => {
  const response = await api.OUTREACH.ORG_SETTINGS.get().catch((error) => {
    return Promise.reject(error);
  });
  return response.data;
};

export const toggleGate = async (gate, enabled) => {
  await api.DNA.GATES.put({
    urlExtend: `/${gate}`,
    data: { platform: "web", enabled: enabled },
  }).catch((error) => {
    return Promise.reject(error);
  });
};
