import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { ModalDeprecated, ModalConfirm } from "@evertrue/et-components";

const Message = styled.div`
  padding: 32px;
  line-height: 21px;
`;

export default function (message, callback) {
  const target = document.createElement("div");
  document.body.appendChild(target);

  const withCleanup = (answer) => {
    ReactDOM.unmountComponentAtNode(target);
    document.body.removeChild(target);
    callback(answer);
  };

  ReactDOM.render(
    <ModalDeprecated width={440}>
      <ModalConfirm
        saveLabel="Stay on this page"
        cancelLabel="Leave this page"
        header="Are you sure?"
        onCancel={() => withCleanup(true)}
        onSubmit={() => withCleanup(false)}
      >
        <Message>{message}</Message>
      </ModalConfirm>
    </ModalDeprecated>,
    target
  );
}
