import React, { Component } from "react";
import {
  ModalHeaderDeprecated,
  ModalBody,
  ModalFooterWithButtons,
  AdvancedCombobox,
  AlertActions,
} from "@evertrue/et-components";
import styled from "styled-components";
import Api from "utils/api";
import DatePicker from "components/date-picker";
import moment from "moment";

const OPTIONS = [
  { label: "Today", value: "now-1d" },
  { label: "Last 7 days", value: "now-1w" },
  { label: "Last 30 days", value: "now-1M" },
  { label: "Custom date range", value: "custom" },
];

const getQuery = (timePeriod) => ({
  name: "approved_suggestions",
  search: {
    must: [
      {
        is_approved: {
          match: true,
        },
      },
      {
        updated_at: timePeriod,
      },
    ],
  },
});

class CDCExportController extends Component {
  state = {
    selected: undefined,
    dateQuery: undefined,
  };

  startExport = async () => {
    try {
      await Api.EXPORT.SUGGESTIONS.post({
        data: JSON.stringify(getQuery(this.state.dateQuery)),
        params: { export_guid: true },
      });
      AlertActions.launchToast({
        type: "success",
        message:
          "Your export has started. It will be sent to your email when finished.",
        timeout: 3000,
      });
    } catch (error) {
      AlertActions.launchToast({
        type: "error",
        message: "Export failed. Please try again or contact support.",
        timeout: 3000,
      });
    }
  };

  handleSelectTimePeriod = (selected) => {
    if (selected.value !== "custom") {
      this.setState({ dateQuery: { gte: selected.value } });
    }
    this.setState({ selected });
  };

  handleCustomRangeSelect = ({ to, from }) => {
    if (to && from) {
      const dateQuery = {
        gte: moment(from).format("YYYY-MM-DD"),
        lte: moment(to).format("YYYY-MM-DD"),
      };
      this.setState({ dateQuery });
    }
  };

  render() {
    const showCustom =
      this.state.selected && this.state.selected.value === "custom";

    return (
      <ModalWrapper>
        <ModalHeaderDeprecated
          header="Export Approved Suggestions"
          closeModal={this.props.close}
        />
        <ModalBody>
          <ContentWrapper>
            <LabelWrapper>Time Period</LabelWrapper>
            <AdvancedCombobox
              placeholder="Select date range..."
              options={OPTIONS}
              value={this.state.selected}
              onChange={this.handleSelectTimePeriod}
            />
            {showCustom && (
              <DatePicker onChange={this.handleCustomRangeSelect} />
            )}
          </ContentWrapper>
        </ModalBody>
        <ModalFooterWithButtons
          saveLabel="Export"
          disableSave={!this.state.dateQuery}
          onSubmit={this.startExport}
          closeModal={this.props.close}
          onCancel={this.props.close}
        />
      </ModalWrapper>
    );
  }
}

const ModalWrapper = styled.div``;
const ContentWrapper = styled.div`
  padding: 36px 0;
`;
const LabelWrapper = styled.div`
  padding-bottom: 16px;
`;

export default CDCExportController;
