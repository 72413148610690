import { AlertActions } from "@evertrue/et-components";

const showErrorToast = (msg) => {
  const message =
    msg ||
    "Sorry, there was an error completing your request. If the problem persists, please contact genius@evertrue.com";
  AlertActions.removeToast(); // Clear existing toast
  AlertActions.launchToast({
    type: "error",
    message,
    timeout: 2000,
  });
};

const JOURNEY_GROUP_FIELDS = `{
  id
  group_type
  name
  creator_user_id
  creator {
    name
  }
  user_count
  journey_count
  }`;

export const showSuccessToast = (msg) => {
  AlertActions.removeToast(); // Clear existing toast
  AlertActions.launchToast({
    type: "success",
    message: msg,
    timeout: 2000,
  });
};

export const createJourneyGroup = async (params) => {
  const response = await api.JOURNEYS.JOURNEY_GROUPS.post({
    params,
  }).catch((error) => {
    showErrorToast(
      "There was a problem creating your Category. Please try again."
    );
    return Promise.reject(error);
  });

  return response.data;
};

export const editJourneyGroup = async (data, journeyGroupId, oid) => {
  //Added the below fields in the payload since its required in the backend.
  if (oid) data.oid = oid;
  data.active = true;
  const response = await api.JOURNEYS.JOURNEY_GROUP.put({
    data,
    urlArgs: { id: journeyGroupId },
  }).catch((error) => {
    showErrorToast(
      "There was a problem editing your Cadence Category. Please try again."
    );
    return Promise.reject(error);
  });

  return response.data;
};

export const deactivateJourneyGroup = async (journeyGroup) => {
  const { name = "this Cadence Category", id } = journeyGroup;
  const response = await api.JOURNEYS.JOURNEY_GROUP.delete({
    urlArgs: { id },
  }).catch((error) => {
    showErrorToast(`There was a problem deleting ${name}. Please try again.`);
    return Promise.reject(error);
  });
  showSuccessToast(`${name} deactivated successfully`);
  return response.data;
};

export const getJourneyGroupUsersByGroupId = async (journeyGroupId) => {
  const query = `
  query($journeyGroupId:BigInt!){
    journeyGroupUsersByJourneyGroupId(journeyGroupId:$journeyGroupId) {
      id
      user {
        id
        name
        email
      }
    }
  }`;

  // Function to sort the results by user name in ascending order.
  // Note: Sorting on the front end is currently the best approach because the user data is being
  // joined from a different database, making it difficult to sort server-side.
  function sortByNameAsc(data) {
    return data.journeyGroupUsersByJourneyGroupId
      .filter((item) => item && !!item.user && item.user.name)
      .sort((a, b) => {
        return a.user.name.localeCompare(b.user.name);
      });
  }

  const {
    data: { data: users },
  } = await api.GRAPHQL.GRAPHQL.post({
    data: JSON.stringify({
      operationName: null,
      query,
      variables: { journeyGroupId },
    }),
  }).catch((error) => {
    showErrorToast(
      "There was a problem fetching users for this Cadence Category. Please try again."
    );
    return Promise.reject(error);
  });

  return sortByNameAsc(users);
};

export const removeUserFromJourneyGroup = async (journeyGroupUser) => {
  const {
    id,
    user: { name = "User" },
  } = journeyGroupUser;
  const response = await api.JOURNEYS.JOURNEY_GROUP_USER.delete({
    urlArgs: { id },
  }).catch((error) => {
    showErrorToast(
      `There was a problem removing ${name} from this Cadence Category. Please try again.`
    );
    return Promise.reject(error);
  });
  showSuccessToast(
    `${name} has been removed from this Cadence Category successfully`
  );
  return response.data;
};

export const addUsersToJourneyGroup = async (journeyGroupId, users) => {
  const userIds = users.map((user) => user.id);
  const response = await api.JOURNEYS.BULK_JOURNEY_GROUP_USERS.post({
    data: {
      journey_group_id: journeyGroupId,
      user_ids: userIds,
      is_group_owner: false,
    },
  }).catch((error) => {
    showErrorToast(
      "There was a problem adding users to this Cadence Category. Please try again."
    );
    return Promise.reject(error);
  });
  return response.data;
};

export const getJourneyGroups = async (includeInactive) => {
  const query = `query ($journeyGroupType: String, $includeInactive: Boolean!) { journeyGroups(journeyGroupType: $journeyGroupType, includeInactive: $includeInactive) ${JOURNEY_GROUP_FIELDS} }`;
  const { data } = await api.GRAPHQL.GRAPHQL.post({
    data: JSON.stringify({
      operationName: null,
      query,
      variables: { includeInactive },
    }),
  }).catch((error) => {
    showErrorToast("There was a problem fetching Categories. Please try again.");
    return Promise.reject(error);
  });
  return data.data.journeyGroups;
};
