import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import str from "underscore.string";
import styled from "styled-components";
import { ModalForm, Checkbox } from "@evertrue/et-components";
import { FormWrapper } from "style/components/form";
import uid from "uuid/v4";

const CustomImportedTypeModal = ({
  fieldName,
  types,
  handleCreate,
  afterClose,
  onClose = () => {},
}) => {
  const [formTypes, setFormTypes] = useState([]);

  useEffect(() => {
    const defaultFormTypes = types.map((type) => {
      return { value: type, checked: false };
    });

    setFormTypes(defaultFormTypes);
  }, [types]);

  const handleSubmit = async () => {
    const customTypes = formTypes.filter((type) => type.checked);
    await Promise.all(
      customTypes.map((customType) =>
        handleCreate({ [fieldName]: customType.value })
      )
    );
    afterClose();
  };

  return (
    <ModalForm
      onClose={onClose}
      disableFunc={() => !formTypes.filter((type) => type.checked).length}
      header={"Manage Imported Fields"}
      handleSubmit={handleSubmit}
      width={500}
      render={() => (
        <FormWrapper>
          <Wrapper>
            {formTypes.length ? (
              formTypes.map((type) => (
                <Checkbox
                  label={type.value}
                  onChange={(checked) => {
                    const updatedTypes = formTypes.map((updatedType) =>
                      updatedType.value === type.value
                        ? { ...updatedType, checked }
                        : updatedType
                    );
                    setFormTypes(updatedTypes);
                  }}
                  checked={type.checked}
                  key={uid()}
                >
                  {type.value}
                </Checkbox>
              ))
            ) : (
              <div>No items to add</div>
            )}
          </Wrapper>
        </FormWrapper>
      )}
    />
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .et--checkbox {
    padding: 4px 0;
  }
`;

CustomImportedTypeModal.propTypes = {
  fieldName: PropTypes.string.isRequired,
  types: PropTypes.array.isRequired,
  handleCreate: PropTypes.func.isRequired,
  afterClose: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default CustomImportedTypeModal;
