import React, { Component } from "react";
import T from "prop-types";
import _ from "underscore";
import {
  FlexTable,
  Checkbox,
  FlexTableSortHeaderCell,
  Icon,
  EmptyStateMessage,
} from "@evertrue/et-components";
import { titleCase } from "utils/utils";
import env from "utils/env";
import { getAppNameForKey } from "utils/utils";
import styled from "styled-components";
import { colors } from "style/colors";
import moment from "moment";

const LIMIT = 10;

class CDCTable extends Component {
  static propTypes = {
    submissions: T.array,
    page: T.number,
    total: T.number,
    onPageChange: T.func,
    is_date_desc: T.bool,
    onDateSortChange: T.func,
    active_row_id: T.number,
    onRowClick: T.func,
    onSelect: T.func,
  };

  static defaultProps = {
    submissions: [],
    page: 0,
    total: 0,
    onPageChange: _.noop,
    is_date_desc: true,
    onDateSortChange: _.noop,
    active_row_id: undefined,
    onRowClick: _.noop,
    onSelect: _.noop,
  };

  formatColumns() {
    return [
      {
        header: "",
        headerStyle: { flexGrow: 1 },
        Cell: (item) => (
          <td
            className={item.className}
            onClick={(e) => e.stopPropagation()}
            style={{ flexGrow: 1 }}
          >
            <Checkbox
              checked={item.checked}
              onChange={(isChecked) =>
                this.props.onSelect(item.suggestions[0].id, isChecked)
              }
            />
          </td>
        ),
      },
      {
        header: "Constituent Name",
        Cell: (item) => (
          <td className={item.className}>
            <a
              href={`${env.evertrueUrl}/contact/${item.contact_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.contact_name}
            </a>
          </td>
        ),
      },

      {
        header: "Type",
        Cell: (item) => (
          <td
            className={item.className}
            style={{ flexDirection: "column", alignItems: "flex-start" }}
          >
            <strong>
              {item.suggestions[0].action_type
                ? item.suggestions[0].action_type === "FORMER"
                  ? "Retire"
                  : titleCase(item.suggestions[0].action_type)
                : "Unavailable"}
            </strong>
            <div>
              {item.suggestions[0].data_type
                ? titleCase(item.suggestions[0].data_type)
                : null}
            </div>
          </td>
        ),
      },
      {
        header: (
          <FlexTableSortHeaderCell onClick={this.props.onDateSortChange}>
            Date
            <CDCTableHeaderAction>
              <Icon
                icon={this.props.is_date_desc ? "arrow-down" : "arrow-up"}
              />
            </CDCTableHeaderAction>
          </FlexTableSortHeaderCell>
        ),
        Cell: (item) => (
          <td
            style={{ flexDirection: "column", alignItems: "baseline" }}
            className={item.className}
          >
            <div>{moment(item.created_at).format("M/DD/YYYY")}</div>
            <div>{moment(item.created_at).format("h:mm A")}</div>
          </td>
        ),
      },
      {
        header: "Submitted by",
        accessor: "submitter_user_name",
      },
      {
        header: "Source",
        accessor: (item) => getAppNameForKey(item.app_id),
      },
    ];
  }

  render() {
    return (
      <CDCTableWrapper>
        <FlexTable
          columns={this.formatColumns()}
          data={this.props.submissions}
          onRowClick={this.props.onRowClick}
          emptyMessage={<EmptyStateMessage text="You have no search results" />}
          handlePageChange={this.props.onPageChange}
          page={this.props.page}
          total={this.props.total}
          limit={LIMIT}
          getTrProps={(data) => ({
            onClick: (e) => {
              this.props.onRowClick(data.id);
            },
          })}
        />
      </CDCTableWrapper>
    );
  }
}

const CDCTableWrapper = styled.div`
  flex-basis: 70%;
  .et--fixed-table-rows--row {
    cursor: pointer;
  }
  td {
    padding: 12px 8px;
  }
`;

const CDCTableHeaderAction = styled.span`
  cursor: pointer;
  margin-left: 4;
  color: ${colors.linkText};
`;

export default CDCTable;
