import React from "react";
import PropTypes from "prop-types";
import { AuthContext } from "scripts/auth-connector";

// subscribes to the AuthContext.Provider rendered at top level of the app

const IsSuperUser = ({ children }) => (
  <AuthContext.Consumer>
    {({ super_user }) => (super_user ? children : null)}
  </AuthContext.Consumer>
);

IsSuperUser.propTypes = {
  children: PropTypes.node,
};

IsSuperUser.defaultProps = {
  children: null,
};

export default IsSuperUser;
