import _ from "underscore";
import React from "react";
import axios from "axios";
// functions to be used when implementing a new section with this editor

/* diffs an array with an object indexed by ids to see
 *  if there are any changes in the properties listed in the keys array.
 *  used when using disableSave and disableCancel on the editor.
 *  store the original state as an object and diff your client side state with it
 *  keys: an array of keys (properties) on the objects to diff
 */
export const getDiffedItems = (keys, prevItemsMap = {}, current = []) => {
  return current.filter((item) => {
    const original = prevItemsMap[item.id] || {};
    return keys.some((key) => original[key] !== item[key]);
  });
};
// hook that's used in multiple editors, most have same api/needs so abstract state management logic
export const useMultiColumnConfigState = ({
  initialDataState, // any
  fetchDataFunc, // fn => Promise
  handleFetchDataError = () => {}, // fn
  keysToDiff, // array of strings
  useEffectDependencies = [], // array
}) => {
  const [data, setData] = React.useState(initialDataState);
  const [loading, setLoading] = React.useState(false);
  const [orig_data_map, setOrigDataMap] = React.useState({});
  const [item_to_edit, setItemToEdit] = React.useState(null);

  const appliedGetDiffedItems = _.partial(getDiffedItems, keysToDiff);
  const signal = React.useRef(axios.CancelToken.source());

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchDataFunc(signal.current.token);
      // save so we can diff to disable button
      setOrigDataMap(_.indexBy(data, "id"));

      setData(data);
      setLoading(false);
    } catch (e) {
      handleFetchDataError(e);
    }
  };

  React.useEffect(() => {
    fetchData();
    return () => {
      if (signal.current) {
        signal.current.cancel(); // cancels fetch request
      }
    };
  }, useEffectDependencies);

  const items_have_changed = Boolean(
    appliedGetDiffedItems(orig_data_map, data).length
  );

  return {
    itemsHaveChanged: items_have_changed,
    fetchData,
    loading,
    setLoading,
    itemToEdit: item_to_edit,
    setItemToEdit,
    data,
    setData,
  };
};
