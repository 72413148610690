import Api from "utils/api";

export const fetchProposalTypes = () => Api.PROPOSAL.TYPES.get();

export const createProposalType = (data) =>
  Api.PROPOSAL.TYPES.post({
    data,
  });

export const deleteProposalType = (data) =>
  Api.PROPOSAL.TYPES.delete({
    urlExtend: `/${data.id}`,
  });

export const bulkUpdateProposalTypes = (data) =>
  Api.PROPOSAL.TYPES.put({
    urlExtend: "/bulk",
    data: data,
  });

export const fetchImportedProposalTypes = () =>
  Api.PROPOSAL.IMPORTED_TYPES.get();

export const filterImportedProposalTypes = (importedTypes, types) => {
  const filtered = importedTypes.filter(
    (item) => !types.find((type) => type.type === item)
  );
  return filtered;
};
