import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { ErrorMessage } from "formik";
import { Checkbox } from "@evertrue/et-components";
import ModalEditor from "components/modal-editor";
import * as ProposalService from "../proposal-service";

const ProposalCustomFieldValueModal = ({
  customFieldId,
  customFieldValue,
  customFieldValues,
  onSuccess,
  ...rest
}) => {
  return (
    <ModalEditor
      {...rest}
      header={`${customFieldValue ? "Edit" : "Add"} Proposal Field Value`}
      initialValues={
        !customFieldValue ? { value: "", active: true } : customFieldValue
      }
      validate={(values) => {
        let errors = {};
        const is_dupe = customFieldValues.find(
          (existingField) => existingField.value === values.value
        );
        if (!values.value) errors = { value: true };
        if (!customFieldValue && is_dupe) {
          errors = { value: "Duplicate custom field names not allowed" };
        }
        if (customFieldValue && _.isEqual(customFieldValue, values))
          errors = { ...errors, no_change: true };
        return errors;
      }}
      onSubmit={(values) => {
        if (!customFieldValue) {
          ProposalService.createProposalCustomFieldPickListValue(
            customFieldId,
            {
              ...values,
              sort_order: customFieldValues.length + 1,
            }
          ).then(onSuccess);
        } else {
          ProposalService.updateProposalCustomFieldPickListValue(
            customFieldId,
            values
          ).then(onSuccess);
        }
      }}
    >
      <ModalEditor.FormField label="Custom Field Value Name" name="value" />
      <ErrorMessage name="value" />
      <div css="padding: 12px 0; display: flex">
        <ModalEditor.FormField label="" name="active">
          {({ field, form }) => (
            <Checkbox
              label="active"
              onChange={(value) => form.setFieldValue("active", value)}
              checked={field.value}
              value="active"
            />
          )}
        </ModalEditor.FormField>
        <div>
          <strong>Active Value</strong>
          <div>
            Enable users to update custom field pick lists using this value.
          </div>
        </div>
      </div>
    </ModalEditor>
  );
};

ProposalCustomFieldValueModal.propTypes = {
  customFieldId: PropTypes.number.isRequired,
};

export default ProposalCustomFieldValueModal;
