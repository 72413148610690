import Api from "utils/api";

export const fetchProposalDesignations = () => Api.PROPOSAL.DESIGNATIONS.get();

export const createProposalDesignation = (data) =>
  Api.PROPOSAL.DESIGNATIONS.post({
    data,
  });

export const deleteProposalDesignation = (data) =>
  Api.PROPOSAL.DESIGNATIONS.delete({
    urlExtend: `/${data.id}`,
  });

export const bulkUpdateProposalDesignations = (data) =>
  Api.PROPOSAL.DESIGNATIONS.put({
    urlExtend: "/bulk",
    data: data,
  });
