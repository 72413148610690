import React from "react";
import PropTypes from "prop-types";
import { NavItem, Nav } from "style/components/nav-tabs";
import {
  ContentWrapper,
  Header,
  Wrapper,
  Subtitle,
} from "apps/layout/components/page-layout-style";

const PageLayout = ({
  header,
  title,
  subtitle,
  navItems = [],
  children,
  contentTop,
  isRow = false,
}) => (
  <Wrapper>
    <Header isRow={isRow}>
      {header || (
        <React.Fragment>
          <h3>{title}</h3>
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        </React.Fragment>
      )}
    </Header>
    {navItems.length ? (
      <Nav position="fixed" top="129px">
        {navItems.map((item) => (
          <NavItem activeClassName="active" key={item.key} to={item.to}>
            {item.label}
          </NavItem>
        ))}
      </Nav>
    ) : null}
    <ContentWrapper top={contentTop}>{children}</ContentWrapper>
  </Wrapper>
);

PageLayout.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  navItems: PropTypes.array,
  children: PropTypes.node,
  contentTop: PropTypes.string,
  isRow: PropTypes.bool,
};

PageLayout.defaultProps = {
  header: null,
  title: "",
  subtitle: "",
  navItems: [],
  children: null,
  contentTop: "70px",
  isRow: false,
};

export default PageLayout;
