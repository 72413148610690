import api from "scripts/utils/api";
import { AlertActions } from "@evertrue/et-components";

const showErrorToast = (msg) => {
  const message =
    msg ||
    "Sorry, there was an error completing your request. If the problem persists, please contact genius@evertrue.com";
  AlertActions.removeToast(); // Clear existing toast
  AlertActions.launchToast({
    type: "error",
    message,
  });
};

const JOURNEY_FIELDS = `{
  id
  name
  journey_type
  oid
  duration_in_days
  number_of_steps
  duration_in_days
  number_of_steps
  active
  objective_description
  journey_group_id
  sort_order
  active_constituents
  steps {
      id
      step_number
      day_number
      description
      active
      default_action_type
      title
    }
    journey_group{
      name
    }
}`;

const getJourneyById = async (journeyId) => {
  const response = await api.JOURNEYS.JOURNEY.get({
    urlArgs: { id: journeyId },
  });
  if (response.status === 200) {
    return response.data;
  }
};

export const getJourneyByIdFromGraphql = async (journeyId) => {
  const query = `query($journeyId: Int!) { journey(journeyId: $journeyId) ${JOURNEY_FIELDS} }`;
  const { data } = await api.GRAPHQL.GRAPHQL.post({
    data: JSON.stringify({
      operationName: null,
      query,
      variables: { journeyId },
    }),
  }).catch((error) => {
    showErrorToast(
      "There was a problem fetching your Cadence. Please try again."
    );
    return Promise.reject(error);
  });
  return data.data.journey;
};

export const getJourneysByGroupId = async (journeyGroupId) => {
  const query = `query($journeyGroupId: BigInt!) { journeysForJourneyGroup(journeyGroupId: $journeyGroupId) ${JOURNEY_FIELDS} }`;
  const { data } = await api.GRAPHQL.GRAPHQL.post({
    data: JSON.stringify({
      operationName: null,
      query,
      variables: { journeyGroupId },
    }),
  }).catch((error) => {
    showErrorToast(
      "There was a problem fetching your Cadences. Please try again."
    );
    return Promise.reject(error);
  });
  return data.data.journeysForJourneyGroup || [];
};

export const getJourneyGroupsForOrg = async () => {
  const response = await api.JOURNEYS.JOURNEY_GROUPS.get();
  if (response.status === 200) {
    return response.data;
  }
};

export const getJourneysForOrg = async () => {
  const journeyGroups = await getJourneyGroupsForOrg();
  const journeys = await Promise.all(
    journeyGroups.map((group) => {
      return getJourneysByGroupId(group.id);
    })
  );

  return journeys.flat();
};

export const createJourney = async (payload, sort_order, oid) => {
  const data = {
    ...payload,
    sort_order, // defaulting to last in table
    duration_in_days: 0,
    number_of_steps: 0,
    active: true,
  };

  if (oid) data.oid = oid;

  const response = await api.JOURNEYS.JOURNEYS.post({ data }).catch((error) => {
    showErrorToast(
      "There was a problem creating your Cadence. Please try again."
    );
    return Promise.reject(error);
  });
  if (response.status === 200) {
    return response.data;
  }
};

export const editJourney = async (payload) => {
  const existingJourney = await getJourneyById(payload.id);
  const data = { ...existingJourney, ...payload };
  const response = await api.JOURNEYS.JOURNEY.put({
    data,
    urlArgs: { id: existingJourney.id },
  }).catch((error) => {
    showErrorToast(
      "There was a problem editing your Cadence. Please try again."
    );
    return Promise.reject(error);
  });
  if (response.status === 200) {
    return response.data;
  }
};

export const deactivateJourney = async (journeyId) => {
  const response = await api.JOURNEYS.JOURNEY.delete({
    urlArgs: { id: journeyId },
  }).catch((error) => {
    showErrorToast(
      "There was a problem deleting your Cadence. Please try again."
    );
    return Promise.reject(error);
  });
  if (response.status === 200) {
    return response.data;
  }
};

export const createJourneyTask = async (payload) => {
  const data = { ...payload, active: true };
  const response = await api.JOURNEYS.JOURNEY_TASKS.post({
    data,
  }).catch((error) => {
    showErrorToast("There was a problem creating your Step. Please try again.");
    return Promise.reject(error);
  });
  if (response.status === 200) {
    return response.data;
  }
};

const getJourneyTaskById = async (journeyTaskId) => {
  const response = await api.JOURNEYS.JOURNEY_TASK.get({
    urlArgs: { id: journeyTaskId },
  });
  if (response.status === 200) {
    return response.data;
  }
};

export const editJourneyTask = async (payload, journeyTaskId) => {
  const existingJourneyTask = await getJourneyTaskById(journeyTaskId);
  const data = { ...existingJourneyTask, ...payload };
  const response = await api.JOURNEYS.JOURNEY_TASK.put({
    data,
    urlArgs: { id: journeyTaskId },
  }).catch((error) => {
    showErrorToast("There was a problem updating your Step. Please try again.");
    return Promise.reject(error);
  });
  if (response.status === 200) {
    return response.data;
  }
};

export const deactivateJourneyTask = async (journeyTaskId) => {
  const response = await api.JOURNEYS.DEACTIVATE_JOURNEY_TASK.put({
    urlArgs: { id: journeyTaskId },
  }).catch((error) => {
    showErrorToast("There was a problem deleting your Step. Please try again.");
    return Promise.reject(error);
  });
  if (response.status === 200) {
    return response.data;
  }
};

export const getActionTypes = async () => {
  const { data } = await api.JOURNEYS.ACTION_TYPES.get();
  const formattedTaskActionTypes = Object.entries(data).map(
    ([value, label]) => ({ value, label })
  );
  return formattedTaskActionTypes;
};
