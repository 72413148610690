import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import IsGated, { useGate } from "apps/dna/is-gated";
import EmmaController from "./emma/emma-controller";
import PlatformManagementController from "./platform-management/platform-management-controller";
import { Nav, NavItem } from "../../style/components/nav-tabs";
import { Layout } from "@evertrue/et-components";
import TitleHeader from "../../components/title-header";
import CrmController from "./crm/crm-controller";

const Integrations = () => {
  const { show: emmaEnabled } = useGate("emma");

  const header = (
    <Fragment>
      <TitleHeader title="Integrations" subtitle="Settings for Integrations" />
      <Nav>
        <IsGated gate="emma">
          <NavItem activeClassName="active" to="/integrations/emma">
            Emma
          </NavItem>
        </IsGated>
        <IsGated gate="meeting_platforms">
          <NavItem
            activeClassName="active"
            to="/integrations/platform-management"
          >
            Meeting Platforms
          </NavItem>
        </IsGated>
        <IsGated gate="crm_integrations">
          <NavItem activeClassName="active" to="/integrations/crm">
            CRM
          </NavItem>
        </IsGated>
      </Nav>
    </Fragment>
  );

  return (
    <Layout header={header} headerBorder={false}>
      <Switch>
        {emmaEnabled && (
          <Route path="/integrations/emma">
            <EmmaController />
          </Route>
        )}
        <Route path="/integrations/platform-management">
          <PlatformManagementController />
        </Route>
        <Route path="/integrations/crm">
          <CrmController />
        </Route>
      </Switch>
    </Layout>
  );
};

export default Integrations;
