import React from "react";

import { IntegrationCard } from "apps/integrations/crm/integration-cards/integration-card";
import raisersEdgeImage from "../images/raisers-edge-logo.png"; // Images are outside of app root defined space. Relative path is necessary.

import useBlackBaudRenxtAuthFlow from "apps/integrations/crm/utils/blackbaud-renxt-auth-flow";

export const BlackbaudRenxtIntegration = () => {
  const { integrationState, handleClick, loading } = useBlackBaudRenxtAuthFlow();

  return (
    <IntegrationCard
      headerTitle={"EverTrue Sync"}
      state={integrationState}
      handleIntegrationClick={handleClick}
      contentImageHref={raisersEdgeImage}
      loading={loading}
      primaryContentText={
        "Automatically syncs data between Blackbaud and EverTrue"
      }
    />
  );
};

export default BlackbaudRenxtIntegration;
