import React from "react";
import styled from "styled-components";
import T from "prop-types";

import { colors } from "../style/colors";

const Module = ({ header: headerContent, children, height, ...rest }) => (
  <Wrapper {...rest} height={height}>
    {headerContent && <ModuleHeader> {headerContent} </ModuleHeader>}
    {children}
  </Wrapper>
);

Module.propTypes = {
  header: T.node,
};

const Wrapper = styled.div`
  height: ${(props) => (props.height ? props.height : "auto")};
  background: ${colors.white};
  border: solid 1px ${colors.border};
  box-shadow: none;
  border-radius: 3px;
`;

const ModuleHeader = styled.div`
  font-size: 16px;
  border-bottom: solid 1px #d2dde0;
  padding: 10px;
  color: #686868;
  width: 100%;
`;
export default Module;
