import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Loading } from "@evertrue/et-components";

export const InlineLoading = ({ text }) => (
  <Relative>
    <Loading position="top" text={text} />
  </Relative>
);

InlineLoading.propTypes = {
  text: PropTypes.string,
};

InlineLoading.defaultProps = {
  text: "",
};

const Relative = styled.div`
  position: relative;
`;

export default InlineLoading;
