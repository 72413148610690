import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { NavItem, Nav } from "style/components/nav-tabs";
import { Button, Icon } from "@evertrue/et-components";
import {
  ContentWrapper,
  HeaderWithButtons,
  Wrapper,
  Subtitle,
  ButtonsContainer,
  TitleWithBackArrow,
} from "apps/layout/components/page-layout-with-buttons-style";

const PageLayoutWithButtons = ({
  header,
  title,
  subtitle,
  navItems = [],
  children,
  contentTop,
  buttons = [],
  backTo = null,
}) => (
  <Wrapper>
    <HeaderWithButtons>
      {header || (
        <React.Fragment>
          {backTo ? (
            <TitleWithBackArrow>
              <Link to={backTo}>
                <Icon icon="chevron-left" size={2} className="settings-back" />
              </Link>
              <div>
                <h3>{title}</h3>
                {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
              </div>
            </TitleWithBackArrow>
          ) : (
            <div>
              <h3>{title}</h3>
              {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
            </div>
          )}
          <ButtonsContainer>
            {buttons.map(({ key, label, onClick, type, renderComponent }) =>
              type === "customComponent" ? (
                renderComponent()
              ) : (
                <Button key={key} type={type} onClick={onClick}>
                  {label}
                </Button>
              )
            )}
          </ButtonsContainer>
        </React.Fragment>
      )}
    </HeaderWithButtons>
    {navItems.length ? (
      <Nav position="fixed" top="129px">
        {navItems.map((item) => (
          <NavItem activeClassName="active" key={item.key} to={item.to}>
            {item.label}
          </NavItem>
        ))}
      </Nav>
    ) : null}
    <ContentWrapper top={contentTop}>{children}</ContentWrapper>
  </Wrapper>
);

PageLayoutWithButtons.defaultProps = {
  header: null,
  title: "",
  subtitle: null,
  navItems: [],
  contentTop: "70px",
  buttons: [],
  backTo: null,
};

PageLayoutWithButtons.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.node,
  navItems: PropTypes.array,
  children: PropTypes.node.isRequired,
  contentTop: PropTypes.string,
  buttons: PropTypes.array,
  backTo: PropTypes.string,
};
export default PageLayoutWithButtons;
