import React, { Component } from "react";

import ComingSoon from "../../components/coming-soon";

class ExportData extends Component {
  render() {
    return <ComingSoon />;
  }
}

export default ExportData;
