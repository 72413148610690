import React from "react";
import PropTypes from "prop-types";
import JourneyGroupModal from "./modals/journey-group-modal";
import DeleteJourneyGroupModal from "./modals/delete-journey-group-modal";
import { ThreeDotActionsMenu, ActionsMenu } from "@evertrue/et-components";

const JourneyGroupActionMenu = ({
  type = "button",
  group = {},
  handleDelete,
  handleEdit,
}) => {
  const editAction = {
    id: 1,
    mountOnClick: ({ is_open, close }) => (
      <JourneyGroupModal
        isOpen={is_open}
        onClose={close}
        group={group}
        editGroup={handleEdit}
      />
    ),
    label: "Edit Category",
    icon: "edit",
  };

  const deleteAction = {
    id: 2,
    mountOnClick: ({ is_open, close }) => (
      <DeleteJourneyGroupModal
        isOpen={is_open}
        onClose={close}
        group={group}
        handleDelete={handleDelete}
      />
    ),
    label: "Delete Category",
    icon: "trash",
  };

  const options = {
    id: 1,
    items: [editAction, deleteAction],
  };

  if (type === "button") return <ActionsMenu options={[options]} />;

  return <ThreeDotActionsMenu options={[options]} iconSize={1} />;
};

JourneyGroupActionMenu.propTypes = {
  type: PropTypes.string,
  group: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

JourneyGroupActionMenu.defaultProps = {
  type: "button",
};

export default JourneyGroupActionMenu;
