import AWS from "aws-sdk/global";
import S3 from "aws-sdk/clients/s3";

export const createS3Client = ({
  roleArn,
  accessKeyId,
  secretAccessKey,
  sessionToken,
  bucket,
}) => {
  const credentials = (AWS.config.credentials =
    new AWS.ChainableTemporaryCredentials({
      params: { RoleArn: roleArn },
      masterCredentials: new AWS.Credentials({
        accessKeyId,
        secretAccessKey,
        sessionToken,
      }),
    }));

  return new S3({ credentials, params: { Bucket: bucket } });
};

export default createS3Client;
