import _ from "underscore";
import config from "utils/env";

const { Raven } = window;
const SENTRY_PATH =
  "https://1220474f8c1748f8bf9ab434b0cbf0bc@sentry.io/1366920";

if (!config.isDevelopment()) {
  Raven &&
    Raven.config(SENTRY_PATH, {
      environment: config.urlEnv,
      tags: { data_environment: config.dataEnv },
      autoBreadcrumbs: { xhr: true, console: true, dom: true, location: true },
    }).install();
}

export default {
  send(message, data) {
    if (data == null) {
      data = {};
    }
    if (!(data.tags || data.extra) && !_.isEmpty(data)) {
      data = { extra: data };
    }

    if (!config.isDevelopment()) {
      if (Raven && Raven.isSetup()) {
        Raven.captureMessage(message, data);
      }
    }
  },

  error(message, data) {
    if (data == null) {
      data = {};
    }
    this.send(message, data);
  },

  warn(message, data) {
    if (data == null) {
      data = {};
    }
    if (!(data.tags || data.extra)) {
      data = { extra: data };
    }
    this.send(message, _.extend({ level: "warning" }, data));
  },

  captureRequest(message, xhr = {}) {
    // if xhr is a type error, which could happen if its just
    // a js error thats getting caught, then add the js error message to default message
    const sentry_message = xhr.message
      ? `${message} - ${xhr.message}`
      : message;

    this.warn(`${sentry_message}`, {
      tags: {
        status_code: xhr.status,
      },
      extra: { response: xhr.stack ? xhr.stack : xhr },
    });
  },
};
