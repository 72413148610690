//--------------------------------
// COLOR VARIABLES
//--------------------------------
export const colors = {
  black: "#000",
  white: "#fff",
  // Wrapper & Background
  darkBase: "#42565e",
  mediumBase: "#4d8899",
  mainBase: "#5598ab",
  lightBase: "#f5f5f5",
  // Text and Icons
  darkestText: "#454545",
  darkerText: "#757575",
  lightText: "#a7a7a7",
  lightestText: "#d8d8d8",
  linkText: "#19a4df",
  linkTextSecondary: "#8e999f",
  // Action & Feedback
  accent: "#d0207e",
  success: "#7ebd37",
  error: "#eb534b",
  warning: "#f0c94a",
  secondary: "#f2f6f7",
  // Social Colors
  linkedinBlue: "#0077b5",
  facebookBlue: "#3b5998",
  // Use-case Variables
  border: "#d2dde0",
  background: "#f5f5f5",
  textHighlight: "#dfefcd",
  blue: "#00aeef",
  green: "#29c111",
  yellow: "#dddf00",
  orange: "#f7941e",
  red: "#df2214",
  teal: "#008080",
  // Button Colors + States
  infoLight: "#5ca4b8",
  infoDark: "#4b8290",
  accentLight: "#de2186",
  accentDark: "#ab1a67",
  secondaryLight: "#f7fcfc",
  secondaryDark: "#e6eaeb",
  errorLight: "#f7584f",
  errorDark: "#c4463f",
  blueGrayDarker: "#144659",
  blueGrayDark: "#6a909a",
  blueGray: "#aec6cc",
  blueGrayLight: "#e2ebed",
  blueGrayLighter: "#f6f8f9",
  grayLighter: "#f7f7f7",
  offSet: "#F3F6F7",
};

export const font = {
  baseFontSize: "14px",
  baseFontColor: colors.darkestText,
  baseLineHeight: "21px",
  baseFontFamily: [
    "Roboto",
    "Helvetica",
    "Tahoma",
    "Geneva",
    "Arial",
    "sans-serif",
  ],
};
