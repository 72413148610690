import React, { useEffect, useState } from "react";
import { useOpenClose, Loading } from "@evertrue/et-components";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderCell,
} from "style/components";
import {
  getJourneysForOrg,
  createJourney,
  editJourney,
  deactivateJourney,
  getJourneyByIdFromGraphql,
} from "apps/journeys/utils/api-requests/journeys-requests";
import PropTypes from "prop-types";
import JourneysModal from "../modals/journeys-modal";
import JourneysTableRow from "./journeys-table-row";
import PageLayoutWithButtons from "scripts/apps/layout/components/page-layout-with-buttons";

const JourneysTable = ({ oid, history }) => {
  const [journeys, setJourneys] = useState([]);
  const [loadingJourneys, setLoadingJourneys] = useState(false);
  const [isModalOpen, openModal, closeModal] = useOpenClose();

  useEffect(() => {
    const getAndSetJourneys = async () => {
      try {
        setLoadingJourneys(true);
        const journeys = await getJourneysForOrg();
        setJourneys(journeys);
      } finally {
        setLoadingJourneys(false);
      }
    };

    getAndSetJourneys();
  }, []);

  const updateTableState = async (action, journey) => {
    // There are some custom fields from GQL that we need
    const journeyFromGql = await getJourneyByIdFromGraphql(journey.id);
    const journeysCopy = [...journeys];
    if (action === "create") {
      journeysCopy.push(journeyFromGql);
      setJourneys(journeysCopy);
    } else if (action === "edit") {
      const indexToReplce = journeysCopy.findIndex((j) => j.id === journey.id);
      journeysCopy[indexToReplce] = journeyFromGql;
      setJourneys(journeysCopy);
    } else if (action === "delete") {
      const indexToDelete = journeysCopy.findIndex((j) => j.id === journey.id);
      journeysCopy.splice(indexToDelete, 1);
      setJourneys(journeysCopy);
    }
  };

  const handleCreate = async (payload) => {
    const sortOrder = journeys.length + 1;
    // TODO: NEED OID
    const journey = await createJourney(payload, sortOrder, oid);
    closeModal();
    history.push(`/cadences/${journey.id}`);
  };

  const handleEdit = async (payload) => {
    const journey = await editJourney(payload);
    updateTableState("edit", journey);
  };

  const handleDelete = async (journey) => {
    await deactivateJourney(journey.id); // returns null
    updateTableState("delete", { id: journey.id });
  };

  return (
    <PageLayoutWithButtons
      title={"Your Institution's Cadences"}
      buttons={[
        { key: 1, label: "Add Cadence", onClick: openModal, type: "default" },
      ]}
    >
      {loadingJourneys ? (
        <Loading />
      ) : (
        <Table>
          <TableHeader>
            <TableHeaderCell>Cadence Name</TableHeaderCell>
            <TableHeaderCell>Steps Due</TableHeaderCell>
            <TableHeaderCell>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{textAlign: "left"}}>Duration</span>
              <small style={{ fontSize: "12px", paddingTop: "5px", color: "#454545"}}>
                “days" refer to <strong>business days</strong>
              </small>
            </div>
            </TableHeaderCell>
            <TableHeaderCell>Active Constituents</TableHeaderCell>
            <TableHeaderCell>Cadence Category</TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableHeader>
          <TableBody>
            {journeys && journeys.length
              ? journeys.map((journey) => (
                  <JourneysTableRow
                    key={journey.id}
                    journey={journey}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                ))
              : null}
          </TableBody>
        </Table>
      )}

      {isModalOpen && (
        <JourneysModal
          isOpen={isModalOpen}
          onClose={closeModal}
          createJourney={handleCreate}
        />
      )}
    </PageLayoutWithButtons>
  );
};

JourneysTable.propTypes = {
  oid: PropTypes.number,
  history: PropTypes.object.isRequired,
};

JourneysTable.defaultProps = {
  oid: null,
};

export default JourneysTable;
