/** 
 * here's a list of all the current action types:
 CALL(1, "Call"),
 EMAIL(2, "Email"),
 LINKEDIN(3, "LinkedIn"),
 RESEARCH(4, "Research"),
 PRINT_MAIL(5, "Print Mail (e.g. Pledgemine, other)"),
 TEXT(6, "Text"),
 THANK_VIEW(7, "ThankView"),
 OTHER(8, "Other");
 */

export function setTaskIcon(default_action_type) {
  let taskIcon;

  switch (default_action_type) {
    case "EMAIL":
    case "LINKEDIN":
    case "TEXT":
    case "RESEARCH":
      taskIcon = `${default_action_type.toLowerCase()}-circle`;
      break;
    case "CALL":
      taskIcon = "phone-circle";
      break;
    default:
      taskIcon = "other-tasks-circle";
      break;
  }

  return taskIcon;
}
