import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ModalEditor from "components/modal-editor";
import * as ProposalService from "../proposal-service";
import {
  Checkbox,
  AdvancedCombobox,
  Button,
  Icon,
} from "@evertrue/et-components";
import { ErrorMessage } from "formik";

const ProposalStageModal = ({ stages = [], stage, onSuccess, ...rest }) => {
  return (
    <ModalEditor
      {...rest}
      header={`${stage ? "Edit" : "Add"} Proposal Stage`}
      initialValues={
        !stage
          ? {
              stage_name: "",
              active: true,
              proposal_amount_type: null,
              status: null,
            }
          : stage
      }
      validate={(values) => {
        let errors = {};
        if (!values.stage_name) errors = { stage_name: true };

        const duplicate = stages.find(
          (existingStage) => existingStage.stage_name === values.stage_name
        );

        if (!stage && duplicate) {
          errors = { stage_name: "Duplicate stages names not allowed" };
        }

        return errors;
      }}
      onSubmit={(values) => {
        if (!stage) {
          ProposalService.createProposalStage({
            ...values,
            sort_order: stages.length + 1,
          }).then(onSuccess);
        } else {
          const { label: _, ...stage } = values; // remove label from payload
          ProposalService.updateProposalStage(stage).then(onSuccess);
        }
      }}
    >
      <ModalEditor.FormField label="Stage Name" name="stage_name" />
      <ErrorMessage name="stage_name" />
      <div css="padding: 12px 0; display: flex">
        <ModalEditor.FormField label="" name="active">
          {({ field, form }) => (
            <Checkbox
              label="active"
              onChange={(value) => form.setFieldValue("active", value)}
              checked={field.value}
              value="active"
            />
          )}
        </ModalEditor.FormField>
        <div>
          <strong>Active Stage</strong>
          <div>Enable users to update prospects using this stage.</div>
        </div>
      </div>

      <ModalEditor.FormField
        label="Map stage to amount type"
        name="proposal_amount_type"
      >
        {({ field, form }) => (
          <FlexWrapper>
            <DropdownWrapper>
              <AdvancedCombobox
                placeholder="Select amount type.."
                label="Map Stage to Amount Type"
                position="up"
                options={[
                  { value: "ORIGINAL", label: "Original" },
                  { value: "ASK", label: "Ask" },
                  { value: "EXPECTED", label: "Expected" },
                  { value: "FUNDED", label: "Funded" },
                ]}
                value={{ value: field.value }}
                onChange={(option) =>
                  form.setFieldValue("proposal_amount_type", option.value)
                }
              />
            </DropdownWrapper>
            {field.value && (
              <Button
                type="simple"
                onClick={() => form.setFieldValue("proposal_amount_type", null)}
                label="Clear field"
              >
                <Icon icon="cancel" title="Clear field" size={1} />
              </Button>
            )}
          </FlexWrapper>
        )}
      </ModalEditor.FormField>

      <ModalEditor.FormField label="Stage Status" name="status">
        {({ field, form }) => (
          <FlexWrapper>
            <DropdownWrapper>
              <AdvancedCombobox
                placeholder="Select a status.."
                label="Stage Status"
                position="up"
                options={[
                  { value: "OPEN", label: "Open Stage" },
                  { value: "CLOSED", label: "Closed Stage" },
                ]}
                value={{ value: field.value }}
                onChange={(option) =>
                  form.setFieldValue("status", option.value)
                }
              />
            </DropdownWrapper>
            {field.value && (
              <Button
                type="simple"
                onClick={() => form.setFieldValue("status", null)}
                label="Clear field"
              >
                <Icon icon="cancel" title="Clear field" size={1} />
              </Button>
            )}
          </FlexWrapper>
        )}
      </ModalEditor.FormField>
    </ModalEditor>
  );
};

const FlexWrapper = styled.div`
  display: flex;
`;

const DropdownWrapper = styled.div`
  width: 400px;
`;

ProposalStageModal.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  stage: PropTypes.object,
  stages: PropTypes.array,
};

export default ProposalStageModal;
