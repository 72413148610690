import React from "react";
import PropTypes from "prop-types";
import JourneyTaskActionMenu from "../journey-task-action-menu";
import styled from "styled-components";
import { Icon } from "@evertrue/et-components";
import { setTaskIcon } from "../../utils/journeys-helpers";

const TaskRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  margin: 20px;
  background-color: white;
`;

const JourneyTaskRow = ({
  journeyTask,
  handleEdit,
  handleDelete,
  journeyId,
}) => {
  const {
    title = "",
    description = "",
    day_number,
    step_number,
    default_action_type,
  } = journeyTask;

  return (
    <TaskRowContainer>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icon
          icon={setTaskIcon(default_action_type)}
          iconKit="gt2"
          size={2}
          style={{ marginRight: "20px", flex: "none" }}
        />
        <span
          style={{
            marginRight: "40px",
            flex: "none",
            minWidth: "180px",
          }}
        >
          Step {step_number} | Day {day_number}
        </span>
        <div style={{ padding: "2px" }}>
          <div style={{ fontWeight: "500" }}>{title}</div>
          <div style={{ fontSize: "12px", paddingTop: "2px" }}>
            {description}
          </div>
        </div>
      </div>
      <JourneyTaskActionMenu
        journeyTask={journeyTask}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        type="threeDotMenu"
        journeyId={journeyId}
      />
    </TaskRowContainer>
  );
};

JourneyTaskRow.propTypes = {
  journeyTask: PropTypes.object,
  handleDelete: PropTypes.func,
  handleEdit: PropTypes.func,
  journeyId: PropTypes.number,
};

JourneyTaskRow.defaultProps = {
  journeyTask: {},
  handleDelete: () => {},
  handleEdit: () => {},
  journeyId: null,
};

export default JourneyTaskRow;
