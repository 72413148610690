import * as Yup from "yup";

export const AddStepSchema = Yup.object().shape({
  stepTitle: Yup.string()
    .required("Please enter a step name")
    .max(120, "Cadence name must be at most 120 characters long"),
  stepDescription: Yup.string()
    .max(250, "Cadence name must be at most 120 characters long")
    .nullable(),
  stepNumber: Yup.number()
    .required("Please enter a step number")
    .positive()
    .integer(),
  dayNumber: Yup.number()
    .positive()
    .integer()
    .required("Please enter a day number"),
  stepType: Yup.object()
    .shape({
      label: Yup.string().required(),
      value: Yup.mixed()
        .oneOf([
          "OTHER",
          "RESEARCH",
          "CALL",
          "THANK_VIEW",
          "LINKEDIN",
          "PRINT_MAIL",
          "TEXT",
          "EMAIL",
        ])
        .required(),
    })
    .required("Please select a step type"),
});
