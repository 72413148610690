import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { hot } from "react-hot-loader";
import {
  AlertController,
  OverlayController,
  Loading,
  Layout,
} from "@evertrue/et-components";
import AppHeader from "apps/layout/header/app-header";
import AuthConnector from "scripts/auth-connector";
import OrgSelection from "apps/auth/components/org-selection";
import prompt_override from "utils/router-prompt-override";
import GateProvider from "apps/dna/gate-context";
import DebugController from "./utils/api/debug-controller";
import AppRouteController from "scripts/app-route-controller";
import SideNav from "apps/layout/side-navigation/side-nav";
import config from "utils/env";
import { FlagProvider } from "./apps/launch-darkly/flag-context";

// import { getDeviceOS } from "utils/utils";

const AuthRouteController = () => {
  // const [mobileDeviceOS, setMobileDeviceOS] = useState(getDeviceOS());

  // useEffect(() => {
  //   const handleDeviceOS = () => setMobileDeviceOS(getDeviceOS());
  //   window.addEventListener("resize", handleDeviceOS);
  //   return () => {
  //     window.removeEventListener("resize", handleDeviceOS);
  //   };
  // }, [mobileDeviceOS]);

  window.Appcues.page();

  return (
    <Router getUserConfirmation={prompt_override}>
      <React.Fragment>
        <AlertController />
        <OverlayController />
        <AuthConnector>
          {(authProps) => {
            const {
              is_renewing,
              is_changing_orgs,
              has_session,
              oid,
              organization,
              user_id,
              user,
              super_user,
            } = authProps;

            if (user_id) {
              window.mixpanel && window.mixpanel.identify(user_id);
              window.Appcues.identify(user_id, { orgId: oid, ...authProps });
              mixpanel.people.set_once({ ...user });
            }

            return (
              <Switch>
                {/* should only see if logged out */}
                <Route path="/login">
                  {is_renewing ? (
                    <Loading />
                  ) : user_id && oid ? (
                    <Redirect to="/" />
                  ) : user_id ? (
                    <Redirect to="/auth/org" />
                  ) : (
                    window.location.replace(config.redirectLoginUrl)
                  )}
                </Route>

                {/* should see if logged in, but don't have an org */}
                <Route path="/auth/org">
                  {!user_id ? (
                    <Redirect to="/login" />
                  ) : (
                    <Layout
                      headerBorder={0}
                      header={<AppHeader user={user} org={organization} />}
                    >
                      <OrgSelection
                        user={user}
                        current_oid={oid}
                        is_changing_orgs={is_changing_orgs}
                      />
                    </Layout>
                  )}
                </Route>

                {/* all the authenticated routes */}
                <Route>
                  <FlagProvider oid={oid} user={user}>
                    <GateProvider key={oid} oid={oid} user_id={user_id}>
                      <Switch>
                        {/* debugging stuff for API work */}
                        <Route path="/debug">
                          <Layout
                            header={
                              <AppHeader user={user} org={organization} />
                            }
                          >
                            <DebugController />
                          </Layout>
                        </Route>
                        <Route>
                          {
                            /* TODO: Figure out a better way to judge if mobile or not. M1 chip seems to have messed with details.
                         {mobileDeviceOS ? (
                          <div
                            style={{
                              backgroundColor: "#5ca4b8",
                              color: "white",
                              height: "100%",
                              width: "100%",
                              padding: "350px 30px",
                              fontSize: "16px",
                            }}
                          >
                            <p style={{ textAlign: "center", marginBottom: "16px" }}>- We're sorry -</p>
                            <p>
                              Data Manager does not support mobile devices. Please use a computer to access this
                              application.
                            </p>
                          </div>
                        ) : */
                            !user_id ? (
                              has_session ? (
                                <Loading />
                              ) : (
                                <Redirect to="/login" />
                              )
                            ) : !oid ? (
                              <Redirect to="/auth/org" />
                            ) : (
                              <Layout
                                header={
                                  <AppHeader user={user} org={organization} />
                                }
                                headerBorder={0}
                                leftPanel={<SideNav super_user={super_user} />}
                                leftPanelWidth={235}
                                leftPanelBorder={0}
                              >
                                {user_id && (
                                  <AppRouteController
                                    oid={oid}
                                    super_user={super_user}
                                    organization={organization.name}
                                  />
                                )}
                              </Layout>
                            )
                          }
                        </Route>
                      </Switch>
                    </GateProvider>
                  </FlagProvider>
                </Route>
              </Switch>
            );
          }}
        </AuthConnector>
      </React.Fragment>
    </Router>
  );
};

export default hot(module)(AuthRouteController);
