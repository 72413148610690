import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "@evertrue/et-components";

const PAGE_KEY = "mnvchxx6cghv";
const $statusIndicatorRed = "#e74c3c";
const $statusIndicatorOrange = "#e67e22";
const $statusIndicatorYellow = "#f1c40f";
const $statusIndicatorGreen = "#2ecc71";

const Wrapper = styled.div`
  padding: 16px 23px;
`;

const IndicatorDot = styled.span`
  display: inline-block;
  background-color: ${(props) => props.color};
  width: 10px;
  height: 10px;
  border-radius: 99px;
`;

const IndicatorText = styled(Link)`
  margin-left: 12px;
  font-weight: 500;
  color: white;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: white;
  }
`;

const StatusIndicator = () => {
  const [status, setStatus] = useState(null);
  useEffect(() => {
    const sp = new window.StatusPage.page({ page: PAGE_KEY });
    sp.status({
      success: (data) => setStatus(data.status),
    });
  }, []);

  const indicatorColor = () => {
    if (status) {
      if (status.indicator === "critical") {
        return $statusIndicatorRed;
      } else if (status.indicator === "major") {
        return $statusIndicatorOrange;
      } else if (status.indicator === "minor") {
        return $statusIndicatorYellow;
      } else {
        return $statusIndicatorGreen;
      }
    }
  };

  return (
    <Wrapper>
      <IndicatorDot color={indicatorColor()} />
      <IndicatorText
        href="https://evertrue.statuspage.io"
        target="_BLANK"
        title="Network Status"
      >
        {status && status.description}
      </IndicatorText>
    </Wrapper>
  );
};

export default StatusIndicator;
