/* eslint-disable react/jsx-indent, indent */
import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import _ from "underscore";
import T from "prop-types";

const ListWrapper = styled.div`
  ${(props) => props.listStyles};
`;

const DraggableItem = ({
  item: { component: TheComponent },
  item,
  disabled,
  index,
  dragHandle,
}) => {
  return (
    <Draggable
      index={index}
      isDragDisabled={disabled}
      draggableId={item.id || item.value}
    >
      {(innerProvided, innerSnapshot) => (
        <div data-testid="draggable-item">
          <div
            ref={innerProvided.innerRef}
            className={
              innerSnapshot.isDragging ? "dnd-controller--is-dragging" : ""
            }
            style={{
              ...innerProvided.draggableStyle,
              userSelect: "none",
            }}
            {...innerProvided.draggableProps}
            {...(dragHandle ? {} : innerProvided.dragHandleProps)}
          >
            <TheComponent
              dragHandleProps={
                dragHandle ? innerProvided.dragHandleProps : null
              }
            />
          </div>
          {innerProvided.placeholder}
        </div>
      )}
    </Draggable>
  );
};

const DNDList = (props) => {
  const {
    id,
    list: {
      items = [],
      grouped = false,
      emptyStateComponent,
      disabled = false,
      dragHandle,
    },
  } = props;
  const groupedItems = grouped && _.groupBy(items, "group");
  return (
    <Droppable droppableId={id}>
      {(provided) => (
        <ul
          style={{ minHeight: "20vh" }}
          ref={provided.innerRef}
          data-testid={`dnd-list-${id}`}
          {...provided.droppableProps}
        >
          {!items.length && emptyStateComponent}
          {!grouped
            ? items.map((item, index) => (
                <DraggableItem
                  dragHandle={dragHandle}
                  index={index}
                  item={item}
                  key={item.id || item.value}
                  disabled={disabled}
                />
              ))
            : _.map(groupedItems, (innerItems = [], key) => (
                <div key={key}>
                  <span className="drag-list--header">{key}</span>
                  {innerItems.map((innerItem) => (
                    <DraggableItem
                      dragHandle={dragHandle}
                      item={innerItem}
                      key={innerItem.id || innerItem.value}
                      disabled={disabled}
                    />
                  ))}
                </div>
              ))}
          {provided.placeholder}
        </ul>
      )}
    </Droppable>
  );
};
/*

*/
class DNDMultiColumnController extends Component {
  static propTypes = {
    lists: T.PropTypes.object, // ex {active: {items:[]}, inactive: {items:[]}}
    onReorder: T.PropTypes.func,
    className: T.PropTypes.string,
    listStyles: T.PropTypes.string,
  };

  static defaultProps = {
    lists: {},
    onReorder: _.noop,
    className: "",
    listStyles: "",
  };

  handleSameListDrag(result, list) {
    const list_id = result.source.droppableId;
    const start_index = result.source.index;
    const end_index = result.destination.index;
    const edited_list = [...list];

    const [removed] = edited_list.splice(start_index, 1);
    edited_list.splice(end_index, 0, removed);
    const lists_map = _.mapObject(this.props.lists, ({ items }) => items);
    // onReorder just passes up an obj like {available: [items]}
    this.props.onReorder({ ...lists_map, [list_id]: edited_list });
  }

  handleSeparateListDrag(result, sourceList, destinationList) {
    const destination_id = result.destination.droppableId;
    const source_id = result.source.droppableId;
    const dest_items = [
      ...destinationList.slice(0, result.destination.index),
      sourceList[result.source.index],
      ...destinationList.slice(result.destination.index),
    ];
    const source_items = sourceList.filter(
      (item, index) => index !== result.source.index && item
    );
    const lists_map = _.mapObject(this.props.lists, ({ items }) => items);
    // onReorder just passes up an obj like {available: [items]}
    this.props.onReorder({
      ...lists_map,
      [destination_id]: dest_items,
      [source_id]: source_items,
    });
  }

  onDragEnd = (result) => {
    if (!result.destination) return;
    const source_list = this.props.lists[result.source.droppableId];

    const destination_list = this.props.lists[result.destination.droppableId];

    if (result.source.droppableId === result.destination.droppableId) {
      if (destination_list.notSortable) return;

      this.handleSameListDrag(result, destination_list.items);
    } else {
      this.handleSeparateListDrag(
        result,
        source_list.items,
        destination_list.items
      );
    }
  };
  render() {
    const { lists, className, listStyles, dragHandle } = this.props;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className={className}>
          {_.map(lists, (list, key) => (
            <ListWrapper key={key} listStyles={listStyles}>
              <DNDList id={key} list={list} dragHandle={dragHandle} />
            </ListWrapper>
          ))}
        </div>
      </DragDropContext>
    );
  }
}

export default DNDMultiColumnController;
