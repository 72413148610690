import styled from "styled-components";
import { Icon, OutsideClick } from "@evertrue/et-components";
import { colors, font } from "style/colors";

export const NameTag = styled.p`
  padding-right: 8px;
`;

export const Header = styled.div`
  border-bottom: 1px solid ${colors.border};
  padding-bottom: 16px;
`;

export const SubDetails = styled.div`
  border-bottom: 1px solid ${colors.border};
  padding: 8px 0;
`;

export const DetailsText = styled.div`
  display: inline-block;
  padding-left: 8px;
  vertical-align: top;
  line-height: 22px;
`;

export const DropDownButtonWrapper = styled.div`
  padding: 12px 0 0 0;
`;

export const DropDownButton = styled.span`
  color: ${colors.white};
  background-color: transparent;
  border: none;
  cursor: default;
  padding: 12px;
  display: flex;
  height: 100%;
`;

export const MenuButton = styled.button`
  text-align: left;
  padding: 8px 0;
  width: 100%;
  background-color: transparent;
  border: none;
  font-family: Roboto;
  color: #137fac;
  &:hover {
    background: ${colors.offSet};
  }
`;

export const HeaderDropdownBody = styled(OutsideClick)`
  background: ${colors.white};
  padding: 16px 16px 8px 16px;
  color: ${colors.darkestText};
  font-size: ${font.baseFontSize};
  font-weight: normal;
  line-height: 21px;
`;

export const StyledIcon = styled(Icon)`
  margin-right: 16px;
  font-size: 1.7em;
  color: #137fac;
`;
