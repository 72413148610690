import styled from "styled-components";
import React from "react";
import { colors } from "../colors";
import { EmptyStateMessage } from "@evertrue/et-components";

export const TableHeaderTr = styled.tr`
  display: flex;
  width: 100%;
  border-top: 1px solid ${colors.border};
  border-bottom: 1px solid ${colors.border};
  background-color: ${colors.blueGrayLighter};
  flex: 0 0 auto;
`;
export const TableHeader = (props) => (
  <thead>
    <TableHeaderTr {...props} />
  </thead>
);

export const TableRow = styled.tr`
  display: flex;
  width: 100%;
  border-bottom: ${(props) =>
    props.borderBottom || `1px solid ${colors.border}`};
  align-items: center;
  background: ${colors.white};
`;
const css = `
  display: flex;
  align-items: center;
  flex-basis: 25%;
  padding: 16px 0 16px 30px;
  box-sizing: border-box;
  list-style: none;
`;
export const TableCell = styled.td`
  ${css};
`;

export const TableCellDraggable = styled(TableCell)`
  padding-left: 26px;
`;

export const TableHeaderCell = styled.th`
  color: ${colors.blueGrayDark};
  padding: 16px 30px;
  ${css};
`;

export const TableBody = styled.tbody`
  height: 100%;
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const Table = styled.table`
  width: 100%;
  height: auto;
`;

export const TableEmptyState = (props) => (
  <tr>
    <td>
      <EmptyStateMessage {...props} />
    </td>
  </tr>
);
