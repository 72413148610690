import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getLogFile } from "apps/importer/get-log-file";

import { ProcessMessage } from "apps/importer/components/import-preprocess/process-message";
import {
  SectionHeader,
  BoldText,
  ProcessContainer,
} from "apps/importer/common/importer-styled-components";
import { Button } from "@evertrue/et-components";
import { ImportProgressBar } from "apps/importer/common/components/import-progress-bar";
import { usePollingResults } from "apps/importer/hooks/use-polling-results";
import { WORKER_TYPE, IMPORT, STATUS } from "apps/importer/import-helper";
import { InlineLoading } from "apps/importer/common/components/inline-loading";

export const UploadActive = ({ history, jobId }) => {
  const { jobData, status, results } = usePollingResults({
    jobId,
    workerType: WORKER_TYPE.IMPORT,
    history,
  });

  const { additional_files } = jobData;
  const auditFilename =
    additional_files &&
    additional_files.import &&
    additional_files.import.find(({ type }) => type === "AUDIT_LOG");

  return (
    <StyledProcessContainer>
      <SectionHeader>
        <BoldText>Import Progress</BoldText>
        <div>Importing your records</div>
        <ImportProgressBar percent={results.percentage_complete || 0} />
        {status === STATUS.RUNNING ? (
          <InlineLoading text={"Importing Records"} />
        ) : (
          <ProcessMessage
            status={results.status}
            messageText={results.status_display_name}
          />
        )}
      </SectionHeader>
      <ButtonGroup>
        <Button
          type="secondary"
          onClick={() =>
            getLogFile("audit_log", auditFilename.s3_filename, IMPORT, jobId)
          }
          disabled={status === STATUS.RUNNING}
        >
          Download Log File
        </Button>
        <Button
          onClick={() => history.push("/importer")}
          disabled={status === STATUS.RUNNING}
        >
          Return to Import Jobs
        </Button>
      </ButtonGroup>
    </StyledProcessContainer>
  );
};

const StyledProcessContainer = styled(ProcessContainer)`
  height: auto;
`;

UploadActive.propTypes = {
  jobId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
};

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
`;

export default UploadActive;
