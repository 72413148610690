import React, { Fragment } from "react";
import { Layout, Tooltip2 as Tooltip, Icon } from "@evertrue/et-components";
import TitleHeader from "../../components/title-header";
import JourneyGroupButtons from "./components/groups";
import JourneyVoipButton from "./components/voip";
import PropTypes from "prop-types";
import IsFlagged from "../launch-darkly/is-flagged";

const TOP_SECRET_DOG_URL =
  "https://www.chichisandme.com/wp-content/uploads/2017/12/brown-puppy-with-necklace-on-red-background-shutterstock_757866418.jpg";

const EnableSignal = ({ oid, organization }) => {
  const header = (
    <Fragment>
      <TitleHeader
        title="Configure Signal"
        subtitle="Enable or disable Signal for the organization."
      />
    </Fragment>
  );

  return (
    <Layout header={header} headerBorder={false}>
      <JourneyGroupButtons />
      <JourneyVoipButton oid={oid} organization={organization} />
      <IsFlagged feature="supportImplementationTest">
        <div style={{ margin: 20, paddingBottom: 250 }}>
          <Tooltip
            contentWidth={350}
            trigger={
              <span>
                <Icon icon="question-circle" size={2} />
              </span>
            }
          >
            <img src={TOP_SECRET_DOG_URL} alt="TOP-SECRET-DOG" width={250} />
          </Tooltip>
        </div>
      </IsFlagged>
    </Layout>
  );
};

EnableSignal.propTypes = {
  oid: PropTypes.number,
  organization: PropTypes.string,
};

EnableSignal.defaultProps = {
  oid: 0,
  organization: "",
};

export default EnableSignal;
