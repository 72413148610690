import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Modal,
  ModalBody,
  ModalHeaderWithActions,
  Icon,
} from "@evertrue/et-components";
import { colors } from "style/colors";
import { STATUS, WORKER_TYPE } from "apps/importer/import-helper";

import { usePollingResults } from "apps/importer/hooks/use-polling-results";
import { ImportProgressBar } from "apps/importer/common/components/import-progress-bar";
import { InlineLoading } from "apps/importer/common/components/inline-loading";
import { ProcessMessage } from "apps/importer/components/import-preprocess/process-message";

export const MODAL_ACTIONS = {
  CANCEL: "cancel",
  SUBMIT: "submit",
};

const ImportPreCheckModal = ({
  jobId = null,
  onModalAction,
  isOpen,
  history,
}) => {
  const { results, status } = usePollingResults({
    jobId,
    workerType: WORKER_TYPE.FILE_CHECK,
    history,
  });

  const STATUS_ICON = {
    [STATUS.RUNNING]: {
      icon: "spinner-2",
      spin: true,
      color: "",
      disableContinue: true,
    },
    [STATUS.SUCCESS]: {
      icon: "success-circle",
      color: "green",
      disableContinue: false,
    },
    [STATUS.FAILED]: {
      icon: "cancel-circle",
      color: "red",
      disableContinue: true,
    },
  };

  const { icon, color, disableContinue, spin } = STATUS_ICON[status] || {};

  const handleModalAction = (modalAction) => {
    onModalAction({ modalAction });
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => onModalAction({ modalAction: MODAL_ACTIONS.CANCEL })}
    >
      <ModalHeaderWithActions
        title="File Pre-Check"
        submitLabel="Continue"
        closeLabel="Close"
        closeModal={() => handleModalAction(MODAL_ACTIONS.CANCEL)}
        onSubmit={() => handleModalAction(MODAL_ACTIONS.SUBMIT)}
        disableSubmit={disableContinue}
      />
      <ModalBody>
        <ImportProgressBar percent={results.percentage_complete} />

        <FixedHeight>
          {status === STATUS.RUNNING ? (
            <InlineLoading text={"Validating File"} />
          ) : (
            <React.Fragment>
              <ProcessMessage
                status={results.status}
                messageText={results.status_display_name}
              />

              <Content>
                <FileCheck>
                  <Icon icon={icon} size={1} style={{ color }} spin={spin} />
                  <div>Reading File Headers - Checking Previous Mappings</div>
                </FileCheck>
                <FileCheck>
                  <Icon icon={icon} size={1} style={{ color }} spin={spin} />
                  <div>Valid CSV File</div>
                </FileCheck>
                {results.values && (
                  <Results>
                    <div>Results:</div>
                    <div>{results.status_display_name}</div>
                    {results && results.values.error_message && (
                      <div>{results.values.error_message}</div>
                    )}
                  </Results>
                )}
              </Content>
            </React.Fragment>
          )}
        </FixedHeight>
      </ModalBody>
    </Modal>
  );
};

ImportPreCheckModal.propTypes = {
  jobId: PropTypes.number,
  onModalAction: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

ImportPreCheckModal.defaultProps = {
  jobId: null,
};

export default ImportPreCheckModal;

const FixedHeight = styled.div`
  height: 300px;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FileCheck = styled.div`
display: flex;
width: 500px;
padding-top: 16px;

> p {
  margin-left: 6px;
}
`;

const Results = styled.div`
  border-top: 2px solid ${colors.border};
  width: 100%;
  margin-top: 8px;
  padding-top: 8px;
  padding-left: 24px;
`;
