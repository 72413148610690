import React from "react";
import styled from "styled-components";
import { colors } from "style/colors";
import { Flex, Link } from "@evertrue/et-components";

const ImportHeader = () => {
  return (
    <HeaderWrapper>
      <ImportTitle>Importer</ImportTitle>

      <Flex justifyContent="space-between">
        <SubTitle>
          Import CSV files to add, edit, and update your records.
        </SubTitle>
        <Link
          title="feedback"
          href="https://evertrue.typeform.com/to/xhzMiTtY"
          target="_blank"
        >
          Feedback
        </Link>
      </Flex>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  background-color: ${colors.white};
  border-bottom: 2px solid ${colors.border};
  padding: 13px 16px;
  display: block;
`;

const ImportTitle = styled.p`
  font-size: 24px;
  margin-bottom: 8px;
`;

const SubTitle = styled.p`
  color: ${colors.lightText};
`;

export default ImportHeader;
