import _ from "underscore";
import React, { Component } from "react";
import {
  Loading,
  SearchBox,
  AlertActions,
  ModalWrapper,
  Button,
} from "@evertrue/et-components";
import Decorator from "@evertrue/client-decorator";
import { Wrapper } from "style/components";
import { searchCompare } from "utils/utils";
import ConstituentFieldsTable from "apps/constituents/components/constituent-field-table";
import * as ContactCustomFieldService from "apps/constituents/constituent-custom-fields-service";
import ConstituentFieldModal from "apps/constituents/components/constituent-field-modal";
import {
  ActionBar,
  SearchBoxWrapper,
} from "apps/interactions/controllers/custom-fields-interactions-controller-style";
import { formatLabel } from "apps/custom-fields/custom-fields-utils";

const WHITELIST = [
  "id",
  "oid",
  "data_type",
  "default",
  "app_keys",
  "filterable",
  "description",
  "createdAt",
  "updatedAt",
  "sortOrder",
  "visible",
  "permissions",
];

class CustomFieldsConstituentsController extends Component {
  state = {
    customFields: [],
    searchVal: "",
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      this.setState({
        loading: true,
      });
      const { data = [] } = await ContactCustomFieldService.fetchCustomFields();
      const customFields = _.reject(
        Decorator.Schema.getCustomFields(data),
        (field) => field.deleted
      );
      const dataWithNames = _.map(customFields, (field = {}) => ({
        ...field,
        typeLabel: formatLabel(field.data_type),
      }));

      this.setState({
        customFields: dataWithNames,
        loading: false,
      });
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  };

  showToastAndFetch = (message = "Custom field created.") => {
    AlertActions.launchToast({
      type: "success",
      message,
      timeout: 2000,
    });
    this.fetchData();
  };

  handleModalSubmit = async (obj = {}) => {
    if (this.validateDisplayName(obj.description)) return;

    await ContactCustomFieldService.createCustomField(
      _.pick(
        { ...obj, sortOrder: this.state.customFields.length + 1 },
        WHITELIST
      )
    );
    this.showToastAndFetch();
  };

  updateField = async (field = {}) => {
    await ContactCustomFieldService.updateCustomField(field);
    this.showToastAndFetch("Custom field updated.");
  };

  deleteField = async (id) => {
    await ContactCustomFieldService.deleteCustomField(id);
    this.showToastAndFetch("Custom field deleted.");
  };

  handleSearch = (searchVal) => {
    this.setState({
      searchVal,
    });
  };

  handleToggleModal = () => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  };

  validateDisplayName = (name = "") => {
    if (!name) {
      AlertActions.launchToast({
        type: "error",
        message: "A custom field must have a display name",
      });
    }
    const existing = this.state.customFields.find(
      ({ description = "" } = {}) =>
        description.toLowerCase() === name.toLowerCase()
    );

    if (existing) {
      AlertActions.launchToast({
        type: "error",
        message: "Display names must be unique",
      });
    }
    return !name || existing;
  };

  render() {
    const filteredCustomFields = this.state.customFields.filter(
      ({ description } = {}) => searchCompare(description, this.state.searchVal)
    );

    return (
      <Wrapper>
        <ActionBar>
          <SearchBoxWrapper>
            <SearchBox
              placeholder="Search Field Names"
              onChange={this.handleSearch}
            />
          </SearchBoxWrapper>
          <ModalWrapper
            isOpen={this.state.isModalOpen}
            width={500}
            onClose={this.handleToggleModal}
            render={({ close }) => (
              <ConstituentFieldModal
                fieldType="constituents"
                onSubmit={this.handleModalSubmit}
                close={close}
              />
            )}
          />
          <Button type="default" onClick={this.handleToggleModal}>
            New Constituent Field
          </Button>
        </ActionBar>
        {this.state.loading ? (
          <Loading />
        ) : (
          <ConstituentFieldsTable
            filteredCustomFields={filteredCustomFields}
            handleUpdate={this.updateField}
            onDelete={this.deleteField}
          />
        )}
      </Wrapper>
    );
  }
}

export default CustomFieldsConstituentsController;
