import styled from "styled-components";
import { colors } from "style/colors";
import { Button, BooleanRadio, Icon } from "@evertrue/et-components";

export const ContinueButton = styled(Button)`
  margin-top: 14px;
  float: right;
`;

export const BodyWrapper = styled.div`
  background-color: ${colors.background};
  display: flex;
  margin-top: 20px;
  padding: 10px;
  justify-content: space-evenly;
  align-items: flex-center;
`;

export const Header = styled.p`
  font-size: 16px;
  margin: 16px 0 8px 16px;
`;

export const HeadersContainer = styled.div`
  border: solid 1px ${colors.darkerText};
  width: 90%;
  margin: 0 5% 12px 5%;
  padding: 5px;
  height: 45%;
  overflow-y: scroll;
  border-radius: 3px;
`;

export const SectionHeader = styled.div`
  width: 90%;
  margin: 5% 5% 0 5%;
  font-size: 16px;
  color: ${colors.darkestText};
`;

export const BoldText = styled.p`
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 4px;
`;

export const RadioGroup = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 4px;
  margin-left: 65px;
  flex-direction: column;
`;

export const StyledBooleanRadio = styled(BooleanRadio)`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  .et--radio {
    display: flex;
    margin-bottom: 24px;
    margin-left: 0;
    line-height: 22px;
  }
  .et--radio + .et--radio {
    margin-left: 0;
  }
  label {
    margin-left: 0;
  }
`;

export const Subtext = styled.p`
  margin-top: 10px;
  color: ${colors.darkerText};
`;

export const ProcessContainer = styled.div`
  background-color: ${colors.white};
  border: ${colors.border} 1px solid;
  width: 450px;
  height: 700px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SectionIcon = styled(Icon)`
  padding-top: 6px;
  color: ${colors.blueGrayDark};
  cursor: pointer;
`;

export const GearIcon = styled(Icon)`
  color: ${colors.blueGrayDark};
  cursor: pointer;
  margin-right: 4px;
  margin-left: -2px;
`;

export const DeleteIcon = styled(Icon)`
  margin-right: 6px;
  margin-top: 6px;
  padding: auto;
  color: ${colors.blueGrayDark};
  cursor: pointer;
`;

export const Centered = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContentText = styled.div`
  color: ${colors.darkestText};
  padding-top: 16px;
  font-size: 24px;
`;

export const ToggleHeader = styled.p`
  color: "${colors.darkestText}";
  font-size: 16px;
  font-weight: 500;
  padding: 15px 0px 5px 0px;
`;

export const ToggleDescription = styled.p`
  color: ${colors.lightText};
  font-size: 12px;
  padding: 0px 0px 10px 0px;
  width: 550px;
`;

export const AlertDescription = styled.p`
  color: ${colors.error};
  font-size: 12px;
  padding: 10px 0px;
  width: 550px;
`;
