import React from "react";
import Api from "utils/api";
import { colors } from "style/colors";
import styled from "styled-components";
import ModalEditor from "components/modal-editor";

const PlatformDisconnectModal = ({
  account,
  refresh,
  onSuccess,
  onError,
  ...rest
}) => {
  return (
    <ModalEditor
      {...rest}
      initialValues={{ confirm: "" }}
      saveLabel="Disconnect"
      validate={(value) => {
        let errors = {};
        if (value.confirm.toLowerCase() !== "i understand")
          errors.confirm = true;
        return errors;
      }}
      header="Disconnect Account"
      onSubmit={() =>
        Api.AUTH.INTEGRATIONS.delete({
          urlExtend: `/${account.id}`,
          data: { state: "deleted" },
        })
          .then(onSuccess)
          .then(refresh)
          .catch(onError)
      }
    >
      <WarningMessage>
        This will delete the connection with the integration.
      </WarningMessage>
      <WarningMessage>
        Please type “I Understand” to disconnect your account.
      </WarningMessage>
      <div
        style={{
          color: colors.errorDark,
          fontWeight: "500",
          marginBottom: "5px",
        }}
      >
        Note: This cannot be undone
      </div>
      <ModalEditor.FormField
        autoComplete="off"
        placeholder="I Understand"
        name="confirm"
      />
    </ModalEditor>
  );
};

const WarningMessage = styled.div`
  margin-bottom: 16px;
`;

export default PlatformDisconnectModal;
