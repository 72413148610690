import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import { useGate } from "apps/dna/is-gated";
import OrgSelection from "apps/auth/components/org-selection";
import ConstituentsRouteController from "apps/constituents/constituent-route-controller";
import InteractionsRouteController from "apps/interactions/interaction-route-controller";
import VolunteersRouteController from "apps/volunteers/volunteers-route-controller";
import RelationshipManagementRouteController from "apps/relationship-management/relationship-management-route-controller";
import ExportData from "apps/export-data/export-data";
import CDCManager from "apps/cdc/cdc-manager";
import ImporterRouteController from "apps/importer/importer-route-controller";
import Notifications from "apps/notifications/notifications";
import Integrations from "apps/integrations/integrations";
import RedirectController from "./redirect-controller.js";
import ErrorPage from "components/error-page.js";
import ProposalRouteController from "apps/proposals/controllers/proposal-route-controller.js";
import { shouldAllowImporterNavigation } from "./route-utils";
import JourneysRouteController from "./apps/journeys/journeys-route-controller.js";
import JourneyGroupsRouteController from "./apps/journey-groups/journey-groups-route-controller.js";
import EnableSignal from "./apps/enable-signal/enable-signal.js";

const AppRouteController = ({ super_user, oid, organization }) => {
  const { show: importer_enabled } = useGate("importer_v3");

  return (
    <Switch>
      {/* Data Management */}
      <Route path="/importer">
        {shouldAllowImporterNavigation({ super_user, importer_enabled }) ? (
          <ImporterRouteController />
        ) : (
          <Redirect to="/" />
        )}
      </Route>
      <Route path="/export-data">
        <ExportData />
      </Route>
      <Route path="/cdc-manager">
        <CDCManager />
      </Route>

      {/* Configuration */}
      <Route path="/cadences">
        <JourneysRouteController oid={oid} />
      </Route>
      <Route path="/constituents">
        {super_user ? <ConstituentsRouteController /> : <Redirect to="/" />}
      </Route>
      <Route path="/interactions">
        {super_user ? <InteractionsRouteController /> : <Redirect to="/" />}
      </Route>
      <Route path="/relationship-management">
        <RelationshipManagementRouteController />
      </Route>
      <Route path="/volunteers">
        {super_user ? <VolunteersRouteController /> : <Redirect to="/" />}
      </Route>
      <Route path="/cadencecategories">
        <JourneyGroupsRouteController oid={oid} />
      </Route>

      {/* Settings */}
      <Route path="/notifications">
        <Notifications />
      </Route>

      {/* Integrations */}
      <Route path="/integrations">
        <Integrations />
      </Route>

      {/* Onboarding */}
      <Route path="/enable-signal">
        <EnableSignal oid={oid} organization={organization} />
      </Route>

      {/* Proposals */}
      <Route path="/proposals">
        <ProposalRouteController />
      </Route>

      <Route path="/error">
        <ErrorPage />
      </Route>

      {/* Misc */}
      <Route path="/auth/org">
        {super_user ? <OrgSelection current_oid={oid} /> : <Redirect to="/" />}
      </Route>

      {/* TODO: Add landing page */}

      {/* Fall through landing / error page*/}
      <Route>
        <RedirectController />
      </Route>
    </Switch>
  );
};

AppRouteController.propTypes = {
  super_user: PropTypes.bool,
  oid: PropTypes.number,
  organization: PropTypes.string,
};

AppRouteController.defaultProps = {
  super_user: false,
  oid: 0,
  organization: "",
};

export default AppRouteController;
