import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const getErrorMessages = (values) => {
  let errorMessages = []
    values.forEach(element => {
      errorMessages.push(element.error_message)
    });
  return errorMessages;
}

export const PreprocessErrors = ({ stats }) => {
  var renderedOutput = getErrorMessages(stats).map(item => <div> {item} </div>)
  return (
    <Results>
      {renderedOutput}
    </Results>
  );
};

PreprocessErrors.propTypes = {
    stats: PropTypes.array,
};

PreprocessErrors.defaultProps = {
    stats: [],
};

const Results = styled.div`
  margin: 0 0 0 44px;
`;

export default PreprocessErrors;
