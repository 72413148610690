import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "@evertrue/et-components";
import { BodyWrapper } from "apps/importer/common/importer-styled-components";
import { DetailListButton } from "apps/importer/common/components/detail-list-button";
import { withRouter } from "react-router-dom";
import { colors } from "style/colors";
import { useTracking } from "apps/importer/hooks/use-tracking";
import { UploadContainer } from "apps/importer/components/import-preprocess/upload/upload-container";
import { PreprocessContainer } from "apps/importer/components/import-preprocess/preprocessing/preprocess-container";
import { MappingButtonViewOnly } from "scripts/apps/importer/components/import-mapping/mapping-view-only/mapping-view-only-buttons";
import moment from "moment";

const ImportPreProcess = withRouter(({ history, match, location }) => {
  const { jobId } = match.params;
  const { state } = location;

  const detailsConfig = [
    {
      id: "file-name",
      label: "File Name:",
      value: (state && state.fileName) || "",
    },
    {
      id: "job-id",
      label: "Job ID:",
      value: jobId,
    },
    {
      id: "import-time",
      label: "Import Time:",
      value:
        state && state.importTime
          ? moment(state.importTime).format("M/DD/YYYY h:mm A")
          : "",
    },
  ];

  useTracking({
    history,
    trackingString: "Importer Pre-processing and Import page",
  });

  const [hasUploadStarted, setHasUploadStarted] = useState(false);

  return (
    <React.Fragment>
      <div>
        <DetailListButton />
        <MappingButtonViewOnly jobId={jobId}></MappingButtonViewOnly>
      </div>
      <DetailsContainer>
        {detailsConfig.map(({ id, label, value, title }) => (
          <DetailLine key={id}>
            <Label>{label}</Label>
            {value}
          </DetailLine>
        ))}
      </DetailsContainer>
      <BodyWrapper>
        <PreprocessContainer
          history={history}
          jobId={jobId}
          hasUploadStarted={hasUploadStarted}
          setHasUploadStarted={setHasUploadStarted}
        />
        <UploadContainer
          history={history}
          jobId={jobId}
          hasUploadStarted={hasUploadStarted}
          setHasUploadStarted={setHasUploadStarted}
        />
      </BodyWrapper>
      <HelpText>
        Please contact{" "}
        <EmailLink href="mailto:Genius@evertrue.com">
          Genius@evertrue.com
        </EmailLink>{" "}
        with any questions.
      </HelpText>
    </React.Fragment>
  );
});

export const Results = styled.div`
  margin: 24px 0 0 44px;
`;

export const SubTitleSection = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 15px;
`;

export const DeletionConfirm = styled.div`
  line-height: 24px;
  font-size: 16px;
`;
const HelpText = styled.div`
  margin-top: 55px;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  padding-bottom: 12px;
  color: ${colors.darkestText};
`;

const EmailLink = styled(Link)`
  font-weight: 500;
  cursor: pointer;
`;

const DetailsContainer = styled.div`
  margin: 16px 0 16px 32px;
`;

const DetailLine = styled.div`
  margin-top: 8px;
  line-height: 1.5;
  font-size: 14px;
`;

const Label = styled.span`
  font-weight: 600;
  margin-right: 8px;
  color: ${colors.darkText};
`;

export default ImportPreProcess;
