import React from "react";
import { Modal, ModalHeader, ModalBody, Button } from "@evertrue/et-components";

const CancelImportModal = ({ isOpen, closeModal, cancelImport }) => {
    return (
        <Modal isOpen={isOpen} closeModal={closeModal}>
            <ModalHeader title="Cancel Import?" closeModal={closeModal} />
            <ModalBody>
                <p>Are you sure you want to cancel this import?</p><br></br>
                <p>This action cannot be undone. You will need to perform a new import.</p>
            </ModalBody>
            <div style={{textAlign:"center", margin:"10px 0 15px"}}>
                <Button type="secondary" onClick={() => closeModal()}>
                Close
                </Button>
                <Button onClick={() => cancelImport()}>
                Confirm
                </Button>
            </div>
        </Modal>
    );
};

export default CancelImportModal;