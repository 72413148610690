import { AlertActions } from "@evertrue/et-components";
import Api from "utils/api";

// .catches in this files are in order to only handle API call errors
// if put in the controller in try/catch, the JS code after the API call would be
// caught if errored

export const fetchCustomFields = () =>
  Api.INTERACTION.CUSTOM_FIELD.get().catch((e) => {
    // todo add sentry
    showErrorToast(
      "There was a problem finding your custom fields. Please reload the browser to try again."
    );
    return Promise.reject(e);
  });

export const createCustomField = (data) =>
  Api.INTERACTION.CUSTOM_FIELD.post({
    data,
  }).catch((e) => {
    // todo add sentry
    showErrorToast();
    return Promise.reject(e);
  });

export const updateCustomField = (data) =>
  Api.INTERACTION.CUSTOM_FIELD.put({
    data,
  }).catch((e) => {
    // todo add sentry
    showErrorToast();
    return Promise.reject(e);
  });

export const deleteCustomField = (id) =>
  Api.INTERACTION.CUSTOM_FIELD.delete({
    urlExtend: `/${id}`,
  }).catch((e) => {
    // todo add sentry
    showErrorToast(
      "There was a problem deleting your custom field please try again."
    );
    return Promise.reject(e);
  });

export const fetchCustomFieldValues = (id) =>
  Api.INTERACTION.CUSTOM_FIELD_VALUES.get({
    urlArgs: { customFieldId: id },
    params: {
      limit: 150,
    },
  });

export const createCustomFieldValue = (customFieldId, data) =>
  Api.INTERACTION.CUSTOM_FIELD_VALUES.post({
    urlArgs: { customFieldId },
    data,
  });

export const updateCustomFieldValues = (customFieldId, id, data) =>
  Api.INTERACTION.CUSTOM_FIELD_VALUES.put({
    urlArgs: { customFieldId },
    urlExtend: `/${id}`,
    data,
  });

export const deleteCustomFieldValue = (customFieldId, id) => {
  return Api.INTERACTION.CUSTOM_FIELD_VALUES.delete({
    urlArgs: { customFieldId },
    urlExtend: `/${id}`,
  });
};

export const fetchImportedCustomFields = (esField) =>
  Api.SEARCH.CONTACT_NOTE.post({
    data: { facet: [{ field: `${esField}.untouched`, size: 250 }] },
  });

function showErrorToast(
  message = "There was a problem saving your custom field please try again."
) {
  AlertActions.launchToast({
    type: "error",
    message,
  });
}
