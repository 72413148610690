import _ from "underscore";
import React from "react";
import T from "prop-types";
import moment from "moment";
import {
  ConfigButtonContainer,
  LastEditedLabel,
  List,
  ListBody,
  SidePanel,
  StageGroupName,
  UnsavedLabel,
} from "apps/stages/components/stages-style";
import uid from "uuid/v4";
import AddStageSetModal from "apps/stages/components/add-stage-set-modal";
import { Button, ModalTriggerDeprecated } from "@evertrue/et-components";

const StagesNav = ({
  groups,
  baseRoute,
  unsavedGroupID,
  onCreate,
  groupType,
}) => (
  <SidePanel>
    <ConfigButtonContainer>
      <ModalTriggerDeprecated modal={<AddStageSetModal onSubmit={onCreate} />}>
        <Button type="secondary" disabled={groupType === "DXO"}>
          Create Stage Set
        </Button>
      </ModalTriggerDeprecated>
    </ConfigButtonContainer>

    <List>
      <ListBody>
        {groups.map((group) => (
          <StageGroupName
            activeClassName="active"
            to={`${baseRoute}${group.id}`}
            key={group.id || uid()}
          >
            <div>{group.name}</div>
            {Boolean(group.updated_at) && (
              <LastEditedLabel>
                Last edited: {moment(group.updated_at).format("MMMM Do, YYYY")}
              </LastEditedLabel>
            )}
            {unsavedGroupID === group.id && (
              <UnsavedLabel>Unsaved changes</UnsavedLabel>
            )}
          </StageGroupName>
        ))}
      </ListBody>
    </List>
  </SidePanel>
);

StagesNav.propTypes = {
  groups: T.array,
  baseRoute: T.string,
  unsavedGroupID: T.number,
  onCreate: T.func,
  groupType: T.string,
};

StagesNav.defaultProps = {
  groups: [],
  baseRoute: "",
  unsavedGroupID: null,
  onCreate: _.noop,
  groupType: null,
};

export default StagesNav;
