import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { colors } from "style/colors";

export const StageGroupName = styled(NavLink)`
  display: block;
  color: ${colors.darkerText};
  text-align: left;
  border-bottom: 1px solid ${colors.border};
  padding: 16px;
  margin-left: 12px;

  &.active {
    box-shadow: 0 0px 0 ${colors.mainBase} inset,
      4px 0 0 ${colors.mainBase} inset;
    background: ${colors.blueGrayLight};
  }
`;

export const SidePanel = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  height: 100%;
`;

export const ConfigButtonContainer = styled.div`
  flex: 0 0 auto;
  text-align: center;
  padding: 16px;
  border-bottom: 1px solid ${colors.border};
`;

export const List = styled.div`
  max-height: 60vh;
  position: relative;
  overflow-y: auto;
  min-height: 0;
`;

export const ListBody = styled.div`
  margin: 0;
  overflow: auto;
  max-height: 100%;
  min-height: 0;
`;

export const LastEditedLabel = styled.div`
  margin-top: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.lightText};
`;

export const UnsavedLabel = styled.div`
  margin-top: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.error};
`;
