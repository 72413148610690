import React, { useState, useEffect } from "react";
import Api from "utils/api";
import moment from "moment";
import styled from "styled-components";
import { Icon } from "@evertrue/et-components";

export const LastUpdate = styled.div`
  color: white;
  padding: 16px 20px 0 20px;
  font-size: 14px;
`;

export const LastUpdateText = styled.span`
  margin-left: 8px;
  font-weight: 500;
`;

export const LastUpdateDate = styled.p`
  font-weight: 400;
  margin-left: 26px;
  margin-top: 8px;
`;

const ApiStatus = () => {
  const [lastUpdate, setLastUpdate] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    setLoading(true);
    
    try {
      const fetchLatestData = async () => {
        const response = await Api.IMPORT.LATEST.get();

        setLastUpdate(response.data.status_started_at);
        setLoading(false);
      }

      fetchLatestData();
    } catch (err) {
      console.log(error);
      setLoading(false);
    }

  }, []);

  const date = loading
    ? "Unavailable"
    : moment(lastUpdate).format("MM/DD/YYYY @ h:mm A");

  return (
    <LastUpdate>
      <Icon icon="access-time" />
      <LastUpdateText>Last Data Update</LastUpdateText>
      <LastUpdateDate>{date}</LastUpdateDate>
    </LastUpdate>
  );
};

export default ApiStatus;
