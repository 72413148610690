import _ from "underscore";
import React from "react";
import Api from "utils/api";
import ModalEditor from "../../../components/modal-editor";
import { launchToast } from "./account-actions";

const EmmaAddModal = ({ refresh, onSuccess, ...rest }) => {
  return (
    <ModalEditor
      {...rest}
      initialValues={{
        name: "",
        public_api_key: "",
        private_api_key: "",
        remote_id: "",
      }}
      isInitialValid={false}
      header="Add Emma Account"
      saveLabel="Add Account"
      validate={(value) => {
        let errors = {};
        if (_.contains(value, "")) errors = { errors: true };
        return errors;
      }}
      onSubmit={(value) =>
        Api.EMMA.ACCOUNTS.post({
          data: value,
          disableAlerts: true,
        })
          .then(refresh)
          .then(onSuccess)
          .catch((message) => launchToast(message, "error"))
      }
    >
      <ModalEditor.FormField
        autoComplete="off"
        label="Emma Account Name"
        placeholder="What do you want to call this account in EverTrue?"
        name="name"
      />
      <ModalEditor.FormField
        autoComplete="off"
        label="Public API Key"
        placeholder="Public API key from Emma"
        name="public_api_key"
      />
      <ModalEditor.FormField
        autoComplete="off"
        label="Private API Key"
        placeholder="Private API key from Emma"
        name="private_api_key"
      />
      <ModalEditor.FormField
        autoComplete="off"
        label="Emma Account ID"
        placeholder="123456"
        name="remote_id"
      />
    </ModalEditor>
  );
};

export default EmmaAddModal;
