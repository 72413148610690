import React from "react";
import { Icon, AlertActions, ActionsMenu } from "@evertrue/et-components";
import { colors } from "style/colors";
import PlatformDisconnectModal from "./platform-disconnect-modal";
import PlatformUpdateModal from "./platform-update-modal";

export const launchToast = (message = "Success", type = "success") => {
  setTimeout(() => {
    AlertActions.launchToast({
      type,
      message,
      timeout: 5000,
    });
  }, 800);
};

const PlatformAccountActions = ({ account, refresh }) => {
  return (
    <ActionsMenu
      trigger={() => <Icon icon="more-horiz" size={2} />}
      options={[
        {
          id: 1,
          icon: "lock",
          label: "Update Credentials",
          mountOnClick: ({ close, is_open }) => (
            <PlatformUpdateModal
              onSuccess={() => launchToast("Account credentials updated")}
              onError={(message) =>
                launchToast(
                  "There was an error during update. Please re-enter your information and try again.",
                  "error"
                )
              }
              account={account}
              refresh={refresh}
              onClose={close}
              isOpen={is_open}
            />
          ),
        },
        {
          id: 2,
          icon: "delete",
          label: "Disconnect Account",
          iconStyle: { color: colors.red },
          mountOnClick: ({ close, is_open }) => (
            <PlatformDisconnectModal
              onClose={close}
              isOpen={is_open}
              account={account}
              open_key="disconnect"
              refresh={refresh}
              onSuccess={() => launchToast("Account Successfully Disconnected")}
              onError={(message) => launchToast(message, "error")}
            />
          ),
        },
      ]}
    />
  );
};

export default PlatformAccountActions;
