import _ from "underscore";
import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import api from "utils/api";
import styled from "styled-components";
import { Loading, OrgPicker } from "@evertrue/et-components";
import { colors } from "style/colors";
import { getOrgs } from "utils/org-utils";

const { object, bool } = PropTypes;

const OrgPickerWrapper = styled.div`
  text-align: center;
  padding-top: 150px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const OrgPickerMain = styled.div`
  width: 30%;
  height: 65%;
  display: inline-block;
  background: ${colors.white};
  padding: 24px;
  text-align: left;
`;

const OrgSelection = (props) => {
  const [redirect, setRedirect] = React.useState(false);
  const [orgs, setOrgs] = React.useState([]);
  const [orgs_loading, setOrgsLoading] = React.useState(false);

  React.useEffect(() => {
    if (props.user.super_user) {
      setOrgsLoading(true);
      api.AUTH.ORGS.get()
        .then((resp = {}) => {
          setOrgs(resp.data);
          setOrgsLoading(false);
        })
        .catch(() => {
          setOrgs([]);
          setOrgsLoading(false);
        });
    }
  }, [props.user.super_user]);

  const handleSubmit = (org) => {
    api.changeOrg(org.id).then(() => setRedirect(true));
  };

  const { super_user } = props.user;

  const orgs_to_choose = super_user ? orgs : getOrgs(props.user);

  const options = _.map(orgs_to_choose, (org = {}) => {
    const label = super_user ? `${org.name} (${org.id})` : `${org.name} `;
    return { ...org, label, value: org.id };
  });

  return redirect ? (
    <Redirect to="/" />
  ) : orgs_loading ? (
    <Loading text="Loading Organizations..." />
  ) : props.is_changing_orgs ? (
    <Loading text={"Switching your org..."} />
  ) : (
    <OrgPickerWrapper>
      <OrgPickerMain>
        <OrgPicker
          orgs={_.sortBy(options, "label")}
          handleChange={handleSubmit}
        />
      </OrgPickerMain>
    </OrgPickerWrapper>
  );
};

OrgSelection.propTypes = {
  user: object,
  is_changing_orgs: bool,
};

OrgSelection.defaultProps = {
  user: {},
  is_changing_orgs: false,
};
export default OrgSelection;
