import { Route, Switch } from "react-router-dom";
import React from "react";
import JourneyGroupsTable from "scripts/apps/journey-groups/components/journey-groups-table";
import PropTypes from "prop-types";

const JourneyGroupsRouteController = ({ oid }) => {
  return (
    <Switch>
      <Route path="/cadencecategories/" render={() => <JourneyGroupsTable oid={oid} />} />
    </Switch>
  );
};

JourneyGroupsRouteController.propTypes = {
  oid: PropTypes.number.isRequired,
};

export default JourneyGroupsRouteController;
