import React from "react";
import moment from "moment";
import str from "underscore.string";
import {
  Loading,
  Button,
  FlexTable,
  Bar,
  Layout,
  AlertActions,
  useOpenClose,
} from "@evertrue/et-components";
import { usePromiseResolver } from "@evertrue/promise-resolver";
import Api from "utils/api";
import PlatformAccountActions from "./platform-account-actions";
import PlatformAddModal from "./platform-add-modal";

const launchToast = (message = "Success") => {
  setTimeout(() => {
    AlertActions.launchToast({
      type: "success",
      message,
      timeout: 5000,
    });
  }, 800);
};

const PlatformManagementController = () => {
  const [is_modal_open, openModal, closeModal] = useOpenClose();
  const [data = {}, loading, , refresh] = usePromiseResolver(
    () => Api.AUTH.INTEGRATIONS.get(),
    []
  );

  const accounts = data.data;
  const columns = [
    {
      key: 1,
      header: "Status",
      accessor: (account) => str.capitalize(account.state),
    },
    {
      key: 1,
      header: "Platform",
      accessor: (account) => str.capitalize(account.platform),
    },
    {
      key: 2,
      header: "Account Name",
      accessor: (account) => account.display_name,
    },
    {
      key: 3,
      header: "Account ID",
      accessor: (account) => account.account_id,
    },
    {
      key: 4,
      header: "Last Updated",
      accessor: (account) => moment(account.updated_at).format("MMM DD, YYYY"),
    },
    {
      key: 5,
      header: "Actions",
      accessor: (account) => (
        <PlatformAccountActions account={account} refresh={refresh} />
      ),
    },
  ];
  const header = (
    <Bar
      right={
        <React.Fragment>
          <Button onClick={openModal}>New Platform</Button>
          <PlatformAddModal
            isOpen={is_modal_open}
            refresh={refresh}
            onClose={closeModal}
            onSuccess={() => launchToast("Account successfully added")}
          />
        </React.Fragment>
      }
    />
  );
  return (
    <Layout
      header={header}
      headerBorder={false}
      style={{
        height: "100%",
      }}
    >
      {accounts && (
        <FlexTable
          caption="Platform Management Table"
          data={accounts}
          columns={columns}
        />
      )}
      {loading && <Loading />}
    </Layout>
  );
};
export default PlatformManagementController;
