import React, { useEffect, useState } from "react";
import {
  useOpenClose,
  EmptyStateMessage,
  Icon,
  Loading,
} from "@evertrue/et-components";
import {
  getJourneyByIdFromGraphql,
  editJourney,
  deactivateJourney,
  createJourneyTask,
  editJourneyTask,
  deactivateJourneyTask,
} from "apps/journeys/utils/api-requests/journeys-requests";
import PropTypes from "prop-types";
import PageLayoutWithButtons from "scripts/apps/layout/components/page-layout-with-buttons";
import JourneyActionMenu from "../journey-action-menu";
import JourneyTaskModal from "../modals/journey-task-modal";
import JourneyTaskRow from "./journey-task-row";
import { isEmpty } from "underscore";

const JourneyPage = ({ history, journeyId }) => {
  const [loadingSteps, setLoadingSteps] = useState(false);
  const emptySubtext = (
    <div>
      <p>
        Now, add steps for each constituent to progress through. Think about
        your ideal spacing and outreach type, and customize the cadence to your
        specific needs. Make sure to add enough details to the description of
        each step to help your team understand the intention behind each action!
      </p>
      <br></br>
      <p>
        For inspiration and proven outreach strategies, check out the latest
        articles on the{" "}
        <a
          href="https://www.evertrue.com/blog/"
          target="_blank"
          rel="noreferrer"
        >
          EverTrue blog
        </a>
      </p>
    </div>
  );
  const emptyText = (
    <div>
      <Icon icon="cadences" iconKit="gt2" size={2} />
      <p>You’ve successfully created a cadence!</p>
    </div>
  );
  const [journey, setJourney] = useState({});
  const [isOpen, openModal, closeModal] = useOpenClose(false);
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    const getAndSetJourney = async () => {
      try {
        setLoadingSteps(true);
        const journey = await getJourneyByIdFromGraphql(journeyId);
        setSteps(journey.steps);
        setJourney(journey);
      } finally {
        setLoadingSteps(false);
      }
    };

    getAndSetJourney();
  }, [journeyId]);

  const handleEditJourney = async (payload) => {
    const journey = await editJourney(payload);
    setJourney(journey);
  };

  const handleDeleteJourney = async (journey) => {
    await deactivateJourney(journey.id);
    history.push("/cadences");
  };

  const updateTableState = async (action, journeyTask) => {
    const stepsCopy = [...steps];
    if (action === "create") {
      stepsCopy.push(journeyTask);
      setSteps(stepsCopy.sort((a, b) => a.step_number - b.step_number));
    } else if (action === "edit") {
      const indexToReplce = stepsCopy.findIndex((j) => j.id === journeyTask.id);
      stepsCopy[indexToReplce] = journeyTask;
      setSteps(stepsCopy.sort((a, b) => a.step_number - b.step_number));
    } else if (action === "delete") {
      const indexToDelete = stepsCopy.findIndex((j) => j.id === journeyTask.id);
      stepsCopy.splice(indexToDelete, 1);
      setSteps(stepsCopy);
    }
  };

  const handleCreateJourneyTask = async (payload) => {
    const newJourneyTask = await createJourneyTask(payload);
    updateTableState("create", newJourneyTask);
    return newJourneyTask;
  };

  const handleEditJourneyTask = async (payload, journeyTaskId) => {
    const journeyTask = await editJourneyTask(payload, journeyTaskId);
    updateTableState("edit", journeyTask);
  };

  const handleDeleteJourneyTask = async (id) => {
    await deactivateJourneyTask(id);
    updateTableState("delete", { id });
  };

  const { name, objective_description } = journey;
  return (
    <PageLayoutWithButtons
      title={name}
      subtitle={objective_description}
      backTo="/cadences"
      buttons={[
        { key: 1, label: "Add Step", onClick: openModal, type: "secondary" },
        {
          key: 2,
          type: "customComponent",
          renderComponent: () => (
            <JourneyActionMenu
              key={2}
              journey={journey}
              handleEdit={handleEditJourney}
              handleDelete={handleDeleteJourney}
            />
          ),
        },
      ]}
    >
      {loadingSteps ? (
        <Loading />
      ) : isEmpty(steps) ? (
        <EmptyStateMessage icon="" text={emptyText} subtext={emptySubtext} />
      ) : (
        <div>
          {steps &&
            steps.map((step) => {
              return (
                <JourneyTaskRow
                  journeyTask={step}
                  key={step.id}
                  handleEdit={handleEditJourneyTask}
                  handleDelete={handleDeleteJourneyTask}
                  journeyId={journeyId}
                />
              );
            })}
        </div>
      )}

      {isOpen && (
        <JourneyTaskModal
          stepCount={steps.length}
          isOpen={isOpen}
          onClose={closeModal}
          createJourneyTask={handleCreateJourneyTask}
          journeyId={journeyId}
        />
      )}
    </PageLayoutWithButtons>
  );
};

JourneyPage.propTypes = {
  history: PropTypes.object.isRequired,
  journeyId: PropTypes.number.isRequired,
};

export default JourneyPage;
