import React, { useEffect, useState } from "react";
import { Button } from "@evertrue/et-components";
import {
  ToggleHeader,
  ToggleDescription,
} from "scripts/apps/importer/common/importer-styled-components";
import { onboardGroup } from "../utils/api-requests";
import { getJourneyGroups } from "scripts/apps/journey-groups/utils/api-requests";
import ErrorLogger from "utils/error-logger";

function hasGroup(group, name) {
  return group.name === name;
}

const JourneyGroupButtons = () => {
  const [hasDxGroup, setHasDxGroup] = useState(false);
  const [hasSignalGroup, setHasSignalGroup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // fetch and set group states (hasDxGroup, hasSignalGroup) based on journey groups from GraphQL
  useEffect(() => {
    const fetchJourneyGroups = async () => {
      try {
        setIsLoading(true);
        const journeyGroupsFromGql = await getJourneyGroups(false);
        const hasDx = journeyGroupsFromGql.some((group) =>
          hasGroup(group, "DX")
        );
        setHasDxGroup(hasDx);

        const hasSignal = journeyGroupsFromGql.some((group) =>
          hasGroup(group, "Signal By EverTrue")
        );
        setHasSignalGroup(hasSignal);
      } catch (error) {
        ErrorLogger.error(
          "There was an issue fetching the status of the category."
        );
      } finally {
        setIsLoading(false); // this will run NO MATTER WHAT, even if there is an error
      }
    };
    fetchJourneyGroups();
  }, []);

  // called on click to onboard the specific group (signal, dx) and set its corresponding status variable to true
  function handleClick(group) {
    try {
      onboardGroup(group);
    } catch (error) {
      ErrorLogger.error("There was an issue onboarding the category.");
    } finally {
      if (group === "signal") {
        setHasSignalGroup(true);
      } else if (group === "dx") {
        setHasDxGroup(true);
      }
    }
  }

  const buttonContainerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    marginTop: "5px",
  };
  return (
    <div>
      <div style={{ margin: "20px" }}>
        <ToggleHeader>Cadences and Cadence Categories</ToggleHeader>
        <ToggleDescription>
          Clicking the Onboard button next to each category name will populate all
          associated Cadences and Steps for this organization in app.evertrue.
        </ToggleDescription>
        <div>
          <div style={buttonContainerStyle}>
            <Button
              disabled={hasSignalGroup || isLoading}
              onClick={() => handleClick("signal")}
            >
              {hasSignalGroup ? "Onboarded" : "Onboard"}
            </Button>
            <div>Signal By EverTrue</div>
          </div>
          <div style={buttonContainerStyle}>
            <Button
              disabled={hasDxGroup || isLoading}
              onClick={() => handleClick("dx")}
            >
              {hasDxGroup ? "Onboarded" : "Onboard"}
            </Button>
            <div>DX</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JourneyGroupButtons;
