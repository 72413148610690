import React from "react";
import styled from "styled-components";
import { colors } from "style/colors";
import PropTypes from "prop-types";

const { string, any, bool } = PropTypes;

const Header = styled.div`
  padding: 12px 24px;
  background-color: ${colors.white};
  border-bottom: solid 1px ${colors.border};
  font-weight: normal;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Title = styled.div`
  font-size: 20px;
  line-height: 24px;
`;

export const Subtitle = styled.div`
  color: ${colors.lightText};
  font-size: 14px;
  margin-top: 4px;
`;

const HeaderLeft = styled.div`
  flex-grow: 0;
`;

const HeaderCenter = styled.div`
  flex-grow: 1;
`;

const HeaderRight = styled.div`
  flex-grow: 0;
`;

const propTypes = {
  title: string,
  subtitle: string,
  right: any,
  bottomBorder: bool,
};

const TitleHeader = ({ title, subtitle, right, bottomBorder }) => (
  <Header>
    <HeaderLeft>
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </HeaderLeft>
    <HeaderCenter />
    {right && <HeaderRight>{right}</HeaderRight>}
  </Header>
);

TitleHeader.propTypes = propTypes;

export default TitleHeader;
