import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalHeader, ModalBody, Button } from "@evertrue/et-components";

const DeleteJourneyModal = ({ isOpen, onClose, journey, handleDelete }) => {
  const { name, active_constituents } = journey;

  const deleteJourney = async () => {
    onClose();
    await handleDelete(journey);
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <ModalHeader title={"Delete Cadence"} closeModal={onClose} />
      <ModalBody scrollable={false}>
        {active_constituents && active_constituents > 0 ? (
          <div style={{ padding: "40px" }}>
            <span>
              You cannot delete the cadence "{name}" because you currently have{" "}
            </span>
            <strong>{active_constituents}</strong> constituents in this cadence.
          </div>
        ) : (
          <div>
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              Are you sure you want to delete the cadence "{name}" ?
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "16px",
                justifyContent: "flex-end",
              }}
            >
              <Button type="simple" onClick={onClose}>
                Cancel
              </Button>
              <div style={{ margin: "0 20px" }}>
                <Button type="destroy" onClick={deleteJourney}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

DeleteJourneyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  journey: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default DeleteJourneyModal;
