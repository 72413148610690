import React from "react";
import PageLayout from "apps/layout/components/page-layout";
import { Redirect, Route, Switch } from "react-router-dom";
import Stages from "apps/stages/controllers/stages-controller";

const InteractionRouteController = () => (
  <PageLayout
    title={"Volunteers | Stage Configuration"}
    subtitle="Customize your volunteer stages"
  >
    <Switch>
      <Route
        path="/volunteers/stages/:id?"
        render={({ match: { params }, history }) => {
          const stage_group_id = params.id
            ? parseInt(params.id, 10)
            : undefined;
          return (
            <Stages
              history={history}
              stageGroupId={stage_group_id}
              groupType="POOL"
              baseRoute="/volunteers/stages/"
            />
          );
        }}
      />
      <Route
        path="/volunteers"
        render={() => <Redirect to="/volunteers/stages" />}
      />
    </Switch>
  </PageLayout>
);

export default InteractionRouteController;
