import React from "react";
import styled from "styled-components";
import { AuthContext } from "scripts/auth-connector";

const UserTimestamp = ({ importStartTime }) => {
  const userName = React.useContext(AuthContext).user.name;
  return (
    <TimeStamp>{`Import Started: ${importStartTime} by ${userName}`}</TimeStamp>
  );
};

const TimeStamp = styled.div`
  margin-top: 8px;
  padding-left: 4px;
`;

export default UserTimestamp;
