import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { colors } from "style/colors";

import { ButtonTooltip, Icon } from "@evertrue/et-components";

const ImportStatsTable = ({ stats = [], title = "" }) => {
  return (
    <React.Fragment>
      {title && <TitleText>{title}</TitleText>}
      <TableContainer>
        <Table>
          <tbody>
            {stats.map(({ stat_name, stat_value, breakdown }) => {
              return (
                <tr>
                  <Label>
                    {stat_name}
                    {breakdown ? (
                      <ButtonTooltip
                        contentWidth={360}
                        trigger={
                          <Icon icon="question-circle" key={stat_name} />
                        }
                      >
                        <React.Fragment>
                          <ImportStatsTable
                            stats={breakdown}
                            title={"Inspection Breakdown"}
                          />
                        </React.Fragment>
                      </ButtonTooltip>
                    ) : null}
                  </Label>
                  <TD>{stat_value == null ? "N/A" : stat_value.toLocaleString()}</TD>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

ImportStatsTable.propTypes = {
  stats: PropTypes.array,
  title: PropTypes.string,
};

ImportStatsTable.defaultProps = {
  stats: [],
  title: "",
};

export default ImportStatsTable;

const TitleText = styled.span`
  font-weight: 500;
  width: 90%;
`;

export const Table = styled.table`
  width: 90%;
  margin: auto;
  border: 1px solid black;
  border-collapse: collapse;
`;

export const TableContainer = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
`;

export const TD = styled.td`
  border: 1px solid ${colors.border};
  text-align: center;
`;

export const Label = styled.td`
  border: 1px solid ${colors.border};
  font-weight: 500;
  padding: 6px;
`;
