import { TableCell, TableRow } from "style/components";
import React from "react";
import PropTypes from "prop-types";
import JourneyGroupActionMenu from "./journey-group-action-menu";

const JourneyGroupsTableRow = ({ group = {}, handleDelete, handleEdit }) => {
  const { name = "", creator, user_count = 0, journey_count = 0 } = group;

  const creatorName = creator ? creator.name : "EverTrue";

  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{user_count}</TableCell>
      <TableCell>{journey_count}</TableCell>
      <TableCell>{creatorName}</TableCell>
      <TableCell>
        <JourneyGroupActionMenu
          type="threeDotMenu"
          group={group}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
        />
      </TableCell>
    </TableRow>
  );
};

JourneyGroupsTableRow.propTypes = {
  group: PropTypes.object.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
};

export default JourneyGroupsTableRow;
