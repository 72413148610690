import React from "react";
import PropTypes from "prop-types";
import Api from "utils/api";
import { Link } from "react-router-dom";
import {
  FlexTable,
  Button,
  Icon,
  Tooltip2,
  FlexTableSortHeaderCell,
} from "@evertrue/et-components";
import moment from "moment";
import { getStatusInfo, getJobRoute } from "apps/importer/import-helper";
import styled from "styled-components";
import { colors } from "style/colors";

const columns = (onColumnSort, sortOrder) => [
  {
    headerClassName: "icon-column",
    cellClassName: "icon-column",
    accessor: ({ status }) => {
      let { icon, color } = getStatusInfo({ status });
      return (
        <Icon icon={icon} style={{ color, marginRight: "10px" }} size={2} />
      );
    },
  },
  {
    header: "Status",
    headerClassName: "data-column",
    cellClassName: "data-column",
    accessor: (data) => {
      return <React.Fragment>{data.status_display_name}</React.Fragment>;
    },
  },
  {
    header: "Job Id",
    headerClassName: "data-column",
    cellClassName: "data-column",
    accessor: (data) => data.id,
  },
  {
    header: "File Name",
    headerClassName: "data-column",
    cellClassName: "data-column",
    accessor: (data) => {
      return (
        <Tooltip2
          trigger={<TruncatedFileName>{data.s3_filename}</TruncatedFileName>}
        >
          <FileName>{data.s3_filename}</FileName>
        </Tooltip2>
      );
    },
  },
  {
    header: (
      <FlexTableSortHeaderCell
        isSorted
        sortOrder={sortOrder}
        onClick={onColumnSort}
        className="data-column"
      >
        Import Time
      </FlexTableSortHeaderCell>
    ),
    headerClassName: "data-column",
    cellClassName: "data-column",
    accessor: (data) => {
      return (
        <React.Fragment>
          {moment(data.created_at).format("M/DD/YYYY h:mm A")}
        </React.Fragment>
      );
    },
  },
  {
    header: "Import Type",
    headerClassName: "data-column",
    cellClassName: "data-column",
    accessor: (data) => {
      return <React.Fragment>{data.type_display_name}</React.Fragment>;
    },
  },
  {
    header: "Import Method",
    headerClassName: "data-column",
    cellClassName: "data-column",
    accessor: (data) => {
      const automatic = data.automatic_import ? "Automatic" : "Manual";
      const full = data.full_import ? "Full" : "Partial";
      return (
        <React.Fragment>
          {automatic} / {full}
        </React.Fragment>
      );
    },
  },
  {
    header: "Download",
    headerClassName: "data-column",
    cellClassName: "data-column",
    accessor: (data) => {
      let downloadIcon;
      let disabledDownload;
      let downloadColor;
      if (
        data.status === "FAILED" ||
        moment().diff(moment(data.created_at), "days") >= 90
      ) {
        downloadIcon = "cloud-download-blocked";
        disabledDownload = true;
        downloadColor = colors.blueGray;
      } else {
        downloadIcon = "cloud-download";
        disabledDownload = false;
        downloadColor = colors.mainBase;
      }

      const downloadLink = () => {
        Api.IMPORT.IMPORTS.get({
          urlExtend: `/${data.id}/download`,
        }).then((response) => {
          const { s3_url } = response.data;
          if (response.data.s3_url) {
            let link = document.createElement("a");
            link.download = data.filename || "";
            link.href = s3_url;
            link.target = "_blank";
            let evt = document.createEvent("MouseEvents");
            evt.initMouseEvent(
              "click",
              true,
              true,
              window,
              1,
              0,
              0,
              0,
              0,
              false,
              false,
              false,
              false,
              0,
              null
            );
            link.dispatchEvent(evt);
          }
        });
      };

      return (
        <Button
          type="simple"
          label="Download Import CSV"
          disabled={disabledDownload}
          onClick={downloadLink}
        >
          <Icon
            icon={downloadIcon}
            size={2}
            style={
              disabledDownload
                ? { cursor: "default", color: `${downloadColor}` }
                : { color: `${downloadColor}` }
            }
          />
        </Button>
      );
    },
  },
  {
    header: "Details",
    headerClassName: "data-column",
    cellClassName: "data-column",
    accessor: (data) => {
      const route = getJobRoute(data.id, data.mapping_complete, data.status);

      return (
        <Link
          to={{
            pathname: route,
            state: {
              // Add state with required data
              fileName: data.s3_filename,
              importTime: data.created_at,
            },
          }}
        >
          <Icon
            icon="go-to-page"
            title="Import Details"
            size={2}
            style={{ color: colors.mainBase }}
          />
        </Link>
      );
    },
  },
];

const ImportTable = ({ data, loading, onColumnSort, sortOrder }) => {
  if (data) {
    return (
      <TableStyle>
        <FlexTable
          emptyStateMessageText="No Imports Found"
          caption={"Imports Table"}
          data={data}
          columns={columns(onColumnSort, sortOrder)}
          loading={loading}
          tableFlexBasis="auto"
        />
      </TableStyle>
    );
  }
  return null;
};

ImportTable.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onColumnSort: PropTypes.func,
  sortOrder: PropTypes.bool,
};

ImportTable.defaultProps = {
  loading: false,
  onColumnSort: () => {},
  sortOrder: false,
};

const FileName = styled.div`
  flex-wrap: wrap;
  line-height: 20px;
  cursor: pointer;
  button {
    color: ${colors.linkText};
  }
`;

const TruncatedFileName = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TableStyle = styled.div`
  .icon-column {
    min-width: 70px;
    padding: 12px;
    text-align: center;
    justify-content: center;
    flex: none;
  }

  .data-column {
    padding: 8px 0px;
    text-align: center;
    justify-content: center;
  }
`;

export default ImportTable;
