import React, { Component } from "react";
import _ from "underscore";
import T from "prop-types";
import {
  AdvancedCombobox,
  ModalHeaderDeprecated,
  ModalBody,
  ModalFooterWithButtons,
  Switch,
} from "@evertrue/et-components";
import { DeleteButton, HintText } from "./team-modal-style";
import {
  Form,
  Label,
  FormRow,
  FormField,
} from "../../../style/components/form";
import StageGroupResolver from "apps/stages/resolvers/stage-group-resolver";

class TeamModal extends Component {
  static propTypes = {
    team: T.object,
    onSubmit: T.func.isRequired,
    onDelete: T.func,
    close: T.func,
    groupType: T.string,
  };

  static defaultProps = {
    team: {},
    onSubmit: _.noop,
    onDelete: _.noop,
    close: _.noop,
    groupType: "TEAM",
  };

  state = {
    isEditing: !_.isEmpty(this.props.team),
    newTeamData: {
      name: "",
      pool_type: this.props.groupType,
      selection_mode: "DISABLED",
      ...this.props.team,
    },
  };

  handleChange = (key, value) => {
    this.setState({ newTeamData: { ...this.state.newTeamData, [key]: value } });
  };

  handleDelete = (id) => {
    const name = this.state.newTeamData.name;
    const confirm_text = `Deleting a team can not be undone, are you sure you want to delete ${name}?`;
    var confirmed = window.confirm(confirm_text);
    if (confirmed) {
      this.props.onDelete(id);
      this.props.close();
    }
  };

  render() {
    const { id, name, selection_mode, stage_group_id } = this.state.newTeamData;
    const isDisabled = !name;
    const { close } = this.props;
    const header = this.state.isEditing ? "Edit Team" : "Create Team";

    return (
      <div>
        <ModalHeaderDeprecated header={header} closeModal={close} />
        <ModalBody>
          <Form>
            <Label htmlFor="display-name">Team Name:</Label>
            <FormRow>
              <FormField>
                <input
                  type="text"
                  onChange={({ target: { value } }) =>
                    this.handleChange("name", value)
                  }
                  name="name"
                  value={name}
                />
              </FormField>
            </FormRow>

            <Label>Solicitor Self-Selection</Label>
            <FormRow>
              <FormField>
                <HintText>
                  Allow solicitors to add/remove prospects from the team
                </HintText>
                <Switch
                  onChange={(val) =>
                    this.handleChange(
                      "selection_mode",
                      val ? "SOLICITOR_SELECTION" : "DISABLED"
                    )
                  }
                  name="selection_mode"
                  checked={selection_mode === "SOLICITOR_SELECTION"}
                />
              </FormField>
            </FormRow>

            <Label>Stage Group</Label>
            <FormRow>
              <FormField>
                <StageGroupResolver groupType={this.props.groupType}>
                  {({ stageGroups = [] }) => {
                    const options = _.map(stageGroups, (stageGroup = {}) => {
                      return { value: stageGroup.id, label: stageGroup.name };
                    });
                    return (
                      <AdvancedCombobox
                        value={{ value: stage_group_id }}
                        options={options}
                        onChange={(val = {}) =>
                          this.handleChange("stage_group_id", val.value)
                        }
                      />
                    );
                  }}
                </StageGroupResolver>
              </FormField>
            </FormRow>
          </Form>
        </ModalBody>
        <ModalFooterWithButtons
          disableSave={isDisabled}
          onSubmit={() => this.props.onSubmit(this.state.newTeamData)}
          header={header}
          onCancel={close}
          closeModal={close}
          closeOnSubmit
        >
          {this.state.isEditing && (
            <DeleteButton onClick={() => this.handleDelete(id)}>
              Delete Team
            </DeleteButton>
          )}
        </ModalFooterWithButtons>
      </div>
    );
  }
}

export default TeamModal;
