import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalBody,
  AdvancedCombobox,
  ModalHeaderWithActions,
} from "@evertrue/et-components";
import {
  Form,
  JourneysFormField,
  JourneysRow,
  JourneysLabel,
  JourneysTextArea,
  JourneysInput,
} from "scripts/style/components/form";
import { getJourneyGroupsForOrg } from "../../utils/api-requests/journeys-requests";
import { CreateJourneySchema } from "../../schemas/create-journey-schema";
import { Formik } from "formik";
import CharacterCounter from "scripts/components/character-counter";

const TYPE_OPTIONS = [
  { label: "Other", value: "OTHER" },
  { label: "Qualification", value: "QUALIFICATION" },
  { label: "Cultivation", value: "CULTIVATION" },
  { label: "Solicitation", value: "SOLICITATION" },
  { label: "Stewardship", value: "STEWARDSHIP" },
];

const journeyModalDefaults = (existingJourney = {}, journeyGroups = []) => {
  const {
    name: journeyName = "",
    objective_description: journeyDescription = "",
    journey_group_id: journeyGroupId = "",
    journey_type: journeyType = "",
  } = existingJourney;

  const selected = journeyGroups.find(
    (group) => group.value === journeyGroupId
  );

  const selectedType = TYPE_OPTIONS.find((type) => type.value === journeyType);

  return {
    journeyName,
    journeyDescription,
    journeyGroupId: selected,
    journeyType: selectedType,
  };
};

const JourneysModal = ({
  isOpen,
  onClose,
  existingJourney = {},
  editJourney = () => {},
  createJourney = () => {},
}) => {
  const { id: editJourneyId } = existingJourney;
  const [journeyGroups, setJourneyGroups] = useState([]);

  const taskDefaults = journeyModalDefaults(existingJourney, journeyGroups);

  const handleSubmit = ({
    journeyName,
    journeyDescription,
    journeyGroupId,
    journeyType,
  }) => {
    const { value: journeyGroupIdVal = null } = journeyGroupId;
    const { value: journeyTypeVal = null } = journeyType;

    const payload = {
      id: editJourneyId,
      name: journeyName,
      objective_description: journeyDescription,
      journey_group_id: journeyGroupIdVal,
      journey_type: journeyTypeVal,
    };
    if (editJourneyId) {
      editJourney(payload);
    } else {
      delete payload.id;
      createJourney(payload);
    }
    onClose();
  };

  useEffect(() => {
    const getAndSetJourneyGroups = async () => {
      const journeyGroupsFromApi = await getJourneyGroupsForOrg();

      const journeyGroupOptions = journeyGroupsFromApi.map((group) => ({
        label: group.name,
        value: group.id,
      }));

      setJourneyGroups(journeyGroupOptions);
    };

    if (isOpen) getAndSetJourneyGroups();
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <Formik
        initialValues={taskDefaults}
        validationSchema={CreateJourneySchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, isValid }) => {
          return (
            <React.Fragment>
              <ModalHeaderWithActions
                title={
                  editJourneyId ? "Edit Cadence Details" : "Create Cadence"
                }
                submitLabel={editJourneyId ? "Update" : "Create"}
                closeModal={onClose}
                onSubmit={handleSubmit}
                disableSubmit={!isValid}
              />
              <ModalBody scrollable={false}>
                <Form style={{ padding: "0" }}>
                  <JourneysRow>
                    <span>* Indicates required field</span>
                    <JourneysFormField>
                      <JourneysLabel htmlFor="display-name">
                        Cadence Name *
                      </JourneysLabel>
                      <JourneysInput
                        type="text"
                        onChange={handleChange("journeyName")}
                        name="journeyName"
                        value={values.journeyName}
                      />
                      <CharacterCounter
                        limit={120}
                        count={values.journeyName.length}
                      />
                    </JourneysFormField>
                    <JourneysFormField>
                      <JourneysLabel htmlFor="field-type">
                        Description
                      </JourneysLabel>
                      <JourneysTextArea
                        onChange={handleChange("journeyDescription")}
                        name="journeyDescription"
                        id="cadence-description"
                        value={values.journeyDescription}
                      />
                      <CharacterCounter
                        limit={250}
                        count={values.journeyDescription.length}
                      />
                    </JourneysFormField>
                    <JourneysFormField>
                      <JourneysLabel htmlFor="field-type">
                      Cadence Category *
                      </JourneysLabel>
                      <AdvancedCombobox
                        placeholder="Select Cadence Category..."
                        options={journeyGroups}
                        value={values.journeyGroupId}
                        name="journeyGroupId"
                        onChange={(v) => {
                          setFieldValue("journeyGroupId", v);
                        }}
                      />
                    </JourneysFormField>
                    <JourneysFormField>
                      <JourneysLabel htmlFor="field-type">
                        Cadence Goal *
                      </JourneysLabel>
                      <AdvancedCombobox
                        placeholder="Select Cadence Goal..."
                        options={TYPE_OPTIONS}
                        value={values.journeyType}
                        name="journeyType"
                        onChange={(v) => {
                          setFieldValue("journeyType", v);
                        }}
                      />
                    </JourneysFormField>
                  </JourneysRow>
                </Form>
              </ModalBody>
            </React.Fragment>
          );
        }}
      </Formik>
    </Modal>
  );
};

JourneysModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  existingJourney: PropTypes.object,
  editJourney: PropTypes.func,
  createJourney: PropTypes.func,
};

JourneysModal.defaultProps = {
  existingJourney: {},
  editJourney: () => {},
  createJourney: () => {},
};

JourneysModal.defaultProps = {
  editDetails: {},
};

export default JourneysModal;
