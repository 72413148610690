import { AlertActions } from "@evertrue/et-components";

export const launchToast = ({
  message = "Success",
  type = "success",
  timeout = 5000,
}) => {
  AlertActions.launchToast({
    type,
    message,
    timeout,
  });
};
