import styled from "styled-components";
import { colors } from "style/colors";

export const Label = styled.label`
  display: inline-block;
  padding: 12px 0;
  font-weight: 500;
`;

export const StyledInput = styled.input`
  border-radius: 8px !important;
  border: solid 1px #b7b7b7 !important;
`;

export const FormWrapper = styled.div`
  padding: 8px 52px 24px;
`;

export const Form = styled.div`
  padding: 0 20px;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid ${colors.border};

  &:last-child {
    border-bottom: none;
  }
`;

export const FormField = styled.div`
  flex: 1;
  margin-right: 8px;

  &:last-child {
    margin: 0;
  }
`;

export const TextArea = styled.textarea`
  background-color: #f5f5f5;
  border: none;
  min-height: 217px;
  width: 432px;
  margin: 10px auto;
  resize: none;
  padding: 15px;
  font-size: 14px;
`;

export const HintText = styled.div`
  font-size: 12px;
  color: ${colors.lightText};
  margin-bottom: 12px;
`;

export const JourneysLabel = styled.label`
  display: inline-block;
  padding: 8px 0;
  font-size: 14px;
  line-height: 16.41px;
  font-weight: 400;
`;

export const JourneysRow = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.border};

  &:last-child {
    border-bottom: none;
  }
`;

export const JourneysFormField = styled.div`
  flex: 1;
  margin: 8px;
`;

export const JourneysTextArea = styled.textarea`
  background-color: #f9f9f9;
  border-radius: 8px;
  border: solid 1px #b7b7b7;
  min-height: 110px;
  width: 100%;
  resize: none;
  font-size: 14px;
`;

export const JourneysInput = styled.input`
  border-radius: 8px !important;
  border: solid 1px #b7b7b7 !important;
`;

export const ListItemWithTrashCan = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0px;
  border-bottom: 1px solid ${colors.border};

  &:last-child {
    border-bottom: none;
  }
`;
