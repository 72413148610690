import React, { Component } from "react";
import T from "prop-types";
import _ from "underscore";
import {
  ModalDeprecated,
  ModalConfirm,
  Checkbox,
} from "@evertrue/et-components";
import { FormWrapper, Label } from "style/components";
import { StatusSection } from "apps/stages/components/add-stage-modal-style";

const EMPTY_STAGE = () => ({
  stage: "",
  active: true,
});

class AddStageModal extends Component {
  static propTypes = {
    isEditing: T.bool,
    stageGroupId: T.number,
    stage: T.object,
    handleAddStageToGroup: T.func,
    onClose: T.func,
    groupType: T.string,
  };

  static defaultProps = {
    stage: {},
  };

  state = {
    stage: this.props.isEditing ? this.props.stage : EMPTY_STAGE(),
  };

  handleChange = (key, val) => {
    this.setState({
      stage: {
        ...this.state.stage,
        [key]: val,
      },
    });
  };

  render() {
    const is_disabled = this.props.isEditing
      ? !_.isEqual(this.props.stage, this.state.stage)
      : this.state.stage.stage;
    return (
      <ModalDeprecated {...this.props} width={500}>
        <ModalConfirm
          onClose={this.props.onClose}
          header={`${this.props.isEditing ? "Edit" : "Add New"} Stage`}
          disableSave={!is_disabled}
          saveLabel={this.props.isEditing ? "Edit" : "Add"}
          onSubmit={() => {
            this.props.handleAddStageToGroup(
              this.props.stageGroupId,
              this.state.stage
            );
          }}
        >
          <FormWrapper>
            <Label>Stage Name</Label>
            <input
              type="text"
              placeholder="Enter name for stage..."
              value={this.state.stage.stage}
              onChange={({ target: { value } }) =>
                this.handleChange("stage", value)
              }
            />

            <StatusSection>
              <Checkbox
                checked={this.state.stage.active === true}
                onChange={(val) => this.handleChange("active", val)}
                disabled={this.props.groupType === "DXO"}
              >
                <div>
                  <strong>Active Stage</strong>
                  <div>Enable users to update prospects using this stage.</div>
                </div>
              </Checkbox>
            </StatusSection>
          </FormWrapper>
        </ModalConfirm>
      </ModalDeprecated>
    );
  }
}

export default AddStageModal;
