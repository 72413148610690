import { useState, useEffect } from "react";
import Api from "scripts/utils/api";

const useIntegrations = () => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const withLoader = (fn) => {
        setLoading(true);
        fn().finally(() => {
            setLoading(false);
        });
    }

    const fetchAllIntegrations = async () => {
        withLoader(async () => {
            const { data } = await Api.AUTH.INTEGRATIONS.get();
            setData(data);
        });
    }

    return {
        data,
        error,
        loading,
        fetchAllIntegrations,
    }
}

export default useIntegrations;