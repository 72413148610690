import { useCallback, useEffect, useState } from "react";
import Api from "utils/api";
import { launchToast } from "utils/show-toast";

import {
  isRunning,
  STATUS,
  getProcessState,
  WORKER_TYPE_TO_PROCESS,
} from "apps/importer/import-helper";

export const usePollingResults = ({ jobId, workerType, history }) => {
  const [jobData, setJobData] = useState({});
  const [results, setResults] = useState({});
  const [status, setStatus] = useState(STATUS.UNKNOWN);

  const fetchResults = useCallback(async () => {
    try {
      const response = await Api.IMPORT.IMPORT_JOB_RESULTS.get({
        urlArgs: { job_id: jobId },
        params: { worker_type: workerType },
      });
      const result = response.data.results.find(
        ({ worker_type }) => worker_type === workerType
      );

      const currentProcessState = getProcessState(
        WORKER_TYPE_TO_PROCESS[workerType],
        result.status
      );

      setJobData(response.data.job);
      setResults(result);
      setStatus(currentProcessState);
    } catch (err) {
      launchToast({
        message: `Unable to get job results for workerType:${workerType} on jobId:${jobId}`,
        type: "error",
      });

      history.push("/importer");

      console.log(err);
    }
  }, [jobId, workerType, history]);

  useEffect(() => {
    if (jobId) {
      fetchResults();
    } else {
      setJobData({});
      setResults({});
      setStatus(STATUS.UNKNOWN);
    }
  }, [jobId, fetchResults]);

  useEffect(() => {
    if (isRunning(WORKER_TYPE_TO_PROCESS[workerType], results.status)) {
      setTimeout(() => {
        fetchResults();
      }, 5000);
    }
  }, [fetchResults, jobId, workerType, results]);

  return { jobData, results, status };
};

export default usePollingResults;
