import { AuthContext } from "scripts/auth-connector";
import { useContext, useState, useEffect, useCallback } from "react";
import { searchUsersForOrg } from "./utils/api-requests";

const LIMIT = 5;
const ROLES = ["GivingTree User", "GivingTree Owner"];

const useSearchUsers = () => {
  const { organization } = useContext(AuthContext);
  const [users, setUsers] = useState([]);

  // "name" is what the user is typing in the search bar
  // offset is the number of results already loaded in
  const searchUsers = useCallback(
    async (name, offset, excludedUserIds = []) => {
      const { roles = [] } = organization;
      const roleIdsToSearch = roles
        .filter((role) => ROLES.includes(role.name))
        .map((role) => role.id);

      const {
        data: { users: userResults = [] },
      } = await searchUsersForOrg(
        name,
        roleIdsToSearch,
        excludedUserIds,
        offset,
        LIMIT
      );
      if (offset > 0) {
        setUsers((prev) => [...prev, ...userResults]);
      } else {
        setUsers(userResults);
      }
    },
    [organization]
  );

  useEffect(() => {
    searchUsers();
  }, [searchUsers]);

  return { users, searchUsers };
};

export default useSearchUsers;
