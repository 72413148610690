import React from "react";
import PropTypes from "prop-types";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { Flex } from "@evertrue/et-components";

import { colors } from "style/colors";
import Field from "apps/importer/components/import-mapping/field";
import {
  SectionIcon,
  DeleteIcon,
} from "apps/importer/common/importer-styled-components";

const MappingItem = ({
  fieldColumn,
  draggableItems,
  unmapValue,
  addField,
  removeField,
  sequenceCounter,
  viewOnly,
}) => {
  const { id, field_name, footnote, display_name, callbackArgs } = fieldColumn;
  const columnItem = fieldColumn.attributeKeys;
  const columnItemExists = columnItem && columnItem.length > 0;
  const isListField = fieldColumn.type === "list_field";

  return (
    <Container key={field_name} isListField={isListField}>
      <Flex justifyContent="left">
        {isListField && (
          <SectionIcon
            icon={!viewOnly && ("add-circle")}
            size={1}
            onClick={() => addField(callbackArgs)}
          />
        )}
        {`${display_name} ${sequenceCounter ? sequenceCounter : ""}`}
        <Footnote>{footnote ? "*".repeat(footnote) : null}</Footnote>
      </Flex>

      <Droppable droppableId={`${id}`} isDropDisabled={columnItemExists}>
        {(provided, snapshot) => (
          <React.Fragment>
            <Flex justifyContent="right">
              {isListField && sequenceCounter !== null && (
                <DeleteIcon
                  icon="trash"
                  onClick={() => removeField(callbackArgs)}
                />
              )}
              <DropContainer
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {provided.placeholder}
                {columnItemExists &&
                  columnItem.map((fieldKey, index) => {
                    const field = draggableItems && draggableItems[fieldKey];
                    return (
                      <Field
                        key={field.id}
                        index={index}
                        field={field}
                        isMapped={true}
                        callbackArgs={callbackArgs}
                        unmapValue={unmapValue}
                        viewOnly={viewOnly}
                      />
                    );
                  })}
              </DropContainer>
            </Flex>
          </React.Fragment>
        )}
      </Droppable>
    </Container>
  );
};

MappingItem.propTypes = {
  draggableItems: PropTypes.object.isRequired,
  fieldColumn: PropTypes.object,
  unmapValue: PropTypes.func.isRequired,
  addField: PropTypes.func,
  removeField: PropTypes.func,
  sequenceCounter: PropTypes.number,
  viewOnly: PropTypes.bool.isRequired,
};

MappingItem.defaultProps = {
  addField: () => {},
  removeField: () => {},
  fieldColumn: {},
  sequenceCounter: null,
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 26px;
  margin-bottom: 4px;
  margin-left: ${(props) => (props.isListField ? "-24px" : 0)};
`;
const DropContainer = styled.div`
  border: 1px dashed darkgrey;
  width: 160px;
  height: 26px;
  background-color: ${(props) =>
    props.isDraggingOver ? colors.lightBase : "lightgrey"};
`;
const Footnote = styled.span`
  color: red;
`;

export default MappingItem;
