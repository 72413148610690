import { DefaultSectionDelimiter }  from "apps/importer/components/import-mapping/mapping-sections/section";

export function formatFieldName(field_name){
    if(field_name == null) return field_name;
    return field_name.includes(DefaultSectionDelimiter)
        ? field_name.split(DefaultSectionDelimiter)[0]
        : field_name;
} 

export function formatNamingID(sectionID, formattedFieldName){
    return sectionID
        ? `${formattedFieldName}${DefaultSectionDelimiter}${sectionID}`
        : formattedFieldName;
}