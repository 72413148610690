import React from "react";

import { IntegrationCard } from "apps/integrations/crm/integration-cards/integration-card";
import blackbaudCrmImage from "../images/blackbaud-crm-logo.png"; // Images are outside of app root defined space. Relative path is necessary.

import useBlackBaudCrmAuthFlow from "apps/integrations/crm/utils/blackbaud-crm-auth-flow";

export const BlackbaudCrmIntegration = () => {
  const { integrationState, handleClick, loading } = useBlackBaudCrmAuthFlow();

  return (
    <IntegrationCard
      headerTitle={"EverTrue Sync"}
      state={integrationState}
      handleIntegrationClick={handleClick}
      contentImageHref={blackbaudCrmImage}
      loading={loading}
      primaryContentText={
        "Automatically syncs data between Blackbaud and EverTrue"
      }
    />
  );
};

export default BlackbaudCrmIntegration;
