import React from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import { Icon } from "@evertrue/et-components";
import styled from "styled-components";
import { colors } from "style/colors";

export const Field = ({ index, field, isMapped, unmapValue, callbackArgs, viewOnly }) => {
  return (
    <Draggable draggableId={field.id} index={index} isDragDisabled={viewOnly}>
      {(provided) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          viewOnly={viewOnly}
        >
          {isMapped ? (
            <React.Fragment>
              <span>{field.attribute_label}</span>
              {!viewOnly && (
                <UnmapButton
                  icon="cancel"
                  size={1}
                  onClick={() =>
                    unmapValue(
                      field,
                      callbackArgs.sectionIndex,
                      callbackArgs.itemIndex
                    )
                  }
                />
              )}
            </React.Fragment>
          ) : (
            field.attribute_label
          )}
        </Container>
      )}
    </Draggable>
  );
};

Field.propTypes = {
  index: PropTypes.number.isRequired,
  field: PropTypes.object.isRequired,
  isMapped: PropTypes.bool,
  unmapValue: PropTypes.func,
  callbackArgs: PropTypes.object,
  viewOnly: PropTypes.bool.isRequired,
};

Field.defaultProps = {
  isMapped: false,
  unmapValue: () => {},
  callbackArgs: {},
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 4px;
  border: 1px dashed grey;
  color: ${colors.white};
  background: ${props => props.viewOnly ? (colors.blueGray) : (colors.mainBase)};
  width: 161px;
  height: 26px;
  line-height: 26px;
  margin: -1px -1px 4px -1px;
  float: right;
  span {
    width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      margin-right: 8px;
      width: auto;
    }
  }

  &:hover {
    min-width: 161px;
    width: max-content;
  }
`;

const UnmapButton = styled(Icon)`
  padding-top: 5px;
  cursor: pointer;
`;

export default Field;
