import _ from "underscore";
import React from "react";
import Api from "utils/api";
import PropTypes from "prop-types";
import { AlertActions } from "@evertrue/et-components";
import ModalEditor from "../../../components/modal-editor";

const initialValues = {
  name: "",
  api_key: "",
  api_secret: "",
  account_id: "",
}

export const launchToast = (message = "Success", type = "success") => {
  setTimeout(() => {
    AlertActions.launchToast({
      type,
      message,
      timeout: 5000,
    });
  }, 800);
};

const PlatformAddModal = ({ refresh, onSuccess, ...rest }) => {
  return (
    <ModalEditor
      {...rest}
      initialValues={{...initialValues}}
      isInitialValid={false}
      header="Add Platform"
      saveLabel="Add Account"
      validate={(value) => {
        let errors = {};
        if (_.contains(value, "")) errors = { errors: true };
        return errors;
      }}
      onSubmit={({name, account_id, api_key, api_secret}) => {
        const data = {
          integration: {
            display_name: name,
            platform: "zoom_oauth",
            account_id,
            authentication: {
              client_id: api_key,
              client_secret: api_secret,
            },
          },
        };

        Api.AUTH.INTEGRATIONS.post({
          data,
          disableAlerts: true,
        })
          .then(refresh)
          .then(onSuccess)
          .catch(() =>
            launchToast(
              "There was an error submitting your integration",
              "error"
            )
          );
      }}
    >
      <ModalEditor.FormField
        autoComplete="off"
        label="Platform Name"
        placeholder="What do you want to call this account in EverTrue?"
        name="name"
      />
      <ModalEditor.FormField
        autoComplete="off"
        label="API Key"
        placeholder="API key"
        name="api_key"
      />
      <ModalEditor.FormField
        autoComplete="off"
        label="API Secret"
        placeholder="API Secret"
        name="api_secret"
      />
      <ModalEditor.FormField
        autoComplete="off"
        label="Platform Account ID"
        placeholder="123456"
        name="account_id"
      />
    </ModalEditor>
  );
};

PlatformAddModal.propTypes = {
  refresh: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default PlatformAddModal;
