import React from "react";
import PropTypes from "prop-types";
import JourneyTaskModal from "apps/journeys/components/modals/journey-task-modal";
import DeleteJourneyTaskModal from "scripts/apps/journeys/components/modals/delete-journey-task-modal";
import { ThreeDotActionsMenu, ActionsMenu } from "@evertrue/et-components";

const JourneyTaskActionMenu = ({
  journeyTask = {},
  handleEdit,
  handleDelete,
  type = "button",
  journeyId,
}) => {
  const editAction = {
    id: 1,
    mountOnClick: ({ is_open, close }) => (
      <JourneyTaskModal
        isOpen={is_open}
        onClose={close}
        existingJourneyTask={journeyTask}
        editJourneyTask={handleEdit}
        journeyId={journeyId}

      />
    ),
    label: "Edit Step",
    icon: "edit",
  };

  const deleteAction = {
    id: 2,
    mountOnClick: ({ is_open, close }) => (
      <DeleteJourneyTaskModal
        isOpen={is_open}
        onClose={close}
        journeyTask={journeyTask}
        handleDelete={handleDelete}
      />
    ),
    label: "Delete Step",
    icon: "trash",
  };

  const options = {
    id: 1,
    items: [editAction, deleteAction],
  };

  return type === "button" ? (
    <ActionsMenu options={[options]} />
  ) : (
    <ThreeDotActionsMenu options={[options]} iconSize={1} />
  );
};

JourneyTaskActionMenu.propTypes = {
  journeyTask: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  type: PropTypes.string,
  journeyId: PropTypes.number,
};

JourneyTaskActionMenu.defaultProps = {
  type: "button",
  journeyId: null,
};

export default JourneyTaskActionMenu;
