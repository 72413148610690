import React from "react";
import ReactDOM from "react-dom";
import AuthRouteController from "scripts/auth-route-controller";
// pollyfil for URLSearchParams (IE11)
import URLSearchParams from "url-search-params"; // eslint-disable-line
import "./styles/index.css";

import api from "utils/api";

window.api = api;

const rootEl = document.getElementById("root");

ReactDOM.render(<AuthRouteController />, rootEl);
