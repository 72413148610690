import styled from "styled-components";

export const StatusSection = styled.div`
  margin-top: 16px;
  line-height: 18px;

  label {
    display: flex;
  }

  .et--checkbox--box {
    min-width: 22px;
  }
`;

export const CustomTypesError = styled.div`
  margin-top: 10px;
`;
