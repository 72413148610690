import React from "react";
import { GateContext } from "apps/dna/gate-context";
import PropTypes from "prop-types";

// subscribes to the GateProvider rendered at top level of the app
export const useGate = (gate) => {
  const { gates = {}, loading } = React.useContext(GateContext);
  const current_gate = gates[gate] || {};
  const { enabled = false } = current_gate;
  return { show: enabled, loading };
};

const IsGated = (props) => {
  const { show, loading } = useGate(props.gate);

  if (props.render && typeof props.render === "function") {
    return props.render({ show, loading });
  }
  return show ? props.children : null;
};

IsGated.defaultProps = {
  gate: "",
  render: undefined,
};

IsGated.propTypes = {
  render: PropTypes.func,
  gate: PropTypes.string,
};

export default IsGated;
