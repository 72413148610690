import styled from "styled-components";

export const SearchInput = styled.input`
  &::-ms-clear {
    display: none;
  }

  &::-webkit-search-cancel-button {
    display: none;
  }
`;

export const SearchResults = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
`;

export const SearchResultItem = styled.div`
  &:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  padding: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;

  &:hover {
    background-color: #fcf8fc;
  }
`;
