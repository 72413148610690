import React, { Component } from "react";
import {
  Loading,
  SearchBox,
  AlertActions,
  ModalWrapper,
  Button,
  ModalTriggerDeprecated,
} from "@evertrue/et-components";
import T from "prop-types";
import { Wrapper } from "style/components";
import { searchCompare } from "utils/utils";
import * as RmService from "apps/relationship-management/relationship-management-service";
import TeamModal from "apps/relationship-management/components/team-modal";
import TeamsTable from "apps/relationship-management/components/teams-table";
import {
  ActionBar,
  SearchBoxWrapper,
} from "apps/interactions/controllers/custom-fields-interactions-controller-style";
import MultipleTeamsModal from "../components/multiple-teams-modal";

class RmTeamsController extends Component {
  state = {
    teams: [],
    searchText: "",
    isTeamModalOpen: false,
    isMultipleTeamsModalOpen: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    try {
      this.setState({ loading: true });
      const { data = {} } = await RmService.fetchPools(this.props.groupType);
      this.setState({ teams: data.items || [], loading: false });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  showToastAndFetch = (message = "Team Created") => {
    AlertActions.launchToast({
      type: "success",
      message,
      timeout: 2000,
    });
    this.fetchData();
  };

  handleModalSubmit = async (data = {}) => {
    await RmService.createPool(data);
    this.showToastAndFetch("Team created.");
  };

  handleTeamsModalSubmit = async (teams = {}) => {};

  updateTeam = async (team = {}) => {
    await RmService.updatePool(team.id, team);
    this.showToastAndFetch("Team updated.");
  };

  deleteTeam = async (id) => {
    await RmService.deletePool(id);
    this.showToastAndFetch("Team deleted.");
  };

  handleSearch = (searchText) => {
    this.setState({ searchText });
  };

  render() {
    const filteredTeams = this.state.teams.filter(({ name }) =>
      searchCompare(name, this.state.searchText)
    );

    return (
      <Wrapper>
        <ActionBar>
          <SearchBoxWrapper>
            <SearchBox
              placeholder="Search Team Names"
              onChange={this.handleSearch}
            />
          </SearchBoxWrapper>
          <div>
            <ModalWrapper
              isOpen={this.state.isTeamModalOpen}
              width={500}
              onClose={() => this.setState({ isTeamModalOpen: false })}
              render={({ close }) => (
                <TeamModal
                  onSubmit={this.handleModalSubmit}
                  close={close}
                  groupType={this.props.groupType}
                />
              )}
            />
            <Button
              type="default"
              onClick={() => this.setState({ isTeamModalOpen: true })}
            >
              Create Team
            </Button>

            <ModalTriggerDeprecated
              modal={
                <MultipleTeamsModal
                  onSubmit={this.handleTeamsModalSubmit}
                  postSubmit={this.showToastAndFetch}
                  groupType={this.props.groupType}
                />
              }
            >
              <Button type="default" style={{ marginLeft: 10 }}>
                Bulk Create Teams
              </Button>
            </ModalTriggerDeprecated>
          </div>
        </ActionBar>
        {this.state.loading ? (
          <Loading />
        ) : (
          <TeamsTable
            teams={filteredTeams}
            handleUpdate={this.updateTeam}
            onDelete={this.deleteTeam}
            groupType={this.props.groupType}
          />
        )}
      </Wrapper>
    );
  }
}

RmTeamsController.defaultProps = {
  groupType: "TEAM",
};
RmTeamsController.propTypes = {
  groupType: T.string,
};

export default RmTeamsController;
