import React from "react";

import { IntegrationCard } from "apps/integrations/crm/integration-cards/integration-card";
import raisersEdgeImage from "../images/raisers-edge-logo.png"; // Images are outside of app root defined space. Relative path is necessary.

import useDatasyncV1AuthFlow from "scripts/apps/integrations/crm/utils/use-datasync-v1-auth-flow";

export const NxtIntegration = () => {
  const { handleIntegrationClick, handleLogoutClick, state } = useDatasyncV1AuthFlow();

  return (
    <IntegrationCard
      headerTitle={"Data Sync V1"}
      state={state}
      handleIntegrationClick={handleIntegrationClick}
      handleLogoutClick={handleLogoutClick}
      contentImageHref={raisersEdgeImage}
      allowDeactivate={true}
      primaryContentText={
        "Automatically syncs data from Raiser’s Edge to EverTrue"
      }
    />
  );
};

export default NxtIntegration;
