import styled from "styled-components";
import _ from "underscore";
import React, { Component } from "react";
import T from "prop-types";
import { Icon, ModalWrapper } from "@evertrue/et-components";
import {
  TableBody,
  PlainButton,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableEmptyState,
  Table,
} from "style/components";
import { colors } from "style/colors";
import TeamModal from "apps/relationship-management/components/team-modal";
import StageGroupResolver from "apps/stages/resolvers/stage-group-resolver";

class TeamsTable extends Component {
  state = {
    isModalOpen: false,
    selectedTeam: {},
  };

  static propTypes = {
    teams: T.array,
    onDelete: T.func,
    handleUpdate: T.func,
    groupType: T.string,
  };

  static defaultProps = {
    teams: [],
    onDelete: _.noop,
    handleUpdate: _.noop,
    groupType: "TEAM",
  };

  handleToggleModal = (selectedTeam = {}) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      selectedTeam,
    });
  };

  render() {
    const { teams, handleUpdate, onDelete } = this.props;

    return (
      <Table>
        <TableHeader>
          <TableHeaderCell>Team Name</TableHeaderCell>
          <TableHeaderCell>Solicitor Self-Selection</TableHeaderCell>
          <TableHeaderCell>Stage Group</TableHeaderCell>
          <TableHeaderCell />
        </TableHeader>
        <TableBody>
          {!teams.length ? (
            <TableEmptyState text="No teams found" />
          ) : (
            teams.map((team) => (
              <TableRow key={team.id}>
                <TableCell>{team.name}</TableCell>
                <TableCell>
                  {team.selection_mode === "SOLICITOR_SELECTION"
                    ? "Enabled"
                    : "Disabled"}
                </TableCell>
                <TableCell>
                  <StageGroupResolver
                    groupId={team.stage_group_id}
                    groupType={this.props.groupType}
                  >
                    {({ stageGroup = {} }) => {
                      return stageGroup.name || "-";
                    }}
                  </StageGroupResolver>
                </TableCell>
                <TableCell>
                  <EditButton onClick={() => this.handleToggleModal(team)}>
                    <Icon icon="edit" />
                  </EditButton>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        <ModalWrapper
          isOpen={this.state.isModalOpen}
          onClose={this.handleToggleModal}
          width={500}
          render={({ close }) => (
            <TeamModal
              close={close}
              team={this.state.selectedTeam}
              onDelete={onDelete}
              onSubmit={handleUpdate}
              groupType={this.props.groupType}
            />
          )}
        />
      </Table>
    );
  }
}

const EditButton = styled(PlainButton)`
  color: ${colors.lightText};
  font-size: 18px;

  &:hover {
    color: ${colors.linkText};
  }
`;

export default TeamsTable;
