import React, { Component } from "react";
import _ from "underscore";
import T from "prop-types";
import {
  ModalDeprecated,
  ModalConfirm,
  Loading,
} from "@evertrue/et-components";
import { FormWrapper, Label, TextArea } from "../../../style/components/form";
import * as RmService from "../relationship-management-service";

const teamDefault = {
  name: "",
  selection_mode: "DISABLED",
};

class MultipleTeamsModal extends Component {
  static propTypes = {
    onSubmit: T.func.isRequired,
    close: T.func,
    groupType: T.string,
  };

  static defaultProps = {
    onSubmit: _.noop,
    close: _.noop,
    groupType: "TEAM",
  };

  state = {
    text: "",
  };

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  handleSubmit = async () => {
    this.setState({ loading: true });

    const teamNames = this.state.text.split(",");

    for (let teamName of teamNames) {
      //Remove beginning and trailing whitespaces.
      teamName = teamName.trim();

      const teamData = {
        ...teamDefault,
        pool_type: this.props.groupType,
        ...{ name: teamName },
      };

      await RmService.createPool(teamData);
    }
    this.setState({ loading: false });

    this.props.postSubmit(`${teamNames.length} Teams Created`);
  };

  render() {
    const isDisabled = !this.state.text;
    const header = "Create Teams";

    return (
      <ModalDeprecated {...this.props} width={500}>
        <ModalConfirm
          header={header}
          disableSave={isDisabled}
          onSubmit={this.handleSubmit}
        >
          {this.state.loading && <Loading />}
          <FormWrapper>
            <Label htmlFor="display-name">Team Names:</Label>
            <TextArea
              id="display-name"
              onChange={(event) =>
                this.handleChange("text", event.target.value)
              }
              placeholder={
                "Enter a comma separated list of team names. These teams will be created " +
                "with the default settings, which means, no solicitor self selection and no stage group."
              }
            />
          </FormWrapper>
        </ModalConfirm>
      </ModalDeprecated>
    );
  }
}

export default MultipleTeamsModal;
