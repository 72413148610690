import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SearchBox from "scripts/components/search/search-box";
import useSearchUsers from "scripts/apps/auth/use-search-users";
import CharacterCounter from "scripts/components/character-counter";
import ExistingUser from "apps/journey-groups/journey-group-users/existing-user";
import { JourneyGroupSchema } from "apps/journey-groups/schemas/journey-group-schema";
import {
  getJourneyGroupUsersByGroupId,
  removeUserFromJourneyGroup,
} from "apps/journey-groups/utils/api-requests";
import { Formik, Field, FieldArray, ErrorMessage } from "formik";
import {
  Modal,
  ModalBody,
  ModalHeaderWithActions,
  Button,
} from "@evertrue/et-components";
import { StyledBooleanRadio } from "scripts/apps/importer/common/importer-styled-components";
import { Label, StyledInput } from "scripts/style/components/form";

const getDefaults = (group, users = []) => {
  const { name = "", group_type = null } = group;
  const isDxo = group_type === "DXO";
  return {
    name,
    isDxo,
    existingJourneyGroupUsers: users,
    usersToAdd: [],
  };
};

const FormField = ({ field, form, ...props }) => {
  return <StyledInput {...field} {...props} />;
};

const JourneyGroupModal = ({
  isOpen,
  onClose,
  group = {},
  editGroup = async () => {},
  createGroup = async () => {},
}) => {
  const { id: editGroupId } = group;
  const { users, searchUsers } = useSearchUsers();
  const [defaults, setDefaults] = useState({});
  const [loadingDefaults, setLoadingDefaults] = useState(false);
  const [visibleUsersCount, setVisibleUsersCount] = useState(50);

  useEffect(() => {
    const setDefaultValues = async () => {
      try {
        setLoadingDefaults(true);
        let usersArr = [];
        if (group.id) {
          usersArr = await getJourneyGroupUsersByGroupId(group.id);
        }
        setDefaults(getDefaults(group, usersArr));
      } finally {
        setLoadingDefaults(false);
      }
    };

    if (isOpen) setDefaultValues();
  }, [isOpen, group]);

  const submitClick = async (values) => {
    const { name, isDxo, usersToAdd } = values;
    const payload = {
      ...group,
      name,
      group_type: isDxo ? "DXO" : null,
    };
    if (editGroupId) {
      await editGroup(payload, editGroupId, usersToAdd);
    } else {
      await createGroup(payload, usersToAdd);
    }
    onClose();
  };

  const handleSearchInputChange = (v, values = {}) => {
    // don't return users that are already on the modal
    const { existingJourneyGroupUsers = [], usersToAdd = [] } = values;
    const existingUserIds = existingJourneyGroupUsers.map(
      ({ user }) => user.id
    );
    const addedUserIds = usersToAdd.map((user) => user.id);
    const excludedUserIds = [...existingUserIds, ...addedUserIds];
    searchUsers(v, 0, excludedUserIds);
  };

  const handleShowMoreUsers = () => {
    setVisibleUsersCount(visibleUsersCount + 50);
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      {!loadingDefaults && (
        <Formik
          initialValues={defaults}
          validationSchema={JourneyGroupSchema}
          onSubmit={(values) => submitClick(values)}
        >
          {({ handleSubmit, setFieldValue, values, isValid }) => (
            <React.Fragment>
              <ModalHeaderWithActions
                title={editGroupId ? "Edit Category" : "Add Category"}
                submitLabel={"Save"}
                closeModal={onClose}
                onSubmit={handleSubmit}
                disableSubmit={!isValid}
              />
              <ModalBody scrollable={false}>
                <div style={{ padding: " 0 20px" }}>
                  <Label htmlFor="name">Cadence Category Name</Label>
                  <Field
                    component={FormField}
                    id="name"
                    name="name"
                    type="text"
                  />
                  <CharacterCounter
                    limit={120}
                    count={values.name ? values.name.length : 0}
                  />
                  <div style={{ color: "red", marginTop: "0.5rem" }}>
                      <ErrorMessage name="name" />
                  </div>
                  <Label htmlFor="isDxo">Is this Category associated with a DX program?</Label>
                  <StyledBooleanRadio
                    name="isDxo"
                    label="Is this Category associated with a DX program?"
                    onChange={(_name, v) => setFieldValue("isDxo", v)}
                    value={values.isDxo}
                  />
                  <Label htmlFor="users">Cadence Category Users</Label>
                  <FieldArray name="usersToAdd">
                    {({ insert, remove, push }) => (
                      <React.Fragment>
                        <SearchBox
                          placeholder={"Add new user"}
                          defaultValue=""
                          onChange={(v) => handleSearchInputChange(v, values)}
                          onSelect={(user) => {
                            push(user);
                          }}
                          searchResults={users}
                        />
                        <div>
                          {values.usersToAdd &&
                            values.usersToAdd.length > 0 && (
                              <React.Fragment>
                                <Label htmlFor="usersToAdd">
                                  Users To Add:
                                </Label>
                                {values.usersToAdd.map((user, index) => (
                                  <ExistingUser
                                    onClick={() => remove(index)}
                                    key={user.id}
                                    name={user.name}
                                  />
                                ))}
                              </React.Fragment>
                            )}
                        </div>
                      </React.Fragment>
                    )}
                  </FieldArray>
                  <FieldArray name="existingJourneyGroupUsers">
                    {({ insert, remove, push }) => (
                      <div>
                        {values.existingJourneyGroupUsers &&
                          values.existingJourneyGroupUsers.length > 0 && (
                            <React.Fragment>
                              <Label htmlFor="existingJourneyGroupUsers">
                                Users In This Category:
                              </Label>
                              {values.existingJourneyGroupUsers
                                .slice(0, visibleUsersCount)
                                .map((journeyGroupUser, index) => (
                                  <ExistingUser
                                    key={journeyGroupUser.id}
                                    onClick={async () => {
                                      await removeUserFromJourneyGroup(
                                        journeyGroupUser
                                      );
                                      remove(index);
                                    }}
                                    name={journeyGroupUser.user.name}
                                  />
                                ))}
                              {values.existingJourneyGroupUsers.length >
                                visibleUsersCount && (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "15px",
                                  }}
                                >
                                  <Button
                                    aria-label="See more users"
                                    type="secondary"
                                    onClick={handleShowMoreUsers}
                                  >
                                    See More...
                                    </Button>
                                </div>
                              )}
                            </React.Fragment>
                          )}
                      </div>
                    )}
                  </FieldArray>
                </div>
              </ModalBody>
            </React.Fragment>
          )}
        </Formik>
      )}
    </Modal>
  );
};

JourneyGroupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  group: PropTypes.object,
  editGroup: PropTypes.func,
  createGroup: PropTypes.func,
};

JourneyGroupModal.defaultProps = {
  group: {},
  editGroup: () => {},
  createGroup: () => {},
};

FormField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

FormField.defaultProps = {
  field: {},
  form: {},
};

export default JourneyGroupModal;
