import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ProgressBar } from "@evertrue/et-components";
import {
  Centered,
  Subtext,
} from "apps/importer/common/importer-styled-components";
import { colors } from "style/colors";

const BAR_WIDTH = "350px";

export const ImportProgressBar = ({ percent }) => {
  return (
    <React.Fragment>
      <Centered>
        <ProgressBar
          percent={percent}
          width={BAR_WIDTH}
          backgroundColor={colors.white}
          progressColor={colors.accent}
        />
      </Centered>
      <Centered>
        <Subtext>{`${percent}% Complete`}</Subtext>
      </Centered>
    </React.Fragment>
  );
};

export const ProgressPlaceHolder = styled.div`
  height: 40px;
`

ImportProgressBar.propTypes = {
  percent: PropTypes.number,
};

ImportProgressBar.defaultProps = {
  percent: 0,
};
