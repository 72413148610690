import React from "react";
import _ from "underscore";
import { Redirect } from "react-router-dom";
import { GateContext } from "apps/dna/gate-context";
import { AuthContext } from "scripts/auth-connector";

const RedirectController = ({ gate }) => {
  return (
    <AuthContext.Consumer>
      {({ user_id }) => {
        return (
          <GateContext.Consumer>
            {({ gates = {}, loading }) => {
              const parsedGates = _.mapObject(
                gates,
                (gate) => !!(gate && gate.enabled)
              );

              const loaded = !loading && user_id && !_.isEmpty(parsedGates);

              // Adjust landing page based on gating
              let redirect_url;
              if (parsedGates.importer_v3) {
                redirect_url = "/importer";
              } else if (parsedGates.cdc_profile) {
                redirect_url = "/cdc-manager";
              } else if (parsedGates.emma) {
                redirect_url = "/integrations/emma";
              } else {
                redirect_url = "/constituents";
              }

              return loaded ? <Redirect to={redirect_url} /> : null;
            }}
          </GateContext.Consumer>
        );
      }}
    </AuthContext.Consumer>
  );
};

export default RedirectController;
