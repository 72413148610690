import Api from "utils/api";

export const fetchPools = (type = "TEAM") =>
  Api.ASSIGNMENTS.POOLS.get({
    params: {
      pool_type: type,
      limit: 10000,
    },
  });

export const createPool = (data) =>
  Api.ASSIGNMENTS.POOLS.post({
    data: data,
  });

export const updatePool = (id, data) =>
  Api.ASSIGNMENTS.POOLS.put({
    urlExtend: `/${id}`,
    data: data,
  });

export const deletePool = (id) =>
  Api.ASSIGNMENTS.POOLS.delete({
    urlExtend: `/${id}`,
  });
