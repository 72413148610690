import React from "react";
import T from "prop-types";
import _ from "underscore";
import str from "underscore.string";
import uuid from "uuid/v4";
import { ModalForm, Checkbox } from "@evertrue/et-components";
import { FormWrapper, Label } from "style/components/form";
import {
  StatusSection,
  CustomTypesError,
} from "apps/custom-types/components/custom-type-modal-style";

function getEmptyData(defaultCategories, fieldName) {
  let data = {
    [fieldName]: "",
    active: true,
    categories: defaultCategories,
  };

  return data;
}

const CustomTypeModal = (props) => (
  <ModalForm
    {...props}
    onClose={props.onClose}
    width={500}
    data={
      _.isEmpty(props.customType)
        ? getEmptyData(props.defaultCategories, props.fieldName)
        : props.customType
    }
    disableFunc={props.disableFunc}
    handleSave={props.handleSave}
    label={`${str.capitalize(props.fieldName)} Type`}
    render={({ state, setState, errors }) => (
      <FormWrapper>
        <Label htmlFor="name">Name</Label>
        <input
          id="name"
          type="text"
          name="name"
          autoComplete="off"
          value={state[props.fieldName]}
          onChange={({ target: { value } }) =>
            setState({ [props.fieldName]: value })
          }
        />
        {!_.isEmpty(errors) && <CustomTypesError>{errors}</CustomTypesError>}
        <StatusSection>
          <Checkbox
            label="active status"
            checked={state.active === true}
            onChange={() => setState({ active: !state.active })}
          >
            <div>
              <strong>Active</strong>
              <div>
                Enable gift officers and admins to update and create records
                using this {props.fieldName}.
              </div>
            </div>
          </Checkbox>
          {props.categories.map((category) => (
            <div key={uuid()} style={{ marginTop: 10 }}>
              <Checkbox
                label={category.display_name}
                checked={state.categories.some(
                  (existingCategory) =>
                    existingCategory.category === category.category
                )}
                onChange={(checked) => {
                  if (checked) {
                    setState((prev) => ({
                      categories: [
                        {
                          display_name: category.display_name,
                          category: category.category,
                        },
                        ...prev.categories,
                      ],
                    }));
                  } else {
                    setState((prev) => ({
                      categories: prev.categories.filter(
                        (existing) => existing.category !== category.category
                      ),
                    }));
                  }
                }}
              >
                <div>
                  <strong>{category.display_name}</strong>
                  <div>{category.description}</div>
                </div>
              </Checkbox>
            </div>
          ))}
        </StatusSection>
      </FormWrapper>
    )}
  />
);

CustomTypeModal.propTypes = {
  defaultCategories: T.array,
  categories: T.array,
  onClose: T.func,
  customType: T.object,
  disableFunc: T.func,
  handleSave: T.func,
  fieldName: T.string.isRequired,
};

CustomTypeModal.defaultProps = {
  defaultCategories: [],
  categories: [],
  onClose: () => null,
  customType: {},
  disableFunc: () => null,
  handleSave: () => null,
  fieldName: "",
};

export default CustomTypeModal;
