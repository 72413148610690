import _ from "underscore";
import { Component } from "react";
import T from "prop-types";
import * as StageService from "apps/stages/stages-service";

class StageGroupResolver extends Component {
  static propTypes = {
    groupId: T.number,
    groupType: T.string,
  };

  static defaultProps = {
    groupId: null,
    groupType: null,
  };

  state = {
    loading: false,
    stageGroups: [],
  };

  componentDidMount() {
    this.fetchStageGroups();
  }

  fetchStageGroups = async () => {
    this.setState({ loading: true });
    const { data } = await StageService.fetchStageGroups(this.props.groupType);
    this.setState({ stageGroups: data, loading: false });
  };

  render() {
    const stageGroup =
      _.findWhere(this.state.stageGroups, { id: this.props.groupId }) || {};
    return this.props.children({
      stageGroups: this.state.stageGroups,
      stageGroup: stageGroup,
      loading: this.state.loading,
    });
  }
}

export default StageGroupResolver;
