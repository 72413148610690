import React from "react";
import { Link } from "react-router-dom";
import { colors } from "style/colors";
import { Flex } from "../../../../../../../node_modules/@evertrue/et-components/dist/index";

const buttonStyle = {
  float: "right",
  fontSize: "16px",
  lineHeight: "40px",
  color: `${colors.linkText}`,
  textDecoration: "underline",
  paddingRight: "15px",
}

const noticeStyle = {
  paddingLeft: "50px",
  paddingTop: "15px", 
  color: `${colors.red}`,
  display: "inline-block",
  width: "100%",
}

export const MappingButtonViewOnly = ({ jobId }) => {
  return (
    <Link to={ "/importer/" + jobId + "/import-mapping-layout-view-only" } style={buttonStyle} onClick={() => window.mixpanel.track("Importer View Mapping", {})}>
      <span>View mapping</span>
    </Link>
  );
};

export const MappingButtonBack = ({ jobId }) => {
  return (
    <Link to={ "/importer/" + jobId + "/import-preprocess" } style={buttonStyle}>
      <span>Close mapping</span>
    </Link>
  );
};

export const MappingViewOnlyNotice = () =>{
  return (
    <Flex style={noticeStyle}>
      Note: This is a view only page. No modifications can be made at this time. Select "Close mapping" to view current status
    </Flex>
  );
};