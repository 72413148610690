import styled from "styled-components";
import React, { Component } from "react";
import T from "prop-types";
import {
  Switch,
  AdvancedCombobox,
  Icon,
  ModalWrapper,
} from "@evertrue/et-components";
import _ from "underscore";

import {
  Table,
  TableBody,
  PlainButton,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableEmptyState,
} from "style/components";
import { colors } from "style/colors";
import { titleCase } from "utils/utils";
import { availableProducts } from "apps/custom-fields/custom-fields-utils";
import ConstituentFieldModal from "../components/constituent-field-modal";

class ConstituentFieldsTable extends Component {
  state = {
    isModalOpen: false,
    selectedField: {},
  };

  static propTypes = {
    filteredCustomFields: T.array,
    onDelete: T.func,
    handleUpdate: T.func,
  };

  static defaultProps = {
    filteredCustomFields: [],
    onDelete: _.noop,
    handleUpdate: _.noop,
  };

  handleToggleModal = (selectedField = {}) => {
    this.setState({
      isModalOpen: !this.state.isModalOpen,
      selectedField,
    });
  };

  renderRow = (customField = {}, handleUpdate) => (
    <TableRow key={customField.id}>
      <TableCell>
        <NameButton onClick={() => this.handleToggleModal(customField)}>
          {customField.description}{" "}
        </NameButton>
      </TableCell>
      <TableCell>{titleCase(customField.typeLabel)}</TableCell>
      <TableCell>
        <AdvancedCombobox
          options={availableProducts}
          value={availableProducts.filter((obj) =>
            _.contains(customField.app_keys, obj.value)
          )}
          onChange={(value) =>
            handleUpdate({ ...customField, app_keys: _.pluck(value, "value") })
          }
          placeholder="0 Selected"
          multiple
        />
      </TableCell>
      <TableCell>
        <Switch
          onChange={(visible) => handleUpdate({ ...customField, visible })}
          name={`visible-${customField.id}`}
          checked={customField.visible}
        />
      </TableCell>
      <TableCell>
        <Switch
          onChange={(filterable) =>
            handleUpdate({ ...customField, filterable })
          }
          name={`filterable-${customField.id}`}
          checked={customField.filterable}
        />
      </TableCell>
      <TableCell>
        <EditButton onClick={() => this.handleToggleModal(customField)}>
          <Icon icon="edit" />
        </EditButton>
      </TableCell>
    </TableRow>
  );

  render() {
    const { filteredCustomFields, handleUpdate, onDelete } = this.props;

    return (
      <Table>
        <TableHeader>
          <TableHeaderCell>Display Name</TableHeaderCell>
          <TableHeaderCell>Field Type</TableHeaderCell>
          <TableHeaderCell>Products</TableHeaderCell>
          <TableHeaderCell>Profile Display</TableHeaderCell>
          <TableHeaderCell>Filtering</TableHeaderCell>
          <TableHeaderCell />
        </TableHeader>
        <TableBody>
          {!filteredCustomFields.length ? (
            <TableEmptyState text="No results found" />
          ) : (
            filteredCustomFields.map((field) =>
              this.renderRow(field, handleUpdate, onDelete)
            )
          )}
        </TableBody>
        <ModalWrapper
          isOpen={this.state.isModalOpen}
          onClose={this.handleToggleModal}
          width={500}
          render={({ close }) => (
            <ConstituentFieldModal
              close={close}
              fieldData={this.state.selectedField}
              fieldType="constituents"
              onDelete={onDelete}
              onSubmit={(fieldData) => handleUpdate(fieldData)}
            />
          )}
        />
      </Table>
    );
  }
}

const NameButton = styled(PlainButton)`
  color: ${colors.linkText};

  &:hover {
    color: ${colors.accent};
  }
`;

const EditButton = styled(PlainButton)`
  color: ${colors.lightText};
  font-size: 18px;

  &:hover {
    color: ${colors.linkText};
  }
`;

export default ConstituentFieldsTable;
