import React from "react";
import PropTypes from "prop-types";
import _ from "underscore";
import { useMultiColumnConfigState } from "components/column-config-editor/column-config-editor-utils";
import ColumnConfigEditor from "components/column-config-editor/column-config-editor";
import * as ProposalService from "../proposal-service";
import Module from "components/module";
import { TopRowWrapper } from "components/column-config-editor/column-config-editor-style";
import { Button, useOpenClose } from "@evertrue/et-components";
import { launchToast } from "utils/show-toast";
import ProposalCustomFieldValueModal from "apps/proposals/components/proposal-custom-field-value-modal";

const ProposalCustomFieldValuesController = (props) => {
  const {
    data: values,
    setData: setValues,
    loading,
    itemToEdit: item_to_edit,
    fetchData: fetchValues,
    setItemToEdit,
    itemsHaveChanged: items_have_changed,
  } = useMultiColumnConfigState({
    keysToDiff: ["active", "sort_order"],
    initialDataState: [],
    fetchDataFunc: _.partial(
      ProposalService.fetchProposalCustomFieldPickListValues,
      props.customFieldId
    ), // todo: pass id to useEffect
    useEffectDependencies: [props.customFieldId],
  });
  const [is_modal_open, openModal, closeModal] = useOpenClose();

  const handleDeleteItem = async (item) => {
    await ProposalService.deleteProposalCustomFieldPickListValue(item.id);
    launchToast({ message: "Value deleted" });
    fetchValues();
  };
  const handleSave = async () => {
    await ProposalService.updateMultipleProposalCustomFieldPickListValues(
      props.customFieldId,
      values
    );
    fetchValues();
    launchToast({ message: "Value saved" });
  };

  const active_values = values.filter((i) => i.active);
  const inactive_values = values.filter((i) => !i.active);
  return (
    <Module css="margin: 24px;">
      <TopRowWrapper>
        <Button onClick={openModal}>New Value</Button>
        <ProposalCustomFieldValueModal
          onClose={() => {
            if (item_to_edit) setItemToEdit(null);
            closeModal();
          }}
          onSuccess={async () => {
            await fetchValues();
            launchToast({
              message: `Custom Field Value ${
                item_to_edit ? "Updated" : "Added"
              }`,
            });
          }}
          customFieldId={props.customFieldId}
          customFieldValue={item_to_edit}
          isOpen={item_to_edit || is_modal_open}
          customFieldValues={values}
        />
      </TopRowWrapper>
      <ColumnConfigEditor
        loading={loading}
        onChange={({ listOne, listTwo }) => {
          setValues([...listOne, ...listTwo]);
        }}
        searchKey="value"
        onEdit={setItemToEdit}
        handleSave={handleSave}
        onDelete={handleDeleteItem}
        disableSave={!items_have_changed}
        disableCancel={!items_have_changed}
        handleCancel={fetchValues}
        listOne={{ label: "Inactive Values", items: inactive_values }}
        listTwo={{ label: "Active Values", items: active_values }}
      />
    </Module>
  );
};

ProposalCustomFieldValuesController.propTypes = {
  customFieldId: PropTypes.number.isRequired,
};

export default ProposalCustomFieldValuesController;
