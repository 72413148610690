import React from "react";
import PageLayout from "apps/layout/components/page-layout";
import { Redirect, Route, Switch } from "react-router-dom";
import CustomFieldsConstituentsController from "./controllers/constituents-custom-fields-controller";

const CustomFieldsController = () => (
  <PageLayout
    title={"Constituents | Custom Fields"}
    subtitle="Customize constituent fields"
  >
    <Switch>
      <Route
        path="/constituents/custom-fields"
        render={() => <CustomFieldsConstituentsController />}
      />
      <Route
        path="/constituents"
        render={() => <Redirect to="/constituents/custom-fields" />}
      />
    </Switch>
  </PageLayout>
);

export default CustomFieldsController;
