import React from "react";
import _ from "underscore";
import { ErrorMessage } from "formik";
import { AdvancedCombobox, Switch } from "@evertrue/et-components";
import ModalEditor from "components/modal-editor";
import * as ProposalService from "../proposal-service";
import { FormField, FormRow, HintText } from "style/components/form";
import { getIsFieldPickList } from "apps/custom-fields/custom-fields-utils";
// property names slightly differ from interaction custom fields so not reusing that field type array
const FIELD_TYPES = [
  { value: "DATE", label: "Date" },
  { value: "LONG", label: "Number" },
  { value: "DOUBLE", label: "Currency" },
  { value: "BOOLEAN", label: "Boolean" },
  {
    value: "STRING:FREEFORM",
    data_type: "STRING",
    ui_control_type: "FREEFORM",
    label: "String - Freeform",
  },
  {
    value: "STRING:PICKLIST",
    data_type: "STRING",
    ui_control_type: "DROPDOWN",
    label: "String - Pick list",
  },
];

const ProposalCustomFieldModal = ({
  customField,
  customFields,
  onSuccess,
  ...rest
}) => {
  return (
    <ModalEditor
      {...rest}
      header={`${customField ? "Edit" : "Add"} Proposal Custom Field`}
      initialValues={
        !customField ? { display_name: "", active: true } : customField
      }
      validate={(values) => {
        let errors = {};
        const is_dupe = customFields.find(
          (existingField) => existingField.display_name === values.display_name
        );
        if (!values.display_name) errors = { display_name: true };
        if (!customField && is_dupe) {
          errors = { display_name: "Duplicate custom field names not allowed" };
        }
        if (!values.data_type)
          errors = { ...errors, data_type: "You must select a data type" };
        if (customField && _.isEqual(customField, values))
          errors = { ...errors, no_change: true };
        return errors;
      }}
      onSubmit={(values) => {
        const payload = {
          ...values,
          // change STRING:FREEFORM to STRING b/c that is how API expects it
          data_type: values.data_type.includes("STRING")
            ? "STRING"
            : values.data_type,
        };

        if (!customField) {
          ProposalService.createProposalCustomField({
            ...payload,
            sort_order: customFields.length + 1,
          }).then(onSuccess);
        } else {
          ProposalService.updateProposalCustomField(payload).then(onSuccess);
        }
      }}
    >
      <FormRow>
        <FormField>
          <ModalEditor.FormField
            label="Custom Field Name"
            name="display_name"
          />
          <ErrorMessage name="display_name" />
        </FormField>
        <FormField>
          <ModalEditor.FormField name="data_type" label="Field Type">
            {({ field, form }) => (
              <AdvancedCombobox
                disabled={Boolean(customField)}
                onChange={(val) => {
                  form.setFieldValue("data_type", val.value);
                  form.setFieldValue("ui_control_type", val.ui_control_type);
                }}
                options={FIELD_TYPES}
                value={{ value: field.value }}
              />
            )}
          </ModalEditor.FormField>
        </FormField>
      </FormRow>
      <ModalEditor.FormField label="Required" name="required">
        {({ field, form }) => (
          <React.Fragment>
            <HintText>Required Field on Interaction form</HintText>
            <Switch
              label="required"
              disabled={
                customField &&
                getIsFieldPickList(
                  customField.data_type,
                  customField.ui_control_type
                ) &&
                customField.values.filter((field) => field.active).length
              }
              onChange={(val) => form.setFieldValue("required", val)}
              checked={field.value}
            />
          </React.Fragment>
        )}
      </ModalEditor.FormField>
    </ModalEditor>
  );
};

ProposalCustomFieldModal.propTypes = {};

export default ProposalCustomFieldModal;
