import React, { Component } from "react";
import ComingSoon from "../../components/coming-soon";

class Notifications extends Component {
  render() {
    return <ComingSoon />;
  }
}

export default Notifications;
