import Api from "utils/api";
import { launchToast } from "utils/show-toast";

export const getLogFile = async (fileType, fileName, workerType, jobId) => {
  try {
    const response = await Api.IMPORT.IMPORT_LOGS.get({
      urlArgs: { job_id: jobId },
      params: { file_type: fileType, worker_type: workerType },
    });

    const { s3_url } = response.data;
    if (s3_url) {
      let link = document.createElement("a");
      link.download = fileName || "";
      link.href = s3_url;
      let evt = document.createEvent("MouseEvents");
      evt.initMouseEvent(
        "click",
        true,
        true,
        window,
        1,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      link.dispatchEvent(evt);
    }
  } catch (error) {
    launchToast({
      message: error,
      type: "error",
    });
  }
};
