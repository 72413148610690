import Api from "utils/api";

const extractData = (fn) => fn.then((res) => res.data);

export const fetchProposalStages = (cancelToken) =>
  extractData(
    Api.PROPOSAL.STAGES.get({
      params: { includeInactive: true },
      cancelToken,
    })
  );

export const updateMultipleProposalStages = (data) =>
  Api.PROPOSAL.STAGES.put({ data });

export const updateProposalStage = (data) =>
  Api.PROPOSAL.STAGES.put({ data, urlExtend: `/${data.id}` });

export const createProposalStage = (data) => Api.PROPOSAL.STAGES.post({ data });

export const deleteProposalStage = (id) =>
  Api.PROPOSAL.STAGES.delete({ urlExtend: `/${id}` });

export const fetchProposalCustomFields = (cancelToken) =>
  extractData(
    Api.PROPOSAL.CUSTOM_FIELDS.get({
      cancelToken,
      params: { include_inactive: true },
    })
  );

export const updateMultipleProposalCustomFields = (data) =>
  Api.PROPOSAL.CUSTOM_FIELDS.put({ data });

export const updateProposalCustomField = (data) =>
  Api.PROPOSAL.CUSTOM_FIELDS.put({ data, urlExtend: `/${data.id}` });

export const createProposalCustomField = (data) =>
  Api.PROPOSAL.CUSTOM_FIELDS.post({ data });

export const deleteProposalCustomField = (id) =>
  Api.PROPOSAL.CUSTOM_FIELDS.delete({ urlExtend: `/${id}` });

export const fetchProposalCustomFieldPickListValues = (
  customFieldId,
  cancelToken
) =>
  extractData(
    Api.PROPOSAL.CUSTOM_FIELD_PICKLIST_VALUES.get({
      cancelToken,
      urlArgs: { customFieldId },
      params: { include_inactive: true, limit: 300 },
    })
  ).then((r) => r.items); // returns a pagignated list while other endpoints do not

export const updateMultipleProposalCustomFieldPickListValues = (
  customFieldId,
  data
) =>
  Api.PROPOSAL.CUSTOM_FIELD_PICKLIST_VALUES.put({
    data,
    urlArgs: { customFieldId },
  });

export const updateProposalCustomFieldPickListValue = (customFieldId, data) =>
  Api.PROPOSAL.CUSTOM_FIELD_PICKLIST_VALUES.put({
    data,
    urlArgs: { customFieldId },
    urlExtend: `/${data.id}`,
  });

export const createProposalCustomFieldPickListValue = (customFieldId, data) =>
  Api.PROPOSAL.CUSTOM_FIELD_PICKLIST_VALUES.post({
    data,
    urlArgs: { customFieldId },
  });

export const deleteProposalCustomFieldPickListValue = (
  customFieldId,
  picklistValueId
) =>
  Api.PROPOSAL.CUSTOM_FIELD_PICKLIST_VALUES.delete({
    urlArgs: { customFieldId },
    urlExtend: `/${picklistValueId}`,
  });
