import React from "react";
import PropTypes from "prop-types";
import SideNavLink from "./side-nav-link";
import ApiStatus from "./api-status";
import StatusIndicator from "./status-indicator";
import IsGated, { useGate } from "apps/dna/is-gated";
import IsFlagged from "scripts/apps/launch-darkly/is-flagged";
import IsSuperUser from "apps/auth/is-super-user";
import styled from "styled-components";
import { colors } from "style/colors";
import UserHasRole from "apps/auth/user-has-role";
import ReturnToCore from "./back-to-core";
import { shouldAllowImporterNavigation } from "../../../route-utils";
import EnvSwitcherPill from "components/env-switch-pill";

// Placeholder sections are commented out for now,
// but maintained since paths, controllers are built

export const SideNavWrapper = styled.div`
  background-color: ${colors.darkBase};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  height: 100%;
  overflow-y: auto;
  width: 235px;
`;

export const List = styled.div`
  list-style: none;
  font-size: 14px;
  overflow-x: hidden;
  padding: 8 0px;
`;

export const Divider = styled.div`
  padding: 6px 20px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  margin-top: 16px;
  color: hsla(0, 0%, 100%, 0.5);
`;

const StatusIndicators = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 15px;
  min-height: 200px;
`;

const SideNav = ({ super_user }) => {
  const { show: proposal_writes_enabled } = useGate("proposal_writes");
  const { show: proposal_custom_fields_enabled } = useGate(
    "proposal_custom_fields"
  );
  const { show: importer_enabled } = useGate("importer_v3");

  return (
    <SideNavWrapper>
      <List>
        <ReturnToCore />
        <Divider>Data Management</Divider>

        {shouldAllowImporterNavigation({ super_user, importer_enabled }) ? (
          <SideNavLink
            to="/importer"
            icon="upload-contact"
            isActive={(match, location) =>
              location.pathname.includes("importer")
            }
          >
            Import Data
          </SideNavLink>
        ) : null}

        <IsGated gate="cdc_profile">
          <SideNavLink
            to="/cdc-manager"
            icon="build"
            isActive={(match, location) =>
              location.pathname.includes("cdc-manager")
            }
          >
            Review Contact Updates
          </SideNavLink>
        </IsGated>

        <IsSuperUser>
          <Divider>Configuration</Divider>
          <SideNavLink
            to="/constituents/custom-fields"
            icon="person"
            isActive={(match, location) =>
              location.pathname.includes("constituents")
            }
          >
            Constituents
          </SideNavLink>
          <IsGated gate="ugc_show_imported_notes">
            <SideNavLink
              to="/interactions/custom-fields"
              icon="interactions"
              isActive={(match, location) =>
                location.pathname.includes("interactions")
              }
            >
              Interactions
            </SideNavLink>
          </IsGated>

          <IsGated gate="rm_assignments">
            <SideNavLink
              to="/relationship-management/teams"
              icon="insert-chart"
              isActive={(match, location) =>
                location.pathname.includes("relationship-management")
              }
            >
              Relationship Management
            </SideNavLink>
          </IsGated>

          <IsGated gate="volunteers">
            <SideNavLink
              to="/volunteers/stages"
              icon="group"
              isActive={(match, location) =>
                location.pathname.includes("volunteers")
              }
            >
              Volunteers
            </SideNavLink>
          </IsGated>
          {(proposal_writes_enabled || proposal_custom_fields_enabled) && (
            <SideNavLink
              to="/proposals"
              icon="other-interaction"
              isActive={(match, location) =>
                location.pathname.includes("proposals")
              }
            >
              Proposals
            </SideNavLink>
          )}
          <SideNavLink
            to="/enable-signal"
            icon="settings"
            isActive={(match, location) =>
              location.pathname.includes("enable-signal")
            }
          >
            Configure Signal
          </SideNavLink>
        </IsSuperUser>
        <IsGated gate="integrations">
          <UserHasRole roles={["Admin", "Console User"]}>
            <Divider>Settings</Divider>
            <SideNavLink
              to="/integrations"
              icon="settings"
              isActive={(match, location) =>
                location.pathname.includes("integrations")
              }
            >
              Integrations
            </SideNavLink>
          </UserHasRole>
        </IsGated>
        <IsFlagged feature="signal">
          <SideNavLink
            to="/cadences"
            icon="cadences"
            iconKit="gt2"
            isActive={(match, location) =>
              location.pathname.includes("cadences")
            }
          >
            Cadences
          </SideNavLink>
          <SideNavLink
            to="/cadencecategories"
            icon="users"
            isActive={(match, location) =>
              location.pathname.includes("cadencecategories")
            }
          >
            Cadence Categories
          </SideNavLink>
        </IsFlagged>

        {/*Disable this for now*/}
        {false && (
          <React.Fragment>
            <Divider>Settings</Divider>
            <SideNavLink to="/notifications" icon="notifications">
              Notifications
            </SideNavLink>
          </React.Fragment>
        )}
      </List>
      <StatusIndicators>
        <EnvSwitcherPill />
        <ApiStatus />
        <StatusIndicator />
      </StatusIndicators>
    </SideNavWrapper>
  );
};

SideNav.propTypes = {
  super_user: PropTypes.bool,
};

SideNav.defaultProps = {
  super_user: false,
};

export default SideNav;
