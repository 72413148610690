import _ from "underscore";
import React from "react";
import Api from "utils/api";
import styled from "styled-components";
import ModalEditor from "../../../components/modal-editor";

const EmmaReauthModal = ({ account, refresh, onSuccess, onError, ...rest }) => {
  return (
    <ModalEditor
      {...rest}
      saveLabel="Save"
      isInitialValid={false}
      initialValues={{ public_api_key: "", private_api_key: "" }}
      header="Update Account Credentials"
      validate={(value) => {
        if (!_.every(value, _.identity)) return { errors: true };
        return {};
      }}
      onSubmit={(value) =>
        Api.EMMA.ACCOUNT_CREDS.put({
          urlArgs: { id: account.id },
          disableAlerts: true,
          data: value,
        })
          .then(onSuccess)
          .then(refresh)
          .catch(onError)
      }
    >
      {account.invalidated_at && (
        <InfoMessage>
          Your Emma account credentials are no longer valid. Please
          re-authenticate with your API keys from Emma.
        </InfoMessage>
      )}
      <ModalEditor.FormField
        type="text"
        autoComplete="off"
        label="Public API Key"
        name="public_api_key"
      />
      <ModalEditor.FormField
        type="text"
        autoComplete="off"
        label="Private API Key"
        name="private_api_key"
      />
    </ModalEditor>
  );
};

const InfoMessage = styled.div`
  background-color: #e2ebed;
  font-size: 14px;
  border-radius: 2px;
  padding: 8px;
  line-height: 1.5;
  white-space: pre-wrap;
  margin: 0px -10px 10px;
`;

export default EmmaReauthModal;
