import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import Api from "utils/api";
import {
  BodyWrapper,
} from "apps/importer/common/importer-styled-components";
import { DetailListButton } from "apps/importer/common/components/detail-list-button";
import { MappingContainer } from "apps/importer/components/import-mapping/mapping-containers/shared-styles";
import { useDragAndDrop } from "apps/importer/components/import-mapping/hooks/use-drag-and-drop";
import { useSchema } from "apps/importer/components/import-mapping/hooks/use-schema";
import { useImportMapping } from "apps/importer/components/import-mapping/hooks/use-import-mapping";
import { MappedContainer } from "apps/importer/components/import-mapping/mapping-containers/mapped-container";
import { ExcludedContainer } from "apps/importer/components/import-mapping/mapping-containers/excluded-container";
import { UnmappedContainer } from "apps/importer/components/import-mapping/mapping-containers/unmapped-container";
import { MappingButtonBack, MappingViewOnlyNotice } from "./mapping-view-only/mapping-view-only-buttons";
import { useTracking } from "apps/importer/hooks/use-tracking";
import { Button, useOpenClose } from "@evertrue/et-components";
import { launchToast } from "utils/show-toast";

import CancelImportModal from "apps/importer/components/import-preprocess/cancel-import/cancel-import-modal.js";

import { BoldText } from "apps/importer/common/importer-styled-components";
import {
  FlexDiv,
  StyledLinkButton,
  FloatRightDiv,
} from "apps/importer/components/import-mapping/mapping-containers/shared-styles";

const ImportMappingLayout = withRouter(({ history, match, viewOnly = false }) => {
  useTracking({ history, trackingString: "Import Mapping Page" });
  const jobId = match.params.jobId;
  const {
    addField,
    addSection,
    removeField,
    removeSection,
    addLinkedField,
    removeLinkedField,
    schema,
    excludedImportColumns,
    unmappedImportColumns,
    display_name,
    footnotes,
    autoMap,
    setAutoMap,
    unmapValue,
    unmapExcluded,
    onDragEndCallback,
    excludeAllUnmappedItems,
    unmapAllExcludedItems,
  } = useSchema({
    jobId,
    viewOnly,
  });

  const [isOpen, openModal, closeModal] = useOpenClose();

  const {
    draggableItems,
    fieldColumns,
    unmappedItems,
    excludedItems,
    onDragEnd,
  } = useDragAndDrop({
    schema,
    excludedImportColumns,
    unmappedImportColumns,
    jobId,
    onDragEndCallback,
  });

  const { handleContinueClick } = useImportMapping({
    fieldColumns,
    history,
    jobId,
  });

  const cancelImport = async () => {
    try {
      await Api.IMPORT.IMPORT_JOB_CANCEL.post({
        urlArgs: { job_id: jobId },
      });
    } catch (error) {
      launchToast({
        message: `Unable to cancel import for job:${jobId}`,
        type: "error",
      });
    }
    history.push("/importer");
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <DetailListButton />
        {viewOnly && (<MappingButtonBack jobId={jobId} />)}

      </div>
      {viewOnly && (<MappingViewOnlyNotice />)}
      <BodyWrapper>
        <div>
          <FlexDiv>
            <BoldText>
              Import Template: <Title>{display_name}</Title>
            </BoldText>
            {!viewOnly && (
              <FloatRightDiv>
                <StyledLinkButton
                  title="Unmap All"
                  type="simple"
                  onClick={() => {
                    setAutoMap(false);
                  }}
                  disabled={autoMap === false}
                >
                  Unmap
                </StyledLinkButton>
                <Separator>|</Separator>
                <StyledLinkButton
                  title=" Auto Map"
                  type="simple"
                  onClick={() => {
                    setAutoMap(true);
                  }}
                  disabled={autoMap === true}
                >
                  {" Automap"}
                </StyledLinkButton>
              </FloatRightDiv>
            )}
          </FlexDiv>
          <MappedContainer
            display_name={display_name}
            setAutoMap={setAutoMap}
            autoMap={autoMap}
            schema={schema}
            fieldColumns={fieldColumns}
            addField={addField}
            removeField={removeField}
            addLinkedField={addLinkedField}
            removeLinkedField={removeLinkedField}
            draggableItems={draggableItems}
            unmapValue={unmapValue}
            addSection={addSection}
            removeSection={removeSection}
            footnotes={footnotes}
            viewOnly={viewOnly}
          />
        </div>
        <div>
          <FlexDiv>
            <BoldText>Unmapped Fields</BoldText>
            {!viewOnly && (
              <StyledLinkButton
                title="Skip All"
                type="simple"
                onClick={excludeAllUnmappedItems}
                disabled={unmappedItems && unmappedItems.length === 0}
              >
                Skip all
              </StyledLinkButton>
            )}
          </FlexDiv>
          <MappingContainer>
            <UnmappedContainer
              unmappedItems={unmappedItems}
              autoMap={autoMap}
            />
            <ExcludedContainer
              excludedItems={excludedItems}
              unmapExcluded={unmapExcluded}
              unmapAllExcludedItems={unmapAllExcludedItems}
              viewOnly={viewOnly}
            />
          </MappingContainer>
          {!viewOnly && (<ButtonGroup>
            <Button type="secondary" onClick={isOpen ? closeModal : openModal}>
              Cancel
            </Button>
            <Button onClick={handleContinueClick}>
              Continue
            </Button>
          </ButtonGroup>
          )}
          <CancelImportModal
            isOpen={isOpen}
            closeModal={closeModal}
            cancelImport={cancelImport}
          ></CancelImportModal>
        </div>
      </BodyWrapper>
    </DragDropContext>
  );
});

const Title = styled.span`
  font-weight: 400;
`;

const Separator = styled.span`
  margin-right: 8px;
`;

export const ButtonGroup = styled.div`
  margin-top: 14px;
  float: right;
`;

export default ImportMappingLayout;
