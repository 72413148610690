import React from "react";
import _ from "underscore";
import T from "prop-types";
import {
  AlertActions,
  Button,
  ModalTriggerDeprecated,
  Portal,
} from "@evertrue/et-components";
import ColumnConfigEditor from "components/column-config-editor/column-config-editor";
import * as AssignmentTitlesService from "apps/relationship-management/services/assignment-titles-service";
import AssignmentTitleModal from "apps/relationship-management/components/assignment-title-modal";
import { StyledEmptyStateMessage } from "components/column-config-editor/column-config-editor-style";
import {
  Container,
  TypeWrapper,
  Unsaved,
} from "apps/relationship-management/controllers/assignment-titles-controller-style";
import Module from "components/module";
import AddFromEsModal from "apps/relationship-management/components/add-from-es-modal";
import { getDiffedItems } from "components/column-config-editor/column-config-editor-utils";
import { launchToast } from "utils/show-toast";

const keys = ["sort_order", "active", "title"];
const diffItemsWithKeys = _.partial(getDiffedItems, keys);
const TITLE_LIMIT = 50;

const CreateAssignmentTitleModal = ({ existingTitles = [], ...props }) => (
  <AssignmentTitleModal
    header="Create an Assignment Title"
    disableFunc={(data) => {
      const dupe = existingTitles.find(
        (existing) => existing.title === data.title
      );
      return {
        isDisabled: !data.title || dupe,
        ...(dupe && {
          errors: `'${data.title}' is an existing Assignment Title, please use a unique name.`,
        }),
      };
    }}
    {...props}
  />
);
CreateAssignmentTitleModal.defaultProps = {
  existingTitles: [],
};
CreateAssignmentTitleModal.propTypes = {
  existingTitles: T.array,
};

const AssignmentTitlesController = ({ groupType }) => {
  const [titles, setTitles] = React.useState([]);
  const [origTitles, setOrigTitles] = React.useState([]);
  const [item_to_edit, setItemToEdit] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const fetchAssignmentTitles = async () => {
    setLoading(true);
    const { data } = await AssignmentTitlesService.fetchAssignmentTitles();
    setTitles(data.items);
    setOrigTitles(_.indexBy(data.items, "id")); // save this to diff off for handleSave
    setLoading(false);
  };

  React.useEffect(() => {
    fetchAssignmentTitles();
  }, []);

  const getActiveItems = () => titles.filter((i) => i.active);

  const createAssignmentTitle = async (data) => {
    const duplicate_name = titles.find((title) => title.title === data.title);
    if (duplicate_name) {
      AlertActions.launchToast({
        type: "error",
        message: "Cannot have duplicate assignment title names.",
        timeout: 5000,
      });
      return;
    }
    const active_items = getActiveItems();
    // so 0 is unsorted and length + 1 would be the last one
    const new_title = data.active
      ? { ...data, sort_order: active_items.length + 2 }
      : data;
    try {
      await AssignmentTitlesService.createAssignmentTitle(new_title);
      fetchAssignmentTitles();
    } catch (error) {
      AlertActions.launchToast({
        type: "error",
        message: "There was an error saving your assignment title",
      });
    }
  };

  const updateAssignmentTitle = async (data) => {
    const active_items = getActiveItems();
    // if a modal changes it from inactive to active, add to end of list w/ sort_order
    const existing = titles.find((title) => title.id === data.id);
    const updated_title =
      !existing.active && data.active
        ? { ...data, sort_order: active_items.length + 2 }
        : data;
    setTitles((prev) => {
      return prev.map((item) => (item.id === data.id ? updated_title : item));
    });
  };

  const handleEditItem = (item) => {
    setItemToEdit(item);
  };

  const handleDeleteItem = async (item) => {
    await AssignmentTitlesService.deleteAssignmentTitle(item);
    fetchAssignmentTitles();
    launchToast({ type: "success", message: "Successfully deleted" });
  };

  const onModalClose = () => {
    setItemToEdit(null);
  };

  const handleSave = async () => {
    setLoading(true);
    const itemsHaveChanged = diffItemsWithKeys(origTitles, titles);
    if (itemsHaveChanged.length) {
      try {
        await AssignmentTitlesService.bulkUpdateAssignmentTitles(
          itemsHaveChanged
        );
        fetchAssignmentTitles();
        successToast();
      } catch (error) {
        AlertActions.launchToast({
          type: "error",
          message: "There was an error saving your assignment titles",
        });
      }
    }
    setLoading(false);
  };

  const handleCancel = () => {
    fetchAssignmentTitles();
  };

  const handleListChange = ({ listOne = [], listTwo = [] }) => {
    setTitles([...listOne, ...listTwo]);
  };

  const listOneItems = titles.filter((title) => !title.active);

  const listTwoItems = titles
    .filter((title) => title.active)
    .sort((a, b) => a.sort_order - b.sort_order);

  const itemsHaveChanged = Boolean(
    diffItemsWithKeys(origTitles, titles).length
  );
  const has_max = titles.length >= TITLE_LIMIT;

  return (
    <TypeWrapper>
      <Module>
        <Container>
          {!!titles.length && (
            <ModalTriggerDeprecated
              disable={has_max}
              modal={
                <CreateAssignmentTitleModal
                  existingTitles={titles}
                  handleSave={createAssignmentTitle}
                />
              }
            >
              <Button disabled={has_max}>New Assignment Title</Button>
            </ModalTriggerDeprecated>
          )}
          {itemsHaveChanged && <Unsaved>Unsaved Changes</Unsaved>}
          <ModalTriggerDeprecated
            disable={has_max}
            modal={
              <AddFromEsModal
                groupType={groupType}
                header="Manage Imported Titles"
                filterFunc={(item = {}) => {
                  return !titles.find((title) => title.title === item.value);
                }}
                handleCreate={async (data) => {
                  await AssignmentTitlesService.createAssignmentTitle({
                    ...data,
                    sort_order: listTwoItems.length + 1,
                    active: true,
                  });
                  successToast();
                }}
                whenClose={() => {
                  fetchAssignmentTitles();
                }}
              />
            }
          >
            <Button disabled={has_max}>Manage Imported Titles</Button>
          </ModalTriggerDeprecated>
        </Container>
        {!titles.length ? (
          <StyledEmptyStateMessage
            icon="add-circle-outline"
            text="Create an assignment title to get started"
          >
            <div>
              An assignment title is a way to differentiate statuses or types of
              prospects.
            </div>
            <ModalTriggerDeprecated
              modal={
                <CreateAssignmentTitleModal
                  existingTitles={titles}
                  handleSave={createAssignmentTitle}
                />
              }
            >
              <Button>Create Assignment Title</Button>
            </ModalTriggerDeprecated>
          </StyledEmptyStateMessage>
        ) : (
          <ColumnConfigEditor
            loading={loading}
            onChange={handleListChange}
            onEdit={handleEditItem}
            onDelete={handleDeleteItem}
            handleCancel={handleCancel}
            handleSave={handleSave}
            disableCancel={!itemsHaveChanged}
            disableSave={!itemsHaveChanged}
            searchKey="title"
            enabletoggleAll
            listOne={{
              label: "Available",
              items: listOneItems,
              notSortable: true,
            }}
            listTwo={{ label: "Active", items: listTwoItems }}
          />
        )}

        {!_.isEmpty(item_to_edit) && (
          <Portal>
            <AssignmentTitleModal
              header="Edit Assignment Title"
              submitLabel="Edit"
              disableFunc={(data) => !data.title}
              onClose={onModalClose}
              onUnmount={onModalClose}
              handleSave={updateAssignmentTitle}
              assignmentTitle={item_to_edit}
              isEditing
            />
          </Portal>
        )}
      </Module>
    </TypeWrapper>
  );
};

AssignmentTitlesController.defaultProps = {
  groupType: "TEAM",
};
AssignmentTitlesController.propTypes = {
  groupType: T.string,
};

function successToast() {
  AlertActions.launchToast({
    type: "success",
    message: "Assignment Title Saved",
    timeout: 3000,
  });
}

export default AssignmentTitlesController;
