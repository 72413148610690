import React, { Component } from "react";
import _ from "underscore";
import T from "prop-types";
import { titleCase } from "utils/utils";
import {
  AdvancedCombobox,
  ModalHeaderDeprecated,
  ModalBody,
  ModalFooterWithButtons,
  Switch,
  Checkbox,
} from "@evertrue/et-components";
import {
  DeleteButton,
  HintText,
  OptionList,
} from "./constituent-field-modal-style";
import {
  Form,
  Label,
  FormRow,
  FormField,
} from "../../../style/components/form";
import { availableProducts } from "apps/custom-fields/custom-fields-utils";

const FIELD_TYPES = [
  { value: "string", label: "String" },
  { value: "number", label: "Number" },
  { value: "date_string", label: "Date" },
  { value: "boolean", label: "Boolean" },
  { value: "currency", label: "Currency" },
];

class ConstituentModal extends Component {
  static propTypes = {
    fieldType: T.string.isRequired,
    onSubmit: T.func.isRequired,
    fieldData: T.any,
    onDelete: T.func,
    close: T.func,
  };

  static defaultProps = {
    fieldData: {},
    onSubmit: _.noop,
    onDelete: _.noop,
    close: _.noop,
  };

  state = {
    newFieldData: this.formatInitialData(this.props.fieldData),
    isEditing: !_.isEmpty(this.props.fieldData),
  };

  formatInitialData(fieldData) {
    if (!_.isEmpty(fieldData)) {
      const data_type = _.findWhere(FIELD_TYPES, {
        value: fieldData.data_type,
      });
      return { ...fieldData, data_type };
    }
    return {};
  }

  handleSubmit = () => {
    const fieldData = { ...this.state.newFieldData };
    const { description, data_type } = fieldData;
    fieldData.description = description.trim();
    fieldData.data_type = data_type.value;
    fieldData.permissions = [];
    fieldData.default = false;
    this.props.onSubmit(fieldData);
  };

  handleChange = (key, value) => {
    this.setState({
      newFieldData: { ...this.state.newFieldData, [key]: value },
    });
  };

  handleCheckboxChange = (value) => {
    let app_keys = this.state.newFieldData.app_keys || [];
    if (_.contains(app_keys, value)) {
      app_keys = _.filter(app_keys, (key) => key !== value);
    } else {
      app_keys.push(value);
    }
    this.handleChange("app_keys", app_keys);
  };

  handleDelete = (id) => {
    this.props.onDelete(id);
    this.props.close();
  };

  renderOptionList = () =>
    _.map(availableProducts, (product) => (
      <Checkbox
        key={product.value}
        checked={_.contains(this.state.newFieldData.app_keys, product.value)}
        onChange={() => this.handleCheckboxChange(product.value)}
      >
        {product.label}
      </Checkbox>
    ));

  render() {
    const {
      id,
      data_type = {},
      description = "",
      filterable = true,
      visible = true,
    } = this.state.newFieldData;
    const isDisabled = !description || !data_type.value;
    const { fieldType, close } = this.props;
    const header = `
      ${this.state.isEditing ? "Edit " : "New "}
      Custom ${titleCase(fieldType)} Field
    `;

    return (
      <div>
        <ModalHeaderDeprecated header={header} closeModal={close} />
        <ModalBody>
          <Form>
            <FormRow>
              <FormField>
                <Label htmlFor="display-name">Display Name:</Label>
                <input
                  type="text"
                  onChange={({ target: { value } }) =>
                    this.handleChange("description", value)
                  }
                  name="description"
                  value={description}
                />
              </FormField>
              <FormField>
                <Label htmlFor="field-type">Field Type:</Label>
                <AdvancedCombobox
                  value={data_type}
                  options={FIELD_TYPES}
                  onChange={(val) => this.handleChange("data_type", val)}
                  disabled={this.state.isEditing}
                />
              </FormField>
            </FormRow>
            <Label>Enabled Products</Label>
            <FormRow>
              <FormField>
                <OptionList>{this.renderOptionList()}</OptionList>
              </FormField>
            </FormRow>
            <Label>Filtering & Profile Display:</Label>
            <FormRow>
              <FormField>
                <HintText>Available as Filter in Core Search</HintText>
                <Switch
                  onChange={(val) => this.handleChange("filterable", val)}
                  name="filterable"
                  checked={filterable}
                />
              </FormField>
              <FormField>
                <HintText>{"Display on Constituent's Profile"}</HintText>
                <Switch
                  onChange={(val) => this.handleChange("visible", val)}
                  name="visible"
                  checked={visible}
                />
              </FormField>
            </FormRow>
          </Form>
        </ModalBody>
        <ModalFooterWithButtons
          disableSave={isDisabled}
          onSubmit={this.handleSubmit}
          header={header}
          onCancel={close}
          closeModal={close}
          closeOnSubmit
        >
          {this.state.isEditing && (
            <DeleteButton onClick={() => this.handleDelete(id)}>
              Remove Custom Field
            </DeleteButton>
          )}
        </ModalFooterWithButtons>
      </div>
    );
  }
}

export default ConstituentModal;
