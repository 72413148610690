import _ from "underscore";
import paramify from "jquery-param";
import ErrorLogger from "utils/error-logger";

const METHODS = ["get", "put", "post", "delete", "patch"];

// export constands for API calls

export const SCOPED = "SCOPED";

// export constants that can be thrown to signal

export const UNAUTHORIZED = {
  error: "401 from an authenticated request to an evertrue platform service",
};

export const WRONG_SCOPE = {
  error: "403 from data request.  wrong scope access",
};

export const LOGGED_OUT = new Error("401 from Auth (or Skiff)");

const isEmptyParam = (val) =>
  _.isUndefined(val) ||
  _.isNull(val) ||
  _.isNaN(val) ||
  (_.isArray(val) && _.isEmpty(val));

const compact = (obj) => _.pick(obj, (val) => !isEmptyParam(val));

const optionDefaults = {
  onPersistSessionInfo: _.noop,
  onPersistImpersonateUserId: _.noop,
};

const createAPI = (apiOptions) => {
  const {
    // general constants definign the enviornment
    app_key, // like, who even are you?
    base_route, // used to define enviornment

    // nested object of { GROUP: { ENDPOINT: url } } defining API routes
    endpoints,

    // optional initital state (if prersisted across app starts)
    initial_token,
    initial_oid,
    initial_impersonation_user_id,

    // optional calls that can persist values
    onPersistSessionInfo,
    onPersistImpersonateUserId,

    // methods defining how auth calls will be done
    onRefreshSession, // should return a promise that resolves to a session
    onCreateSession, // should return a promise that resolves to a session
    onDeleteSesssion,

    // method defining how to make a genereal reuqest.
    // default and auth headers will be inserted, and params will be formatted into the URL
    onRequest,
  } = _.defaults(apiOptions, optionDefaults);

  /* state management */

  const state = {
    nextOid: initial_oid,
    log: [],
    currentSession: {},
    pendingSession: undefined,
    impersonateUserId: undefined,
  };

  const dispatcher = { cbs: [], debugCbs: [] };

  const getValue = () => {
    const { pendingSession, nextOid } = state;

    const session = state.currentSession || {};
    const { oid } = session;

    // session stuff
    const is_renewing = !!pendingSession;
    const is_changing_orgs = !!(nextOid && oid && nextOid !== oid);
    const has_session = !!session.token;

    // user stuff
    const user = session.user || {};

    const { id: user_id, super_user } = user;

    const user_affiliations =
      (session && session.user && session.user.affiliations) || [];

    const user_affiliation =
      _.find(
        user_affiliations,
        (a) => a && a.organization && a.organization.id === oid
      ) || {};

    const user_roles = _.pluck(user_affiliation.affiliation_roles, "role");

    // org stuff
    const organization = session.organization || {};

    const new_props = {
      is_renewing,
      is_changing_orgs,
      has_session,
      session,
      oid,
      organization,
      user_id,
      user,
      user_affiliations,
      user_affiliation,
      user_roles,
      super_user: !!super_user,
    };

    const orgs = _.map(
      user.affiliations,
      ({ affiliation_roles = [], organization = {} }) => {
        const roles = _.compact(
          _.pluck(_.pluck(affiliation_roles, "role"), "name")
        );
        return { ...organization, role_names: roles };
      }
    );

    const old_props = {
      renewing: is_renewing,
      has_session: !!(session && session.token),
      changing_orgs: is_changing_orgs,
      oid,
      org: organization,
      organization,
      session: session,
      user_id: user.id,
      super_user: !!super_user,
      orgs: orgs,
    };

    return { ...old_props, ...new_props };
  };

  let triggerTimeout = undefined;

  const trigger = () => {
    clearTimeout(triggerTimeout);
    // if multiple auth events happen in one event callback,
    // trigger will only be called once, after they are done
    triggerTimeout = setTimeout(() => {
      const value = getValue();
      _.each(dispatcher.cbs, (cb) => cb(value));
    }, 0);
  };

  let debugTriggerTimeout = undefined;

  const debugTrigger = () => {
    clearTimeout(debugTriggerTimeout);
    debugTriggerTimeout = setTimeout(() => {
      _.each(dispatcher.debugCbs, (cb) => cb(state));
    }, 0);
  };

  const setState = (newState) => {
    _.extend(state, newState);
    trigger();
  };

  const log = (type, info = {}) => {
    const ts = new Date();
    const entry = {
      ...info,
      type,
      occured_at: ts.valueOf(),
      time: ts.toLocaleTimeString("en-US"),
    };
    state.log = _.last(state.log.concat(entry), 500);
    debugTrigger();
  };

  const getSession = () =>
    state.pendingSession || Promise.resolve(state.currentSession);

  const saveSession = (session = {}) => {
    setState({ currentSession: session });
    onPersistSessionInfo(session);
    return session;
  };

  const saveImpersonateId = (user_id) => {
    setState({ impersonateUserId: user_id });
    onPersistImpersonateUserId(user_id);
    return user_id;
  };

  /* managing the session */

  /* renewing is done when a short session is invalid,
  usually indicated by a 401 from a platform service,
  but also when starting the app cold with no prior session information.
  */

  const renewSession = (oid) => {
    log("renew:start");

    const promise = onCreateSession(oid)
      .then((session) => {
        setState({ pendingSession: undefined });
        log("renew:success", { session });
        saveSession(session);
        return session;
      })
      .catch((error) => {
        setState({ pendingSession: undefined });
        log("renew:failure", { error });
        saveSession({});
      });

    setState({ pendingSession: promise });

    return promise;
  };

  const refreshSession = (token, oid) => {
    const promise = onRefreshSession(token, oid)
      .then((session) => {
        setState({ pendingSession: undefined });
        log("hydrate:success", { session });
        saveSession(session);
        return session;
      })
      .catch((error) => {
        setState({ pendingSession: undefined });
        log("hydrate:failure", { error });
        return renewSession(oid);
      });

    setState({ pendingSession: promise });

    return promise;
  };

  /* refrshing a session is done when we have some prior information about a session and want to verify it.
  usually a renewal can be attempted, but in the case of untrusted 2FA sessions,
  this can cause too many 2FA prompts,
  so a renwal is used to verify a session but remain silent on success.

  Note:  a refresh failure will cause a renewal attempt.
  */

  const refreshCurrentSession = () => {
    if (state.pendingSession) {
      log("refresh-current:busy");
      return state.pendingSession;
    } else {
      log("refresh-current:start");
      const { token, oid } = state.currentSession;
      return refreshSession(token, oid);
    }
  };

  const deleteSession = () => {
    return onDeleteSesssion().then(() => {
      saveSession({});
    });
  };

  const getHeaders = (headers = {}, session = {}, impersonate_user_id) => {
    return compact({
      "Content-Type": "application/json; charset=UTF-8",
      Accept: "application/json, text/plain",
      "Application-Key": app_key,
      "Authorization-Provider": "evertrueauthtoken",
      Authorization: session.token,
      "Authorization-Impersonate": impersonate_user_id,
      ...headers,
    });
  };

  const formatUrl = (url = "", urlArgs) => {
    if (!url.match(":")) {
      return url;
    } else if (_.size(urlArgs) < 1) {
      throw new Error(
        "Endpoint " +
          url +
          " requires arguments, set 'urlArgs' object in options"
      );
    } else {
      return url.replace(/\/:[^/]*/g, (match) => {
        const key = match.replace(":", "").replace("/", "");
        const value = urlArgs[key];
        return value ? `/${value}` : "";
      });
    }
  };

  const getUrl = (url = "", urlExtend = "", urlArgs, params) => {
    const root_string = base_route + formatUrl(url, urlArgs) + urlExtend;

    return _.isEmpty(params)
      ? root_string
      : root_string + "?" + decodeURIComponent(paramify(params));
  };

  const getOptions = (options, session, impersonate_user_id) => {
    const { url, urlExtend, urlArgs, params, headers, ...rest } = options;

    const requestParams = compact({ oid: session.oid, ...params });

    const formattedOptions = {
      ...rest,
      url: getUrl(url, urlExtend, urlArgs, requestParams),
      // params: requestParams,
      headers: getHeaders(headers, session, impersonate_user_id),
    };

    return formattedOptions;
  };

  const sessionMethods = {
    getValue,
    setOid(oid) {
      console.log("DEPRECATED, try changeOrg()");
      setState({ nextOid: oid });
      return renewSession(oid);
    },
    changeOrg(oid) {
      setState({ nextOid: oid });
      return renewSession(oid);
    },
    impersonate(user_id) {
      saveImpersonateId();
    },
    endImpersonate() {
      saveImpersonateId();
    },
    login() {},
    logout() {
      deleteSession();
    },
    bind(cb) {
      dispatcher.cbs = dispatcher.cbs.concat(cb);
      return getValue();
    },
    unbind(cb) {
      dispatcher.cbs = _.without(dispatcher.cbs, cb);
    },
    debug: {
      report() {
        _.each(state, (val, key) => {
          if (_.isArray(val)) {
            console.log(key + ": ");
            console.table(val);
          } else {
            console.log(`${key}: `, val);
          }
        });
      },
      getState() {
        return state;
      },
      bind(cb) {
        dispatcher.debugCbs = dispatcher.debugCbs.concat(cb);
      },
      unbind(cb) {
        dispatcher.debugCbs = _.without(dispatcher.debugCbs, cb);
      },
      renewSession() {
        renewSession(state.currentSession.oid);
      },
      refreshCurrentSession,
      trigger,
      setInvalidToken() {
        const session = { ...state.currentSession, token: "abc123" };
        setState({ currentSession: session });
        log("test:invalidate-short-token", { session });
      },
      originals: { onRefreshSession, onCreateSession, onDeleteSesssion },
    },
  };

  const request = (options) => {
    log("request:start", { url: options.url });

    const promise = new Promise((resolve, reject) => {
      const { impersonateUserId } = state;

      return getSession()
        .then((session) => {
          const { oid } = session;
          const requestOptions = getOptions(
            options,
            session,
            impersonateUserId
          );

          return onRequest(requestOptions)
            .then(resolve)
            .catch((error) => {
              console.log("API error: ", error);
              ErrorLogger.captureRequest("API error: ", error);
              if (error === UNAUTHORIZED) {
                log("request:unauthorized", { url: options.url });
                // todo: check for other request's retry => totes fully unauthorized

                // if (state.pendingSession) {
                //   console.log("was pending");
                // } else {
                //   console.log("need to try renewing");
                // }

                const retryPromise = state.pendingSession || renewSession(oid);

                retryPromise.then((session = {}) => {
                  const retryRequestOptions = getOptions(
                    options,
                    session,
                    impersonateUserId
                  );

                  onRequest(retryRequestOptions).then(resolve).catch(reject);
                });
              } else {
                reject(error);
              }
            });
        })
        .catch((error) => {
          console.log("pending renewal didn't go well");
          reject(error);
        });
    });

    promise
      .then((resp) => {
        log("request:success", { url: options.url });
        return resp;
      })
      .catch((error) => {
        log("request:failure", { url: options.url });
        throw error;
      });

    return promise;
  };

  const apiCalls = _.mapObject(endpoints, (group) =>
    _.mapObject(group, (endpoint) => {
      const verbs = {};
      _.each(METHODS, (method) => {
        verbs[method] = (opts = {}) => {
          return request({
            url: endpoint,
            ...opts,
            type: method.toUpperCase(),
          });
        };
      });
      return verbs;
    })
  );

  const api = { ...apiCalls, ...sessionMethods };

  // all of these cases will put a pending session onto the stack,
  // so all data requests will wait for that on page load

  if (
    _.isString(initial_token) &&
    _.size(initial_token) > 20 &&
    _.isFinite(initial_oid)
  ) {
    // we have an inital token, so we can try to turn that into a session
    saveSession({ token: initial_token, oid: initial_oid });
    refreshSession(initial_token, initial_oid);
    // if we don't have anything stored, try renewing right now
  } else if (_.isFinite(initial_oid)) {
    // we have initital OID so we know what scope to attempt to get into
    renewSession(initial_oid);
  } else {
    // we don't even know where we're going, so we're going to the lobby
    renewSession();
  }

  if (_.isFinite(initial_impersonation_user_id)) {
    saveImpersonateId(initial_impersonation_user_id);
  }

  return api;
};

export default createAPI;
