import _ from "underscore";
import React, { Fragment } from "react";
import styled from "styled-components";
import { colors } from "style/colors";
import config from "utils/env";
import { Popover2, Icon } from "@evertrue/et-components";
import { Link } from "react-router-dom";

const EnvSwitcherPill = () => {
  const env = config.isStageData() ? "Stage" : "Production";
  const other = config.isStageData() ? "Production" : "Stage";
  const urlEnv = config.getUrlEnvironment();

  const options = [
    {
      label: `Switch to ${other}`,
      onClick: config.switchEnvironment,
      icon: "settings",
    },
    {
      href: "/auth/org",
      label: "Org Picker",
      icon: "career",
    },
    {
      href: "/debug",
      label: "Debugging",
      icon: "timeline",
    },
  ];

  return urlEnv !== "production" ? (
    <Wrap>
      <Popover2 contentWidth={320} trigger={<Pill>{env}</Pill>}>
        {({ closePopover }) => (
          <div onClick={() => _.defer(closePopover)}>
            {_.map(options, ({ href, label, onClick, icon }) => {
              const children = (
                <Fragment>
                  <Icon icon={icon} /> {label}
                </Fragment>
              );
              return href ? (
                <LinkItem to={href} onClick={onClick} key={icon}>
                  {children}
                </LinkItem>
              ) : (
                <ButtonItem onClick={onClick} key={icon}>
                  {children}
                </ButtonItem>
              );
            })}
          </div>
        )}
      </Popover2>
    </Wrap>
  ) : null;
};

const Wrap = styled.span`
  padding: 8px 20px;
`;

const Pill = styled.span`
  background-color: ${colors.teal};
  border-radius: 8px;
  font-size: 13px;
  padding: 4px 9px;
  color: ${colors.white};
  cursor: pointer;
  border: none;
`;

const styles = `
  width: 100%;
  display: block;
  padding: 12px;
  border: none;
  background-color: transparent;
  text-align: left;
  font-size: 16px;
  color: ${colors.darkestText};
  &:hover {
    color: ${colors.teal};
  }

`;

const LinkItem = styled(Link)`
  ${styles};
`;

const ButtonItem = styled.button`
  ${styles};
`;

export default EnvSwitcherPill;
