import React from "react";
import {
  Loading,
  Button,
  FlexTable,
  Bar,
  Layout,
  AlertActions,
  Tooltip2 as Tooltip,
  Icon,
  useOpenClose,
} from "@evertrue/et-components";
import { usePromiseResolver } from "@evertrue/promise-resolver";
import Api from "utils/api";
import AccountActions from "./account-actions";
import EmmaAddModal from "./emma-add-modal";
import moment from "moment";

const launchToast = (message = "Success") => {
  setTimeout(() => {
    AlertActions.launchToast({
      type: "success",
      message,
      timeout: 5000,
    });
  }, 800);
};

const EmmaController = () => {
  const [is_modal_open, openModal, closeModal] = useOpenClose();
  const [data = {}, loading, , refresh] = usePromiseResolver(
    () => Api.EMMA.ACCOUNTS.get(),
    []
  );

  const accounts = data.data;
  const columns = [
    {
      key: 1,
      header: "Account Status",
      accessor: (account) => {
        const isInvalid = account.invalidated_at;
        const icon_props = {
          size: 2,
          icon: isInvalid ? "unlink" : "link",
          style: {
            color: isInvalid ? "#de2186" : "#D2DDE0",
            cursor: isInvalid ? "pointer" : "default",
          },
        };
        return (
          <span
            style={{
              marginLeft: 25,
            }}
          >
            <Tooltip
              contentWidth={250}
              trigger={
                <span>
                  <Icon {...icon_props} />
                </span>
              }
            >
              <div
                style={{
                  lineHeight: "20px",
                }}
              >
                {isInvalid
                  ? "Your Emma account credentials are no longer valid. Please re-authenticate with your API keys from Emma."
                  : "Your Emma account is linked and working correctly"}
              </div>
            </Tooltip>
          </span>
        );
      },
    },
    {
      key: 1,
      header: "Emma Account Name",
      accessor: (account) => account.name,
    },
    {
      key: 2,
      header: "Emma Account ID",
      accessor: (account) => account.remote_id,
    },
    {
      key: 3,
      header: "Public API Key",
      accessor: (account) => account.public_api_key,
    },
    {
      key: 4,
      header: "Last Updated",
      accessor: (account) =>
        moment(account.updated_at).format("MM/DD/YY h:mma"),
    },
    {
      key: 5,
      header: "Actions",
      accessor: (account) => (
        <AccountActions account={account} refresh={refresh} />
      ),
    },
  ];
  const header = (
    <Bar
      right={
        <React.Fragment>
          <Button onClick={openModal}>New Account</Button>
          <EmmaAddModal
            isOpen={is_modal_open}
            refresh={refresh}
            onClose={closeModal}
            onSuccess={() => launchToast("Account successfully added")}
          />
        </React.Fragment>
      }
    />
  );
  return (
    <Layout
      header={header}
      headerBorder={false}
      style={{
        height: "100%",
      }}
    >
      {accounts && (
        <FlexTable
          caption="Emma Accounts Table"
          data={accounts}
          columns={columns}
          style={{
            height: "100%",
          }}
        />
      )}
      {loading && <Loading />}
    </Layout>
  );
};
export default EmmaController;
