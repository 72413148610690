import React, { Component } from "react";
import _ from "underscore";
import T from "prop-types";
import {
  AdvancedCombobox,
  ModalHeaderDeprecated,
  ModalBody,
  ModalFooterWithButtons,
  Switch,
} from "@evertrue/et-components";
import { titleCase } from "utils/utils";
import { DeleteButton } from "./custom-field-modal-style";
import {
  Form,
  Label,
  FormRow,
  FormField,
  HintText,
} from "../../../style/components/form";
import { getIsFieldPickList } from "../custom-fields-utils";

const FIELD_TYPES = [
  { value: "DATE", label: "Date" },
  { value: "LONG", label: "Number" },
  { value: "DOUBLE", label: "Currency" },
  { value: "BOOLEAN", label: "Boolean" },
  {
    value: "STRING:FREEFORM",
    data_type: "STRING",
    control_type: "FREEFORM",
    label: "String - Freeform",
  },
  {
    value: "STRING:DROPDOWN",
    data_type: "STRING",
    control_type: "DROPDOWN",
    label: "String - Pick list",
  },
];

class CustomFieldModal extends Component {
  static propTypes = {
    fieldType: T.string.isRequired, // "constituents", "interactions"
    onSubmit: T.func.isRequired,
    fieldData: T.any,
    onDelete: T.func,
    close: T.func,
    pickListValues: T.array,
  };

  static defaultProps = {
    fieldData: {},
    onDelete: _.noop,
    close: _.noop,
    pickListValues: [],
  };

  state = {
    newFieldData: this.props.fieldData,
  };

  handleSubmit = () => {
    const fieldData = { ...this.state.newFieldData };
    const { displayName, dataType, uiControlType } = fieldData;
    fieldData.displayName = displayName.trim();
    fieldData.dataType = dataType;
    fieldData.uiControlType = uiControlType;
    this.props.onSubmit(fieldData);
  };

  handleChangeDataType = ({ value, data_type, control_type }) => {
    const updated_data = { ...this.state.newFieldData };
    updated_data.dataType = data_type || value;
    updated_data.uiControlType = control_type;

    // if users choses another type, made it required, the chose back to picklist this would not let it be required
    if (
      this.state.newFieldData.required &&
      getIsFieldPickList(data_type, control_type)
    ) {
      updated_data.required = false;
    }

    this.setState({ newFieldData: updated_data });
  };

  handleChange = (key, value) => {
    this.setState({
      newFieldData: { ...this.state.newFieldData, [key]: value },
    });
  };

  handleDelete = (id) => {
    this.props.onDelete(id);
    this.props.close();
  };

  render() {
    const {
      id,
      dataType,
      displayName = "",
      uiControlType,
    } = this.state.newFieldData;
    const isDisabled = !displayName || !dataType;
    const { fieldType, close } = this.props;
    const isEditing = this.props.fieldData.id;
    const header = `
      ${isEditing ? "Edit " : "New "}
      Custom ${titleCase(fieldType)} Field
    `;
    let selected_value = dataType;
    if (dataType === "STRING") {
      selected_value = uiControlType
        ? `${dataType}:${uiControlType}`
        : `${dataType}:DROPDOWN`;
    }

    return (
      <div>
        <ModalHeaderDeprecated header={header} closeModal={close} />
        <ModalBody>
          <Form>
            <FormRow>
              <FormField>
                <Label htmlFor="display-name">Display Name:</Label>
                <input
                  type="text"
                  onChange={({ target: { value } }) =>
                    this.handleChange("displayName", value)
                  }
                  name="displayName"
                  value={displayName}
                />
              </FormField>
              <FormField>
                <Label htmlFor="field-type">Field Type:</Label>
                <AdvancedCombobox
                  value={{ value: selected_value }}
                  options={FIELD_TYPES}
                  onChange={this.handleChangeDataType}
                  disabled={Boolean(isEditing)}
                />
              </FormField>
            </FormRow>
            <FormRow>
              <FormField>
                <Label>Required:</Label>
                <HintText>Required Field on Interaction form</HintText>
                <Switch
                  disabled={
                    getIsFieldPickList(dataType, uiControlType) &&
                    !this.props.pickListValues.filter((field) => field.active)
                      .length
                  }
                  onChange={(val) => this.handleChange("required", val)}
                  checked={this.state.newFieldData.required}
                />
              </FormField>
            </FormRow>
          </Form>
        </ModalBody>
        <ModalFooterWithButtons
          disableSave={isDisabled}
          onSubmit={this.handleSubmit}
          header={header}
          onCancel={close}
          closeModal={close}
          closeOnSubmit
        >
          {isEditing && (
            <DeleteButton onClick={() => this.handleDelete(id)}>
              Remove Custom Field
            </DeleteButton>
          )}
        </ModalFooterWithButtons>
      </div>
    );
  }
}

export default CustomFieldModal;
