import React from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Modal,
  ModalBody,
  ModalHeaderWithActions,
  useOpenClose,
} from "@evertrue/et-components";

const ConfirmDelete = ({ field, fieldDisplayName, onDelete }) => {
  const [isOpen, openModal, closeModal] = useOpenClose();

  return (
    <React.Fragment>
      <IconButton
        size={1}
        title="delete custom field"
        icon="delete"
        onClick={openModal}
      />

      <Modal isOpen={isOpen} closeModal={closeModal} size="small">
        <ModalHeaderWithActions
          title="Are you sure?"
          submitLabel="OK"
          closeModal={closeModal}
          onSubmit={() => {
            onDelete(field);
            closeModal();
          }}
        />
        <ModalBody>
          <p>{`Are you sure you want to delete ${fieldDisplayName}?`}</p>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

ConfirmDelete.propTypes = {
  field: PropTypes.object.isRequired,
  fieldDisplayName: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ConfirmDelete;
