import React, { Component } from "react";
import _ from "underscore";
import {
  Button,
  ContactCard,
  Icon,
  Loading,
  AlertActions,
} from "@evertrue/et-components";
import styled from "styled-components";
import T from "prop-types";
import CDCAddressBlock from "./cdc-address-block";
import Api from "utils/api";
import Decorator from "@evertrue/client-decorator";
import Utils from "utils/env";
import { asyncDelay } from "utils/utils";
import { TextArea as BaseTextArea } from "../../style/components/form";

const TextArea = styled(BaseTextArea)`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
`;

const iconMap = {
  email: "email",
  phone: "call",
  address: "map-pin",
  misc: "misc",
};

class CDCCardView extends Component {
  static propTypes = {
    submission: T.object,
    onAction: T.func,
  };

  static defaultProps = {
    submission: {},
    onAction: _.noop,
  };

  state = {
    loading: false,
    contact: {},
    suggestion: this.props.submission.suggestions[0],
  };

  componentDidMount() {
    this.fetchContact();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.submission.contact_id !== this.props.submission.contact_id) {
      this.fetchContact();
    }
    if (prevProps.submission.id !== this.props.submission.id) {
      this.setState({ suggestion: this.props.submission.suggestions[0] });
    }
  }

  componentWillUnmount() {
    this._unmounted = true;
  }

  async fetchContact() {
    const { submission } = this.props;
    try {
      this.setLoading(true);
      const { data } = await Api.CONTACT.CONTACT.get({
        urlArgs: { id: submission.contact_id },
      });
      this.setState({
        contact: Decorator.Contacts.parse(data),
        loading: false,
      });
    } catch (error) {
      this.setLoading(false);
    }
  }

  handleReject = async () => {
    const { suggestion } = this.state;
    try {
      this.setLoading(true);
      await Api.SUBMISSIONS.REJECT.post({ urlArgs: { id: suggestion.id } });

      // ES has a 1 second delay for rewrites, so this delay is needed so the table is properly updated on the next fetch
      await asyncDelay(1000);

      this.showSuccessToast("rejected", suggestion.data_type);
      this.setLoading(false);

      this.props.onAction();
    } catch (error) {
      this.showErrorToast();
      this.setLoading(false);
    }
  };

  handleApprove = async () => {
    const { suggestion } = this.state;
    try {
      this.setLoading(true);
      await Api.SUBMISSIONS.APPROVE.post({ urlArgs: { id: suggestion.id } });

      // ES has a 1 second delay for rewrites, so this delay is needed so the table is properly updated on the next fetch
      await asyncDelay(1000);

      this.showSuccessToast("approved", suggestion.data_type);
      this.setLoading(false);
      this.props.onAction();
    } catch (error) {
      this.showErrorToast();
      this.setLoading(false);
    }
  };

  handleUndo = async (action) => {
    const { suggestion } = this.state;
    try {
      this.setLoading(true);
      await Api.SUBMISSIONS.OPEN.post({ urlArgs: { id: suggestion.id } });

      // ES has a 1 second delay for rewrites, so this delay is needed so the table is properly updated on the next fetch
      await asyncDelay(1000);

      this.showUndoToast(action);
      this.props.onAction();

      this.setLoading(false);
    } catch (error) {
      this.showErrorToast();
      this.setLoading(false);
    }
  };

  setLoading = (loading) => {
    if (!this._unmounted) {
      this.setState({ loading });
    }
  };

  showSuccessToast = (action, data_type) => {
    const contactName = Decorator.Contacts.getFullName(
      Decorator.Contacts.parse(this.state.contact)
    );
    AlertActions.removeToast(); // Clear existing toast
    AlertActions.launchToast({
      type: action === "approved" ? "success" : "info",
      message: (
        <div className="button">
          Success! {contactName} {data_type} information has been {action}
          {action === "approved" ? " and is ready for export" : null}
          <CDCUndoButton onClick={() => this.handleUndo(action)}>
            Undo {action === "approved" ? "Approve" : "Reject"}
          </CDCUndoButton>
        </div>
      ),
    });
  };

  showUndoToast = (action) => {
    AlertActions.removeToast(); // Clear existing toast
    AlertActions.launchToast({
      type: "success",
      message: `Your ${action} action was undone`,
    });
  };

  showErrorToast = () => {
    AlertActions.removeToast(); // Clear existing toast
    AlertActions.launchToast({
      type: "error",
      message:
        "Sorry, there was an error completing your request, please try again later",
      timeout: 5000,
    });
  };

  getConstituentRemoteID = () => {
    const { identities } = this.state.contact;
    const remote_id = _.findWhere(identities, { type: "remote_id" });
    return remote_id && remote_id.value;
  };

  renderLabel = (propertiesType) => {
    const { suggestion } = this.state;
    if (suggestion.action_type === "misc") return "misc";
    else if (suggestion[propertiesType].type)
      return suggestion[propertiesType].type;
    return suggestion.data_type;
  };

  renderSuggestion = () => {
    const { former_properties = {}, new_properties = {} } =
      this.state.suggestion;
    if (!_.isEmpty(former_properties) && !_.isEmpty(new_properties)) {
      return this.renderEdit();
    } else if (!_.isEmpty(new_properties) && _.isEmpty(former_properties)) {
      return this.renderFormerNew("new_properties");
    } else if (!_.isEmpty(former_properties) && _.isEmpty(new_properties)) {
      return this.renderFormerNew("former_properties");
    } else {
      return this.renderMisc();
    }
  };

  renderEdit = () => {
    return (
      <FlexRow>
        <DataBlock>
          <DataLabel>{this.renderLabel("former_properties")}</DataLabel>
          <DataSource>Imported</DataSource>
          <Data>{this.renderData("former_properties")}</Data>
        </DataBlock>

        <DataBlock>
          <DataSource>Suggested</DataSource>
          <Data>{this.renderData("new_properties")}</Data>
        </DataBlock>
      </FlexRow>
    );
  };

  renderFormerNew = (propertiesType) => {
    return (
      <Row>
        <DataBlock>
          <DataLabel>{this.renderLabel(propertiesType)}</DataLabel>
          <DataSource>
            {propertiesType === "former_properties" ? "Imported" : "Suggested"}
          </DataSource>
          <Data>{this.renderData(propertiesType)}</Data>
        </DataBlock>
      </Row>
    );
  };

  renderData = (propertiesType) => {
    const { data_type } = this.state.suggestion;
    if (this.state.suggestion[propertiesType]) {
      if (data_type === "address") {
        return (
          <CDCAddressBlock address={this.state.suggestion[propertiesType]} />
        );
      } else {
        return this.state.suggestion[propertiesType][data_type];
      }
    }
  };

  renderActionType = () => {
    const actionType =
      this.state.suggestion.action_type === "FORMER"
        ? "Retire"
        : this.state.suggestion.action_type.toLowerCase();
    return (
      <ActionType>
        {this.state.suggestion.action_type === "MISC" ? (
          <React.Fragment>
            <Icon icon={iconMap.misc} size={2} />
            <Action>
              <span>New</span>
              <span>{actionType}</span>
            </Action>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Icon icon={iconMap[this.state.suggestion.data_type]} size={2} />
            <div>
              <span>{actionType}</span>
              <span>{this.state.suggestion.data_type.toLowerCase()}</span>
            </div>
          </React.Fragment>
        )}
      </ActionType>
    );
  };

  renderMisc = () => {
    return (
      <Row>
        <DataBlock>
          <DataSource>Misc</DataSource>
          <TextArea disabled value={this.state.suggestion.other} />
        </DataBlock>
      </Row>
    );
  };

  render() {
    const parsedContact = Decorator.Contacts.parse(this.state.contact) || {};
    const formattedName = Decorator.Contacts.getFullName(parsedContact);
    const roleWithYear = Decorator.Profile.getRoleWithYear(parsedContact);
    const link = `${Utils.evertrueUrl}/contact/${parsedContact.id}`;
    const constituentID = this.getConstituentRemoteID();

    return (
      <CDCCard>
        <ContactRow>
          {this.state.loading ? (
            <Loading />
          ) : (
            <ContactCard
              contact={parsedContact}
              formattedName={formattedName}
              roleWithYear={roleWithYear}
              link={link}
              target="_blank"
            />
          )}
          {this.renderActionType()}
        </ContactRow>
        {constituentID && (
          <ConstituentIDLabel>
            Constituent ID:
            <ConstituentID> {constituentID}</ConstituentID>
          </ConstituentIDLabel>
        )}
        <ActionsRow>
          <FlexRow>
            <Button type="destroy" onClick={this.handleReject}>
              Reject
            </Button>
            <Button onClick={this.handleApprove}>Approve</Button>
          </FlexRow>
        </ActionsRow>
        {this.renderSuggestion()}
        <Row>
          <InfoBlock>
            Info
            <TextArea disabled value={this.props.submission.note} />
          </InfoBlock>
        </Row>
      </CDCCard>
    );
  }
}

const CDCCard = styled.section`
  flex-basis: 30%;
  background-color: #ffffff;
  padding-right: 12px;
  padding-left: 12px;
`;

const ContactRow = styled.section`
  display: flex;
  margin: 16px auto 0;
  align-items: center;
  justify-content: space-between;
`;

const FlexRow = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  &:nth-child(3) {
    border-top: 1px solid #d2dde0;
  }

  button {
    min-width: 168px;
    height: 40px;
    margin: 32px 0;
  }
`;

const ActionsRow = styled.div`
  border-bottom: 1px solid #d2dde0;
`;

const Action = styled.div`
  line-height: 16px;
`;

const Row = styled.section`
  margin: 0 auto;
`;

const ActionType = styled.div`
  display: flex;
  margin-right: 20px;
  justify-content: space-around;
  text-transform: capitalize;
  text-align: left;
  span {
    display: block;
    &:first-child {
      font-weight: 500;
    }
  }
  i {
    color: #5598ab;
  }
`;

const DataLabel = styled.div`
  width: 100%;
  text-transform: uppercase;
  color: #4a4a4a;
  font-size: 14px;
  margin-bottom: 10px;
`;

const DataSource = styled.div`
  color: #a7a7a7;
  font-size: 14px;
`;

const ConstituentIDLabel = styled.div`
  margin-top: -8px;
  margin-left: 88px;
  color: #a7a7a7;
  font-size: 14px;
`;

const ConstituentID = styled.span`
  color: #454545;
`;

const DataBlock = styled.div`
  margin-top: 32px;
  margin-bottom: 50px;
`;

const InfoBlock = styled.div`
  margin-top: 32px;
`;

const Data = styled.div`
  margin-top: 17px;
  line-height: 20px;
`;

const CDCUndoButton = styled.button`
  display: block;
  background: none;
  color: #ffffff;
  border-color: #ffffff;
  padding: 10px 0;
  margin-top: 7px;
  font-weight: bold;
`;

export default CDCCardView;
