import React from "react";
import T from "prop-types";
import _ from "underscore";
import { ModalForm, Checkbox } from "@evertrue/et-components";
import { FormWrapper, Label } from "style/components/form";
import styled from "styled-components";

export const StatusSection = styled.div`
  margin-top: 16px;
  line-height: 18px;

  label {
    display: flex;
  }

  .et--checkbox--box {
    min-width: 22px;
  }
`;

export const Errors = styled.div`
  margin-top: 10px;
`;

function getEmptyData() {
  return {
    title: "",
    active: true,
  };
}

const AssignmentTitleModal = (props) => (
  <ModalForm
    onClose={props.onClose}
    width={500}
    header={props.header || "Assignment Title"}
    data={
      _.isEmpty(props.assignmentTitle) ? getEmptyData() : props.assignmentTitle
    }
    handleSubmit={props.handleSave}
    disableFunc={props.disableFunc}
    render={({ state = {}, setState, errors }) => (
      <FormWrapper>
        <Label htmlFor="name">Title</Label>
        <input
          id="name"
          type="text"
          name="name"
          value={state.title}
          onChange={({ target: { value } }) => setState({ title: value })}
        />
        {!_.isEmpty(errors) && <Errors>{errors}</Errors>}
        <StatusSection>
          <Checkbox
            label="active status"
            checked={state.active === true}
            onChange={() => setState({ active: !state.active })}
          >
            <div>
              <strong>Active</strong>
              <div>Enable Assignment Title</div>
            </div>
          </Checkbox>
        </StatusSection>
      </FormWrapper>
    )}
  />
);

AssignmentTitleModal.propTypes = {
  header: T.string,
  onClose: T.func,
  assignmentTitle: T.object,
  disableFunc: T.func,
  handleSave: T.func,
};

AssignmentTitleModal.defaultProps = {
  header: "",
  onClose: () => null,
  assignmentTitle: {},
  disableFunc: () => null,
  handleSave: () => null,
};

export default AssignmentTitleModal;
