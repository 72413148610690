import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon } from "@evertrue/et-components";
import { BoldText } from "apps/importer/common/importer-styled-components";
import { colors } from "style/colors";
import { getStatusInfo } from "apps/importer/import-helper";

export const ProcessMessage = ({ status, messageText, importCancelled }) => {
  const { icon, color, messageSubText } = getStatusInfo({ status }) || {};
  return (
    <div>
      <Message>
        {importCancelled ? <Icon icon={"cancel-circle"} size={5} style={{ color: "red" }}></Icon> : <Icon icon={icon} size={5} style={{ color }}></Icon>}
        <MessageHeader>{messageText}</MessageHeader>
      </Message>
      <Results>
        <BoldText>Results:</BoldText>
        <div>{messageSubText}</div>
      </Results>
    </div>
  );
};

ProcessMessage.propTypes = {
  status: PropTypes.string.isRequired,
  messageText: PropTypes.string,
};

ProcessMessage.defaultProps = {
  messageText: "",
};

const MessageHeader = styled.div`
  color: ${colors.darkestText};
  padding-top: 16px;
  font-size: 24px;
`;

const Results = styled.div`
  margin: 24px 0 0 44px;
`;

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 200px;
  border-bottom: 1px solid;
  border-color: ${colors.border};
  width: 90%;
  margin: auto;
`;

export default ProcessMessage;
