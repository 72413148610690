import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Layout } from "@evertrue/et-components";
import ImportHeader from "./components/import-header";
import ImportUpload from "./components/import-upload/import-upload";
import ImporterController from "./importer-controller";
import ImportMappingLayout from "./components/import-mapping/import-mapping-layout";
import ImportPreProcess from "./components/import-preprocess/import-preprocess";

const ImporterRouteController = () => {
  return (
    <Layout header={<ImportHeader />} headerBorder={false}>
      <Switch>
        <Route exact path="/importer">
          <ImporterController />
        </Route>
        <Route path="/importer/:jobId?/import-upload">
          <ImportUpload />
        </Route>
        <Route path="/importer/:jobId/import-mapping-layout">
          <ImportMappingLayout />
        </Route>
        <Route path="/importer/:jobId/import-mapping-layout-view-only">
          <ImportMappingLayout
            viewOnly={true}
          />
        </Route>
        <Route path="/importer/:jobId/import-preprocess">
          <ImportPreProcess />
        </Route>
        <Redirect to="/importer" />
      </Switch>
    </Layout>
  );
};

export default ImporterRouteController;
