import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Flex,
  Icon,
  ThreeDotActionsMenu,
} from "@evertrue/et-components";
import styled from "styled-components";

const ACTIVE = "Active";
const PENDING = "Pending";
const INACTIVE = "Inactive";
const LOADING = "Loading";
const CONNECT = "Connect";
const CONNECTING = "Connecting";

export const STATE_ENUM = {
  active: ACTIVE,
  pending: PENDING,
  disabled: INACTIVE,
};

const STATE_MAP = {
  [ACTIVE]: { integrationState: ACTIVE, buttonProps: null },
  [PENDING]: {
    integrationState: PENDING,
    buttonProps: {
      ButtonIcon: <Icon icon="spinner" spin />,
      isDisabled: true,
      text: CONNECTING,
    },
  },
  [INACTIVE]: {
    integrationState: INACTIVE,
    buttonProps: { ButtonIcon: null, isDisabled: false, text: CONNECT },
  },
};

const mapToState = ({ state }) => {
  return STATE_MAP[STATE_ENUM[state]]
    ? STATE_MAP[STATE_ENUM[state]]
    : STATE_MAP[INACTIVE];
};

export const IntegrationCard = ({
  primaryContentText,
  headerTitle,
  state,
  handleIntegrationClick,
  handleLogoutClick,
  contentImageHref,
  loading,
  allowDeactivate,
}) => {
  const { integrationState, buttonProps } = mapToState({ state });
  const showDeactivateButton = allowDeactivate === true && integrationState === ACTIVE;

  const accordionItems = [];
  if (showDeactivateButton) {
    accordionItems.push({
      id: accordionItems.length,
      icon: "logout",
      label: "Deactivate",
      onClick: handleLogoutClick,
    });
  }

  return (
    <Card>
      <HeaderSection>
        <HeaderTitle>
          {headerTitle}
          {!loading && buttonProps && (
            <Button
              onClick={handleIntegrationClick}
              disabled={buttonProps.isDisabled}
              type="secondary"
              label="integration-button"
            >
              {buttonProps.ButtonIcon}
              {buttonProps.text}
            </Button>
          )}
        </HeaderTitle>
      </HeaderSection>
      <ContentSection>
        <img src={contentImageHref} width="260" alt="contentImageHref" />
        <ContentText>{primaryContentText}</ContentText>
      </ContentSection>
      <FooterSection>
        <Flex justifyContent="space-between" alignItems="flex-start">
          {loading ? <ContentText>{LOADING}</ContentText> : <ContentText>{integrationState}</ContentText>}
          {!loading && accordionItems.length > 0 && (
            <ThreeDotActionsMenu
              options={accordionItems}
              iconSize={1}
            />
          )}
        </Flex>
      </FooterSection>
    </Card>
  );
};

IntegrationCard.defaultProps = {
  headerTitle: "Integration",
  state: null,
  handleIntegrationClick: () => {},
  handleLogoutClick: () => {},
  contentImageHref: null,
  primaryContentText: null,
};

IntegrationCard.propTypes = {
  headerTitle: PropTypes.string,
  state: PropTypes.oneOf(["active", "pending", "disabled", null]),
  handleIntegrationClick: PropTypes.func,
  handleLogoutClick: PropTypes.func,
  contentImageHref: PropTypes.string,
  primaryContentText: PropTypes.string,
};

export default IntegrationCard;

const Card = styled.div`
  margin: 40px;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  width: 300px;
`;

const HeaderSection = styled.div`
  width: 300px;
  border-radius: 12px 12px 0px 0px;
  padding: 8px 20px;
`;

const HeaderTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  color: #737373;
`;

const FooterSection = styled.div`
  width: 300px;
  height: 31px;
  padding: 8px 20px;
  border-radius: 0px 0px 12px 12px;
`;

const ContentText = styled.div`
  color: #737373;
  lineheight: 16px;
  fontsize: 14px;
  fontweight: normal;
  fontstyle: normal;
  fontfamily: Roboto;
`;

const ContentSection = styled.div`
  width: 300px;
  padding: 8px 20px 8px 20px;
  height: 120px;
  background: #ffffff;
`;
