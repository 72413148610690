import _ from "underscore";
import React, { Component } from "react";
import PropTypes from "prop-types";
import Api from "utils/api";
import ErrorLogger from "utils/error-logger";

const { any, number } = PropTypes;

export const GateContext = React.createContext({});

class GateProvider extends Component {
  static propTypes = {
    user_id: number,
    oid: number,
    children: any,
  };

  static defaultProps = {
    user_id: undefined,
    oid: undefined,
  };

  state = {
    gates: {},
    loading: false,
    error: undefined,
  };

  componentDidMount() {
    this.fetchGates();
  }

  reloadGates = () => {
    this.fetchGates();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.oid !== this.props.oid ||
      prevProps.user_id !== this.props.user_id
    ) {
      this.fetchGates();
    }
  }

  async fetchGates() {
    const { user_id, oid } = this.props;
    if (!_.isFinite(oid)) {
      this.setState({ gates: {}, loading: false, error: "no oid" });
    } else if (!_.isFinite(user_id)) {
      this.setState({ gates: {}, loading: false, error: "no user id" });
    } else {
      try {
        this.setState({ loading: true });
        const resp = await Api.DNA.GATES.get({
          params: { platform: "web", oid, user_id },
        });
        console.log("gates response: ", resp);
        this.setState({
          gates: resp.data.features,
          loading: false,
          error: undefined,
        });
      } catch (error) {
        ErrorLogger.captureRequest("fetchGates error", error);
        this.setState({ gates: {}, loading: false, error });
      }
    }
  }

  render() {
    return (
      <GateContext.Provider value={{...this.state, reload: this.reloadGates}}>
        {this.props.children}
      </GateContext.Provider>
    );
  }
}

export default GateProvider;
