import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Icon } from "@evertrue/et-components";
import { colors } from "../style/colors";

const ErrorPage = ({ text = "" }) => (
  <Wrapper>
    <Content>
      <Icon icon="build" size={3} />
      <h3>Whoops!</h3>
      <div>
        {text ? (
          <p>{text}</p>
        ) : (
          <p>Sorry, something went wrong! Please try again later</p>
        )}
      </div>
    </Content>
  </Wrapper>
);

ErrorPage.propTypes = {
  text: PropTypes.string,
};

const Content = styled.div`
  padding: 20vh 0;
  max-width: 600px;
  line-height: 21px;
  margin: 0 auto;

  h3 {
    margin-top: 16px;
  }

  i {
    color: ${colors.lightText};
  }
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  text-align: center;
`;

export default ErrorPage;
