import styled from "styled-components";
import { colors } from "style/colors";

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TitleWithBackArrow = styled.div`
  display: flex;
  align-items: flex-start;
  flex-basis: 50%;
`;

export const HeaderWithButtons = styled.div`
  width: 100%;
  padding: 24px;
  background-color: ${colors.white};
  border-bottom: solid 1px ${colors.border}
  font-weight: normal;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    font-size: 20px;
    line-height: 24px;
  }
`;

export const Subtitle = styled.div`
  color: ${colors.lightText};
  font-size: 14px;
  margin-top: 4px;
  flex-basis: 100%;
`;

export const ContentWrapper = styled.div`
  overflow: auto;
`;
