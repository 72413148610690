import React from "react";
import PageLayout from "apps/layout/components/page-layout";
import { Redirect, Route, Switch } from "react-router-dom";
import CustomFieldsInteractionsController from "apps/interactions/controllers/interactions-custom-fields-controller";
import CustomTypesController from "apps/custom-types/controllers/custom-types-controller";
import CustomFieldValuesController from "apps/custom-fields/components/custom-field-values-controller";
import * as InteractionService from "./services/interaction-types-service";

const InteractionRouteController = () => (
  <React.Fragment>
    <Switch>
      <Route
        path="/interactions/custom-fields/values/:id?"
        render={({ match: { params }, location }) => {
          const custom_field_id = params.id
            ? parseInt(params.id, 10)
            : undefined;
          return (
            <CustomFieldValuesController
              customField={location.state}
              customFieldId={custom_field_id}
            />
          );
        }}
      />
      <Route
        render={() => (
          <PageLayout
            contentTop="113px" // hack for absolute positioning. couldn't get proper scrolling to work otherwise
            navItems={[
              {
                to: "/interactions/custom-fields",
                label: "Custom Fields",
                key: 1,
              },
              { to: "/interactions/types", label: "Types", key: 2 },
            ]}
            title={"Interactions | Custom Fields"}
            subtitle="Configure data for interactions"
          >
            <Switch>
              <Route
                path="/interactions/custom-fields"
                render={() => <CustomFieldsInteractionsController />}
              />
              <Route
                path="/interactions/types"
                render={() => (
                  <CustomTypesController
                    maxCount={50}
                    fieldName="type"
                    defaultCategories={[{ category: "CONTACT" }]}
                    categories={[
                      {
                        category: "VISIT",
                        display_name: "Visit",
                        description:
                          "Enables this interaction type to count towards the number of visits on the Launchpad visit widget.",
                      },
                    ]}
                    endPoints={{
                      fetch: InteractionService.fetchInteractionTypes,
                      create: InteractionService.createInteractionType,
                      delete: InteractionService.deleteInteractionType,
                      bulkUpdate: InteractionService.bulkUpdateInteractionTypes,
                      fetchImported:
                        InteractionService.fetchImportedInteractionTypes,
                      filterImported:
                        InteractionService.filterImportedInteractionTypes,
                    }}
                  />
                )}
              />
              <Route
                path="/interactions"
                render={() => <Redirect to="/interactions/custom-fields" />}
              />
            </Switch>
          </PageLayout>
        )}
      />
    </Switch>
  </React.Fragment>
);

export default InteractionRouteController;
