import React, { Component } from "react";
import _ from "underscore";
import CDCCardView from "components/cdc/cdc-card-view";
import styled from "styled-components";
import CDCTable from "components/cdc/cdc-table";
import CDCExportController from "components/cdc/cdc-export-controller";
import Api from "utils/api";
import {
  Loading,
  SearchBox,
  BulkSelect,
  AlertActions,
  ActionsMenu,
  Modal2,
} from "@evertrue/et-components";
import PageLayout from "apps/layout/components/page-layout";
import { Subtitle } from "apps/layout/components/page-layout-style";
import {
  ActionBar,
  SearchBoxWrapper,
} from "apps/interactions/controllers/custom-fields-interactions-controller-style";
import { colors } from "style/colors";
import { cheapCloneData, asyncDelay } from "utils/utils";

const LIMIT = 10;

const getQuery = (order, searchText) => {
  return {
    has_child: [
      {
        type: "suggestion",
        query: {
          must: [{ is_approved: { exists: false } }],
        },
      },
    ],
    must: _.compact([
      searchText
        ? {
            contact_name: { wildcard: `${searchText}*` },
            submitter_user_name: { wildcard: `${searchText}*` },
          }
        : null,
    ]),
    sort: [{ created_at: { order } }],
  };
};

class CDCManager extends Component {
  state = {
    loading: false,
    submissions: [],
    activeSubmission: {},
    page: 1,
    total: 1,
    is_date_desc: true,
    search_query: "",
    selectedIds: [],
    bulkSelect: false,
  };

  componentDidMount() {
    this.fetchSubmissions();
    this.debounceSearch = _.debounce(this.handleNameSearch, 300);
  }

  fetchSubmissions = async () => {
    try {
      this.setState({ loading: true });
      const { data } = await Api.SUBMISSIONS.SEARCH.post({
        params: { limit: LIMIT, offset: (this.state.page - 1) * LIMIT },
        data: getQuery(
          this.state.is_date_desc ? "desc" : "asc",
          this.state.search_query
        ),
      });
      this.setState({
        submissions: data.items,
        activeSubmission: data.items[0] || {},
        loading: false,
        total: data.total,
      });
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  approveBulkSubmissions = async () => {
    try {
      this.setState({ loading: true });
      this.setState({ bulkSelect: false });
      await Api.SUBMISSIONS.APPROVE_BULK.post({
        params: { id: this.state.selectedIds },
      });
      // ES has a 1 second delay before changes take effect
      await asyncDelay(1000);
      this.fetchSubmissions();
      this.setState({ selectedIds: [] });
      this.showSuccessToast("approved");
    } catch (error) {
      this.setState({ loading: false });
      this.showErrorToast();
    }
  };

  rejectBulkSubmissions = async () => {
    try {
      this.setState({ loading: true });
      this.setState({ bulkSelect: false });
      await Api.SUBMISSIONS.REJECT_BULK.post({
        params: { id: this.state.selectedIds },
      });
      // ES has a 1 second delay before changes take effect
      await asyncDelay(1000);
      this.fetchSubmissions();
      this.setState({ selectedIds: [] });
      this.showSuccessToast("rejected");
    } catch (error) {
      this.setState({ loading: false });
      this.showErrorToast();
    }
  };

  showSuccessToast = (action) => {
    AlertActions.removeToast(); // Clear existing toast
    AlertActions.launchToast({
      type: "success",
      message: (
        <span>
          Success! Review updates have been {action}
          {action === "approved" ? " and are ready for export" : null}
        </span>
      ),
    });
  };

  showErrorToast = () => {
    AlertActions.removeToast(); // Clear existing toast
    AlertActions.launchToast({
      type: "error",
      message:
        "Sorry, there was an error completing your request. If the problem persists, please contact genius@evertrue.com",
    });
  };

  handlePageChange = async (page) => {
    await this.setState({ page });
    this.fetchSubmissions();
  };

  handleDateSortChange = async () => {
    await this.setState({ is_date_desc: !this.state.is_date_desc, page: 1 });
    this.fetchSubmissions();
  };

  handleNameSearch = async (search_query) => {
    await this.setState({ search_query, page: 1 });
    this.fetchSubmissions();
  };

  handleRowClick = (id) => {
    const activeSubmission = _.findWhere(this.state.submissions, { id });
    this.setState({ activeSubmission });
  };

  handleSelectSubmission = (id, isChecked) => {
    const { selectedIds } = this.state;
    if (isChecked) {
      selectedIds.push(id);
      this.setState({ selectedIds });
    } else {
      this.setState({ selectedIds: _.without(selectedIds, id) });
    }
  };

  handleBulkSelect = (isChecked, submissions) => {
    if (isChecked) {
      this.setState({
        selectedIds: this.state.submissions.map(
          ({ suggestions }) => suggestions[0].id
        ),
      });
      if (submissions.length > 0) {
        this.setState({ bulkSelect: isChecked });
      }
    } else {
      this.setState({ selectedIds: [], bulkSelect: isChecked });
    }
  };

  renderHeader() {
    return (
      <CDCManagerHeaderWrapper>
        <div>
          <h3>Review Contact Data Updates</h3>
          <Subtitle>Approve and reject contact suggestions</Subtitle>
        </div>
        <ActionsMenu
          options={[
            {
              id: 1,
              key: "export",
              label: "Export Approved Suggestions",
              mountOnClick: ({ close, is_open }) => (
                <Modal2 isOpen={is_open} onClose={close}>
                  <CDCExportController close={close} />
                </Modal2>
              ),
              icon: "cloud-download",
            },
          ]}
        />
      </CDCManagerHeaderWrapper>
    );
  }

  renderBulkActions = ({ closePopover }) => {
    return (
      <CDCDataManagerBulkActionWrapper>
        <CDCDataManagerBulkAction
          onClick={() => {
            this.approveBulkSubmissions();
            closePopover();
          }}
        >
          Approve Selected
        </CDCDataManagerBulkAction>
        <CDCDataManagerBulkAction
          onClick={() => {
            this.rejectBulkSubmissions();
            closePopover();
          }}
        >
          Reject Selected
        </CDCDataManagerBulkAction>
      </CDCDataManagerBulkActionWrapper>
    );
  };

  render() {
    const { submissions, selectedIds } = this.state;
    const clonedSubmissions = cheapCloneData(submissions);
    const formattedSubmissions = _.map(clonedSubmissions, (submission = {}) => {
      if (_.contains(selectedIds, submission.suggestions[0].id)) {
        submission.checked = true;
      } else {
        submission.checked = false;
      }
      return submission;
    });

    return (
      <PageLayout header={this.renderHeader()}>
        <ActionBar>
          <CDCDataManagerActionsWrapper>
            <BulkSelect
              onChange={(isChecked) =>
                this.handleBulkSelect(isChecked, submissions)
              }
              checked={!!selectedIds.length}
              partial={selectedIds.length < submissions.length}
              actions={this.renderBulkActions}
            />
            <SearchBoxWrapper>
              <SearchBox
                placeholder="Search By Name..."
                onChange={this.debounceSearch}
              />
            </SearchBoxWrapper>
          </CDCDataManagerActionsWrapper>
        </ActionBar>
        {this.state.loading ? (
          <Loading />
        ) : (
          <CDCDataManager>
            <CDCTable
              submissions={formattedSubmissions}
              page={this.state.page}
              total={this.state.total}
              onPageChange={this.handlePageChange}
              is_date_desc={this.state.is_date_desc}
              onDateSortChange={this.handleDateSortChange}
              active_row_id={this.state.activeSubmission.id}
              onRowClick={this.handleRowClick}
              onSelect={this.handleSelectSubmission}
            />
            {!_.isEmpty(this.state.activeSubmission) ? (
              <CDCCardView
                submission={this.state.activeSubmission}
                onAction={this.fetchSubmissions}
              />
            ) : null}
          </CDCDataManager>
        )}
      </PageLayout>
    );
  }
}

const CDCDataManager = styled.div`
  overflow-y: hidden;
  display: flex;
`;
const CDCManagerHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const CDCDataManagerActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 440px;
`;
const CDCDataManagerBulkActionWrapper = styled.div`
  width: 256px;
  padding: 8px 32px;
`;
const CDCDataManagerBulkAction = styled.div`
  color: ${colors.linkText};
  padding: 16px 0;
  cursor: pointer;
`;

export default CDCManager;
