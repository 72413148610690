import React from "react";
import _ from "underscore";
import { AuthContext } from "scripts/auth-connector";

// subscribes to the AuthContext.Provider rendered at top level of the app

const UserHasRole = ({ roles, children }) => (
  <AuthContext.Consumer>
    {({ user_roles, super_user }) => {
      //If a super user, don't worry about roles, just always return the thing
      if (super_user) {
        return children;
      } else {
        const roleNames = _.pluck(user_roles, "name");
        const hasRoles = _.any(roles, (role) => _.contains(roleNames, role));
        return hasRoles ? children : null;
      }
    }}
  </AuthContext.Consumer>
);

export default UserHasRole;
