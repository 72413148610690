import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "@evertrue/et-components";
import styled from "styled-components";
import PropTypes from "prop-types";
import { colors } from "../../../style/colors";

const SideNavItem = ({ icon, to, children, isActive, iconKit = "gt" }) => (
  <NavItem to={to} activeClassName="active" isActive={isActive}>
    <Icon size={1} icon={icon} style={{ width: "1em" }} iconKit={iconKit} />{" "}
    {children}
  </NavItem>
);

SideNavItem.propTypes = {
  icon: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isActive: PropTypes.func,
  iconKit: PropTypes.string,
};

SideNavItem.defaultProps = {
  isActive: () => null,
  iconKit: "gt",
};

const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  color: ${colors.white};
  height: 32px;
  padding: 6px 20px;

  i {
    color: hsla(0, 0%, 100%, 0.5);
    margin-right: 12px;
  }

  &:hover {
    background-color: #2d3b40;
    color: #fff;
  }

  &.active {
    box-shadow: 0 0px 0 ${colors.success} inset, 4px 0 0 ${colors.success} inset;
    background-color: #2d3b40;
    color: #fff;

    i {
      color: ${colors.success};
    }
  }
`;

export default SideNavItem;
