import React from "react";
import styled from "styled-components";
import { Icon, Link } from "@evertrue/et-components";
import env from "utils/env";

const WrapperLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: solid 1px #37484f;
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  color: white;
`;

const ReturnText = styled.span`
  color: white;
  font-size: 14px;
  padding-left: 8px;
  &:hover {
    text-decoration: underline;
  }
`;

const BackToCore = () => {
  const coreLink = env.isStageData()
    ? "https://stage-app.evertrue.com"
    : "https://app.evertrue.com";

  return (
    <WrapperLink
      href={coreLink}
      target="_BLANK"
      title="To EverTrue App"
      data-bypass={true}
    >
      <StyledIcon icon="evertrue-circle" size={1} />
      <ReturnText>Go To EverTrue App</ReturnText>
    </WrapperLink>
  );
};

export default BackToCore;
