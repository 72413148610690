import useHeaders from "apps/importer/hooks/use-headers";

import { formatFieldName, formatNamingID } from "apps/importer/components/import-mapping/utils";

export const useDragAndDrop = ({
  schema,
  excludedImportColumns,
  unmappedImportColumns,
  jobId,
  onDragEndCallback,
}) => {
  const { headerColumns } = useHeaders(jobId);

  const createDroppables = () => {
    if (schema) {
      const mappedData = schema.reduce(
        (previousValue, section, sectionIndex) => {
          const reduceSchema = section.schema.reduce(
            (previousValue, item, itemIndex) => {
              const itemAttr = item.import_column ? [item.import_column] : [];
              const formattedFieldName = formatFieldName(item.field_name);
              const namingID = formatNamingID(item.section_id, formattedFieldName);
              return {
                ...previousValue,
                [namingID]: {
                  id: namingID,
                  callbackArgs: {
                    field: item,
                    sectionIndex,
                    itemIndex,
                  },
                  attributeKeys: itemAttr,
                  field_name: namingID,
                  ...item,
                },
              };
            },
            {}
          );

          return { ...previousValue, ...reduceSchema };
        },
        {}
      );
      return mappedData;
    }
    return {};
  };

  const createDraggableItems = () => {
    if (headerColumns) {
      return headerColumns.reduce(
        (o, key) => ({
          ...o,
          [key.import_column]: {
            id: key.import_column,
            attribute_label: key.import_column,
          },
        }),
        {}
      );
    }

    return {};
  };

  const draggableItems = createDraggableItems();
  const droppableAreaList = {
    "unmapped-attributes": {
      id: "unmapped-attributes",
      attributeKeys: unmappedImportColumns || [],
    },
    "explicit-skip-attributes": {
      id: "explicit-skip-attributes",
      attributeKeys: excludedImportColumns || [],
    },
    ...createDroppables(),
  };

  const { attributeKeys: excludedItemAttrs } =
    droppableAreaList["explicit-skip-attributes"];
  const { attributeKeys: unmappedItemAttrs } =
    droppableAreaList["unmapped-attributes"];
  const unmappedItems =
    draggableItems &&
    unmappedItemAttrs &&
    unmappedItemAttrs.map((taskId) => draggableItems[taskId]);
  const excludedItems =
    draggableItems &&
    excludedItemAttrs &&
    excludedItemAttrs.map((taskId) => draggableItems[taskId]);

  // Make sure that we actually need to call the schema handler, scrub the data prior to asking for a state update with onDragEndCallback.
  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;
    const finish = destination && droppableAreaList[destination.droppableId];
    const start = droppableAreaList[source.droppableId];

    // If item is dropped out of a droppable area.
    if (!destination) {
      return;
    }
    //If item is dropped in the same droppable area in the same place.
    if (start === finish && destination.index === source.index) {
      return;
    }

    onDragEndCallback({
      destination: finish,
      source: start,
      importColumn: draggableId,
    });
    return;
  };

  return {
    draggableItems: createDraggableItems(),
    fieldColumns: droppableAreaList,
    unmappedItems,
    excludedItems,
    onDragEnd,
  };
};

export default useDragAndDrop;
