import React from "react";
import styled from "styled-components";
import { Icon, Popover2, OutsideClick, Link } from "@evertrue/et-components";
import { colors, font } from "style/colors";

const HelpIcon = styled(Icon)`
  font-size: 1.8em;
`;

const DropDownButton = styled.span`
  display: flex;
  color: ${colors.white};
  background-color: ${(props) => (props.open ? "#4c899a" : "transparent")};
  border: none;
  height: 100%;
  cursor: default;
  padding: 12px;
  align-items: center;

  &:hover {
    background-color: #4c899a;
  }
`;

const DropdownBody = styled(OutsideClick)`
  display: block;
  background: ${colors.white};
  font-size: ${font.baseFontSize};
  font-weight: normal;
  line-height: 21px;
`;

const HelpItem = styled.ul`
  padding: 8px 24px;
  width: 100%;
  font-family: Roboto;
  color: #137fac;
  &:hover {
    background: ${colors.offSet};
  }
`;

const StyledIcon = styled(Icon)`
  padding-right: 30px;
`;

const HelpHeaderButton = () => {
  return (
    <Popover2
      contentWidth={200}
      trigger={({ togglePopover, is_open }) => (
        <DropDownButton lightText onClick={togglePopover} open={is_open}>
          <HelpIcon icon="help" size={1} />
        </DropDownButton>
      )}
    >
      {({ closePopover }) => (
        <DropdownBody>
          <Link
            href="https://evertrue.forumbee.com/"
            title="EverTrue Help Article"
            target="_BLANK"
            data-bypass={true}
          >
            <HelpItem>
              <StyledIcon icon="resources" size={1} />
              Help Articles
            </HelpItem>
          </Link>
          <Link
            title="Link toEverTrue Customer Exchange"
            target="_BLANK"
            onClick={() => {
              window.location.href = "mailto:genius@evertrue.com";
            }}
            data-bypass={true}
          >
            <HelpItem>
              <StyledIcon icon="chat" size={1} />
              EverTrue Support
            </HelpItem>
          </Link>
          <Link
            href="https://www.evertrue.com/customer-community/"
            title="Link toEverTrue Customer Exchange"
            target="_BLANK"
            data-bypass={true}
          >
            <HelpItem>
              <StyledIcon icon="community" size={1} />
              EverTrue Exchange
            </HelpItem>
          </Link>
        </DropdownBody>
      )}
    </Popover2>
  );
};

export default HelpHeaderButton;
