import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Api from "utils/api";
import moment from "moment";
import {
  SectionHeader,
  BoldText,
  ProcessContainer,
} from "apps/importer/common/importer-styled-components";
import { ImportProgressBar } from "apps/importer/common/components/import-progress-bar";
import { Flex, Button, LinkButton, useOpenClose, Icon } from "@evertrue/et-components";
import ImportStatsTable from "apps/importer/components/import-preprocess/import-stats-table";
import { InlineLoading } from "apps/importer/common/components/inline-loading";
import { launchToast } from "utils/show-toast";
import { ProcessMessage } from "apps/importer/components/import-preprocess/process-message";

import { getLogFile } from "apps/importer/get-log-file";
import {
  STATUS,
  getProcessState,
  WORKER_TYPE,
  TRANSCODE,
} from "apps/importer/import-helper";

import UserTimestamp from "components/user-timestamp";

import { usePollingResults } from "apps/importer/hooks/use-polling-results";

import CancelImportModal from "apps/importer/components/import-preprocess/cancel-import/cancel-import-modal.js";
import PreprocessErrors from "scripts/apps/importer/components/import-preprocess/preprocessing/preprocess-error-messages.js";

const PreprocessStats = ({ results, deleteFilename, jobId, getLogFile, importCancelled, auditFilename }) => {
  return (
    <div>
      {!importCancelled ? <ProcessMessage
        status={results.status}
        messageText={results.status_display_name}
      /> : <ProcessMessage
        status={results.status}
        messageText={"Import Cancelled"}
        importCancelled={true}
      />}

      {results.values && results.values[0] && <PreprocessErrors stats={results.values} />}
      {results.values && <ImportStatsTable stats={results.values} />}

      <TableDiv>
        <Flex
          justifyContent={deleteFilename ? "space-between" : "flex-end"}
          flexDirection="row"
        >
          {deleteFilename ? (
            <AuditLogLinkLeft
              onClick={() =>
                getLogFile(
                  "delete_log",
                  deleteFilename.s3_filename,
                  TRANSCODE,
                  jobId
                )
              }
            >
              Download Pending Deletions
            </AuditLogLinkLeft>
          ) : null}
          <AuditLogLink
            onClick={() =>
              getLogFile(
                "audit_log",
                auditFilename.s3_filename,
                TRANSCODE,
                jobId
              )
            }
          >
            View Results
          </AuditLogLink>
        </Flex>
      </TableDiv>
    </div>
  )
}

export const PreprocessContainer = ({
  history,
  jobId,
  setHasUploadStarted,
  hasUploadStarted,
}) => {
  const [importStartTime, setImportStartTime] = useState(null);
  const [isOpen, openModal, closeModal] = useOpenClose();

  const { jobData, results, status } = usePollingResults({
    jobId,
    workerType: WORKER_TYPE.TRANSCODE,
    history,
  });

  const currentProcessState = getProcessState(TRANSCODE, jobData.status);

  const shouldDisallowStartImport =
    hasUploadStarted ||
    currentProcessState === STATUS.RUNNING ||
    currentProcessState === STATUS.FAILED ||
    currentProcessState === STATUS.CANCELLED;

  const shouldDisallowCancel =
    hasUploadStarted || currentProcessState === STATUS.CANCELLED;

  const handleUploadProcessStart = async () => {
    try {
      await Api.IMPORT.IMPORT_JOB_START.post({
        urlArgs: { job_id: jobId },
      });
      setImportStartTime(moment().format("h:mm:ss a [at] MM/DD/YYYY"));
      setHasUploadStarted(true);
    } catch (error) {
      launchToast({
        message: "Server POST error on Import Job Start for job: " + jobId,
        type: "error",
      });
      console.log("Server POST error on Import Job Start for job: " + jobId);
    }
  };

  const { additional_files } = jobData;

  const deleteFilename =
    additional_files &&
    additional_files.transcode.find(({ type }) => type === "DELETE_LOG");
  const auditFilename =
    additional_files &&
    additional_files.transcode.find(({ type }) => type === "AUDIT_LOG");

  const cancelImport = async () => {
    try {
      await Api.IMPORT.IMPORT_JOB_CANCEL.post({
        urlArgs: { job_id: jobId },
      });
    } catch (error) {
      launchToast({
        message: `Unable to cancel import for job:${jobId}`,
        type: "error",
      });
    }
    history.push("/importer");
  };

  return (
    <div>
      <StyledProcessContainer>
        <SectionHeader>
          <BoldText>Pre-Processing</BoldText>
          <div>Preparing your import</div>
        </SectionHeader>
        <ImportProgressBar percent={results.percentage_complete || 0} />
        {currentProcessState === STATUS.CANCELLED ? (
          <PreprocessStats
            results={results}
            deleteFilename={deleteFilename}
            jobId={jobId}
            getLogFile={getLogFile}
            importCancelled={true}  
            auditFilename={auditFilename}
          />
        ) : status === STATUS.RUNNING ? (
          <InlineLoading text={"Preprocessing Import"} />
        ) : (
          <PreprocessStats
            results={results}
            deleteFilename={deleteFilename}
            jobId={jobId}
            getLogFile={getLogFile}
            importCancelled={false}
            auditFilename={auditFilename}
          />
        )}
        <ButtonGroup>
          <Button
            type="secondary"
            onClick={isOpen ? closeModal : openModal}
            disabled={shouldDisallowCancel}
          >
            Cancel Import
          </Button>

          <Button
            onClick={handleUploadProcessStart}
            disabled={shouldDisallowStartImport}
          >
            Start Import
          </Button>
        </ButtonGroup>
      </StyledProcessContainer>
      {importStartTime && <UserTimestamp importStartTime={importStartTime} />}
      <CancelImportModal
        isOpen={isOpen}
        closeModal={closeModal}
        cancelImport={cancelImport}
      ></CancelImportModal>
    </div>
  );
};

const StyledProcessContainer = styled(ProcessContainer)`
  justify-content: flex-start;
  height: auto;
  min-height: 720px;
  position: relative;
`;

const AuditLogLink = styled(LinkButton)`
  display: flex;
  margin-top: 8px;
  justify-content: flex-end;
  align-self: start;
`;

const AuditLogLinkLeft = styled(AuditLogLink)`
  justify-content: flex-start;
  margin-right: 0px;
`;

export const DeletionConfirm = styled.div`
  line-height: 24px;
  font-size: 16px;
`;

export const TableDiv = styled.div`
  margin-left: 5%;
  margin-right: 5%;
`;

export const SubTitleSection = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const ButtonGroup = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: space-around;
`;


PreprocessContainer.propTypes = {
  history: PropTypes.object.isRequired,
  jobId: PropTypes.number.isRequired,
  setHasUploadStarted: PropTypes.func.isRequired,
  hasUploadStarted: PropTypes.bool.isRequired,
};

PreprocessContainer.defaultProps = {};

export default PreprocessContainer;
