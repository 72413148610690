import React, { Component } from "react";
import _ from "underscore";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  AlertActions,
  Icon,
  ModalTriggerDeprecated,
  Portal,
} from "@evertrue/et-components";
import Module from "components/module";

import ColumnConfigEditor from "components/column-config-editor/column-config-editor";
import * as CustomFieldService from "apps/interactions/interaction-custom-fields-service";
import { asyncDelay } from "utils/utils";
import {
  Unsaved,
  Wrapper,
} from "apps/custom-fields/components/custom-field-values-controller-style";
import PageLayout from "apps/layout/components/page-layout";

import CustomFieldValuesModal from "apps/custom-fields/components/custom-field-values-modal";
import { CUSTOM_FIELD_VALUES_LIMIT } from "apps/custom-fields/custom-fields-utils";
import { TopRowWrapper } from "components/column-config-editor/column-config-editor-style";
import CustomImportedTypeModal from "apps/custom-types/components/custom-imported-type-modal";

function filterActive(status, item) {
  return status ? item.active : !item.active;
}

const inactive = _.partial(filterActive, false);
const active = _.partial(filterActive, true);
const KEYS = ["sort_order", "active", "value"];

const diff_with_keys = _.partial(getDiffedItems, KEYS);

const Modal = (props) => (
  <CustomFieldValuesModal
    header="Add a New Custom Field Value"
    width={500}
    label="Custom Field Value"
    disableFunc={(data) => !data.value}
    {...props}
  />
);

function getDiffedItems(keys, prevItems = [], current = []) {
  const prevItemsMap = _.indexBy(prevItems, "id");

  return current.filter((item) => {
    const original = prevItemsMap[item.id] || {};
    return keys.some((key) => original[key] !== item[key]);
  });
}

function showSuccessToast() {
  AlertActions.launchToast({
    type: "success",
    message: "Value saved",
    timeout: 3000,
  });
}

class CustomFieldValuesController extends Component {
  static propTypes = {
    customField: PropTypes.object,
  };

  static defaultProps = {
    customField: {},
  };

  state = {
    orig_values: [],
    values: [],
    item_to_edit: {},
    importedValues: [],
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const { data } = await CustomFieldService.fetchCustomFieldValues(
      this.props.customField.id
    );
    const { data: importedValues } =
      await CustomFieldService.fetchImportedCustomFields(
        this.props.customField.esField
      );

    const filteredImportedValues = importedValues.facets[
      `${this.props.customField.esField}.untouched`
    ]
      .filter((val) => !data.items.find((item) => item.value === val.value))
      .map((item) => item.value);

    this.setState({
      values: data.items,
      orig_values: data.items,
      importedValues: filteredImportedValues,
    });
  };

  getActiveItems = () => this.state.values.filter(active);

  handleChange = ({ listOne, listTwo }) => {
    this.setState({ values: [...listOne, ...listTwo] });
  };

  handleAddValue = async (value) => {
    const place = this.getActiveItems().length + 2;
    await CustomFieldService.createCustomFieldValue(this.props.customField.id, {
      ...value,
      sort_order: place,
    });

    showSuccessToast();
    this.fetchData();
  };

  handleSave = async () => {
    const itemsHaveChanged = diff_with_keys(
      this.state.orig_values,
      this.state.values
    );
    if (itemsHaveChanged.length) {
      try {
        await Promise.all(
          itemsHaveChanged.map((item = {}) =>
            CustomFieldService.updateCustomFieldValues(
              this.props.customField.id,
              item.id,
              item
            )
          )
        );
        // The endpoint isnt returning updated data right away
        await asyncDelay(500);

        this.fetchData();
        AlertActions.launchToast({
          type: "success",
          message: "Values saved",
          timeout: 3000,
        });
      } catch (error) {
        AlertActions.launchToast({
          type: "error",
          message: "There was an error saving your interaction types",
        });
      }
    }
  };

  handleEditItem = (item_to_edit) => this.setState({ item_to_edit });

  handleUpdateValue = (data) => {
    const active_items = this.getActiveItems();
    // if a modal changes it from inactive to active, add to end of list w/ sort_order
    const existing = this.state.values.find((type) => type.id === data.id);

    const updated_value =
      !existing.active && data.active
        ? { ...data, sort_order: active_items.length + 2 }
        : data;

    this.setState((state) => ({
      values: state.values.map((item) =>
        item.id === data.id ? updated_value : item
      ),
    }));
  };

  handleCancel = () => {
    this.setState({ values: this.state.orig_values });
  };

  handleDeleteItem = async (item) => {
    await CustomFieldService.deleteCustomFieldValue(
      this.props.customField.id,
      item.id
    );
    this.fetchData();
    AlertActions.launchToast({
      type: "success",
      message: "Successfully deleted",
    });
  };

  render() {
    const values = _.sortBy(this.state.values, "sort_order");
    const are_unsaved_changes = Boolean(
      diff_with_keys(this.state.values, this.state.orig_values).length
    );
    const has_max_values = this.state.values >= CUSTOM_FIELD_VALUES_LIMIT;
    return (
      <React.Fragment>
        <PageLayout
          title={
            <React.Fragment>
              <Link to="/interactions/custom-fields">
                <Icon icon="chevron-left" />
              </Link>
              {this.props.customField.displayName}
            </React.Fragment>
          }
        >
          <Wrapper>
            <Module>
              <TopRowWrapper>
                <ModalTriggerDeprecated
                  buttonType="default"
                  disable={has_max_values}
                  modal={
                    <Modal
                      data={{ value: "", active: true }}
                      handleSubmit={this.handleAddValue}
                    />
                  }
                >
                  New Value
                </ModalTriggerDeprecated>
                {are_unsaved_changes && <Unsaved>Unsaved Changes</Unsaved>}
                <ModalTriggerDeprecated
                  buttonType="default"
                  disable={has_max_values}
                  modal={
                    <CustomImportedTypeModal
                      types={this.state.importedValues}
                      handleCreate={async ({ type, ...data }) => {
                        await CustomFieldService.createCustomFieldValue(
                          this.props.customField.id,
                          {
                            ...data,
                            value: type,
                            active: true,
                            sort_order: values.filter(active).length + 1,
                          }
                        );
                        showSuccessToast();
                      }}
                      afterClose={this.fetchData}
                    />
                  }
                >
                  Manage Imported Fields
                </ModalTriggerDeprecated>
              </TopRowWrapper>
              <ColumnConfigEditor
                handleSave={this.handleSave}
                listOne={{
                  label: "Inactive Value",
                  items: values.filter(inactive),
                }}
                listTwo={{
                  label: "Active Value",
                  items: values.filter(active),
                }}
                searchKey="value"
                onEdit={this.handleEditItem}
                onDelete={this.handleDeleteItem}
                disableSave={!are_unsaved_changes}
                disableCancel={!are_unsaved_changes}
                onChange={this.handleChange}
                handleCancel={this.handleCancel}
              />
            </Module>
          </Wrapper>

          {!_.isEmpty(this.state.item_to_edit) && (
            <Portal>
              <Modal
                onClose={() => this.handleEditItem({})}
                disableFunc={(data) =>
                  !data.value && !_.isEqual(data, this.state.item_to_edit)
                }
                onUnmount={() => this.handleEditItem({})}
                data={this.state.item_to_edit}
                handleSubmit={this.handleUpdateValue}
              />
            </Portal>
          )}
        </PageLayout>
      </React.Fragment>
    );
  }
}

export default CustomFieldValuesController;
