import Api from "utils/api";

export const fetchSolicitorTitles = () => Api.PROPOSAL.SOLICITOR_TITLES.get();

export const createSolicitorTitle = (data) =>
  Api.PROPOSAL.SOLICITOR_TITLES.post({
    data,
  });

export const deleteSolicitorTitle = (data) =>
  Api.PROPOSAL.SOLICITOR_TITLES.delete({
    urlExtend: `/${data.id}`,
  });

export const bulkUpdateSolicitorTitles = (data) =>
  Api.PROPOSAL.SOLICITOR_TITLES.put({
    urlExtend: "/bulk",
    data: data,
  });

export const fetchImportedSolicitorTitles = () =>
  Api.PROPOSAL.IMPORTED_SOLICITOR_TITLES.get();

export const filterImportedSolicitorTitles = (importedTitles, titles) => {
  const filtered = importedTitles.filter(
    (item) => !titles.find((title) => title.title === item)
  );
  return filtered;
};
