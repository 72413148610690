import styled from "styled-components";
import { colors } from "style/colors";
import { NavLink } from "react-router-dom";

export const Nav = styled.ul`
  width: 100%;
  background: ${colors.white};
  padding: 12px 30px 0;
  position: ${(props) => props.position || "static"};
  top: ${(props) => props.top || 0};
  border-bottom: 1px solid ${colors.border};
`;

export const NavItem = styled(NavLink)`
  display: inline-block;
  margin-right: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.8em;
  padding-bottom: 8px;
  color: #6a909a;

  padding-bottom: 10px;
  font-weight: bold;

  &.active {
    color: ${colors.accentLight};
    border-bottom: solid 3px ${colors.accentLight};
  }

  &:hover {
    color: ${colors.accentDark};
  }
`;
