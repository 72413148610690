import React from "react";
import _ from "underscore";

const CDCAddressBlock = ({ address }) => {
  return (
    <React.Fragment>
      <div>{address.address_1 || ""}</div>
      <div>{address.address_2 || ""}</div>
      <div>{address.address_3 || ""}</div>
      <div>
        {_.compact([address.city, address.state, address.zip_code]).join(" ")}
      </div>
      <div>{address.country || ""}</div>
    </React.Fragment>
  );
};

export default CDCAddressBlock;
